import React, { useState } from 'react';
import { object, string, mixed } from 'yup';
import propTypes from 'prop-types';

import Review from '../ReviewPage/Review';
import { FormStepper } from '../FormStepper';
import { BasicDetails } from '../Steps/BasicDetails';
import { PanelDetails } from '../Steps/PanelDetails';
import { QuestionDetails } from '../Steps/QuestionDetails';
import SocialPageSetup from '../Steps/SocialPageSetup';

export default function SocialMediaStepper({
  onAddNewStudySubmit,
  environmentData,
  locationTypes,
  languageTypes,
  questionTypes,
  brandTypes,
  respondentTypes,
  defaultValues,
  basicDetailsValidationSchema,
  panelDetailsValidationSchema,
  questionDetailsValidationSchema,
  history,
}) {
  const [stepImperatively, setStepImperatively] = useState(null);
  return (
    <FormStepper
      stepImperatively={stepImperatively}
      setStepImperatively={setStepImperatively}
      initialValues={{
        ...defaultValues,
        is_new_social_system: false,
        panel_seconds_before_move_next: -1,
        study_type_id: 5,
        incontext_site: {code: 'fb', description: 'Facebook'},
        ad_type: 'standard',
        stim_url: '',
        brand_id: null,
        fb_channel_name: '',
        fb_profile_src: '',
        fb_description: '',
        fb_promoted_button_text: 'Learn more',
        promoted: true,
        has_sound: false,
        fb_promoted_description: '',
        fb_promoted_description2: '',
        fb_promoted_domain: '',
        fb_call_to_action: '',
        in_channel_name: '',
        in_call_to_action: 'Learn more',
        in_description: '',
        in_profile_src: '',
        in_promoted_button_text: 'Learn more',
        sc_channel_name: '',
        sc_description: '',
        sc_promo_text: '',
        sc_promo_desc: '',
        sc_promo_top: '',
        sc_promo_rating: 0,
        sc_cta: '',
      }}
      onSubmit={async (values) => {
        await onAddNewStudySubmit(values, history);
      }}
    >
      <BasicDetails
        label='Basic Details'
        title='Tell us about your study'
        validationSchema={basicDetailsValidationSchema}
        {...{environmentData, locationTypes, languageTypes, respondentTypes}}
      />
      <QuestionDetails
        validationSchema={questionDetailsValidationSchema}
        title='What surveys will you use?'
        label='Question Details'
        {...{questionTypes}}
      />
      <PanelDetails
        title='Tell us about your recruitment'
        label='Panel Details'
        validationSchema={panelDetailsValidationSchema}
      />
      <SocialPageSetup
        validationSchema={object().shape({
          incontext_site: object({
            code: mixed().required('Required'),
          }),
          ad_type: string().required('Required, please choose an ad'),
        })}
        title={`Great! Now let's create your ad`}
        label='Social Page Setup'
        {...{brandTypes}}
      />
      <Review
        title='Your project is ready for review'
        label='Review'
        setStepImperatively={setStepImperatively}
        labels={[
          'Basic Details',
          'Question Details',
          'Panel Details',
          'Social Page Setup',
        ]}
      />
    </FormStepper>
  );
}

SocialMediaStepper.propTypes = {
  onAddNewStudySubmit: propTypes.func,
  languageTypes: propTypes.object,
  environmentData: propTypes.object,
  locationTypes: propTypes.object,
  mediaData: propTypes.object,
  questionTypes: propTypes.object,
  studyTypes: propTypes.object,
  brandTypes: propTypes.object,
  respondentTypes: propTypes.object,
  defaultValues: propTypes.object,
  basicDetailsValidationSchema: propTypes.object,
  panelDetailsValidationSchema: propTypes.object,
  questionDetailsValidationSchema: propTypes.object,
  history: propTypes.object,
};
