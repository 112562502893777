import Creators from './actions';
import { attentionReportOperations } from '../../AttentionReport/duck/index';
import { kpiOperations } from '../../Kpi/duck/index';
import { featureAnalysisOperations } from '../../FeatureAnalysis/duck/index';
import DataService from '../../common/services/dataService';


const fetchStimSuccess = Creators.fetchStimSuccess
const fetchStudiesSuccess = Creators.fetchStudiesSuccess
const fetchStimError = Creators.fetchStimError
const fetchStimBegin = Creators.fetchStimBegin;
const clearCheckedStudies = Creators.clearCheckedStudies
const removeAllinStepRow = Creators.removeAllinStepRow
const checkAllinStepRow = Creators.checkAllinStepRow
const removeStudy = Creators.removeStudy
const addStudy = Creators.addStudy
const addStudiesObjectFormat = Creators.addStudiesObjectFormat
const addStudiesStringFormat = Creators.addStudiesStringFormat
const setReportType = Creators.setReportType
const setInitialLoadBegin = Creators.setInitialLoadBegin;
const setInitialLoadFinish = Creators.setInitialLoadFinish;
const setInitialLoadError = Creators.setInitialLoadError;
const setInitialLoadReset = Creators.setInitialLoadReset;
const fetchStudySpecificStimsBegin = Creators.fetchStudySpecificStimsBegin
const fetchStudySpecificStimsError = Creators.fetchStudySpecificStimsError
const fetchStudySpecificStimsSuccess = Creators.fetchStudySpecificStimsSuccess;




const checkAllinStep = ({ index, studyId, cell_id, history }) => {

  // return (dispatch, getState) => {
  //   const isolationDataState = getState().isolationData.data.data;
  //   const stimsInStep = isolationDataState.map(item => item.step_list[index].stimulus_list).reduce(
  //     (accumulator, currentValue) => [...accumulator, ...currentValue],
  //     []
  //   );

  //   const currentArray = (getState().isolationData.whichStudiesAreChecked);
  //   const stepId = isolationDataState[0].step_list[index].step_id

  //   stimsInStep.map(stimulus => `${cell_id}:${stepId}:${studyId}:${stimulus.stimulus_id}`)
  //     .forEach(item => {
  //       if (!currentArray.includes(item)) {
  //         dispatch(addStudy(item))
  //         dispatch(checkAllinStepRow())
  //       } else {
  //         dispatch(removeStudy(item));
  //         dispatch(removeAllinStepRow())
  //       }
  //     })

  //   dispatch(attentionReportOperations.getAttentionReportData(getState().isolationData.whichStudiesAreChecked))
  //   history.push(`/my_studies/overview/${studyId}/study/attention_report`)

  // }

}

const fetchStudy = (payload) => {
  let payloadId = payload.id;
  return async (dispatch) => {
    try {
      dispatch(fetchStimBegin());
      const response = await DataService.fetchStudies(payloadId)
      const res = await DataService.fetchStimData(payloadId)

      // axios.post(`${URL}/studies/${payloadId}/overview`, getHeaders());
      dispatch(fetchStimSuccess(res.data))
      dispatch(fetchStudiesSuccess(response.data))
    }
    catch (error) {
      console.log(error)
      throw error

    }
  }
}

const fetchStudySpecificStims = (id) => {
  return async (dispatch) => {
    dispatch(fetchStudySpecificStimsBegin());
    try {
      const res = await DataService.fetchStudySpecificStims(id)
      dispatch(fetchStudySpecificStimsSuccess(res.data))
    }
    catch (error) {
      console.log(error)
      dispatch(fetchStudySpecificStimsError());
      throw error
    }
  }
}

const postEndLiveStudy = (id) => {
  return async (dispatch) => {
    try {
      dispatch(fetchStimBegin());
      await DataService.postEndLiveStudy(id);
      const response = await DataService.fetchStudies(id)
      dispatch(fetchStudiesSuccess(response.data))
    }
    catch (error) {
      console.log(error)
      throw error
    }
  }
}

const setNewReportCriteria = (checked, studyId, reportType) => {
  return async (dispatch) => {
     dispatch(setInitialLoadBegin({"checked": checked, "studyId": studyId, "reportType": reportType}))
     let selectedStudiesStringFormat = []
      let selectedStudiesObjectFormat = []
      checked.forEach(stim => {
        let stimDetails = stim.split(" ")
        let cell_id = stimDetails[0]
        let step_id = stimDetails[1]
       let stim_id = stimDetails[2]
       selectedStudiesStringFormat.push(`${cell_id}:${step_id}:${studyId}:${stim_id}`) 
       selectedStudiesObjectFormat.push(({
          cellId: parseInt(cell_id),
          stepId: parseInt(step_id),
          studyId: parseInt(studyId),
          stimId: parseInt(stim_id)
        }))
      })
      // localStorage.setItem("attentionReportData", JSON.stringify(selectedStudiesStringFormat));
      dispatch(addStudiesStringFormat(selectedStudiesStringFormat))
      dispatch(addStudiesObjectFormat(selectedStudiesObjectFormat))
      dispatch(setReportType(reportType))
      try{
      if (reportType === "compare"){
       await dispatch(attentionReportOperations.getAttentionReportData(selectedStudiesStringFormat))
      }
      else if (reportType === "kpi"){
        dispatch(kpiOperations.fetchKpiBegin());
          for (let i = 0; i<selectedStudiesObjectFormat.length; i++){
            await dispatch(kpiOperations.fetchKpiData(selectedStudiesObjectFormat[i]))
            await dispatch(kpiOperations.fetchKpiGraphData(i))
        }
        dispatch(kpiOperations.setKpiDataLoaded());
      }
      else if (reportType === "feature"){
        dispatch(featureAnalysisOperations.fetchFeatureBegin());
        // dispatch(featureAnalysisOperations.fetchParticipantReportBegin());
        for (let i = 0; i<selectedStudiesObjectFormat.length; i++){
          await dispatch(featureAnalysisOperations.fetchFeatureData(selectedStudiesObjectFormat[i]))
        }
        dispatch(featureAnalysisOperations.fetchAllFeatureSuccess());
      }

      dispatch(setInitialLoadFinish())
    }
    catch (error) {
      dispatch(setInitialLoadError())
    }

    }
}

const addOrRemoveStudy = (step, cell_id, studyId, stimId) => {
  // cellId:stepId:studyId
  // This is the order
  // let key = `cell_Id${cell_id}:step_Id${step.step_id}:study_id${studyId}:stim_id${stimId}`

  let key = `${cell_id}:${step.step_id}:${studyId}:${stimId}`

  return (dispatch, getState) => {
    let currentArray = (getState().isolationData.whichStudiesAreChecked);
    try{
      if (!currentArray.includes(key)) {
        dispatch(addStudy(key))
      } else {
        dispatch(removeStudy(key));
      }
    }
    catch (error) {
      console.log(error)
    }
  };
}

const goToAttentionReportPage = (whichStudiesAreChecked, { history, match }) => {

  const id = match.params.id;
  return (dispatch) => {

    dispatch(attentionReportOperations.getAttentionReportData(whichStudiesAreChecked))
    history.push(`/my_studies/overview/${id}/study/attention_report`)
  }
}

const compareAllStudies = (isolationData, { history, match }) => {
  // const allStims = isolationData.data.map((item, index) => {
  //   return item.step_list.map((data, i) => {
  //     return data.stimulus_list.map((item1, idx) => {
  //       return `${item.cell_id}:${data.step_id}:${isolationData.study_id}:${item1.stimulus_id}`

  //     })
  //   })
  // }).reduce((acc, curr) => {
  //   acc = acc.concat(curr)
  //   return acc
  // }, []).reduce((acc, curr) => {
  //   acc = acc.concat(curr)
  //   return acc
  // }, [])

  // return (dispatch, getState) => {
  //   const currentArray = (getState().isolationData.whichStudiesAreChecked);
  //   const studyId = isolationData.study_id
  //   dispatch(attentionReportOperations.getAttentionReportData(allStims));
  //   allStims.forEach(item => {
  //     if (!currentArray.includes(item)) {
  //       dispatch(addStudy(item))
  //     }
  //   })
  //   history.push(`/my_studies/overview/${studyId}/study/attention_report`)
  // }
}



export default {
  fetchStimSuccess,
  fetchStimError,
  fetchStimBegin,
  clearCheckedStudies,
  removeAllinStepRow,
  checkAllinStepRow,
  removeStudy,
  addStudy,
  fetchStudy,
  checkAllinStep,
  addOrRemoveStudy,
  goToAttentionReportPage,
  compareAllStudies,
  postEndLiveStudy,
  setNewReportCriteria,
  addStudiesStringFormat,
  addStudiesObjectFormat,
  setReportType,
  setInitialLoadReset,
  fetchStudySpecificStims,




}
