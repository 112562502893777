import React from 'react';
import { Field } from 'formik';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import propTypes from 'prop-types';
import EnvironmentButtons from '../MaterialComponents/EnvironmentButtons';
import SelectMenu from '../MaterialComponents/SelectMenu';
import MaterialSlider from '../MaterialComponents/Slider';
import MoreInfo from '../MaterialComponents/MoreInfo';

export const BasicDetails = ({
  environmentData,
  locationTypes,
  languageTypes,
  studyTypes,
  mediaData,
  includeStudyType,
  includeMediaType,
  respondentTypes,
}) => {

  const panelSizeLabel = (
    <>
      Total respondents over per
      <div style={{ margin: '2px 0 -2px 6px' }}>
        <SelectMenu
          name='respondents'
          options={respondentTypes}
          variant='standard'
        />
      </div>
    </>
  );

  return (
    <>
      <Grid container spacing={3}>
        <EnvironmentButtons cards={environmentData.data} />
        <Grid item xs={12}>
          <Typography variant='subtitle1'>
            Please give your study a name
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Field
            fullWidth
            name='name'
            component={TextField}
            label='Study name'
            variant='filled'
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle1'>
            Please choose the country and language you want to conduct the study
            in
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <SelectMenu
            name='project_location'
            label='Country'
            options={locationTypes}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <SelectMenu
            name='language'
            label='Language'
            options={languageTypes}
          />
        </Grid>
        { includeMediaType ? <>
          <Grid item xs={12}>
            <Typography variant='subtitle1'>
              {includeStudyType ? 'And please select the appropriate media and study types' : 'And please select the appropriate media type for your study'}
            </Typography>
          </Grid>
          <Grid item md={includeStudyType ? 6 : 12} xs={12}>
            <SelectMenu
              name='media_id'
              label='Media type'
              options={mediaData}
            />
          </Grid>
        </> : null}
        <Grid item md={6} xs={12}>
          {includeStudyType ? (
            <SelectMenu
              name='study_type_id'
              label='Study type'
              options={studyTypes}
            />
          ) : ''}
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle1'>
            How many cells and how many respondents per cell?
          </Typography>
        </Grid>
        <Grid container wrap = 'nowrap' item xs={12} >
          <MaterialSlider
            name='number_of_cells'
            label='Number of cells'
            min={1}
            max={20}
            step={1}
            marks={[
              {
                value: 1,
                label: '1',
              },
              {
                value: 20,
                label: '20',
              },
            ]}
          />
          <MoreInfo popup='One cell for each testing variant' />
        </Grid>
        <Grid container wrap = 'nowrap' item xs={12} >
          <MaterialSlider
            name='panel_size'
            label={panelSizeLabel}
            min={100}
            max={1000}
            step={10}
            marks={[
              {
                value: 100,
                label: '100',
              },
              {
                value: 1000,
                label: '1000',
              },
            ]}
          />
          <MoreInfo popup='A respondent is the person who is taking part in the research' />
        </Grid>
      </Grid>
    </>
  );
};

BasicDetails.propTypes = {
  environmentData: propTypes.object,
  locationTypes: propTypes.object,
  languageTypes: propTypes.object,
  studyTypes: propTypes.object,
  mediaData: propTypes.object,
  respondentTypes: propTypes.object,
  includeStudyType: propTypes.bool,
  includeMediaType: propTypes.bool,
};
