import types from './types';

const questionAnswerReducer = (state = {
  questionAnswerOverview: null,
  questionAnswerOverviewLoading: true,
  questionAnswerOverviewError: false,
  currentQuestionSelected: null,
  questionAnswerResults: null,
  questionAnswerResultsLoading: true,
  questionAnswerResultsError: false,
  promptedSpontaneousResults: null,
  promptedSpontaneousResultsLoading: true,
  promptedSpontaneousResultsError: false,
  getParticipantResultsError: false
}, action) => {
  switch (action.type) {
    case types.FETCH_QUESTION_RESPONSE_BEGIN: {
      return {
        ...state,
        questionAnswerOverviewLoading: true,
        questionAnswerOverviewError: false
      };
    }
    case types.FETCH_QUESTION_RESPONSE_SUCCESS: {
      return {
        ...state,
        questionAnswerOverview: action.payload.data,
        questionAnswerOverviewLoading: false,
      };
    }
    case types.FETCH_QUESTION_RESPONSE_ERROR: {
      return {
        ...state,
        questionAnswerOverviewLoading: false,
        questionAnswerOverviewError: true
      };
    }
    case types.SET_NEW_QUESTION_SELECTED: {
      return {
        ...state,
        currentQuestionSelected: action.payload.data,
      };
    }
    case types.GET_QUESTION_DATA_BEGIN: {
      return {
        ...state,
        questionAnswerResultsLoading: true,
        questionAnswerResultsError: false
      };
    }
    case types.GET_QUESTION_DATA_SUCCESS: {
      return {
        ...state,
        questionAnswerResults: action.payload.data,
        questionAnswerResultsLoading: false,
      };
    }
    case types.GET_QUESTION_DATA_ERROR: {
      return {
        ...state,
        questionAnswerResultsLoading: false,
        questionAnswerResultsError: true
      };
    }
    case types.GET_PROMPTED_SPONTANEOUS_BEGIN: {
      return {
        ...state,
        promptedSpontaneousResultsLoading: true,
        promptedSpontaneousResultsError: false
      };
    }
    case types.GET_PROMPTED_SPONTANEOUS_SUCCESS: {
      return {
        ...state,
        promptedSpontaneousResults: action.payload.data,
        promptedSpontaneousResultsLoading: false,
     
      };
    }
    case types.GET_PROMPTED_SPONTANEOUS_ERROR: {
      return {
        ...state,
        promptedSpontaneousResultsLoading: false,
        promptedSpontaneousResultsError: true
      };
    }
    case types.GET_PARTICIPANTS_RESULTS_BEGIN: {
      return {
        ...state,
        getParticipantResultsError: false,
      };
    }
    case types.GET_PARTICIPANTS_RESULTS_ERROR: {
      return {
        ...state,
        getParticipantResultsError: true,
      };
    }
    default:
      return state;
  }
};


export default questionAnswerReducer;



