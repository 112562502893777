import React from "react";
import Chart from "react-apexcharts";

function compare(a, b) {
  const percentA = parseFloat(a.data);
  const percentB = parseFloat(b.data);

  let comparison = 0;
  if (percentA < percentB) {
    comparison = 1;
  } else if (percentA > percentB) {
    comparison = -1;
  }
  return comparison;
}


export default class QuestionAndAnswersGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: this.props.questionAnswerResults.map((singleColumn) => {
        return {
          name: singleColumn.text,
          respondents:  [singleColumn.count],
          data: [Math.round((singleColumn.count/ this.props.totalUserCount) * 1000)/10],
        };
      }).filter(item => { return item.respondents[0] > 0}).sort(compare),
      options: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "90%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
     
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: ["Answers"],
        },
        yaxis: {
          title: {
            text: "Percentage of respondents %",
            style:{
              fontWeight: 400,
              fontSize:12,
            }
          },
          labels: {
            formatter: function(val, index) {
              return val.toFixed();
            }
          }
        },
        colors: ["#42a5f5",  "#66bb6a", "#ffa726", "#7e57c2", "#26a69a", "#ffca28","#ff7043","#5c6bc0", "#ab47bc", "#26c6da", "#ffee58", "#ec407a", "#29b6f6", "#d4e157", "#ef5350"],

        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function(val, { seriesIndex, w }) {
              return `${val}% ${w.config.series[seriesIndex].respondents[0]} of ${props.totalUserCount} respondents`;
            },
          },
        },
      },
    };
  }

  render() {
    return (
      <>
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={330}
        />
      </>
    );
  }
}
