import React from 'react';

function TableHeader({ data }) {

  return (
    <tr>
      {data.map((item, index) => <th style={{ borderTopRightRadius: index === 2 ? 8 : 0, borderBottomRightRadius: index === 2 ? 8 : 0, borderTopLeftRadius: index === 0 ? 8 : 0, borderBottomLeftRadius: index === 0 ? 8 : 0, }} key={index}>{item}</th>)}
    </tr>
  );
}

export default TableHeader;

