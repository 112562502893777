
const FETCH_LINKS_BEGIN = 'FETCH_LINKS_BEGIN';
const FETCH_LINKS_SUCCESS = 'FETCH_LINKS_SUCCESS';
const FETCH_LINKS_ERROR = 'FETCH_LINKS_ERROR';


export default {

  FETCH_LINKS_BEGIN,
  FETCH_LINKS_SUCCESS,
  FETCH_LINKS_ERROR,

};
