import {
  Grid,
  Fab,
  makeStyles,
  Typography,
  FormHelperText,
  Select,
} from '@material-ui/core';
import { Field, FieldArray, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import { TextField as UiTextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import propTypes from 'prop-types';
import MoreInfo from './MoreInfo';

const cache_page = {
  cachepage_url: '',
  stim_list: [
    {
      stim_url: '',
      brand_id: null,
      ad_type_id: '',
    },
  ],
};
const stim = { stim_url: '', brand_id: null, ad_type_id: '' };
const useStyles = makeStyles((theme) => ({
  fab: {
    marginBottom: theme.spacing(3),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  iconButton: {
    position: 'relative',
    right: theme.spacing(1),
  },
  webPageURL:{
    marginTop: '40px'
  }
}));

function StimAndBrand({ adTypes, brandTypes, number}) {

  const classes = useStyles();
  const { values, isSubmitting, setFieldValue } = useFormikContext();

  const isLastCache = (index) => {
    if (index + 1 === values.cachepage_stim_list[number].stim_list.length) {
      return true;
    } else {
      return false;
    }
  };

  function validateEmptyStims(value) {
    let error;
    if (!value) {
      error = 'Required';
    }
    return error;
  }

  function validateFirstStim(value) {
    let error;
    if (!value) {
      error = 'At least 1 stim required';
    }
    return error;
  }

  const handleAutoComplete = (value, name, index, push) => {
    setFieldValue(name, value);
    if (isLastCache(index)) {
      push(stim);
    }
  };

  const handleSelect = (e, index, push) => {
    setFieldValue(e.target.name, e.target.value);
    if (isLastCache(index)) {
      push(stim);
    }
  };

  return (
    <>
      <FieldArray name={`cachepage_stim_list[${number}].stim_list`}>
        {({ push, remove }) => (
          <React.Fragment>
            {values.cachepage_stim_list[number].stim_list.map((_, index) => (
              <Grid key={index} item spacing={1} container justifyContent='flex-end'>
                <Grid item xs={1}>
                  {isLastCache(index) ? (
                    ''
                  ) : (
                    <IconButton
                      className={classes.iconButton}
                      disabled={isSubmitting}
                      onClick={() => remove(index)}
                    >
                      <HighlightOffIcon color='primary' />
                    </IconButton>
                  )}
                </Grid>

                <Grid item xs={10} sm={3}>
                  <Field
                    size='small'
                    variant='filled'
                    onInput={() => (isLastCache(index) ? push(stim) : '')}
                    fullWidth
                    name={`cachepage_stim_list.${number}.stim_list.${index}.stim_url`}
                    component={TextField}
                    validate={
                      index === 0
                        ? validateFirstStim
                        : isLastCache(index)
                          ? ''
                          : validateEmptyStims
                    }
                    label='Stim URL'
                  />
                </Grid>

                <Grid item xs={10} sm={3}>
                  <Field
                    name={`cachepage_stim_list.${number}.stim_list.${index}.brand_id`}
                    validate={
                      index === 0
                        ? validateFirstStim
                        : isLastCache(index)
                          ? ''
                          : validateEmptyStims
                    }
                  >
                    {({
                      field,
                      meta,
                    }) => (
                      <Autocomplete
                        {...field}
                        size='small'
                        fullWidth
                        onChange={(e, value) =>
                          handleAutoComplete(
                            value,
                            `cachepage_stim_list.${number}.stim_list.${index}.brand_id`,
                            index,
                            push
                          )
                        }
                        options={brandTypes.data}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <UiTextField
                            error={meta.touched && Boolean(meta.error)}
                            {...params}
                            label='Brand'
                            variant='filled'
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={10} sm={3}>
                  <Field
                    name={`cachepage_stim_list.${number}.stim_list.${index}.ad_type_id`}
                    validate={
                      index === 0
                        ? validateFirstStim
                        : isLastCache(index)
                          ? ''
                          : validateEmptyStims
                    }
                  >
                    {({
                      field,
                      meta,
                    }) => (
                      <FormControl
                        error={meta.touched && Boolean(meta.error)}
                        size='small'
                        variant='filled'
                      >
                        <InputLabel
                          htmlFor={`cachepage_stim_list.${number}.stim_list.${index}.ad_type_id`}
                        >
                          Ad format
                        </InputLabel>
                        <Select
                          {...field}
                          onChange={(e) => handleSelect(e, index, push)}
                        >
                          {adTypes.data.map((ad) => (
                            <MenuItem id={ad.id} key={ad.id} value={ad}>
                              {ad.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {meta.touched && Boolean(meta.error) ? (
                          <FormHelperText error>{meta.error}</FormHelperText>
                        ) : (
                          ''
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Grid>
              </Grid>
            ))}
          </React.Fragment>
        )}
      </FieldArray>
    </>
  );
}

StimAndBrand.propTypes = {
  brandTypes: propTypes.object,
  adTypes: propTypes.object,
  number: propTypes.number,
};

export default function DynamicFieldArray(props) {
  const classes = useStyles();
  const { values, errors, isSubmitting } = useFormikContext();
  return (
    <>
      <FieldArray name='cachepage_stim_list'>
        {({ push, remove }) => (
          <React.Fragment>
            <Grid alignContent='flex-end' container wrap = 'nowrap' justifyContent='space-between' item xs={12} >
              <Grid>
                <Typography variant='h6'>
                Web page setup
                </Typography>
                <Typography variant='subtitle1'>
                  Please enter the URL of the live web page you want to test your ads on, together with your ad details.
                </Typography>
              </Grid>
              <MoreInfo
                outerContainerClassName='cacheSetup'
                popupArray={
                  [
                    'Live web page URL: The live web page is where your add will be displayed.',
                    'Stim URL: A link to a webpage which features only your test.',
                    'Ad Brand: The brand name of the company featured in your test ad.',
                    'Ad format: The format your test ad will be displayed as.',
                  ]
                }
              />
            </Grid>
            {values.cachepage_stim_list.map((_, index) => (
              <React.Fragment key={index}>
                <Grid item className={classes.webPageURL} xs={1}>
                  { index === 0 ? '': (
                    <IconButton
                      className={classes.iconButton}
                      disabled={isSubmitting}
                      onClick={() => remove(index)}
                    >
                      <HighlightOffIcon color='primary' />
                    </IconButton>
                  )}
                </Grid>
                <Grid className={classes.webPageURL} alignItems='center' container wrap = 'nowrap' item xs={11}>
                  <Typography style={{marginRight: '8px'}} variant='body1'>{`${index + 1}.`}</Typography>
                  <Field
                    variant='filled'
                    fullWidth
                    name={`cachepage_stim_list.${index}.cachepage_url`}
                    component={TextField}
                    label='Live web page URL'
                  />
                </Grid>

                <StimAndBrand {...props} number={index} />
              </React.Fragment>
            ))}

            <Grid item>
              {typeof errors.cachepage_stim_list === 'string' ? (
                <Typography color='error'>
                  {errors.cachepage_stim_list}
                </Typography>
              ) : null}
            </Grid>

            <Grid
              className={classes.fab}
              container
              justifyContent='center'
              item
              xs={12}
            >
              <Grid item>
                <Fab
                  variant='extended'
                  size='medium'
                  color='primary'
                  aria-label='add'
                  disabled={isSubmitting}
                  onClick={() => push(cache_page)}
                  type='button'
                >
                  <AddIcon className={classes.extendedIcon} />
                  Add web page
                </Fab>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </FieldArray>
    </>
  );
}
