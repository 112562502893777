import React from 'react';
import { Formik } from 'formik';
import DataService from '../common/services/dataService';
import TextField from '@material-ui/core/TextField';


class EditUserDialog extends React.Component {

  render() {
    const { loading, username } = this.props;
    if (loading) {
      return <div>Loading...</div>;
    }

    return (
      <Formik
        initialValues={
          {
            email: username.email,
            username: username.username,
            first_name: username.first_name,
              last_name: username.last_name,
              job_title: username.job_title,
            location: username.location,
              phone_number: username.phone_number,
              company_name: username.company_name

          }}
        onSubmit={async (values, actions) => {
          try {
            await DataService.updateUserDetails(values)
          }
          catch (err) {
            actions.setSubmitting(false);
          }
        }}

        >{({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          username
        }) => (
            <form style={{gap:16}} className="my-details__form" onSubmit={handleSubmit}>
              <h2 style={{textAlign: "center"}}>{values.username}</h2>
      
                <TextField
                
                  fullWidth
                  variant="outlined" 
                  id="email"
                  name="email"
                  label="Email"
                  onBlur={handleBlur}
                  value={values.email !== null ? values.email : ''}
                  onChange={handleChange}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
                               
               <TextField
                  fullWidth
                  variant="outlined" 
                  id="first_name"
                  name="first_name"
                  label="First Name"
                  onBlur={handleBlur}
                  value={values.first_name !== null ? values.first_name : ''}
                  onChange={handleChange}
                  error={touched.first_name && Boolean(errors.first_name)}
                  helperText={touched.first_name && errors.first_name}
                />

<TextField
                  fullWidth
                  variant="outlined" 
                  id="last_name"
                  name="last_name"
                  label="Last Name"
                  onBlur={handleBlur}
                  value={values.last_name !== null ? values.last_name : ''}
                  onChange={handleChange}
                  error={touched.last_name && Boolean(errors.last_name)}
                  helperText={touched.last_name && errors.last_name}
                />
                <TextField
                  fullWidth
                  variant="outlined" 
                  id="job_title"
                  name="job_title"
                  label="Job Title"
                  onBlur={handleBlur}
                  value={values.job_title !== null ? values.job_title : ''}
                  onChange={handleChange}
                  error={touched.job_title && Boolean(errors.job_title)}
                  helperText={touched.job_title && errors.job_title}
                />

<TextField
                  fullWidth
                  variant="outlined" 
                  id="phone_number"
                  name="phone_number"
                  label="Phone Number"
                  onBlur={handleBlur}
                  value={values.phone_number !== null ? values.phone_number : ''}
                  onChange={handleChange}
                  error={touched.phone_number && Boolean(errors.phone_number)}
                  helperText={touched.phone_number && errors.phone_number}
                />

<TextField
                  fullWidth
                  variant="outlined" 
                  id="location"
                  name="location"
                  label="Location"
                  onBlur={handleBlur}
                  value={values.location !== null ? values.location : ''}
                  onChange={handleChange}
                  error={touched.location && Boolean(errors.location)}
                  helperText={touched.location && errors.location}
                />

<TextField
                  fullWidth
                  variant="outlined" 
                  disabled
                  id="company_name"
                  name="company_name"
                  label="Company name"
                  onBlur={handleBlur}
                  value={values.company_name !== null ? values.company_name : ''}
                  onChange={handleChange}
                  error={touched.company_name && Boolean(errors.company_name)}
                  helperText={touched.company_name && errors.company_name}
                />

       
              <button style={{margin: "16px auto", width:160}} className="button-primary my-details__button" type="submit" disabled={isSubmitting}>
                Update
            </button>
            </form>
          )}
      </Formik>
    );
  }
}

export default EditUserDialog;


