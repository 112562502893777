import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import Utils from '../../common/utils/Utils';
import Paper from '@material-ui/core/Paper';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import DvrIcon from '@material-ui/icons/Dvr';
import MissedVideoCallRoundedIcon from '@material-ui/icons/MissedVideoCallRounded';
import HowToRegRoundedIcon from '@material-ui/icons/HowToRegRounded';
import ImageSearchRoundedIcon from '@material-ui/icons/ImageSearchRounded';
import WrapTextRoundedIcon from '@material-ui/icons/WrapTextRounded';
import Typography from '@material-ui/core/Typography';
import TimerTwoToneIcon from '@material-ui/icons/TimerTwoTone';
import Tooltip from '@material-ui/core/Tooltip';
import EditStepModal from '../components/EditStepModal';
import { withStyles } from '@material-ui/core/styles';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import MoreInfoPopper from './MoreInfoPopper';

const styles = {
  paperStandard: {
    padding:8,
    margin:'16px 0px',
    borderRadius:6,
    overflow:'hidden',
  },
  paperDense: {
    padding:0,
    margin:'8px 0px',
    borderRadius:6,

  },
  titleStandard: {
    margin:0, fontWeight: 700, color: 'rgb(34, 51, 84)', fontSize: 16
  },
  titleDense: {
    margin:0, fontWeight: 700, color: 'rgb(34, 51, 84)', fontSize: 13
  }
};

const Task = ({ task, index, allowEditing, classes, density, onDeleteClick } ) => {

  const getColor = (stepType) => {
    switch (stepType) {
      case 'Cache Page Step':
      case 'Stim Step':
      case 'Embedded Step':
        return '#ffe0b2';
      case 'External Step':
      case 'Question Step':
        return '#c8e6c9';
      case 'Validation Step':
      case 'Instruction Step':
      case 'Calibration Step':
      case 'Command Step':
      default:
        return '#b2ebf2';
    }
  };

  const getIcon = (stepType) => {
    switch (stepType) {
      case 'Validation Step':
        return (
          <MoreInfoPopper
            content={`Confirms the accuracy and precision of the respondent's eye-tracking`}
            icon={<HowToRegRoundedIcon style={{ color: '#0097a7', fontSize: density === 'dense' ? 22 : 28 }} />}
          />
        );
      case 'Cache Page Step':
        return (
          <MoreInfoPopper
            content='A webpage that has been copied and hosted by Lumen, usually containing one or more ads which will be reported on'
            icon={<DvrIcon style={{ color: '#ff9100', fontSize: density === 'dense' ? 22 : 28 }} />}
          />
        );
      case 'Instruction Step':
        return (
          <MoreInfoPopper
            content='Explains to the respondent what will be happening next'
            icon={<AssignmentIcon style={{ color: '#0097a7', fontSize: density === 'dense' ? 22 : 28 }} />}
          />
        );
      case 'Stim Step':
        return (
          <MoreInfoPopper
            content='A step exclusively showing a single piece of stimulus'
            icon={<ImageSearchRoundedIcon style={{ color: '#ff9100', fontSize: density === 'dense' ? 22 : 28 }} />}
          />
        );
      case 'External Step':
        return (
          <MoreInfoPopper
            content={`A redirection within the study to a website outside of Lumen's domain (e.g. a study platform like Alchemer)`}
            icon={<AltRouteIcon style={{ color: '#43a047', fontSize: density === 'dense' ? 22 : 28 }} />}
          />
        );
      case 'Embedded Step':
        return (
          <MoreInfoPopper
            content={`A webpage created by a third party (including Lumen's eye-tracking script), not hosted on a Lumen domain, but shown as part of a Lumen study`}
            icon={<WrapTextRoundedIcon style={{ color: '#ff9100', fontSize: density === 'dense' ? 22 : 28 }} />}
          />
        );
      case 'Question Step':
        return (
          <MoreInfoPopper
            content='A question or set of questions hosted within the Lumen Research Question Platform (LRQP)'
            icon={<FactCheckIcon style={{ color: '#43a047', fontSize: density === 'dense' ? 22 : 28 }} />}
          />
        );
      case 'Calibration Step':
        return (
          <MoreInfoPopper
            content='Respondents are asked to look at the screen at specific points to allow us to accurately track their attention'
            icon={<MissedVideoCallRoundedIcon style={{ color: '#0097a7', fontSize: density === 'dense' ? 22 : 28 }} />}
          />
        );
      case 'Command Step':
        return (
          <MoreInfoPopper
            content='A step unseen by the respondent, that can trigger a calibration, recalibration, start or stop the camera'
            icon={<MissedVideoCallRoundedIcon style={{ color: '#0097a7', fontSize: density === 'dense' ? 22 : 28 }} />}
          />
        );
      default:
        return (
          <MoreInfoPopper
            content='Respondents are asked to look at the screen at specific points to allow us to accurately track their attention'
            icon={<AssignmentTurnedInIcon style={{ color: '#0097a7', fontSize: density === 'dense' ? 22 : 28 }} />}
          />
        );
    }
  };

  return (
    <Draggable draggableId={`${task.step_order_id}`} index={index}>
      {provided => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Paper
            variant='outlined'
            className={ density === 'dense' ? classes.paperDense : classes.paperStandard}
            style={{ background: getColor(task.step_type)}}
          >
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', padding:density === 'dense' ? '8px 8px 0px 8px' : 8}}>
              <div>
                <Typography variant='subtitle2' className={ density === 'dense' ? classes.titleDense : classes.titleStandard} >{index + 1} | {task.step_type}</Typography>
                {density === 'dense' ? '' : (
                  <>
                    <Tooltip arrow placement='left' disableHoverListener={task.step_name.length < 30} title={task.step_name}>
                      <Typography variant='body2' color= 'textSecondary' style={{margin:0, fontSize: 14}}>{task.step_name ? Utils.AddThreeDotsIfOver30Characters(task.step_name) : null}</Typography>
                    </Tooltip>
                  </>
                )}
              </div>
              { getIcon(task.step_type) }
            </div>
            <div style={{display:'flex', justifyContent:density === 'dense' ? 'flex-end': 'space-between', alignItems: 'center', paddingRight: density === 'dense' ? 4: 8}}>
              {density === 'dense' ? '' : (
                <Tooltip arrow placement='top' title={`Next button will appear after ${task.step_details.replace(/[^0-9]/g, '')} seconds`}>
                  <div style={{display:'flex', alignItems: 'center'}}>
                    <TimerTwoToneIcon style={{color: 'rgba(34, 51, 84, 0.3)', marginRight:8, fontSize:25}} />
                    <Typography
                      style={{
                        fontSize: '0.8125rem',
                        color: 'rgba(0, 0, 0, 0.87)',
                        margin:0, padding: '0px',
                        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                        fontWeight: 500,
                        lineHeight: 1.75
                      }}
                    >{task.step_details.replace(/[^0-9]/g, '')}s</Typography>
                  </div>
                </Tooltip>
              )}
              {allowEditing ?
                density === 'dense' ?
                  '' : <EditStepModal onDeleteClick={() => onDeleteClick(task.step_order_id)} stepData={task} /> : null
              }
            </div>
          </Paper>
        </div>
      )}
    </Draggable>
  );
};

export default (withStyles(styles)(Task));

Task.propTypes = {
  onDeleteClick: PropTypes.func,
  onHamburburgerClick: PropTypes.func,
  index: PropTypes.number,
  task: PropTypes.object,
  classes: PropTypes.object,
  allowEditing: PropTypes.bool,
  density: PropTypes.string,
};
