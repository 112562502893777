import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import Utils from '../common/utils/Utils';
import { batchStimsOperations } from '../Batches/duck/index';
// import Image from '../common/components/Image';
// import DropDown from '../EditStudyDetails/components/DropDown';
import LoadingSpinner from '../common/components/LoadingSpinner';
import Table from './components/Table';
import MiniCard from '../common/components/MiniCard';
import ErrorWidget from '../common/components/Error';
// import Linegraph from '../AttentionReport/components/Graph/Graph';
// import Button from '../common/components/Button';
import { Typography } from '@material-ui/core';


class BatchFeature extends React.Component {
  constructor(props) {
    super(props);
    this.state = { media: ['Original', 'View Order', 'Feature Analysis'], filter: 'stim_source', imageIsLoading: false }
    this.setimageIsLoading= this.setimageIsLoading.bind(this);
  }

  setFilter(filter) {
    switch (filter) {
      case 'View Order':
        filter = 'view_order_urls';
        break
      case 'Original':
        filter = 'stim_source';
        break
      case 'Feature Analysis':
        filter = 'feature_analysis_urls';
        break
      default:
        filter = 'view_order_urls';
    }
    this.setState({ filter: filter })
  }
  setimageIsLoading(loadingornot) {
    this.setState({imageIsLoading:loadingornot})
  }

  whatShape = (stimHeight, stimWidth) => {
    if (stimWidth /stimHeight > 2){
      return "wide"
    }
    else if (stimWidth /stimHeight <= 0.5){
      return "tall"
    }
    else{
      return "square"
    }
  }

  render() {
    const { batchesLoading, reportFeatureData, reportFeatureLoading, reportData, reportLoading, batchGraphLoading } = this.props;

    if (batchesLoading || reportFeatureLoading || reportLoading || batchGraphLoading) {
      return <LoadingSpinner />

    }
    const isNoReportData = reportFeatureData.data.length === 0
    return (
      <React.Fragment>
        {isNoReportData ? <ErrorWidget /> :
          <div className="batch-kpi">
            <div style={{ margin: "52px 8px 24px" }}>
              <Typography variant="h4">Feature Report</Typography>
              <Typography variant="subtitle1">{reportData.data[0].stim_name} : {reportData.data[0].stim_type} : {reportData.data[0].stim_id}</Typography>
            </div>
            {/* <h2 className="kpi__heading">{reportData.data[0].stim_name} : {reportData.data[0].stim_type} : {reportData.data[0].stim_id}</h2> */}

            <div style={{ gap: 24 }} className="kpi__table-graph-container">

              <div style={{ boxShadow: "unset", border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: 16 }} className="kpi__table-container">
                <Typography style={{ padding: "4px 8px" }} variant="h6">Key Feature Metrics</Typography>

                {isNoReportData ? null : <Table featureData={reportFeatureData.data[0]} />}

              </div>
              <div style={{ width: "50%", gap: 16 }} className={classNames("kpi__header-section-container", { 'large': this.props.isLargeImage })}>
                <MiniCard
                  value={Utils.DelimitNumbers(Number(reportData.data[0].stim_data.percent_viewable).toFixed(0))}
                  title="Percent Viewed"
                  suffix="%"
                  color="green"

                />
                <MiniCard
                  value={Utils.DelimitNumbers(Number(reportData.data[0].stim_data.avg_viewed_time).toFixed(1))}
                  title="Average Dwell Time"
                  suffix="s"
                  color="blue"
                />
              </div>
              {/* <div className="attention-report__graph-container">
                <h3 className="kpi__item-heading">Attention Curve</h3>

                <div className="linegraph__container">
                  <Linegraph
                    graphData={batchGraphData}
                    loading={batchGraphLoading}
                  />
                </div>
              </div> */}

            </div>


            <div className="kpi__image-container-wrapper">
              {/* <div className="kpi__image-dropdown-container">
                <div className="kpi__title-dropdown-container">
                  <h3 className="kpi__item-heading">Attention Visuals</h3>
                  <DropDown
                    onSelectItem={(filter) => this.setFilter(filter)}
                    options={this.state.media.map(option => option)}
                    value="Original "
                  />
                </div>
                <div style={{ display: this.state.imageIsLoading ? "block" : "none"}}>
                  <LoadingSpinner style={{margin:"10vh auto"}}/>
                </div>
                <div style={{display:  this.state.imageIsLoading ? "none" : "grid", gap: "16px"}}>
                {visualsData.data.map((item, index) => {
                  const isLarge = Utils.isHeightGreaterThanWidth(item, 'height', 'width');
                  let shape = this.whatShape(item.stim_height, item.stim_width)
                  return (
                    <Image
                    setimageIsLoading={(trueOrFalse) => {this.setimageIsLoading(trueOrFalse)}}
                    shape={shape}
                    name={item.stim_name}
                      imageClass={classNames('kpi__image-container batch-kpi___image', { 'large': isLarge })}
                      key={index}
                      source={item[this.state.filter]['low_quality']}
                    />)
                })
                 }
                 </div>

              </div> */}


              {/* <div className="button-container">
                <Button
                  buttonClass="button-primary with-margin"
                  text="Download Feature Data"
                  title="Download eye tracking data for each AOI."
                  handleClick={() => downloadFeatureData(reportData.internal_vendor_id, reportData.internal_batch_id, reportData.data[0].internal_step_id, reportData.data[0].internal_stim_id, 'DOWNLOAD')}
                />
                <Button
                  buttonClass="button-primary with-margin"
                  text="Download Participant Data"
                  title="Download questionnaire data."
                  handleClick={() => downloadParticipantData(reportData.internal_vendor_id, reportData.internal_batch_id, reportData.data[0].internal_step_id, reportData.data[0].internal_stim_id, 'DOWNLOAD')}
                />

              </div> */}
            </div>
          </div>
        }
      </React.Fragment>
    );
  }
}




const mapStateToProps = (state) => {
  return {
    visualsLoading: state.batchStimsReducer.visualsLoading,
    visualsData: state.batchStimsReducer.visualsData,
    reportFeatureLoading: state.batchStimsReducer.reportFeatureLoading,
    reportFeatureData: state.batchStimsReducer.reportFeatureData,
    batchStims: state.batchStimsReducer.batchStims,
    batchesLoading: state.batchStimsReducer.batchesLoading,
    reportLoading: state.batchStimsReducer.reportLoading,
    reportData: state.batchStimsReducer.reportData,
    batchGraphLoading: state.batchStimsReducer.batchGraphLoading,
    batchGraphData: state.batchStimsReducer.batchGraphData
  };

};

const mapDispatchToProps = (dispatch) => {
  return {
    onClick: (id, vendorId, item, type) => dispatch(batchStimsOperations.onClick(id, vendorId, item, type)),
    // downloadParticipantData: (cellId, stepId, studyId, stimId, download) => (dispatch(batchStimsOperations.downloadParticipantData(cellId, stepId, studyId, stimId, download))),
    // downloadFeatureData: (cellId, stepId, studyId, stimId, download) => (dispatch(batchStimsOperations.downloadFeatureData(cellId, stepId, studyId, stimId, download)))

  }
}



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BatchFeature));

