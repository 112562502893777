import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import * as React from "react";
import { default as ButtonMui } from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { Formik, Form } from "formik";
import { string, object } from "yup";

export default function NewCellWrapper(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    props.onNewCellClick();
    setOpen(true);
  };

  const handleClose = () => {
    props.onCancelClick();
    setOpen(false);
  };



  return (
    <div>
      <div
        style={{
          background: "white",
          color: "white",
          backgrondColour: "white",
          border: "1px dashed rgb(85, 105, 255)",
          width: "120px",
          height: "100px",
          borderRadius: 6,
        }}
      >
        <Tooltip arrow placement="top" title="Add a new cell">
          <IconButton
            onClick={handleClickOpen}
            style={{ width: "120px", height: "100px", borderRadius: 6 }}
            aria-label="delete"
          >
            <AddIcon style={{ color: "rgb(85, 105, 255)", fontSize: "32px" }} />
          </IconButton>
        </Tooltip>
      </div>

      <Formik
        initialValues={{
          value: "",
          shortName: "",
        }}
        validationSchema={object().shape({
          value: string()
            .required("* required")
            .max(100, "Too Long!"),
          shortName: string()
            .required("* required")
            .max(20, "Too Long!"),
        })}
        onSubmit={async (values, actions) => {
          try {
            props.onAddCellSubmit(values.shortName, values.value);
            actions.setSubmitting(false);
            setOpen(false);
            values.value = "";
            values.shortName = "";
          } catch (err) {}
        }}
      >
        {(formProps) => {
          return (
            <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
              <DialogTitle style={{ background: "#00badb", color: "white"}}>Add new cell</DialogTitle>
              <Form onSubmit={formProps.handleSubmit}>
                <DialogContent>
                  <DialogContentText style={{paddingBottom:8}}>
                    Please give your cell a long and short name
                  </DialogContentText>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="standard"
                        id="value"
                        name="value"
                        label="Long name"
                        value={formProps.values.value}
                        onChange={formProps.handleChange}
                        error={
                          formProps.touched.value &&
                          Boolean(formProps.errors.value)
                        }
                        helperText={
                          formProps.touched.value && formProps.errors.value
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="standard"
                        id="shortName"
                        name="shortName"
                        label="Short name"
                        value={formProps.values.shortName}
                        onChange={formProps.handleChange}
                        error={
                          formProps.touched.shortName &&
                          Boolean(formProps.errors.shortName)
                        }
                        helperText={
                          formProps.touched.shortName &&
                          formProps.errors.shortName
                        }
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <ButtonMui onClick={handleClose}>Cancel</ButtonMui>
                  <ButtonMui type="submit" disabled={formProps.isSubmitting}>
                    Create cell
                  </ButtonMui>
                </DialogActions>
              </Form>
            </Dialog>
          );
        }}
      </Formik>
    </div>
  );
}
