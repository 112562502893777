import React, { useState } from 'react';
import { object, string, mixed } from 'yup';
import propTypes from 'prop-types';

import Review from '../ReviewPage/Review';
import { FormStepper } from '../FormStepper';
import { BasicDetails } from '../Steps/BasicDetails';
import { PanelDetails } from '../Steps/PanelDetails';
import { QuestionDetails } from '../Steps/QuestionDetails';
import VideoSetup from '../Steps/VideoSetup.js';

export default function YouTubeStepper({
  onAddNewStudySubmit,
  environmentData,
  locationTypes,
  languageTypes,
  questionTypes,
  brandTypes,
  respondentTypes,
  defaultValues,
  basicDetailsValidationSchema,
  panelDetailsValidationSchema,
  questionDetailsValidationSchema,
  history,
}) {
  const [stepImperatively, setStepImperatively] = useState(null);

  return (
    <FormStepper
      stepImperatively={stepImperatively}
      setStepImperatively={setStepImperatively}
      initialValues={{
        ...defaultValues,
        panel_seconds_before_move_next: -1,
        study_type_id: 5,
        incontext_site: {code: 'yt', description: 'Youtube'},
        stim_url: '',
        brand_id: null,
      }}
      onSubmit={async (values) => {
        await onAddNewStudySubmit(values, history);
      }}
    >
      <BasicDetails
        label='Basic Details'
        title='Tell us about your study'
        validationSchema={basicDetailsValidationSchema}
        {...{environmentData, locationTypes, languageTypes, respondentTypes}}
      />
      <QuestionDetails
        validationSchema={questionDetailsValidationSchema}
        title='What surveys will you use?'
        label='Question Details'
        {...{questionTypes}}
      />
      <PanelDetails
        title='Tell us about your recruitment'
        label='Panel Details'
        validationSchema={panelDetailsValidationSchema}
      />
      <VideoSetup
        validationSchema={object().shape({
          incontext_site: object({
            code: mixed().required('Required'),
          }),
          stim_url: string()
            .url('Must be a valid URL')
            .max(512, 'Too Long!')
            .required('Required'),
          brand_id: mixed().required('Required'),
        })}
        title={`Great! Now let's setup your video`}
        label='Video Setup'
        {...{brandTypes}}
      />
      <Review
        title='Your project is ready for review'
        label='Review'
        setStepImperatively={setStepImperatively}
        labels={[
          'Basic Details',
          'Question Details',
          'Panel Details',
          'Video Setup',
        ]}
      />
    </FormStepper>
  );
}

YouTubeStepper.propTypes = {
  onAddNewStudySubmit: propTypes.func,
  languageTypes: propTypes.object,
  environmentData: propTypes.object,
  locationTypes: propTypes.object,
  questionTypes: propTypes.object,
  brandTypes: propTypes.object,
  respondentTypes: propTypes.object,
  defaultValues: propTypes.object,
  basicDetailsValidationSchema: propTypes.object,
  panelDetailsValidationSchema: propTypes.object,
  questionDetailsValidationSchema: propTypes.object,
  history: propTypes.object,
};
