import React, {useState} from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { contactFormOperations } from '../../ContactUs/duck/index';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChatIcon from '@material-ui/icons/Chat';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  fixedLocation: {
    position: 'fixed',
    top: 7,
    right: 72,
    zIndex: 1,
    [theme.breakpoints.up(1000 + theme.spacing(3) * 2)]: {
      width: '35vw',
    },
    [theme.breakpoints.up(1500 + theme.spacing(3) * 2)]: {
      width: '25vw',
    },
  },
  fabFixedLocation:{
    position: 'fixed',
    top: 8,
    right: 76,
    zIndex: 1,
    color: '#fff',
  },
  GridContainer: {
    padding: theme.spacing(2),
  },
  cancelButton:{
    position: 'absolute',
    right:'0px',
    top:'0px'
  }
}));

const validationSchema = yup.object({
  message: yup
    .string()
    .max(512, 'Too Long!')
    .required('Required'),
});

const FeedbackForm = (props) => {
  const classes = useStyles();
  const [showSnackBar, setShowSnackBar] = useState();
  const [fabMounted, setFabMounted] = useState(true);
  const [feedbackMounted, setFeedbackMounted] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const formik = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema: validationSchema,
    onSubmit: async(values, actions) => {
      actions.setSubmitting(true);
      await props.sendContactMessage(values);
      actions.resetForm();
      setShowSnackBar(true);
    },
  });

  return (
    <div>
      <Snackbar
        open={showSnackBar && props.success}
        onClose={() => setShowSnackBar(false)}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert variant='filled' severity={props.success ? 'success' : 'error'}>
          {props.success ? 'Thank you for your feedback' : 'Uh Oh! Something went wrong'}
        </Alert>
      </Snackbar>
      <Fade
        onEnter={() => setFabMounted(true)}
        onExited={() => setFabMounted(false)}
        mountOnEnter
        unmountOnExit
        in={
          !feedbackMounted && !showForm
        }
      >
        <Tooltip title='Ideas form'>
          <IconButton
            className={classes.fabFixedLocation}
            size='medium'
            onClick ={() => setShowForm(true)}
          >
            <ChatIcon style={{color: 'white', fontSize: 26}} />
          </IconButton>
        </Tooltip>
      </Fade>
      <Fade
        onEnter={() => setFeedbackMounted(true)}
        onExited={() => setFeedbackMounted(false)}
        mountOnEnter
        unmountOnExit
        in={
          !fabMounted && showForm
        }
      >
        <Paper elevation={5} className={classes.fixedLocation}>
          <IconButton className={classes.cancelButton} onClick={() => setShowForm(false)}>
            <CloseIcon/>
          </IconButton>
          <form onSubmit={formik.handleSubmit}>
            <Grid className={classes.GridContainer} container spacing={3}>
              <Grid item xs={12}>
                <Typography variant='h6'>Give us ideas for new features!</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body2' color='textSecondary'>
                  Please let us know if you discover any bugs or if you have any ideas for ways to improve the dashboard such as structure changes, new components, or new pages.
                </Typography>
              </Grid>
              <Grid id='userinput' item xs={12}>
                <TextField
                  fullWidth
                  id='message'
                  name='message'
                  label='I think the dashboard could be improved by...'
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  error={formik.touched.message && Boolean(formik.errors.message)}
                  helperText={formik.touched.message && formik.errors.message}
                  multiline
                />
              </Grid>
              <Grid container justifyContent='center' item xs={12}>
                <Button
                  startIcon={
                    formik.isSubmitting ? (
                      <CircularProgress size='1rem' />
                    ) : null
                  }
                  disabled={formik.isSubmitting}
                  color='primary'
                  variant='text'
                  type='submit'
                >
                  Submit Feedback
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Fade>
    </div>
  );
};

FeedbackForm.propTypes = {
  sendContactMessage: PropTypes.func,
  success: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    success: state.contactUsReducer.success,
  };
};
const mapDispatchToProps = (dispatch) => ({
  sendContactMessage: (userMessage) => dispatch(contactFormOperations.sendContactMessage(userMessage)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FeedbackForm)
);
