import React from 'react'
import { TextField, Button } from '@material-ui/core'
import AddIcon from '@mui/icons-material/Add';

export default function TopOfAddStepTables(props) {
    // buttonText, buttonClick, textFieldLabel, textFieldChange
    return (
        <div style={{width: "100%", padding: "0px 16px 16px 16px", display: "flex", justifyContent: props.button ? "space-between" : "flex-start", alignItems: "center" }}>
            <TextField

                variant="outlined"
                label={props.textFieldLabel}
                onChange={(e) => props.textFieldChange(e.target.value)}
            />
            {props.button ?      <Button variant="contained"
                size="small"
                startIcon={<AddIcon style={{ color: "rgb(255, 255, 255)" }} />}
                style={{ background: "#00badb", color: "rgb(255, 255, 255)", height: 36, textTransform: "none", borderRadius: 8 }}
                onClick={() => props.buttonClick()}
                testId="createStudy"
                title="Creates new study."
            >{props.buttonText}</Button> :
            ""
            }
       
        </div>
    )
}
