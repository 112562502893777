import Creators from './actions';
import DataService from '../../common/services/dataService';
import Utils from '../../common/utils/Utils';
const fetchKpiBegin = Creators.fetchKpiBegin
const fetchKpiSuccess = Creators.fetchKpiSuccess;
const updateKpiNormSuccess = Creators.updateKpiNormSuccess;
const fetchNewKpiNormBegin = Creators.fetchNewKpiNormBegin;
const fetchKpiError = Creators.fetchKpiError;
const setKpiFilter = Creators.setKpiFilter;
const fetchKpiGraphBegin = Creators.fetchKpiGraphBegin;
const fetchKpiGraphSuccess = Creators.fetchKpiGraphSuccess;
const updateKpiGraphSuccess = Creators.updateKpiGraphSuccess;
const fetchKpiGraphError = Creators.fetchKpiGraphError
const fetchKpiRawBegin = Creators.fetchKpiRawBegin
const fetchKpiRawSuccess = Creators.fetchKpiRawSuccess
const fetchKpiRawError = Creators.fetchKpiRawError;
const fetchParticipantReportBegin = Creators.fetchParticipantReportBegin;
const fetchParticipantReportSuccess = Creators.fetchParticipantReportSuccess;
const fetchParticipantReportError = Creators.fetchParticipantReportError;
const setKpiDataLoaded = Creators.setKpiDataLoaded;



const fetchKpiData = ({ studyId, cellId, stepId, stimId }) => {
  return async (dispatch, getState) => {
    const filter = getState().filter.filtersArray;
    let filterData =
      Utils.GroupByCategory(filter, 'name');
    Utils.FormatDataSet(filterData);
    try {
      // dispatch(getParticipantReportData(studyId, cellId, stepId));
      const res = await DataService.fetchKpiData(studyId, cellId, stepId, stimId, filterData);
      dispatch(fetchKpiSuccess(res.data))

    }
    catch (error) {
      dispatch(fetchKpiError({ error: 'Somethign went wrong' }))
    }
  }
}

const getParticipantReportData = (index, download) => {
  return async (dispatch, getState) => {
    let stimData = JSON.parse(JSON.stringify(getState().isolationData.whichStudiesAreCheckedObjectFormat[index]))
    let studyId = stimData.studyId
    let cellId = stimData.cellId
    let stepId = stimData.stepId
    try {
      dispatch(fetchParticipantReportBegin());
      const participantRes = await DataService.getParticipantReport(studyId, cellId, stepId);
      if (download === 'DOWNLOAD') {
        const flattenendObjArray = participantRes.data.data.map(item => Utils.FlattenObject(item))
        const download = Utils.ConvertArrayOfObjectsToCSV(flattenendObjArray)
        Utils.createDownloadLink(download, 'participant-data');
      } else {
        dispatch(fetchParticipantReportSuccess(participantRes.data))
      }
    }
    catch (error) {
      dispatch(fetchParticipantReportError({ error: 'Somethign went wrong' }))
      throw error
    }
  }

}


const returnWhichGraphToFilterBy = (filter) => {
  switch (filter) {
    case 'Dwell Time':
      return filter = {graph_type_id: "1", time_segment: 100, max_time: 5000};
    case 'Page Dwell Time':
      return filter = {graph_type_id: "2", time_segment: 1000, max_time: 60000};
    case 'Viewable Time':
      return filter = {graph_type_id: "3", time_segment: 500, max_time: 15000};
    default:
      return filter = {graph_type_id: "1", time_segment: 100, max_time: 5000};
  }
}

const fetchKpiGraphData = ( index, newGraphType ) => {

  return async (dispatch, getState) => {
    let stimData = JSON.parse(JSON.stringify(getState().isolationData.whichStudiesAreCheckedObjectFormat[index]))
    let studyId = stimData.studyId
    let cellId = stimData.cellId
    let stepId = stimData.stepId
    let stimId = stimData.stimId
    let filterData =returnWhichGraphToFilterBy(newGraphType)
    try {
      dispatch(fetchKpiGraphBegin());
      const graphRes = await DataService.fetchKpiGraphData(parseInt(studyId), parseInt(cellId), parseInt(stepId), parseInt(stimId), filterData);
      if (newGraphType){
        dispatch(updateKpiGraphSuccess(graphRes, index))
      }
      else {
        dispatch(fetchKpiGraphSuccess(graphRes))
      }
    }
    catch (error) {
      dispatch(fetchKpiGraphError(error))
    }
  }
}

const returnWhichNormToFilterBy = (filter) => {
  switch (filter) {
    case 'Brand Norm':
      return filter = 1;
    case 'Brand Category Norm':
      return filter = 2;
    case 'Base Norm':
      return filter = 3;
    case 'Ad Category Norm':
      return filter = 4;
    default:
      return filter = 1;
  }
}

const fetchNewNormData = (newNorm, index) => {
  const newNormID = returnWhichNormToFilterBy(newNorm);
  return async (dispatch, getState) => {
    let stimData = JSON.parse(JSON.stringify(getState().isolationData.whichStudiesAreCheckedObjectFormat[index]))
    let study_id = stimData.studyId
    dispatch(fetchNewKpiNormBegin())
      const data = {
        data: [{cell_id:parseInt(stimData.cellId), step_id: parseInt(stimData.stepId), stim_id: parseInt(stimData.stimId)}],
        norm_type_id: newNormID.toString()
  }
      try {
        const attentionResponse = await DataService.fetchAttentionResponseData(data, study_id);
          let kpiData = JSON.parse(JSON.stringify(getState().kpiReducer.kpiData))
          kpiData[index].norm_data = attentionResponse.data.data[0].norm_data
          kpiData[index].current_norm_type = attentionResponse.data.current_norm_type
          dispatch(updateKpiNormSuccess(kpiData))
      
      //   else if (kpi_or_feature ==="feature"){
      //   console.log("feature")
      // }
    }
      catch (err) {
        dispatch(fetchKpiError({ error: 'Somethign went wrong' }))
      }
    }
  };

const fetchKpiRawData = (index, download) => {
  return async (dispatch, getState) => {
    let stimData = JSON.parse(JSON.stringify(getState().isolationData.whichStudiesAreCheckedObjectFormat[index]))
    let studyId = stimData.studyId
    let cellId = stimData.cellId
    let stepId = stimData.stepId
    let stimId = stimData.stimId
    const filter = getState().filter.filtersArray;
    let filterData =
      Utils.GroupByCategory(filter, 'name');
    Utils.FormatDataSet(filterData);
    try {
      dispatch(fetchKpiRawBegin());
      const rawData = await DataService.fetchKpiRawData(studyId, cellId, stepId, stimId);
      if (download === 'DOWNLOAD') {
        const flattenendObjArray = rawData.data.data.map(item => Utils.FlattenObject(item))
        const download = Utils.ConvertArrayOfObjectsToCSV(flattenendObjArray)
        Utils.createDownloadLink(download, 'stim-data');

      } else {
        dispatch(fetchKpiRawSuccess(rawData))
      }

    }
    catch (error) {
      dispatch(fetchKpiRawError(error))

    }
  }
}


// const downloadStepData = (studyId, cellId, stepId, download) => {

//   return async (dispatch) => {
//     try {
//       dispatch(fetchParticipantReportBegin());
//       const participantRes = await DataService.getParticipantReport(studyId, cellId, stepId);
//       if (download === 'DOWNLOAD') {
//         const download = Utils.ConvertArrayOfObjectsToCSV(participantRes.data.data)
//         if (download === null) {
//           alert('No Data to download')
//         } else {
//           const encodedUri = encodeURI(download);
//           const link = document.createElement("a");
//           link.setAttribute("href", encodedUri);
//           link.setAttribute("download", "Step-data.csv");
//           document.body.appendChild(link); // Required for FF
//           link.click();
//         }
//       } else {
//         dispatch(fetchParticipantReportSuccess(participantRes.data))

//       }

//     }
//     catch (error) {
//       dispatch(fetchParticipantReportError({ error: 'Somethign went wrong' }))

//       throw error
//     }
//   }

// }


export default {
  fetchKpiBegin,
  fetchKpiSuccess,
  updateKpiNormSuccess,
  fetchNewKpiNormBegin,
  fetchKpiError,
  setKpiFilter,
  fetchKpiData,
  fetchKpiGraphBegin,
  fetchKpiGraphSuccess,
  updateKpiGraphSuccess,
  fetchKpiGraphError,
  fetchKpiGraphData,
  fetchKpiRawData,
  getParticipantReportData,
  fetchNewNormData,
  setKpiDataLoaded,


}
