import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';
import propTypes from 'prop-types';

export default function FacebookWatchFeed({values}) {

  const [errored, seterrored] = useState(false);
  useEffect(
    () => {
      seterrored(false);
    },
    [values.stim_url]
  );

  return (
    <Card style={{ width: '100%', boxShadow: 'unset', border: '1px solid rgba(0, 0, 0, 0.12)' }} >
      <CardHeader
        avatar={<Skeleton variant='circle' width={40} height={40} />}
        title={<Skeleton width='80%' style={{ marginBottom: 6 }} />}
        subheader={<Skeleton width='40%' />}
      />
      <div className='post-src'>
        {values.stim_url.split('.')[
          values.stim_url.split('.').length - 1
        ] === 'mp4' && !errored ? (
            <video
              onError={() => seterrored(true)}
              style={{ width: '100%' }}
              controls
              src={values.stim_url}
              alt='pic'
            />
          ) : (
            <img
              style={{width:'100%'}}
              src='https://s3.eu-west-2.amazonaws.com/content.lumen-research.com/cache_page_data/Lumen%2Bwill%2Bset%2Bup%2Bthe%2Bpost.png'
              alt='pic'
            />
          )}
      </div>
      <CardContent>
        <Skeleton style={{ marginBottom: 6 }} />
        <Skeleton width='80%' />
      </CardContent>
    </Card>
  );
}

FacebookWatchFeed.propTypes = {
  values: propTypes.object,
};
