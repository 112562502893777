import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import StarIcon from '@material-ui/icons/StarBorder';
import CardHeader from '@material-ui/core/CardHeader';
import { Link } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import MoreInfo from './MaterialComponents/MoreInfo';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';

import { theme } from './MaterialStyling';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(5, 0, 0),
  },
  heroButtons: {
    marginTop: theme.spacing(0),
  },
  cardGrid: {
    paddingBottom: theme.spacing(8),
  },
  card: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  mainContent: {
    height:'100%',
    padding:theme.spacing(0)
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  reducedPadding: {
    padding: '2px 0px',
  },
  OrMargin: {
    margin: theme.spacing(3, 0, 4, 0),
  },
  facebookIcon: {
    color: '#3B5998',
  },
  linkedInIcon: {
    color: '#0e76a8',
  },
  twitterIcon: {
    color: '#1DA1F2',
  },
  youTubeIcon: {
    color: '#FF0000',
  },
  overallPaper: {
    padding:16, borderRadius: 16,
  }
}));

export default function SplashPage() {
  const classes = useStyles();
  const isSuperUser = localStorage.getItem('superUser') === 'true';
  const [products, setProducts] = useState([
    {
      title: 'Social Media',
      subheader: 'Identify which of your ads works best on social platforms',
      price: '0',
      description1: ['Video', 'Static'],
      description2: ['Newsfeed', 'Stories', 'Watch feed'],
      buttonText: 'Get started',
      buttonVariant: 'outlined',
      moreInfo: 'Test your ad on a Facebook environment (Instagram, Twitter, LinkedIn and Snapchat coming soon)',
      link: '/create-study/social-media-study',
    },
    {
      title: 'Digital Ad Test',
      subheader: 'Identify which of your ads works best as a standard digital format',
      price: '0',
      description1: ['Video', 'Static'],
      description2: ['MPU/DMPU', 'Skyscraper', 'Billboard', 'LeaderBoard'],
      buttonText: 'Get Started',
      buttonVariant: 'outlined',
      link: '/create-study/digital-ad-test',
      moreInfo: 'Test your video or static display ad on a live webpage',
    },
    {
      title: 'YouTube',
      subheader: 'Identify your strongest YouTube creative',
      price: '0',
      description1: ['Video'],
      description2: ['6" bumper', '15" or 20" skippable', 'Non-skippable'],
      buttonText: 'Get started',
      buttonVariant: 'outlined',
      moreInfo: 'Test your video ad on a YouTube environment',
      link: '/create-study/YouTube-study',
    },
    {
      title: 'Vast Ad Test',
      subheader: 'Identify which of your ads works best as a vast video format',
      price: '0',
      description1: ['VAST', 'Video'],
      description2: ['Bespoke web pages'],
      buttonText: 'Get started',
      buttonVariant: 'outlined',
      moreInfo: 'Test your VAST script on a live web page',
      link: '/create-study/vast-ad-test',
    },
  ]);

  useEffect(() => {
    if (!isSuperUser) return;

    const superUserProducts = [
      {
        title: 'Digital Rich Media',
        subheader: 'Test your rich media creative to understand its performance',
        price: '0',
        description1: ['Video', 'Static', 'Interactive'],
        description2: ['Skins', 'Page Takeovers', 'Outstream video'],
        buttonText: 'Get started',
        buttonVariant: 'outlined',
        link: '/create-study/digital-rich-study',
        moreInfo: 'Test your rich media ad (e.g. page skins) or outstream video format ad on a live webpage',
      },
      {
        title: 'Focus Project Setup',
        subheader: 'Assign the panelists for a Lumen study',
        price: '0',
        description1: ['Mobile', 'Desktop', ''],
        buttonText: 'Get started',
        buttonVariant: 'outlined',
        link: '/create-study/Lumen-panel-setup',
        moreInfo: 'Create a task for the Lumen Panel',
      },
    ];

    setProducts(products => products.concat(superUserProducts));
  }, [isSuperUser]);

  return (
    <Paper className={classes.overallPaper}>
      <ThemeProvider theme={theme}>
        <div className={classes.heroContent}>
          <Container maxWidth='sm'>
            <Typography
              variant='h2'
              align='center'
              color='primary'
              gutterBottom
            >
              Choose your study
            </Typography>
            <Typography
              variant='subtitle1'
              align='center'
              color='textSecondary'
              paragraph
            >
              You can now take advantage of amongst the most advanced eye
              tracking technology on the market. Let&apos;s begin by choosing the
              research method that is right for you.
            </Typography>

            <div className={classes.heroButtons}>
              <Grid container spacing={2} justifyContent='center'>
                <Grid item>
                  <MoreInfo
                    outerContainerClassName = ''
                    middleContainerClassName = 'createCustomStudy'
                    innerContainerClassName = ''
                    leftRight='right'
                    popup='Use our cell step creator tool to design your own fully customizable studies.'
                  />
                  <Button
                    component={Link}
                    to='/create-study/custom-study'
                    variant='contained'
                    color='primary'
                  >
                    Create custom study
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>

        <Container className={classes.cardGrid} maxWidth='lg'>
          <Grid className={classes.OrMargin}>
            <Typography
              variant='subtitle1'
              align='center'
              color='textSecondary'
              paragraph
            >
              OR
            </Typography>
          </Grid>
          <Grid container justifyContent='center' spacing={4}>
            {products.map((product, index) => (
              <Grid
                className={classes.card}
                item
                key={index}
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                <Card variant='outlined' className={classes.root}>
                  <MoreInfo
                    outerContainerClassName = ''
                    middleContainerClassName = 'relativeContainer'
                    innerContainerClassName = 'createStudy'
                    leftRight='right'
                    popup={product.moreInfo}
                  />
                  <CardHeader
                    title={product.title}
                    subheader={product.subheader}
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{ align: 'center' }}
                    action={product.title === 'Pro' ? <StarIcon /> : null}
                    className={classes.cardHeader}
                  />
                  <CardContent className={classes.mainContent}>
                    <ul>
                      {product.description1.map((line, index) => (
                        <Typography
                          component='li'
                          variant='subtitle1'
                          align='center'
                          key={index}
                          className={classes.reducedPadding}
                        >
                          {line}
                        </Typography>
                      ))}
                    </ul>
                    {product.description2 ? <>
                      <Divider variant='middle' />
                      <ul>
                        {product.description2.map((line, index) => (
                          <Typography
                            component='li'
                            variant='subtitle1'
                            align='center'
                            key={index}
                            className={classes.reducedPadding}
                          >
                            {line}
                          </Typography>
                        ))}
                      </ul>
                    </> : ''}
                  </CardContent>
                  <CardActions>
                    <Button
                      component={Link}
                      to={product.link}
                      fullWidth
                      variant={product.buttonVariant}
                      color='primary'
                    >
                      {product.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </ThemeProvider>
    </Paper>
  );
}
