import types from './types';

const kpi = (state = {
  kpiData: [],
  kpiDataloading: false,
  newNormLoading: false,
  filter: [],
  graphData: [],
  graphLoading: true,
  rawKpiData: [],
  rawLoading: true,
  participantReportData: [],
  participantReportLoading: true
}, action) => {
  switch (action.type) {
    case types.KPI_DATA_FINISHED_ERROR: {
      return {
        ...state,
        kpiDataloading: false,
        error: null
      };
    }
    case types.KPI_DATA_FINISHED_SUCCESS: {
      return {
        ...state,
        kpiDataloading: false,
        graphLoading: false,
        error: false,
      };
    }
    case types.FETCH_KPI_BEGIN: {
      return {
        ...state,
        rawKpiData:[],
        participantReportData:[],
        filter:[],
        kpiData: [],
        graphData: [],
        kpiDataloading: true,
        error: null
      };
    }
    case types.FETCH_KPI_SUCCESS: {
      return {
        ...state,
        kpiData: [...state.kpiData, action.payload],
        filter: [...state.filter, "Stim Source"]
    
      };
    }
    case types.UPDATE_KPI_NORM_SUCCESS: {
      return {
        ...state,
        kpiData: action.payload,  
        newNormLoading: false,  
      };
    }

    case types.NEW_KPI_NORM_BEGIN: {
      return {
        ...state,
        newNormLoading: true,   
      };
    }
   
    case types.FETCH_KPI_ERROR: {
      return {
        ...state,
        kpiDataloading: false,
        error: action.payload.error,
        items: []
      };
    }
       case types.SET_KPI_FILTER: {
      return {
        ...state,
        filter: state.filter.map((item, index) => {
          if (index === action.payload.index) {
            return action.payload.filter
          }
          return item
        })          
      };
    }
    case types.FETCH_GRAPH_KPI_BEGIN: {
      return {
        ...state,
        graphLoading: true,
        error: null
        
      };
    }
    case types.FETCH_GRAPH_KPI_SUCCESS: {
      return {
        ...state,
        graphData: [...state.graphData, action.payload],
        graphLoading: false
      };
    }
    case types.UPDATE_KPI_GRAPH_SUCCESS: {
      return {
        ...state,
        graphData: state.graphData.map((oldGraphData, index) => {
          if (index === action.payload.index) {
            return action.payload.newGraphData
          }
          return oldGraphData
        }), 
        graphLoading: false
      };
    }
    case types.FETCH_GRAPH_KPI_ERROR:
      return {
        ...state,
        graphLoading: false,
        error: action.payload.error,
      };
    case types.FETCH_KPI_RAW_BEGIN: {
      return {
        ...state,
        rawLoading: true,
        error: null
      };
    }
    case types.FETCH_KPI_RAW_SUCCESS: {
      return {
        ...state,
        rawKpiData: action.payload.data.data,
        rawLoading: false
      };
    }
    case types.FETCH_KPI_RAW_ERROR:
      return {
        ...state,
        rawLoading: false,
        error: action.payload.error,
      };
    case types.FETCH_PARTICIPANT_REPORT_BEGIN: {
      return {
        ...state,
        participantReportLoading: true,
      };
    }
    case types.FETCH_PARTICIPANT_REPORT_SUCCESS: {
      return {
        ...state,
        participantReportData: action.payload.data,
        participantReportLoading: false
      };
    }
    case types.FETCH_PARTICIPANT_REPORT_ERROR:
      return {
        ...state,
        participantReportLoading: false,
      };
    default:
      return state;
  }
};

export default kpi;



