import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import SmartphoneRoundedIcon from '@material-ui/icons/SmartphoneRounded';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import { Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Devices from '@mui/icons-material/Devices';

const styles = {
  greenBox: {
    textTransform: 'uppercase',
    width:'auto',
    background: '#e5f9ed',
    margin:'auto !important',
    padding: '2px 7px',
    color: '#1bc943',
    display: 'flex',
    alignItems: 'center',
    gap:4,
    justifyContent: 'center',
    borderRadius:6,
    whiteSpace: 'nowrap',
    fontSize:'11px !important',
    fontWeight:'700 !important',
  },

  redBox: {
    textTransform: 'uppercase',
    width:'auto',
    background: '#fffbf2',
    padding: '2px 7px',
    margin:'auto !important',
    color: '#f4772e',
    display: 'flex',
    alignItems: 'center',
    gap:4,
    justifyContent: 'center',
    borderRadius:6,
    whiteSpace: 'nowrap',
    fontSize:'11px !important',
    fontWeight:'700 !important',
  },
};

const getEnvironmentIcon = (value) => {
  if (value === 'Webcam') {
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap:8, }}>
        <DesktopWindowsIcon style={{color:'rgb(99, 115, 129)'}} fontSize='small' />
        <Typography nowrap='true' variant='body2' >Webcam</Typography>
      </div>
    );
  }

  if (value === 'Mobile') {
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap:8, }}>
        <SmartphoneRoundedIcon fontSize='small' style={{ color: 'rgb(99, 115, 129)'}} />
        <Typography nowrap='true' variant='body2' >Mobile</Typography>
      </div>
    );
  }

  if (value === 'Lab' || value === 'All devices') {
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap:8, }}>
        <Devices fontSize='small' style={{ color: 'rgb(99, 115, 129)'}} />
        <Typography nowrap='true' variant='body2' >All devices</Typography>
      </div>
    );
  }

  return 'Unknown';
};

const TableCell = (props) => {

  const {
    id,
    title,
    environment,
    media,
    live,
    completed,
    cells,
    participants_requested,
    participants_returned,
    data_collection_end,
    data_collection_start,
    classes,
  } = props;

  return (
    <tr>
      <td style={{paddingLeft: 32}}>
        <Typography variant='subtitle2' >
          <Tooltip placement='right' title='View study details' >
            <Link to = {`/my_studies/overview/${id}`} >
              {title}
            </Link>
          </Tooltip>
        </Typography>
      </td>
      <td>{getEnvironmentIcon(environment)}</td>
      <td><Typography variant='body2' >{media}</Typography></td>
      <td>{live ?
        <Typography className={classes.greenBox} variant='body2' ><CheckIcon fontSize='small' />Live</Typography> :
        <Typography className={classes.redBox} variant='body2'><ClearIcon fontSize='small' /> Not live</Typography>
      }
      </td>
      <td>{completed ?
        <Typography className={classes.greenBox} variant='body2' ><CheckIcon fontSize='small' />Completed</Typography> :
        <Typography className={classes.redBox} variant='body2'><ClearIcon fontSize='small' /> Not completed</Typography>
      }</td>
      <td style={{textAlign: 'right'}}>{cells}</td>
      <td style={{ textAlign: 'right'}}>
        <Tooltip
          placement='top'
          title={<><p>{participants_requested.toString()} participants requested</p> <p>{participants_returned} returned so far</p></>}
        >
          <Typography nowrap='true' variant='body2' >
            {`${participants_returned} / ${participants_requested.toString()}`}
          </Typography>
        </Tooltip>
      </td>
      <td className='RemoveColumnWhen1200' >
        <Typography style={{whiteSpace:'nowrap'}} variant='body2'>{data_collection_end}</Typography>
      </td>
      <td className='RemoveColumnWhen1350' >
        <Typography style={{whiteSpace:'nowrap', textAlign:'right'}} variant='body2'>{data_collection_start}</Typography>
      </td>
    </tr>
  );
};

export default withRouter(withStyles(styles)(TableCell));

TableCell.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  environment: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  media: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  live: PropTypes.bool,
  completed: PropTypes.bool,
  cells: PropTypes.number,
  participants_requested: PropTypes.number,
  participants_returned: PropTypes.number,
  data_collection_end: PropTypes.string,
  data_collection_start: PropTypes.string,
  onClick: PropTypes.func,
  classes: PropTypes.object,
};
