
import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Paper } from '@mui/material';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import MoreInfoPopper from "../../CellStepManagement/components/MoreInfoPopper"
import MissedVideoCallRoundedIcon from '@material-ui/icons/MissedVideoCallRounded';
import HowToRegRoundedIcon from '@material-ui/icons/HowToRegRounded';
import ImageSearchRoundedIcon from '@material-ui/icons/ImageSearchRounded';
import WrapTextRoundedIcon from '@material-ui/icons/WrapTextRounded';
// import Typography from "@material-ui/core/Typography"
// import TimerTwoToneIcon from '@material-ui/icons/TimerTwoTone';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import DvrIcon from '@material-ui/icons/Dvr';
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    wideLabel: {
      width:"100%"
    },
    noMargin: {
        marginRight: "0px !important"
    }

  }));

export default function StepTypesRadioButtons(props) {
    const [value, setValue] = React.useState('');
    const classes = useStyles();

    const handleChange = (event) => {
        setValue(event.target.value);
    
        props.onSelectItem(event.target.value);
    };


    const getIcon = (color) => {
        let colorClass;
        switch (color) {
          case 'Validation':
    
            colorClass = <MoreInfoPopper initialText={color} content="Confirms the accuracy and precision of the respondent's eye-tracking" icon={<HowToRegRoundedIcon style={{ color: "#0097a7", fontSize: 28 }} />} />  
            break;
          case 'Cache Page':
            colorClass =   <MoreInfoPopper initialText={color} content="A webpage that has been copied and hosted by Lumen, usually containing one or more ads which will be reported on" icon={<DvrIcon style={{ color: "#ff9100", fontSize: 28  }} />} />  
            break;
          case 'Instruction':
            colorClass =   <MoreInfoPopper initialText={color} content="Explains to the respondent what will be happening next" icon={<AssignmentIcon style={{ color: "#0097a7", fontSize: 28  }} />} />  
            break;
          case 'Stim':
            colorClass =   <MoreInfoPopper initialText={color} content="A step exclusively showing a single piece of stimulus" icon={<ImageSearchRoundedIcon style={{ color: "#ff9100", fontSize: 28  }} />} />  
            break;
          case 'External':
            colorClass =   <MoreInfoPopper initialText={color} content="A redirection within the study to a website outside of Lumen's domain (e.g. a study platform like Alchemer)" icon={<AltRouteIcon style={{ color: "#43a047",fontSize: 28  }} />} />  
            break;
          case 'Embedded':
            colorClass =   <MoreInfoPopper initialText={color} content="A webpage created by a third party (including Lumen's eye-tracking script), not hosted on a Lumen domain, but shown as part of a Lumen study" icon={<WrapTextRoundedIcon style={{ color: "#ff9100", fontSize: 28  }} />} />  
            break;
          case 'Question':
            colorClass =  <MoreInfoPopper initialText={color} content="A question or set of questions hosted within the Lumen Research Question Platform (LRQP)" icon={<FactCheckIcon style={{ color: "#43a047", fontSize: 28  }} />} /> 
            break;
          case 'Calibration':
            colorClass =   <MoreInfoPopper initialText={color} content="Respondents are asked to look at the screen at specific points to allow us to accurately track their attention" icon={<MissedVideoCallRoundedIcon style={{ color: "#0097a7", fontSize: 28  }} />} />  
            break;
          case 'Command':
            colorClass =   <MoreInfoPopper initialText={color} content="A step unseen by the respondent, that can trigger a calibration, recalibration, start or stop the camera" icon={<MissedVideoCallRoundedIcon style={{ color: "#0097a7", fontSize: 28  }} />} />  
            break;
          default:
            colorClass =   <MoreInfoPopper  initialText={color} content="Respondents are asked to look at the screen at specific points to allow us to accurately track their attention" icon={<AssignmentTurnedInIcon style={{ color: "#0097a7", fontSize: 28  }} />} />  
        }
        return colorClass
      }

    return (
      <>
      {/* <Typography variant="subtitle1" style={{  alignSelf:"flex-start", paddingBottom:16}}>Please choose a step</Typography> */}
        <FormControl style={{width:"100%", marginBottom:16}} component="fieldset">

            <RadioGroup
       
                aria-label="gender"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChange}
            ><div style={{display:"flex", gap:16}}>
                
                        <Paper   variant="outlined" style={{padding: 16, background:"#b2ebf2", display: "grid", width:"100%", height:"min-content" }}  >
                            <Typography style={{fontWeight: "bold"}}  variant="subtitle1">Control steps</Typography>
                            <FormControlLabel  classes={{  label: classes.wideLabel, root: classes.noMargin  }}    value="Command" control={<Radio />} label={getIcon("Command")} />
                            <FormControlLabel classes={{  label: classes.wideLabel, root: classes.noMargin  }}  value="Instruction" control={<Radio />} label={getIcon("Instruction")} />
                            <FormControlLabel classes={{  label: classes.wideLabel, root: classes.noMargin  }}  value="Validation" control={<Radio />} label={getIcon("Validation")} />
                        </Paper>
                   
                        <Paper   variant="outlined" style={{padding: 16, background:"#ffe0b2", display: "grid", width:"100%", height:"min-content"  }} >
                            <Typography style={{fontWeight: "bold"}} variant="subtitle1">Stim Steps</Typography>
                            <FormControlLabel classes={{  label: classes.wideLabel, root: classes.noMargin  }}  value="Cache Page" control={<Radio />} label={getIcon("Cache Page")} />
                            <FormControlLabel classes={{  label: classes.wideLabel, root: classes.noMargin  }}  value="Stim" control={<Radio />} label={getIcon("Stim")} />
                            <FormControlLabel classes={{  label: classes.wideLabel, root: classes.noMargin  }}  value="Embedded" control={<Radio />} label={getIcon("Embedded")} />
                        </Paper>
                  
                        <Paper   variant="outlined" style={{padding: 16, background:"#c8e6c9", display: "grid", width:"100%", height:"min-content"  }}>
                        <Typography style={{fontWeight: "bold"}}  variant="subtitle1">Question steps</Typography>
                            <FormControlLabel classes={{  label: classes.wideLabel, root: classes.noMargin  }}  value="Question" control={<Radio />} label={getIcon("Question")} />
                            <FormControlLabel classes={{  label: classes.wideLabel, root: classes.noMargin  }}  value="External" control={<Radio />} label={getIcon("External")} />
                        
                        </Paper>
                   
                </div>
            </RadioGroup>
        </FormControl>
        </>
    );
}
