import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReviewList from './ReviewList';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { Divider } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import propTypes from 'prop-types';

import StandardCachePageReview from '../ReviewPage/StandardCachePageReview';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  accordion: {
    boxShadow: 'unset',
  },
  buttonMargin: {
    marginRight: theme.spacing(2),
  },
}));

const Review = ({
  labels,
  setStepImperatively,
}) => {
  const allLabels = labels.concat(['Advanced Settings']);

  return (
    <div style={{width: '100%'}}>
      {
        allLabels.map((label, index) => (
          <ReviewItem
            key={index}
            {...{
              setStepImperatively,
              label,
              index
            }}
          />))
      }
    </div>
  );
};

Review.propTypes = {
  labels: propTypes.arrayOf(propTypes.string),
  setStepImperatively: propTypes.func,
};

const ReviewItem = ({
  setStepImperatively,
  index,
  label,
}) => {
  const classes = useStyles();

  const getReviewList = (label) => {
    if (label === 'Web Page Setup') {
      return <StandardCachePageReview />;
    }

    return <ReviewList list={label} />;
  };

  return (
    <>
      <Divider />
      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          {' '}
          <Grid container alignItems='center'>
            <Tooltip title='Edit' placement='left'>
              <IconButton
                className={classes.buttonMargin}
                onClick={() => setStepImperatively(index)}
                onFocus={(event) => event.stopPropagation()}
              >
                <EditIcon fontSize='small' />
              </IconButton>
            </Tooltip>
            <Typography>{label}</Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={12}>
            {getReviewList(label)}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

ReviewItem.propTypes = {
  label: propTypes.string,
  index: propTypes.number,
  setStepImperatively: propTypes.func,
};

export default Review;
