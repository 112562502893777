import types from './types.js';


const addStudy = (key) => {
  return {
    type: types.ADD_COMPARE_STUDY,
    id: key
  };
};
 
const addStudiesObjectFormat = (key) => {
  return {
    type: types.ADD_STUDIES_OBJECT,
    id: key
  };
};

const addStudiesStringFormat = (key) => {
  return {
    type: types.ADD_STUDIES_STRING,
    id: key
  };
};

const setReportType = (key) => {
  return {
    type: types.SET_REPORT_TYPE,
    id: key
  };
};

const removeStudy = (key) => {
  return {
    type: types.REMOVE_COMPARE_STUDY,
    id: key
  };
};

const checkAllinStepRow = () => ({
  type: types.CHECK_ALL_IN_STEP
});

const removeAllinStepRow = () => ({
  type: types.REMOVE_ALL_IN_STEP
});

const clearCheckedStudies = () => {
  return {
    type: types.CLEAR_CHECKED_STUDIES
  };
};

const fetchStimBegin = () => ({
  type: types.FETCH_STIM_BEGIN
});

const fetchStimSuccess = data => ({
  type: types.FETCH_STIM_SUCCESS,
  payload: data
});

const fetchStimError = error => ({
  type: types.FETCH_STIM_ERROR,
  payload: { error }
});

const fetchStudiesSuccess = data => ({
  type: types.FETCH_STUDIES_OVERVIEW_SUCCESS,
  payload: data
});

const setInitialLoadBegin = data => ({
  type: types.INITIAL_LOAD_BEGIN,
  payload: data
});

const setInitialLoadFinish = () => ({
  type: types.INITIAL_LOAD_FINISH
});

const setInitialLoadReset = () => ({
  type: types.INITIAL_LOAD_RESET
});

const setInitialLoadError = () => ({
  type: types.INITIAL_LOAD_ERROR
});

const fetchStudySpecificStimsBegin = () => ({
  type: types.FETCH_STUDY_SPECIFIC_STIMS_BEGIN
});

const fetchStudySpecificStimsSuccess = data => ({
  type: types.FETCH_STUDY_SPECIFIC_STIMS_SUCCESS,
  payload: data
});

const fetchStudySpecificStimsError = () => ({
  type: types.FETCH_STUDY_SPECIFIC_STIMS_ERROR,
});





export default {
  fetchStimSuccess,
  fetchStimError,
  fetchStimBegin,
  clearCheckedStudies,
  removeAllinStepRow,
  checkAllinStepRow,
  removeStudy,
  addStudy,
  fetchStudiesSuccess,
  addStudiesObjectFormat,
  addStudiesStringFormat,
  setReportType,
  setInitialLoadBegin,
  setInitialLoadFinish,
  setInitialLoadError,
  setInitialLoadReset,
  fetchStudySpecificStimsBegin,
  fetchStudySpecificStimsError,
  fetchStudySpecificStimsSuccess,

};


