import React from 'react';
import PropTypes from 'prop-types';

import DigitalStandardStepper from './DigitalStandardStepper';
import CreateStudyContainer from '../CreateStudyContainer';

const DigitalStandardContainer = ({history}) => {

  return (
    <CreateStudyContainer>
      <DigitalStandardStepper {...{history}} />
    </CreateStudyContainer>
  );
};

DigitalStandardContainer.propTypes = {
  history: PropTypes.object,
};

export default DigitalStandardContainer;
