import React , {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography"
import ErrorImage from "../../../assets/images/ErrorImage.png"

class Video extends Component {
  constructor(props){  
    super(props);  
    this.state = {  
         source: this.props.videoSource
      }
  } 
  onClick = (e) =>{
    const node = this.node;
    node.play();
  }

  render(){
    const {className, onLoadStart, name} = this.props;
    return(
      this.state.source === "error" ? 
      <div style={{
        display: "flex",
        flexDirection: "column",
      }}>
        <img
          style={{
            maxHeight: "100%",
            boxShadow:
              "0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%)",
            borderRadius: 8,
            width: "100%",
            padding: 0,
          }}
          alt="lumen dashboard"
          src={ErrorImage}
        />
        <Typography style={{marginTop:"8px"}} color="textSecondary" variant="caption">{name}</Typography>
      </div> :
      <Grid item xs={12} md={6} lg={4} style={{maxHeight:400, maxWidth:"100%", display: "flex", flexDirection: "column"}}>
      <video onError={() => this.setState({source: "error"})} style={{  padding: 0, borderRadius:"8px", maxHeight:"380px", boxShadow:"0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%)"}} onLoadStart={onLoadStart} className={className} onClick={this.onClick} ref={(node) => { this.node = node; }} width="100%" height="100%" id="bgvID" controls >
        <source  src={this.state.source} type="video/mp4" />
      </video>
      <Typography style={{marginTop:"8px"}} color="textSecondary" variant="caption">{name}</Typography>
      </Grid>
    );
  }
}


export default Video;
