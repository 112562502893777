import Creators from './actions';
import HttpService from './HttpService';
import Utils from '../../common/utils/Utils';
const fetchQuestionAnswerOverviewBegin = Creators.fetchQuestionAnswerOverviewBegin;
const fetchQuestionAnswerOverviewSuccess = Creators.fetchQuestionAnswerOverviewSuccess;
const fetchQuestionAnswerOverviewError = Creators.fetchQuestionAnswerOverviewError;
const setNewQuestionSelected = Creators.setNewQuestionSelected;
const getQuestionDataBegin = Creators.getQuestionDataBegin;
const getQuestionDataSuccess = Creators.getQuestionDataSuccess;
const getQuestionDataError = Creators.getQuestionDataError;
const getPromptedSpontaneousBegin = Creators.getPromptedSpontaneousBegin;
const getPromptedSpontaneousSuccess = Creators.getPromptedSpontaneousSuccess;
const getPromptedSpontaneousError = Creators.getPromptedSpontaneousError;
const getParticipantsResultsBegin = Creators.getParticipantsResultsBegin;
const getParticipantsResultsError = Creators.getParticipantsResultsError;




const fetchQuestionAndAnswersOverview = (studyID) => {
  return async (dispatch) => {
    dispatch(fetchQuestionAnswerOverviewBegin());
    try {
      const res = await HttpService.getQuestionOverview(studyID)
      dispatch(fetchQuestionAnswerOverviewSuccess(res.data.data))
    }
    catch (error) {
      dispatch(fetchQuestionAnswerOverviewError());
    }
  }
}

const getQuestionData = (studyID, questionSetCode, questionID, limitToEyetracking) => {
  return async (dispatch) => {
    dispatch(getQuestionDataBegin());
    try {
      let data = {limit_to_eyetracking: limitToEyetracking}
      const res = await HttpService.getQuestionResults(studyID, questionSetCode, parseInt(questionID), data)
      dispatch(getQuestionDataSuccess(res.data.data))
    }
    catch (error) {
      dispatch(getQuestionDataError());
    }
  }
}

const getSpecialPromptedSpontaneousAggregateResult = (studyID, stimID) => {
  return async (dispatch) => {
    dispatch(getPromptedSpontaneousBegin());
    try {
      const res = await HttpService.getPromptedAndSpontaneousAggregateResult(studyID, stimID)
      dispatch(getPromptedSpontaneousSuccess(res.data.data))
    }
    catch (error) {
      dispatch(getPromptedSpontaneousError());
    }
  }
}

const downloadParticipantResults = (studyID, questionType, questionID, limitToEyetracking) => {
  return async (dispatch) => {
    dispatch(getParticipantsResultsBegin());
    try {
      let data = {limit_to_eyetracking: limitToEyetracking}
      let res = await HttpService.getParticipantsResults(studyID, questionType, parseInt(questionID), data)


      let answerOptions = ""
      if (res.data.data.answers.length > 0) {
        answerOptions = res.data.data.answers.map(answer => { return answer.text.replace(/,/g, "")}).join("/")
      }


      let maxNumberOfAnswersFromParticipant = 0
      res.data.data.participant_answer_data.forEach(participant => {
        if (participant.answer_data.length > maxNumberOfAnswersFromParticipant) {
          maxNumberOfAnswersFromParticipant = participant.answer_data.length
        }
      })


      let filteredParticipantData = res.data.data.participant_answer_data.map(participant => {
        let filteredParticipant =  {};
        filteredParticipant["participant_reference"] = participant.participant_reference;
        filteredParticipant["question"] = res.data.data.name.replace(/,/g, "");
        filteredParticipant["question_short_name"] = res.data.data.short_name.replace(/,/g, "");
        filteredParticipant["question_id"] = res.data.data.question_id;
        if (res.data.data.answers.length > 0) {
          filteredParticipant["answer_options"] =  answerOptions;
        }
        for (let i=0; i < parseInt(maxNumberOfAnswersFromParticipant ); i++) {
          filteredParticipant[`question_answer_${i+1}`] = participant.answer_data[i] ? participant.answer_data[i].text.replace(/,/g, "") : ""
        }   
      return filteredParticipant
      })


      const download = Utils.ConvertArrayOfObjectsToCSV(filteredParticipantData)
      Utils.createDownloadLink(download, 'stim-data');
    }
    catch (error) {
      dispatch(getParticipantsResultsError());
    }
  }
}







export default {
  fetchQuestionAnswerOverviewBegin,
  fetchQuestionAndAnswersOverview,
  setNewQuestionSelected,
  getQuestionData,
  getQuestionDataBegin,
  getSpecialPromptedSpontaneousAggregateResult,
  downloadParticipantResults,
  getQuestionDataError,
  getParticipantsResultsBegin
}
