import React from 'react';
import Utils from '../../common/utils/Utils';

const Table = ({ featureData }) => {
  return (
    <table style={{margin: "8px 0px 0px", padding:0}} className="table study">
      <tbody>
        <tr>
          <th style={{ borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }} >Feature </th>
          <th>Average feature dwell</th>
          <th>Average page dwell</th>
          <th style={{ borderTopRightRadius: 8 , borderBottomRightRadius:8}}>Percent panelist viewed</th>
        </tr>
        {featureData.feature_data.map((i, index) =>
          <tr key={index}>
            <td style={{borderBottom: featureData.feature_data.length === index + 1 ? "unset": ""}} >{i.feature_name}</td>
            <td style={{borderBottom: featureData.feature_data.length === index + 1 ? "unset": ""}} >{Utils.DelimitNumbers(Number(i.avg_feature_dwell_time).toFixed(1))}s</td>
            <td style={{borderBottom: featureData.feature_data.length === index + 1 ? "unset": ""}} >{Utils.DelimitNumbers(Number(i.avg_page_dwell_time).toFixed(1))}s</td>
            <td style={{borderBottom: featureData.feature_data.length === index + 1 ? "unset": ""}} >{Utils.DelimitNumbers(Number(i.participant_viewed_percent).toFixed(0))}%</td>
          </tr>)}
      </tbody>
    </table>
  );
};

export default Table;
