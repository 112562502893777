import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { attentionReportOperations } from "./duck/index";
import PropTypes from "prop-types";
import FeatureTable from "./components/FeatureAttention/Table";
import AttentionTable from "./components/AttentionReportTable/Table";
import Visuals from "./components/Visuals/visuals";
// import Utils from "../common/utils/Utils";
import LoadingSpinner from "../common/components/LoadingSpinner";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { theme } from "../CreateStudyPage/MaterialStyling";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";
// import MoreInfo from "../CreateStudyPage/MaterialComponents/MoreInfo";
import MoreMenu from "../Kpi/Components/MoreMenu";
import MultipleAttentionCurves from "./components/MultipleAttentionCurves";
import ChipsArray from "./components/ChipArray";
// import { studiesOperations } from "../Studies/duck/index";
// import Creators from "../Studies/duck/actions";
// import MoreInfoPictureContent from "../CreateStudyPage/MaterialComponents/MoreInfoPictureContent";
import MoreInfoSwitch from "./components/GeneralComponents/MoreInfoSwitch";
import AttentionTableExplanations from "./components/AttentionReportTable/AttentionTableExplanations";
import differentGraphTypes from "../../assets/images/differentGraphTypes.png";
import KeyAreasOfInterest from "../../assets/images/key_areas_of_interest.png";

const styles = {
  root: {
    display: "flex",
  },
  appBarSpacer: {
    marginBottom: 24,
  },
  content: {
    flexGrow: 1,
    overflow: "auto",
    padding: 6
  },
  container: {
    paddingTop: 16,
    paddingBottom: 32,
  },
  paper: {
    padding: 16,
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  largeFixedHeight: {
    height: 400,
    width: "100%",
    marginBottom: "18px",
  },
};

class AttentionReportContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      environment: [
        { name: "Avg Dwell Time", id: 0 },
        { name: "Percent Viewed", id: 1 },
      ],
      visuals: ["Original", "View Order", "Feature Analysis", "Heatmap", "Stim Analysis"],
      norms: [
        "Brand Norm",
        "Brand Category Norm",
        "Base Norm",
        "Ad Category Norm",
      ],
      graphOptions: {
        1: 'Dwell Time',
        2: 'Page Dwell Time',
        3: 'Viewable Time'
      },
      showKpiMoreInfo: false,
      showSurvivalCurvesInfo: false,
      showFeatureAttentionInfo: false,
    };
  }

  // componentDidMount() {
  //   this.props.fetchDemographicData(this.props.match.params.id);
  // }

  render() {
    if (this.props.attentionReportLoading) {
      return <LoadingSpinner />;
    }
    const { classes } = this.props;
    const normValues = Object.values(
      this.props.dropDowns.attention.data.norm_types
    );
    return (
      <div className={classes.root}>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <div className={classes.content}>
            <div className={classes.appBarSpacer} />
            <div className={classes.container}>
              <Grid container spacing={3}>

                <Grid item xs={12}>
                  <ChipsArray chips={Object.values(this.props.attentionData.data.data).map(stim => stim.stim_name)} />
                </Grid>

                <Grid item xs={12}>
                  <Paper elevation={2} className={classes.paper}>
                    <Grid style={{ flexBasis: "unset" }}>
                      <Grid container wrap="nowrap">
                        <Grid container wrap="nowrap" item>
                          <Typography variant="h6">
                            Eye tracking KPIs
                          </Typography>
                          <MoreInfoSwitch toggle={(toggleValue) => this.setState({ showKpiMoreInfo: toggleValue })} />
                          {/* <MoreInfo outerContainerClassName = "cacheSetup" popupArray = {[ "Panelist viewable script percent: Number of viewable script executed impressions \u00F7 number of total participants", "Avg page dwell time: Total Page dwell time (s) \u00F7 number of valid impressions", "Panelist viewable percent: Number of viewable impressions \u00F7 number of valid impressions.", "Avg stim viewable time: Total viewable time (s) \u00F7 number of viewable impressions.", "Panelist viewed percent: Number of viewed impressions \u00F7 number of viewable impressions", "Avg stim dwell time: Total dwell stim time (s) \u00F7 number of viewed impressions"]}  /> */}
                        </Grid>
                        <MoreMenu
                          initialTitle={
                            this.state.norms[
                            this.props.attentionData.data.current_norm_type -
                            1
                            ]
                          }
                          onSelectItem={(filter) =>
                            this.props.setAttentionFilter(filter)
                          }
                          options={normValues.map((option) => option)}
                        />
                      </Grid>
                      <Typography
                        variant="subtitle1"
                        color="textSecondary"
                        gutterBottom
                      >
                        This table reports eye-tracking results against Lumen standard norms
                      </Typography>
                    </Grid>
                    {this.props.attentionLoading ?
                      <LoadingSpinner style={{ padding: "0px", margin: "80px auto" }} /> : this.state.showKpiMoreInfo ?
                        <div style={{ width: "100%", overflow: "auto" }}>
                          <AttentionTableExplanations
                            data={this.props.attentionData}
                            loading={this.props.attentionLoading}
                            filter={this.props.attentionFilter}
                          />
                        </div> :
                        <div style={{ width: "100%", overflow: "auto" }}>
                          <AttentionTable
                            data={this.props.attentionData}
                            loading={this.props.attentionLoading}
                            filter={this.props.attentionFilter}
                          />
                        </div>
                    }

                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper elevation={2}  className={classes.paper}>
                    <Grid style={{ flexBasis: "unset" }}>
                      <Grid container wrap="nowrap">
                        <Grid container wrap="nowrap" item>
                          <Typography variant="h6">
                            Survival curves
                          </Typography>
                          <MoreInfoSwitch toggle={(toggleValue) => this.setState({ showSurvivalCurvesInfo: toggleValue })} />
                          {/* <MoreInfo outerContainerClassName = "cacheSetup" popupArray = {[ "Dwell time: Number of participants with page dwell >0, viewable time >=viewability threshold, dwell time > 10th/sec, by cell and stim \u00F7 Viewable impressions.", "Average page dwell time: Number of participants with time on page >= 1/10th sec, by cell and stim \u00F7 Total number of Viewable impressions", "Viewable time: Number of participants with viewable time >=viewability threshold by 1/10th sec, by cell and stim \u00F7 Total Number of Viewable impressions", ]}  /> */}
                        </Grid>


                        <MoreMenu
                          initialTitle={this.state.graphOptions[this.props.graphData.data.current_graph_type_id]}
                          onSelectItem={(filter) =>
                            this.props.updateGraphValues(filter)
                          }
                          options={Object.values(this.state.graphOptions)}
                        />


                      </Grid>
                      <Typography
                        variant="subtitle1"
                        color="textSecondary"
                        gutterBottom
                      >
                        {this.props.graphData.data.current_graph_type_id === 1 ? "How long your respondents viewed the stim" : this.props.graphData.data.current_graph_type_id === 2 ? "How long your respondents viewed the page the stim was on" : "How long the stim was viewable to your respondents whilst they browsed the stim context"}
                      </Typography>
                    </Grid>
                    {this.props.graphLoading ?
                      <LoadingSpinner style={{ padding: "0px", margin: "80px auto" }} /> :
                      this.state.showSurvivalCurvesInfo ?
                        <div style={{height: "330px", width: "100%", overflow: "auto" }}>
                          <img style={{ width: "100%", margin: "32px auto" }} src={differentGraphTypes} alt="graph example" />
                        </div> :
                        <MultipleAttentionCurves
                          graphData={this.props.graphData}
                        />}
                  </Paper>
                </Grid>

                <Grid item xs={12}>
                  <Paper elevation={2}  className={classes.paper}>
                    <Grid style={{ flexBasis: "unset" }}>
                      <Grid container wrap="nowrap">
                        <Grid container wrap="nowrap" item>
                          <Typography variant="h6">
                            Feature attention
                          </Typography>
                          <MoreInfoSwitch toggle={(toggleValue) => this.setState({ showFeatureAttentionInfo: toggleValue })} />
                          {/* <MoreInfo outerContainerClassName = "alignToTop" popup={<MoreInfoPictureContent standardDescription = "Example showing each key area of interest: " illustration = "keyAreasOfInterest" />} /> */}
                        </Grid>

                        <div style={{ marginRight: "16px" }}>
                          <MoreMenu
                            initialTitle="Avg Dwell Time"
                            onSelectItem={(filter) =>
                              this.props.setFeatureFilter(filter)
                            }
                            options={this.state.environment.map(
                              (option) => option.name
                            )}
                          />
                        </div>
                        <MoreMenu
                          initialTitle={
                            this.state.norms[
                            this.props.featureReportData
                              .current_norm_type - 1
                            ]
                          }
                          onSelectItem={(filter) =>
                            this.props.updateFeatureNorms(filter)
                          }
                          options={normValues.map((option) => option)}
                        />
                      </Grid>
                      <Typography
                        variant="subtitle1"
                        color="textSecondary"
                        gutterBottom
                      >
                        {this.props.featureFilter === "avg_stim_dwell_time" ? "This table shows average dwell time of five key areas of interest" : "This table shows percentage viewed of five key areas of interest"}
                      </Typography>
                    </Grid>
                    {this.props.featureReportLoading ? <LoadingSpinner style={{ padding: "0px", margin: "80px auto" }} /> : this.state.showFeatureAttentionInfo ? <img style={{ height: "320px", margin: "auto", }} src={KeyAreasOfInterest} alt="Area of interest example" /> :
                      <div style={{ width: "100%", overflow: "auto" }}>
                        <FeatureTable
                          data={this.props.featureReportData}
                          filter={this.props.featureFilter}
                        />
                      </div>
                    }
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.paper}>
                    <Grid style={{ flexBasis: "unset" }}>
                      <Grid container wrap="nowrap">
                        <Grid container wrap="nowrap" item>
                          <Typography variant="h6">Visuals</Typography>
                        </Grid>
                        <div style={{ marginLeft: "40px" }}>
                          <MoreMenu
                            initialTitle={
                              this.props.unSortedVisualFilter || "Original"
                            }
                            onSelectItem={(filter) =>
                              this.props.setVisualsFilter(filter)
                            }
                            options={this.state.visuals.map((option) => option)}
                          />
                        </div>
                      </Grid>
                      <Typography
                        variant="subtitle1"
                        color="textSecondary"
                        gutterBottom
                      >{this.props.visualFilter === "original_url" ? "Here are your stims" : this.props.visualFilter === "vieworder_url" ? "Here is the visual representation of the view order" : this.props.visualFilter === "featureanalysis_url" ? "Here is the visual representation of the feature analysis" : this.props.visualFilter === "analysis_url" ? "Here is the visual representation of the stim analysis" : "Here is the visual representation of the data"}
                      </Typography>
                    </Grid>
                    <Visuals
                      loading={this.props.haveVisualsLoaded}
                      data={this.props.visualData}
                      filter={this.props.visualFilter}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </ThemeProvider>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFeatureFilter: (filter) =>
      dispatch(attentionReportOperations.setFeatureFilter(filter)),
    updateFeatureNorms: (filter) =>
      dispatch(attentionReportOperations.updateFeatureNorms(filter)),
    setAttentionFilter: (filter) =>
      dispatch(attentionReportOperations.updateAttentionValues(filter)),
    setVisualsFilter: (filter) =>
      dispatch(attentionReportOperations.setVisualsFilter(filter)),
    // fetchDemographicData: (studyId) =>
    //   dispatch(attentionReportOperations.fetchDemographicData(studyId)),
    updateGraphValues: (filter) =>
      dispatch(attentionReportOperations.updateGraphValues(filter)),
  };
};

const sortFilter = (filter) => {
  switch (filter) {
    case "Feature name":
      return (filter = "feature_name");
    case "Avg stim dwell time":
      return (filter = "avg_stim_dwell_time");
    case "Avg page dwell time":
      return (filter = "avg_page_dwell_time");
    case "Avg Dwell Time":
      return (filter = "avg_stim_dwell_time");
    case "Percent Viewed":
      return (filter = "panelist_viewed_percent");
    default:
      return (filter = "avg_stim_dwell_time");
  }
};

const sortVisualFilter = (filter) => {
  switch (filter) {
    case "Original":
      return (filter = "original_url");
    case "View Order":
      return (filter = "vieworder_url");
    case "Feature Analysis":
      return (filter = "featureanalysis_url");
    case "Stim Analysis":
      return (filter = "analysis_url");
    case "Heatmap":
      return (filter = "heatmap_url");
    default:
      return (filter = "original_url");
  }
};

const mapStateToProps = (state) => {
  return {
    attentionReportLoading: state.attentionReport.attentionReportLoading,
    dropDowns: state.attentionReport.attentionReportData,
    featureReportData: state.attentionReport.featureReportData,
    featureFilter: sortFilter(state.attentionReport.featureFilter.filter),
    featureReportLoading: state.attentionReport.featureLoading,
    attentionData: state.attentionReport.attentionReportData.attention,
    attentionLoading: state.attentionReport.attentionLoading,
    attentionFilter: sortFilter(state.attentionReport.filter.filter),
    visualData: state.attentionReport.visualData,
    haveVisualsLoaded: state.attentionReport.visualLoading,
    visualFilter: sortVisualFilter(state.attentionReport.visualFilter.filter),
    unSortedVisualFilter: state.attentionReport.visualFilter.filter,
    graphData: state.attentionReport.graphData.response,
    graphLoading: state.attentionReport.graphLoading,
    // csvData: Utils.ConvertArrayOfObjectsToCSV(
    //   state.attentionReport.graphData.response,
    //   state.attentionReport.attentionLoading,
    //   "graph"
    // ),
    // demographicData: Utils.ConvertArrayOfObjectsToCSV(
    //   state.attentionReport.demographicData,
    //   state.attentionReport.demographicLoading,
    //   "demographic"
    // ),
  };
};

AttentionReportContainer.propTypes = {
  attentionReportLoading: PropTypes.bool,
  setAttentionFilter: PropTypes.func,
  visualFilter: PropTypes.string,
  unSortedVisualFilter: PropTypes.string,
  visualData: PropTypes.object,
  haveVisualsLoaded: PropTypes.bool,
  // csvData: PropTypes.string,
  setFeatureFilter: PropTypes.func,
  updateFeatureNorms: PropTypes.func,
  updateGraphValues: PropTypes.func,
  featureFilter: PropTypes.string,
  featureReportLoading: PropTypes.bool,
  featureReportData: PropTypes.object,
  attentionLoading: PropTypes.bool,
  graphData: PropTypes.object,
  graphLoading: PropTypes.bool,
  attentionData: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  attentionFilter: PropTypes.string,
  setVisualsFilter: PropTypes.func,
  // fetchDemographicData: PropTypes.func,
  attention: PropTypes.shape({
    data: PropTypes.shape({
      norm_types: PropTypes.object,
    }),
  }),
  dropDowns: PropTypes.object,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(AttentionReportContainer))
);
