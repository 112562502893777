import React from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { string, object } from "yup";
import logo from "../../assets/images/lumen-logo.svg";
import { userOperations } from "../MyDetails/duck/index";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "../CreateStudyPage/MaterialStyling";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

export class Login extends React.Component {

  constructor() {
    super()
    this.state = {
      showPassword: false,
    }
  }
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  render() {
  
    return (
      <Formik
        initialValues={{
          username: "",
          password: "",
          invalidCredentials: false,

        }}
        validationSchema={object().shape({
          username: string().required("Required"),
          password: string().required("Required"),
        })}

        onSubmit={async (values, actions) => {
          actions.setFieldValue("invalidCredentials", false)
         await this.props.login(
            values.username,
            values.password,
            this.props.history
          );
       
          if (!this.props.authenticated) {
            actions.setFieldValue("invalidCredentials", true)
            actions.setSubmitting(false);
          }
          else {
            actions.setSubmitting(false);
          }
        }}
      >
        {({
          handleSubmit,
          isSubmitting,
          handleChange,
          errors,
          touched,
          values,
        }) => (
          <Container component="main" style={{maxWidth:480}}>
            <ThemeProvider theme={theme}>
              <Paper elevation={2} style={{ padding: "80px 48px", background: "white", marginTop: "8vh" }}>
                <Form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <div style={{ width: "60%", margin: " 8px auto 8px" }}>
                        <img src={logo} alt="logo" />
                      </div>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="username"
                        name="username"
                        label="Username"
                        value={values.username}
                        onChange={handleChange}
                        error={touched.username && Boolean(errors.username)}
                        helperText={touched.username && errors.username}
                        variant="outlined"

                      />
                    </Grid>
                    <Grid item xs={12}>

                      <FormControl variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={this.state.showPassword ? 'text' : 'password'}
                          value={values.password}
                          onChange={handleChange}
                          name="password"
                          error={touched.password && Boolean(errors.password)}
                          helperText={touched.password && errors.password}
                          variant="outlined"
                          autoComplete="current-password"
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword}
                                onMouseDown={this.handleMouseDownPassword}
                                edge="end"
                              >
                                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                          labelWidth={70}
                        />
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox value="allowExtraEmails" color="primary" />
                        }
                        label="Remember me"
                      />
                    </Grid> */}

                    <Grid style={{ marginTop: 4 }} item xs={12}>
                    {values.invalidCredentials ? <div style={{position:"relative"}}><Typography style={{  marginLeft:1, position: "absolute", bottom:4 }} variant="caption" color="error" >Invalid username or password</Typography></div> : ""}
                      <Button
                      startIcon={
                        isSubmitting ? (
                          <CircularProgress style={{color:"#2962ff" }}  size="1rem" />
                        ) : null
                      }
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        Sign In
                      </Button>
                    </Grid>
                  </Grid>
              
                  <div style={{ display: "flex", justifyContent: "space-between", marginTop: 15, padding: 1, width: "100%" }}>
                    <Link href="/forgot_password" variant="body2">
                      {/* Forgot password? */}
                    </Link>
                    <Link href="/request_login" variant="body2">
                      Don't have an account? Sign up
                    </Link>

                  </div>


                </Form>
                {/* <FootNote
                  route="/forgot_password"
                  copy="I forgot my password"
                />
                <FootNote route="/contact_us" copy="Request login" /> */}
              </Paper>
            </ThemeProvider>
          </Container>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.userData.error,
    authenticated: state.userData.authenticated
  };
};
const mapDispatchToProps = (dispatch) => ({
  login: (username, password, history) =>
    dispatch(userOperations.loginAttempt(username, password, history)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Login)
);
