const OPEN_CSM_MODAL = 'OPEN_CSM_MODAL';
const CLOSE_CSM_MODAL = 'CLOSE_CSM_MODAL';
const OPEN_DELETE_HAMBURGER_MODAL = 'OPEN_HAMBURGER_MODAL';
const CLOSE_DELETE_HAMBURGER_MODAL = 'CLOSE_HAMBURGER_MODAL';
const ON_DRAG_END = 'ON_DRAG_END';
const ON_DROPDOWN_SELECT = 'ON_DROPDOWN_SELECT';
const ADD_NEW_TASK = 'ADD_NEW_TASK';
const DELETE_TASK = 'DELETE_TASK';
const FETCH_STEP_BEGIN = 'FETCH_STEP_BEGIN';
const FETCH_STEP_SUCCESS = 'FETCH_STEP_SUCCESS';
const FETCH_RAW_STEP_SUCCESS = 'FETCH_RAW_STEP_SUCCESS';
const FETCH_STEP_ERROR = 'FETCH_STEP_ERROR';
const EDIT_STEP_ERROR = 'EDIT_STEP_ERROR';
const POPULATE_FORM_DROPDOWN = 'POPULATE_FORM_DROPDOWN';
const ADD_STEP_ERROR = 'ADD_STEP_ERROR';
const OPEN_CONFIRM_BOX = 'OPEN_CONFIRM_BOX';
const CLOSE_CONFIRM_BOX = 'CLOSE_CONFIRM_BOX';
const OPEN_EDIT_BOX = 'OPEN_EDIT_BOX';
const CLOSE_EDIT_BOX = 'CLOSE_EDIT_BOX';
const EDIT_TASK_BEGIN = 'EDIT_TASK_BEGIN';
const EDIT_TASK_SUCCESS = 'EDIT_TASK_SUCCESS';
const EDIT_TASK_ERROR = 'EDIT_TASK_ERROR';
const ON_TITLE_CHANGE = 'ON_TITLE_CHANGE';
const RESET_STATE_TO_LOADING = 'RESET_STATE_TO_LOADING';
const SAVED = 'SAVED';
const SHOW_MODAL = 'SHOW_MODAL';
const EDIT_DELETE_MODAL = 'EDIT_DELETE_MODAL';
const ADD_STEP = 'ADD_STEP';
const ON_TOGGLE_LOCK_STUDY = 'ON_TOGGLE_LOCK_STUDY';
const CREATE_AUDIENCE = 'CREATE_AUDIENCE';
const EDIT_STUDY = 'EDIT_STUDY';
const ON_STEP_SEARCH = 'ON_STEP_SEARCH';
const STEP_TYPE = 'STEP_TYPE';
const ADD_NEW_ITEM_COMPLETE = 'ADD_NEW_ITEM_COMPLETE';
const CLOSE_CACHE_MODAL = 'CLOSE_CACHE_MODAL';
const OPEN_CACHE_MODAL = 'OPEN_CACHE_MODAL';
const GET_NEXT_BUTTON_OPTIONS_BEGIN = 'GET_NEXT_BUTTON_OPTIONS_BEGIN';
const GET_NEXT_BUTTON_OPTIONS_SUCCESS = 'GET_NEXT_BUTTON_OPTIONS_SUCCESS';
const GET_NEXT_BUTTON_OPTIONS_ERROR = 'GET_NEXT_BUTTON_OPTIONS_ERROR';

export default {

  OPEN_CSM_MODAL,
  CLOSE_CSM_MODAL,
  ON_DRAG_END,
  ON_DROPDOWN_SELECT,
  ADD_NEW_TASK,
  DELETE_TASK,
  FETCH_STEP_BEGIN,
  FETCH_STEP_SUCCESS,
  FETCH_STEP_ERROR,
  EDIT_STEP_ERROR,
  POPULATE_FORM_DROPDOWN,
  FETCH_RAW_STEP_SUCCESS,
  ADD_STEP_ERROR,
  OPEN_DELETE_HAMBURGER_MODAL,
  CLOSE_DELETE_HAMBURGER_MODAL,
  OPEN_CONFIRM_BOX,
  CLOSE_CONFIRM_BOX,
  OPEN_EDIT_BOX,
  CLOSE_EDIT_BOX,
  EDIT_TASK_BEGIN,
  EDIT_TASK_SUCCESS,
  EDIT_TASK_ERROR,
  ON_TITLE_CHANGE,
  RESET_STATE_TO_LOADING,
  SAVED,
  SHOW_MODAL,
  EDIT_DELETE_MODAL,
  ADD_STEP,
  ON_TOGGLE_LOCK_STUDY,
  CREATE_AUDIENCE,
  EDIT_STUDY,
  ON_STEP_SEARCH,
  STEP_TYPE,
  ADD_NEW_ITEM_COMPLETE,
  OPEN_CACHE_MODAL,
  CLOSE_CACHE_MODAL,
  GET_NEXT_BUTTON_OPTIONS_BEGIN,
  GET_NEXT_BUTTON_OPTIONS_SUCCESS,
  GET_NEXT_BUTTON_OPTIONS_ERROR,

};
