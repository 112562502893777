import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function TablePagination(props) {

    return (
        <div >
           
            <div style={{ display: "flex", gap:24, justifyContent:"space-between", alignItems: "center", padding:8 }} >
                <div style={{width:100}}></div>
            <Pagination count={props.numberOfPages} page={parseInt(props.currentPage)} onChange={props.onPageChange} variant="outlined" color="primary" />
                <FormControl variant="standard" style={{width:100}} >
                    <InputLabel id="demo-simple-select-standard-label">Rows per page</InputLabel>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={parseInt(props.rowsPerPage)}
                        onChange={props.onRowsPerPageChange}
                        label="Rows per page"
                    >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                    </Select>
                </FormControl>
              
            </div>
        </div>
    );
}