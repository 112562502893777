import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../../common/components/LoadingSpinner';
// import Button from '../../common/components/Button';
import Typography from "@material-ui/core/Typography";
import Avatar from '@mui/material/Avatar';
import Tooltip from "@material-ui/core/Tooltip";
import DownloadBatchDataButton from "./DownloadBatchDataButton";
import Button from "@material-ui/core/Button"
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

function StimTable({ stims, onKpiClick, onFeatureClick, internalBatchId, vendorId, downloadParticipantData, downloadFeatureData, downloadStimData }) {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(false);
  });

  return (
    <React.Fragment>
      {isLoading ? (<LoadingSpinner />) : (
        <table style={{ padding: "10px 0px 0px" }} className="table study">
          <tbody>
            <tr>
              <th colSpan={4} style={{ borderTopLeftRadius: 8, borderBottomLeftRadius: 8, width: "100%" }} >Your stims</th>
              <th style={{ borderTopRightRadius: 8, borderBottomRightRadius: 8, textAlign: "center" }}></th>
            </tr>
            {stims.map((item, index) => {
              const hasNoFeatures = item.feature_data.length === 0;
              const externalSourceOrURL = item.external_source ? item.external_source : item.url
              const isLastRow = stims.length === index + 1
              return (
                <tr key={index}>
                  <td style={{borderBottom: isLastRow ? "unset": ""}} >
                    <div style={{width: "unset"}} className="study__image-wrapper">
                      <div style={{ padding: "8px 0px", display: "flex", alignItems: "center" }} >
                        <Tooltip placement="bottom" title="Open stim in new tab">
                          <a href={externalSourceOrURL} target="_blank" className="clickableAvatar">
                            {externalSourceOrURL.split(".")[
                              externalSourceOrURL.split(".").length - 1
                            ] === "mp4" || item.type === "video" ?
                              <div style={{ borderRadius: "50%", height: "100%", width: "100%", overflow: "hidden", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <video style={{ height: "100%" }} muted autoPlay >
                                  <source src={externalSourceOrURL} type="video/mp4" />
                                </video>
                              </div>
                              :
                              !externalSourceOrURL ? <Avatar>{item.name ? item.name[0] : item.identifier[0]}</Avatar> :

                                <img src={externalSourceOrURL}   
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src="https://s3.eu-west-2.amazonaws.com/content.lumen-research.com/cache_page_data/ImageAvatar.png";
                                }}
                                 style={{ borderRadius: "50%", height: "100%", width: "100%" }} alt="Remy Sharp" />
                            }
                          </a>
                        </Tooltip>
                        <div style={{ padding: "8px 16px" }}>
                          <Typography variant="subtitle1">{item.name ? `${item.name}` : null}</Typography>
                          <Typography variant="caption">Item identifier: {item.identifier}</Typography>
                        </div>
                      </div>
                      {/* <h4>{item.identifier} {item.name ? `: ${item.name}` : null}</h4> */}
                    </div>
                  </td>
                  <td style={{borderBottom: isLastRow ? "unset": ""}} >
                    <Typography variant="subtitle2" style={{ textTransform: "capitalize", color: "rgb(61, 73, 119)" }}>{item.type ? item.type : "Unknown"}</Typography>
                  </td>
                  <td style={{borderBottom: isLastRow ? "unset": ""}} >
                    <Link
                      to={`/batch_stim_list/aoi/${item.internal_stim_id}?type=batch`}
                    >
                      <Typography variant="subtitle2" style={{ color: "rgb(61, 73, 119)", textTransform: "capitalize" }} >Edit AOIs</Typography>
                    </Link>
                  </td>
                  <td style={{borderBottom: isLastRow ? "unset": ""}}>
                    {/*    <Button
                      buttonClass="button-primary with-margin"
                      text="Download Participant Data"
                      handleClick={() => downloadParticipantData(vendorId, internalBatchId, item.internal_step_id, item.internal_stim_id, 'DOWNLOAD')}
                      title="Download questionnaire data."

                    />

                    <Button
                      buttonClass="button-primary with-margin"
                      text="Download Stim Data"
                      handleClick={() => downloadStimData(vendorId, internalBatchId, item.internal_step_id, item.internal_stim_id, 'DOWNLOAD')}
                      title="Download eye-tracking data for each stimulus."

                    />
                    <Button
                      buttonClass="button-primary with-margin"
                      text="Download Feature Data"
                      handleClick={() => downloadFeatureData(vendorId, internalBatchId, item.internal_step_id, item.internal_stim_id, 'DOWNLOAD')}
                      title="Download eye tracking data for each AOI."
                    /> */}
                    <DownloadBatchDataButton
                      tooltip="Download stim specific data"
                      buttonVersion="Secondary"
                      downloadParticipantData={() => downloadParticipantData(vendorId, internalBatchId, item.internal_step_id, item.internal_stim_id, 'DOWNLOAD')}
                      downloadStimData={() => downloadStimData(vendorId, internalBatchId, item.internal_step_id, item.internal_stim_id, 'DOWNLOAD')}
                      downloadFeatureData={() => downloadFeatureData(vendorId, internalBatchId, item.internal_step_id, item.internal_stim_id, 'DOWNLOAD')}
                    />
                  </td>
                  <td  style={{ textAlign: "right", borderBottom: isLastRow ? "unset": "" }}>
                    <div>
                      {!hasNoFeatures ?
                        <Tooltip placement="bottom" title="View stim report " >
                          <Button
                            style={{ whiteSpace: "nowrap", border: "1px solid rgba(61, 73, 119, 0.5)", color: "rgb(61, 73, 119)", borderRadius: 8, textTransform: "capitalize" }}
                            component={Link}
                            to={`/my_batches/batch_overview/${internalBatchId}&${vendorId}/stim_report?id=${internalBatchId}&vendorId=${vendorId}`}
                            disableElevation
                            variant="outlined"
                            onClick={() => { onKpiClick(internalBatchId, vendorId, item.internal_stim_id, 'KPI', index); onFeatureClick(internalBatchId, vendorId, item.internal_stim_id, 'FEATURE', index) }}
                          >
                            View Report
                          </Button>
                        </Tooltip> : 
                        <Tooltip placement="bottom" title="View stim report" >
                          <Button
                            style={{ whiteSpace: "nowrap",  border: "1px solid rgba(61, 73, 119, 0.5)",  color: "rgb(61, 73, 119)", borderRadius: 8, textTransform: "capitalize" }}
                            component={Link}
                            variant="outlined"
                            to={`/my_batches/batch_overview/${internalBatchId}&${vendorId}/kpi?id=${internalBatchId}&vendorId=${vendorId}`}
                            disableElevation
                            onClick={() => onKpiClick(internalBatchId, vendorId, item.internal_stim_id, 'KPI', index)}
                          >
                            View Report
                          </Button>
                        </Tooltip>}
                    </div>
                    {/* <div className="study__link-container">
                      <span><Link onClick={() => onKpiClick(internalBatchId, vendorId, item.internal_stim_id, 'KPI', index)} to={`/my_batches/batch_overview/${internalBatchId}&${vendorId}/kpi?id=${internalBatchId}&vendorId=${vendorId}`} > Kpi Report</Link></span>
                      {
                        !hasNoFeatures ? <span><Link onClick={() => onFeatureClick(internalBatchId, vendorId, item.internal_stim_id, 'FEATURE', index)} to={`/my_batches/batch_overview/${internalBatchId}&${vendorId}/feature`}>Feature Report</Link></span> : null
                      }
                    </div> */}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )
      }
    </React.Fragment>
  );
};

StimTable.propTypes = {
  headings: propTypes.object,
  kpiData: propTypes.object
};


export default StimTable;


