import { Divider, Grid, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';
import Feed from './Feed';
import Form from './Form';
import propTypes from 'prop-types';

import styles from './pinterest.module.css';

const Pinterest = ({ brandTypes }) => {
  const { values } = useFormikContext();

  return (
    <>
      <Divider variant='middle' />
      <Grid
        style={{ margin: '24px 0px 12px' }}
        container
        wrap='nowrap'
        justifyContent='center'
        item
        xs={12}
      >
        <Typography align='center' variant='h6'>
          Pinterest commercial
        </Typography>
      </Grid>
      <div className={styles.container}>
        <div className={styles.column}>
          <Feed values={values} />
        </div>
        <div className={styles.column}>
          <Form brandTypes={brandTypes} />
        </div>
      </div>
    </>
  );
};

Pinterest.propTypes = {
  brandTypes: propTypes.object,
};

export default Pinterest;
