
import axios from 'axios';
import { getHeaders } from '../../common/shared';
import { URL } from '../../common/constants';

const HttpService = {
  getQuestionOverview: (studyID) => {
    return axios({
      method: 'post',
      url: `${URL}/v1.0/studies/${studyID}/survey/overview`,
      headers: getHeaders().headers
    });
  },
  getQuestionResults: (studyID, questionSetCode, questionID, data) => {
    return axios({
      method: 'post',
      url: `${URL}/v1.0/studies/${studyID}/survey/${questionSetCode}/${questionID}/aggregate`,
      data: data,
      headers: getHeaders().headers
    });
  },
  getPromptedAndSpontaneousAggregateResult: (studyID, stimID) => {
    return axios({
      method: 'post',
      url: `${URL}/v1.0/studies/${studyID}/stim/${stimID}/survey/spontaneous_prompted_brand_aggregate/`,
      headers: getHeaders().headers
    });
  },
  getParticipantsResults: (studyID, questionType, questionID, data) => {
    return axios({
      method: 'post',
      url: `${URL}/v1.0/studies/${studyID}/survey/${questionType}/${questionID}/participant/`,
      data: data,
      headers: getHeaders().headers
    });
  },
};


export default HttpService;