import types from './types';

const {
  FETCH_REPORT_DETAILS_BEGIN,
  FETCH_REPORT_DETAILS_SUCCESS,
  FETCH_REPORT_DETAILS_ERROR,
  FETCH_LOOKER_URL_BEGIN,
  FETCH_LOOKER_URL_SUCCESS,
  FETCH_LOOKER_URL_ERROR,
} = types

const lookerReportReducer = (state = {
  report: null,
  loading: true,
  url: null,
}, action) => {
  switch (action.type) {
    case FETCH_REPORT_DETAILS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_REPORT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        report: action.payload.report,
      };
    case FETCH_REPORT_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FETCH_LOOKER_URL_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_LOOKER_URL_SUCCESS:
      return {
        ...state,
        loading: false,
        url: action.payload.url,
      };
    case FETCH_LOOKER_URL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default lookerReportReducer;
