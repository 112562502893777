import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from 'formik-material-ui-lab';
import { Field, useFormikContext } from 'formik';
import propTypes from 'prop-types';

export default function BrandAutoComplete({ brandTypes }) {
  const { touched, errors } = useFormikContext();

  const brandIDValidationSchema = (value) => {
    return value ? null : 'Required';
  };

  return (
    <Field
      fullWidth ={true}
      name='brand_id'
      validate={brandIDValidationSchema}
      component={Autocomplete}
      options={brandTypes.data}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          error={touched['brand_id'] && !!errors['brand_id']}
          helperText={touched['brand_id'] && !!errors['brand_id'] ? errors['brand_id'] : ''}
          {...params}
          label='Brand name'
          variant='filled'
        />
      )}
    />
  );
}

BrandAutoComplete.propTypes = {
  brandTypes: propTypes.object
};
