import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { Field, useFormikContext } from 'formik';
import BrandAutoComplete from '../../MaterialComponents/BrandAutoComplete';
import MoreInfo from '../../MaterialComponents/MoreInfo';
import MoreInfoPictureContent from '../../MaterialComponents/MoreInfoPictureContent';
import Divider from '@material-ui/core/Divider';
import validator from 'validator';
import { SnapchatPost } from '@lumen-developer/lumen-lookalikes/dist/Previews';
import MenuItem from '@mui/material/MenuItem';
import { Select } from 'formik-mui';
import Rating from '@mui/material/Rating';
import propTypes from 'prop-types';

export default function Snapchat({brandTypes}) {
  const { values, handleChange } = useFormikContext();

  function SnapchatValidationSchema(value, name) {
    let error;
    if (name === 'stim_url') {
      if (!value) {
        error = 'Required';
      } else if (!validator.isURL(value)) {
        error = 'Must be a valid URL';
      } else if (value.length > 512) {
        error = 'Too long';
      }
    }
    if (name === 'sc_channel_name') {
      if (!value) {
        error = 'Required';
      } else if (value.length > 50) {
        error = 'Too long';
      }
    }
    if (name === 'sc_cta') {
      if (!value) {
        error = 'Required';
      }
    }
    if (name === 'sc_description') {
      if (!value) {
        error = 'Required';
      } else if (value.length > 511) {
        error = 'Too long';
      }
    }
    if (name === 'sc_promo_text') {
      if (!value) {
        error = 'Required';
      } else if (value.length > 25) {
        error = 'Too long';
      }
    }
    if (name === 'sc_promo_desc') {
      if (!value) {
        error = 'Required';
      } else if (value.length > 50) {
        error = 'Too long';
      }
    }
    if (name === 'sc_promo_top') {
      if (value.length > 50) {
        error = 'Too long';
      }
    }

    return error;
  }

  return (
    <>
      <Divider variant='middle' />
      <Grid
        style={{ margin: '24px 0px 12px' }}
        container
        wrap='nowrap'
        justifyContent='center'
        item
        xs={12}
      >
        <Typography align='center' variant='h6'>
          Snapchat commercial
        </Typography>
        <MoreInfo
          outerContainerClassName='alignToTop'
          widePaper
          popup={
            <MoreInfoPictureContent
              standardDescription='Lumen has recreated a look-a-like of Snapchat’s Discover Feed which features curated content and “commercial” ads which appear between pieces of content. Each content creator has a title tile which, when tapped, opens up a reel of content from that content creator, split into 15 second sections. If all the content from a creator is viewed it will automatically move on to the next provider in the list. Snapchat is a mobile only platform and therefore not available for desktop tests.'
              illustration='snapchat'
            />
          }
        />
      </Grid>
      <div style={{ overflow: 'hidden', position: 'relative', width: '100%' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '50%',
            padding: 24,
          }}
        >
          <div style={{ height: '667px', width: '375px' }}>
            <SnapchatPost
              stim_url={values.stim_url}
              sc_promo_top={values.sc_promo_top}
              sc_promo_text={values.sc_promo_text}
              sc_promo_desc={values.sc_promo_desc}
              sc_promo_rating={values.sc_promo_rating}
              sc_cta={values.sc_cta}
              sc_channel_name={values.sc_channel_name}
              sc_description={values.sc_description}
            />
          </div>
        </div>
        <div
          style={{
            overflowY: 'scroll',
            height: '100%',
            width: '50%',
            position: 'absolute',
            right: 0,
            top: 0,
            padding: 16,
          }}
        >
          <Grid style={{ width: '100%' }} container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='subtitle1'>
                Please enter the ad URL and choose the brand name
              </Typography>
            </Grid>

            <Grid container wrap='nowrap' item xs={12}>
              <Field
                fullWidth
                name='stim_url'
                component={TextField}
                label='Ad URL'
                variant='filled'
                validate={(value) =>
                  SnapchatValidationSchema(value, 'stim_url')
                }
              />
              <MoreInfo
                outerContainerClassName='moreInfo'
                popup='A link to a webpage only featuring the test ad. We recommend 9 x 16 aspect ratio for Snapchat commercials'
              />
            </Grid>
            <Grid container wrap='nowrap' item xs={12}>
              <BrandAutoComplete brandTypes={brandTypes} />
              <MoreInfo
                outerContainerClassName='moreInfo'
                popup='The brand name of the company featured in the test ad '
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='subtitle1'>
                Now customize the commercial
              </Typography>
            </Grid>
            <Grid container wrap='nowrap' item xs={12}>
              <Field
                fullWidth
                name='sc_channel_name'
                component={TextField}
                label='Profile name'
                variant='filled'
                validate={(value) =>
                  SnapchatValidationSchema(value, 'sc_channel_name')
                }
              />
              <MoreInfo
                outerContainerClassName='moreInfo'
                popup='The profile name as you would like it to appear'
              />
            </Grid>
            <Grid container wrap='nowrap' item xs={12}>
              <Field
                fullWidth
                name='sc_description'
                component={TextField}
                label='Description'
                variant='filled'
                validate={(value) =>
                  SnapchatValidationSchema(value, 'sc_description')
                }
              />
              <MoreInfo
                outerContainerClassName='moreInfo'
                popup='Short title text which appears on the content card'
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='subtitle1'>
                And the promotional card
              </Typography>
            </Grid>
            <Grid container wrap='nowrap' item xs={12}>
              <Field
                fullWidth
                name='sc_promo_text'
                component={TextField}
                label='Promotional text'
                variant='filled'
                validate={(value) =>
                  SnapchatValidationSchema(value, 'sc_promo_text')
                }
              />
              <MoreInfo
                outerContainerClassName='moreInfo'
                popup='Title of the promotional card'
              />
            </Grid>
            <Grid container wrap='nowrap' item xs={12}>
              <Field
                fullWidth
                name='sc_promo_desc'
                component={TextField}
                label='Promotional description'
                variant='filled'
                validate={(value) =>
                  SnapchatValidationSchema(value, 'sc_promo_desc')
                }
              />
              <MoreInfo
                outerContainerClassName='moreInfo'
                popup='Description of the promotional card'
              />
            </Grid>
            <Grid container wrap='nowrap' item xs={12}>
              <Field
                component={Select}
                fullWidth
                variant='filled'
                id='sc_cta'
                name='sc_cta'
                labelId='sc_cta'
                label='Call to action'
                validate={(value) => SnapchatValidationSchema(value, 'sc_cta')}
              >
                <MenuItem value='Learn more'>Learn more</MenuItem>
                <MenuItem value='Shop now'>Shop now</MenuItem>
              </Field>
              <MoreInfo
                outerContainerClassName='moreInfo'
                popup='Hyperlink which guides the user to the next desired step after seeing the ad'
              />
            </Grid>
            <Grid container wrap='nowrap' item xs={12}>
              <Field
                fullWidth
                name='sc_promo_top'
                component={TextField}
                label='Promotional top (optional)'
                variant='filled'
                validate={(value) =>
                  SnapchatValidationSchema(value, 'sc_promo_top')
                }
              />
              <MoreInfo
                outerContainerClassName='moreInfo'
                popup='Optional short title text which appears just above the card'
              />
            </Grid>
            <Grid
              container
              wrap='nowrap'
              style={{ justifyContent: 'space-between' }}
              item
              xs={12}
            >
              <div>
                <Typography style={{ display: 'block' }}>
                  Promotional rating (optional)
                </Typography>
                <Rating
                  name='sc_promo_rating'
                  value={values.sc_promo_rating}
                  onChange={handleChange}
                />
              </div>
              <MoreInfo
                outerContainerClassName='moreInfo'
                popup='Optional average rating'
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <Grid item xs={12}>
        <Divider variant='middle' />
      </Grid>
    </>
  );
}

Snapchat.propTypes = {
  brandTypes: propTypes.object,
};
