import React from 'react';
import Button from '../../common/components/Button';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../common/components/LoadingSpinner'
import { Typography, Paper } from '@material-ui/core';

class Table extends React.Component {

    render() {
        const { loading, data, onClick } = this.props;

        if (loading) {
            return <LoadingSpinner  style={{margin:"80px auto", position: "relative", top: "unset"}} />
        }
        return (
            <div style={{width:"100%"}}>
                    <div style={{padding:"8px 0px 24px", width:"100%"}}>
    <Typography variant="subtitle1">Instruction step</Typography>
    <Typography variant="caption" color="textSecondary">An instruction step explains to the respondent what will be happening next</Typography>
    </div>
                 
                  <Paper style={{padding: "16px 0px", borderRadius: 16, marginBottom:24}} variant="outlined">
            <table style={{ overflow: "initial", padding: 0}} className="table study">
                <tbody>
                <tr>
                    <th>ID</th>
                    <th style={{width:"100%"}}>Name</th>
                    <th>Add step</th>

                </tr>
                {data.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td >{item.step_id}</td>
                            <td >{item.instruction_name}</td>
                            <td ><Button handleClick={() => onClick(item.step_id)} buttonClass="button-primary" text="Add" /></td>
                        </tr>);

                })}
                </tbody>
            </table>
            </Paper>
            </div>
        );
    }
}

export default Table;
Table.propTypes = {
    loading: PropTypes.bool,
    data: PropTypes.array,
    onClick: PropTypes.func
};