import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { editStudyDetailsOperations } from './duck';
import EditStudyDetailsForm from './components/EditStudyDetails';
import PropTypes from 'prop-types';
import Notification from '../CellStepManagement/components/Notification';
import { stimsOperations } from '../Stims/duck/index';
import LoadingSpinner from '.././common/components/LoadingSpinner';
import Paper from '@material-ui/core/Paper';

const EditStudyDetailsContainer = (props) => {
  const {
    environmentLoading,
    mediaLoading,
    studyDetails,
    studyDetailsLoading,
    languageTypes,
    locationTypes,
    questionTypes,
    typesLoading,
    fetchMediaTypes,
    fetchEnvironmentTypes,
    fetchStudyDetails,
    getTypes,
    onSubmit,
    history,
    showNotification,
    match: { params: { id: studyId }},
  } = props;

  useEffect(() => {
    fetchMediaTypes();
    fetchEnvironmentTypes();
    fetchStudyDetails(studyId);
    getTypes();
  }, []);

  const handleSubmit = () => {
    fetchStudyDetails(studyId);
    onSubmit();
    history.push(`/my_studies/overview/${studyId}/`);
  };

  if (typesLoading) {
    return <LoadingSpinner/>;
  }
  else {
    return (
      <Paper style={{ borderRadius:16, padding: 32 }}>
        <EditStudyDetailsForm
          environmentLoading={environmentLoading}
          mediaLoading={mediaLoading}
          studyDetailsLoading={studyDetailsLoading}
          studyDetails={studyDetails}
          onSubmit={handleSubmit}
          id={studyId}
          languageOptions={languageTypes}
          locationTypes={locationTypes}
          questionTypes={questionTypes}
          history={history}
        />
        <Notification showNotification={showNotification} />
      </Paper>
    );
  }
};

const mapDispatchToProps = dispatch => ({
  fetchMediaTypes: () => dispatch(editStudyDetailsOperations.fetchMediaTypes()),
  fetchEnvironmentTypes: () => dispatch(editStudyDetailsOperations.fetchEnvironmentTypes()),
  fetchStudyDetails: (id) => dispatch(editStudyDetailsOperations.fetchStudyDetails(id)),
  getTypes: () => dispatch(stimsOperations.getTypes()),
});

const mapStateToProps = (state) => {
  return {
    mediaLoading: state.editStudyDetails.mediaLoding,
    environmentLoading: state.editStudyDetails.environmentLoading,
    studyDetails: state.editStudyDetails.studyDetails,
    studyDetailsLoading: state.editStudyDetails.studyDetailsLoading,
    showNotification: state.cellStepManagement.showNotification,
    languageTypes: state.stimsReducer.languageTypes,
    locationTypes: state.stimsReducer.locationTypes,
    typesLoading: state.stimsReducer.typesLoading,
    questionTypes: state.stimsReducer.questionTypes,
  };
};

EditStudyDetailsContainer.propTypes = {
  environmentLoading: PropTypes.bool,
  mediaLoading: PropTypes.bool,
  studyDetailsLoading: PropTypes.bool,
  typesLoading: PropTypes.bool,
  studyDetails: PropTypes.object,
  languageTypes: PropTypes.array,
  locationTypes: PropTypes.array,
  questionTypes: PropTypes.array,
  fetchMediaTypes: PropTypes.func,
  fetchEnvironmentTypes: PropTypes.func,
  fetchStudyDetails: PropTypes.func,
  getTypes: PropTypes.func,
  onSubmit: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  showNotification: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditStudyDetailsContainer);
