import React from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import MoreInfo from "../../CreateStudyPage/MaterialComponents/MoreInfo";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  funnelText: {
    width: "320px",
    position: "relative",
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  rowContainerWithBorder: {
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    display: "flex",
    justifyContent: "space-between",
  },
  labelContainer: {
    display: "flex",
    width: 40,
    alignItems: "center",
    gap: 4,
    zIndex: 3,
  },
  normText: {
    width: 160,
    height: 50,
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default function NewAttentionFunnel(props) {
  const classes = useStyles();
  let kpiData = props.kpiData;
  let norms = [
    "Brand Norm",
    "Brand Category Norm",
    "Base Norm",
    "Ad Category Norm",
  ];
  return (
    <Grid container item xs={12}>
      {console.log(props.kpiData.stim_data)}
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          padding: "0px 40px",
        }}
      >
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <div className={classes.normText}>
            <Typography variant="subtitle2">
              {norms[props.kpiData.current_norm_type - 1]}
            </Typography>
          </div>
          <div className={classes.funnelText}>
            <Typography variant="subtitle2">Stim</Typography>
          </div>
        </div>
        <div
          style={{ width: "100%" }}
          className={classes.rowContainerWithBorder}
        >
          <div className={classes.labelContainer}>
            <Typography variant="subtitle2" style={{ whiteSpace: "nowrap" }}>
              % Stim Viewable
            </Typography>
            <MoreInfo popup="Percentage of respondents that were shown this stim" />
          </div>
          <div style={{ display: "flex" }}>
            <div className={classes.normText}>
              <Typography variant="subtitle2">
                {" "}
                {Math.round(
                  [kpiData.norm_data.panelist_viewable_percent] * 10
                ) / 10}
                %
              </Typography>
            </div>
            <div
              className={classes.funnelText}
              style={{
                backgroundColor: "#42a5f5",
                clipPath: `polygon( 100% 0%, 0% 0%, 8% 100%, 92% 100%)`,
              }}
            >
              <Typography variant="subtitle2" component="span">
                {Math.round(
                  [kpiData.stim_data.panelist_viewable_percent] * 10
                ) / 10}
                %
              </Typography>
            </div>
          </div>
        </div>
        <div
          className={classes.rowContainerWithBorder}
          style={{
            width: "calc(100% - 26px)",
            marginRight: 26,
          }}
        >
          <div className={classes.labelContainer}>
            <Typography variant="subtitle2" style={{ whiteSpace: "nowrap" }}>
              Average Stim Viewable Time
            </Typography>
            <MoreInfo popup="Average duration the repondents were shown this stim" />
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                left: 26,
              }}
              className={classes.normText}
            >
              <Typography variant="subtitle2">
                {Math.round([kpiData.norm_data.avg_stim_viewable_time] * 10) /
                  10}
                s
              </Typography>
            </div>
            <div
              className={classes.funnelText}
              style={{
                left: 26,

                backgroundColor: "#66bb6a",
                clipPath: `polygon( 92% 0%, 8% 0%, 16% 100%, 84% 100%)`,
              }}
            >
              <Typography variant="subtitle2" component="span">
                {Math.round([kpiData.stim_data.avg_stim_viewable_time] * 10) /
                  10}
                s
              </Typography>
            </div>
          </div>
        </div>

        <div
          className={classes.rowContainerWithBorder}
          style={{
            width: "calc(100% - 51px)",
            marginRight: 51,
          }}
        >
          <div className={classes.labelContainer}>
            <Typography variant="subtitle2" style={{ whiteSpace: "nowrap" }}>
              % Stim Viewed
            </Typography>
            <MoreInfo popup="Percentage of respondents who actually looked at this stim" />
          </div>

          <div style={{ display: "flex" }}>
            <div
              style={{
                left: 51,
              }}
              className={classes.normText}
            >
              <Typography variant="subtitle2">
                {Math.round([kpiData.norm_data.panelist_viewed_percent] * 10) /
                  10}
                %
              </Typography>
            </div>
            <div
              className={classes.funnelText}
              style={{
                left: 51,

                backgroundColor: "#ffa726",
                clipPath: `polygon( 84% 0%, 16% 0%, 24% 100%, 76% 100%)`,
              }}
            >
              <Typography variant="subtitle2" component="span">
                {Math.round([kpiData.stim_data.panelist_viewed_percent] * 10) /
                  10}
                %
              </Typography>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "calc(100% - 77px)",
            marginRight: 77,
          }}
          className={classes.rowContainerWithBorder}
        >
          <div className={classes.labelContainer}>
            <Typography variant="subtitle2" style={{ whiteSpace: "nowrap" }}>
              Average Stim Dwell Time
            </Typography>

            <MoreInfo popup="Average time respondents actually looked at this stim" />
          </div>
          <div style={{ display: "flex" }}>
            <div
              className={classes.normText}
              style={{
                left: 77,
              }}
            >
              <Typography variant="subtitle2">
                {Math.round([kpiData.norm_data.avg_stim_dwell_time] * 10) / 10}s
              </Typography>
            </div>
            <div
              className={classes.funnelText}
              style={{
                left: 77,

                backgroundColor: "#7e57c2",
                clipPath: `polygon( 76% 0%, 24% 0%, 32% 100%, 68% 100%)`,
              }}
            >
              <Typography
                variant="subtitle2"
                component="span"
                style={{ color: "white" }}
              >
                {Math.round([kpiData.stim_data.avg_stim_dwell_time] * 10) / 10}s
              </Typography>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "calc(100% - 102px)",
            marginRight: 102,
          }}
          className={classes.rowContainerWithBorder}
        >
          <div className={classes.labelContainer}>
            <Typography variant="subtitle2" style={{ whiteSpace: "nowrap" }}>
              Attention Per 1000 Impressions (s)
            </Typography>

            <MoreInfo
              popupArray={[
                "Sum total of attention paid to this ad per 1000 impressions",
                "Attention per 1000 impressions is calculated as follows:",
                "% viewable x % viewed x Avg stim dwell time x 1000",
              ]}
            />
          </div>
          <div style={{ display: "flex" }}>
            <div
              className={classes.normText}
              style={{
                left: 102,
              }}
            >
              <Typography variant="subtitle2">
                {" "}
                {kpiData.norm_data.attention_per_1000_impressions
                  ? `${Math.round([
                      kpiData.norm_data.attention_per_1000_impressions,
                    ])}s`
                  : ""}
              </Typography>
            </div>

            <div
              className={classes.funnelText}
              style={{
                left: 102,
                backgroundColor: "#26a69a",
                clipPath: `polygon( 68% 0%, 32% 0%, 32% 100%, 68% 100%)`,
              }}
            >
              <Typography variant="subtitle2" component="span">
                {Math.round([kpiData.stim_data.attention_per_1000_impressions])}
                s
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
}
