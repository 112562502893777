import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import PictureInPictureIcon from '@mui/icons-material/PictureInPicture';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import Tooltip from "@material-ui/core/Tooltip";
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function DownloadBatchDataButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (buttonClicked) => {
    setAnchorEl(null);
    if (buttonClicked === "participant") {
        props.downloadParticipantData()
    }
    else if (buttonClicked === "stim") {
        props.downloadStimData()
    }
    else if (buttonClicked === "feature") {
        props.downloadFeatureData()
    }
    else if (buttonClicked === "kpiTable") {
      props.downloadGeneralKpiData()
    }
      else if (buttonClicked === "featureTable") {
        props.downloadFeatureKpiData()
    }
  };

  return (
    <div>
      <Tooltip placement="bottom" title={props.tooltip} >
      <Button
      
       style={{ padding: "3px 9px", border: "white" , background:  "white", color: "rgb(61, 73, 119)", borderRadius: 8, textTransform: "capitalize" }}
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        
        disableElevation
        onClick={handleClick}
       
        endIcon={<KeyboardArrowDownIcon />}
      >
        {props.buttonVersion === "primary" ? "Download all data" : "Download data" }
      </Button>
      </Tooltip>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
             <Tooltip placement="bottom" title="Download questionnaire data">
        <MenuItem onClick={() => handleClose("participant")}  disableRipple>
        <GroupRoundedIcon />
          Participant data
        </MenuItem>
        </Tooltip>
        <Tooltip placement="bottom" title="Download eye-tracking data for this stimulus">
        <MenuItem onClick={() => handleClose("stim")}  disableRipple>
          <CropOriginalIcon />
          Stim data
        </MenuItem>
        </Tooltip>
        <Tooltip placement="bottom" title="Download eye tracking data for each AOI">
        <MenuItem onClick={() => handleClose("feature")} disableRipple>
       
          <PictureInPictureIcon />
          Feature data
        </MenuItem>
        </Tooltip>
        {props.downloadGeneralKpiData ?
          <Tooltip placement="bottom" title="Download the key attention metrics table">
            <MenuItem onClick={() => handleClose("kpiTable")} disableRipple>
              <ViewListOutlinedIcon />
              Key attention metrics table
            </MenuItem>
          </Tooltip>
          : ""}
        {props.downloadFeatureKpiData ?
          <Tooltip placement="bottom" title="Download the key feature metrics table">
            <MenuItem onClick={() => handleClose("featureTable")} disableRipple>
              <ViewListOutlinedIcon />
              Key feature metrics table
            </MenuItem>
          </Tooltip>
          : ""}
      </StyledMenu>
    </div>
  );
}