import React from "react";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { useField} from "formik";



export default function CallToActionCheckBox(props) {
  const [field, meta, helpers] = useField(props.name);

  const handleChange = (event) => {
    helpers.setValue(event.target.value);
  };

  return (
    <>
      <FormControl  {...field} error={meta.touched && meta.error} component="fieldset" >
        <FormLabel component="legend">Call to action</FormLabel>
        <RadioGroup  name={props.name} value={field.value} onChange={handleChange}>
        <FormControlLabel value="Shop now" control={<Radio color="primary" />} label="Shop now" />
        <FormControlLabel value="Learn more" control={<Radio color="primary" />} label="Learn more" />
        </RadioGroup>
       
            <FormHelperText error >{meta.error}</FormHelperText>

      </FormControl>
    </>
  );
}
