import React, { useEffect, useRef } from 'react';
import BatchTableContainer from './containers/BatchDataTableContainer';
import { connect } from 'react-redux';
import { batchStimsOperations } from '../Batches/duck/index';
import LoadingSpinner from '../common/components/LoadingSpinner';
import PropTypes from 'prop-types'
import { stimsOperations } from '../Stims/duck/index';
import { addAllType } from '../Dashboard/DashboardContainer';
import Paper from "@material-ui/core/Paper"
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TablePagination from "../common/components/Pagination"

function BatchStims({ getBatchStimsData, batchStimsLoading, stimTypes,  getBatchStimsListData, batchStimsList }) {
  const myTimer = useRef(null)
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [filterName, setfilterName] = React.useState("");
  const [filterStimType, setfilterStimType] = React.useState("");
  const [orderBy, setorderBy] = React.useState("name_asc");


  useEffect(() => {
    if (batchStimsLoading) {
      return (<LoadingSpinner />)
    }
    getBatchStimsData({});
    getBatchStimsListData(rowsPerPage, page, filterName, filterStimType, orderBy )
  }, []);

  const handleSearchChange = (newfilterName) => {
    setfilterName(newfilterName)
    clearTimeout(myTimer.current)
    myTimer.current = setTimeout(() => {
      getBatchStimsListData(parseInt(rowsPerPage), 1, newfilterName, filterStimType, orderBy )
    }, 500);
  }

  const handleSortChange = (newOrderBy) => {
    setorderBy(newOrderBy)
    getBatchStimsListData(parseInt(rowsPerPage), 1, filterName, filterStimType, newOrderBy )
  }

  const handlefilterStimTypeChange = (newfilterStimType) => {
    setfilterStimType(newfilterStimType === "All" ? "" : newfilterStimType);
    getBatchStimsListData(parseInt(rowsPerPage), 1, filterName, newfilterStimType === "All" ? "" : newfilterStimType, orderBy );
  }

  const handleChangePage = (event, newPage) => {
    if (newPage) {
      setPage(newPage);
      getBatchStimsListData(parseInt(rowsPerPage), parseInt(newPage), filterName, filterStimType, orderBy )
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    getBatchStimsListData(parseInt(event.target.value), 1, filterName, filterStimType, orderBy )
  };

  return (
    <Paper  style={{ borderRadius:16, }}>
    <div style={{paddingBottom:16}} className="stims">
      <div style={{padding:16, borderRadius:16, border: "none", display: "flex", justifyContent: "space-between"}} >
       <div style={{display: "grid",gap:16, width:"35%", gridTemplateColumns: "repeat(2, minmax(0, 1fr))" }} >
        <TextField
          style={{zIndex:0, flexGrow: 1}}
          onChange={(e) => handleSearchChange(e.target.value)}
          value={filterName}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          id="outlined-basic" label="Search by name" variant="outlined" />
          <FormControl   style={{zIndex:0, flexGrow: 1}} fullWidth>
          <InputLabel id="demo-simple-select-label">Stim Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Stim types"
            value={filterStimType}
            onChange={(e) => handlefilterStimTypeChange(e.target.value)}
          >
            {stimTypes.map(((item, index) =>  <MenuItem key={index} value={item.description}>{item.description}</MenuItem>))}
          </Select>
        </FormControl>
        </div>
      </div>
      <div style={{ marginRight: 8}}>
      <TablePagination   rowsPerPage={batchStimsList.results_per_page}  onRowsPerPageChange={handleChangeRowsPerPage} numberOfPages={batchStimsList.total_page_num} currentPage={batchStimsList.page_num} onPageChange={handleChangePage}  />
      </div>
      <div className="stims__table-container">
        <BatchTableContainer handleSortChange={handleSortChange} currentOrderBy={orderBy} />
      </div>
      <div style={{ marginRight: 8}}>
      <TablePagination   rowsPerPage={batchStimsList.results_per_page}  onRowsPerPageChange={handleChangeRowsPerPage} numberOfPages={batchStimsList.total_page_num} currentPage={batchStimsList.page_num} onPageChange={handleChangePage}  />
       </div>
    </div>
  </Paper>
  );
}

function mapStateToProps(state) {
  return {
    environmentTypes: state.stimsReducer.environmentTypes,
    mediaTypes: addAllType(state.stimsReducer.mediaTypes),
    stimTypes: addAllType(state.stimsReducer.stimTypes),
    loading: state.stimsReducer.loading,
    batchStimsList: state.batchStimsReducer.batchStimsList

  }
}


function mapDispatchToProps(dispatch) {
  return {
    getBatchStimsData: (data) => dispatch(batchStimsOperations.getBatchStimsData(data)),
    setBatchFilter: (filter, currentSort) => dispatch(batchStimsOperations.setBatchFilter(filter, currentSort)),
    setMediaFilter: (filter) => dispatch(stimsOperations.setStimFilter(filter)),
    getBatchStimsListData: (rowsPerPage, page, filterName, filterStimType, orderBy) => dispatch(batchStimsOperations.getBatchStimsList(rowsPerPage, page, filterName, filterStimType, orderBy))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BatchStims);

BatchStims.propTypes = {
  getBatchStims: PropTypes.func,

};
