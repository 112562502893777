import React from 'react';
import Task from './Task';
import { Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
// import Button from '../../common/components/Button';
import { Paper, Typography,Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField'
import { withStyles } from "@material-ui/core/styles";

const TaskList = styled.div`
`;

const colors = ["#42a5f5",  "#66bb6a", "#ffa726", "#7e57c2", "#26a69a", "#ffca28","#ff7043","#5c6bc0", "#ab47bc", "#26c6da", "#ffee58", "#ec407a", "#29b6f6", "#d4e157", "#ef5350"]

const styles = {
  paperStandard: {
    width: "300px",
    padding: 16,
    borderRadius: 6
  },
  paperDense: {
    width: "180px",
    padding: 8,
    borderRadius: 6
  },
  cellTitleStandard:{
    display:"flex", justifyContent: "space-between"
  },
  cellTitleDense:{
    display:"flex",  flexDirection:"column-reverse", 
  },
  titleDense: {
    fontSize: "1rem"
  },
  subTitleDense: {
    fontSize: "10px"
  }
  
};

class Column extends React.Component {
  state = {
    title: this.props.column.cellName,
    titleFormFocus: false,
    shortName: this.props.column.shortName || ''

  }

  onTitleType = (e) => {
    const title = e.target.value;
    this.setState({ title })
  }

  onShortNameType = (e) => {
    const shortName = e.target.value;
    this.setState({ shortName })
  }

  onTitleClickToggle = () => {
    this.setState(prevState => ({ titleFormFocus: !prevState.titleFormFocus}))
  }

  onTitleChange = (e, TYPE) => {
    const { column, onTitleChange } = this.props;

    const { title, shortName } = this.state;
    if (TYPE === 'TITLE') {
      onTitleChange(column.id, title, TYPE)
    } else {
      onTitleChange(column.id, shortName, TYPE)
    }
    this.onTitleClickToggle()

  }


  render() {
    const { column, handleClick, tasks, onDeleteClick, onHamburburgerClick, allowEditing } = this.props;
    const { titleFormFocus } = this.state;
    const { classes } = this.props;

    return (
      <Paper elevation={this.props.density === "dense" ? 2 : 3} className={ this.props.density === "dense" ? classes.paperDense : classes.paperStandard} style={{ borderTop: this.props.density === "dense" ? `6px solid ${colors[this.props.indexOrder]}` :  `8px solid ${colors[this.props.indexOrder]}`}}>
        <Droppable droppableId={JSON.stringify(column.id)}>
          {provided => (
            <TaskList className="csm__task-list"
                style={{border:"unset", margin:"unset"}}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <div className={ this.props.density === "dense" ? classes.cellTitleDense : classes.cellTitleStandard} >
                <div>
                  {titleFormFocus ?  <>
                        <TextField style={{margin:"8px 4px 8px 1px"}} size="small" id="long" label="Long name" variant="outlined" onBlur={(e) => this.onTitleChange(e, 'TITLE')}  onChange={this.onTitleType} value={this.state.title} />
                        <TextField style={{margin:"8px 4px 8px 1px"}} size="small" inputProps={{ maxLength: 20 }} id="short" label="Short name" variant="outlined" maxLength={4}  onBlur={(e) => this.onTitleChange(e, 'SUBTITLE')}  onChange={this.onShortNameType} value={this.state.shortName}/>
                  
                     </> : <>  
                     <Tooltip arrow placement="left" disableHoverListener={column.cellName.length <= 19}  title={column.cellName}>
                  <Typography variant ="h6" className={ this.props.density === "dense" ? classes.titleDense : ""} >
                      {column.cellName.length > 19 ? `${column.cellName.substring(0,18)}...` : column.cellName }
                  </Typography>
                  </Tooltip>
                  
                  <Typography className={ this.props.density === "dense" ? classes.subTitleDense : ""}  style={{margin:0}} variant ="body2" color="textSecondary" >
                 {column.shortName}
                  </Typography>
                </>
                   }
               
        
             </div>
                <div style={{whiteSpace: "nowrap", marginBottom: 4, alignSelf: this.props.density === "dense" ? "flex-end" : "" }} >
                <Tooltip arrow placement="top" title="Rename cell">
                <IconButton onClick={this.onTitleClickToggle} style={{borderRadius: "6px", margin:2}} aria-label="delete"  size="small">
                  <EditOutlinedIcon fontSize="inherit" />
                </IconButton>
                </Tooltip>
                <Tooltip arrow placement="top" title="Number of steps">
                <Typography noWrap variant="subtitle2" style={{display: "inline",margin:2, background: "rgba(85, 105, 255, 0.1)", color: "rgb(85, 105, 255)",maxHeight: "24px", padding: "2px 8px", borderRadius: "6px"}} >
                  <b>{tasks.length}</b>
                </Typography>
                </Tooltip>
                </div>
              </div>

              {tasks.map((task, index) => (
                <Task
                density = {this.props.density}
                  onDeleteClick={(id) => onDeleteClick(id, column.id)}
                  onHamburburgerClick={(id) => onHamburburgerClick(id, column.id)}
                  key={task.step_order_id}
                  task={task}
                  index={index}
                  allowEditing={allowEditing}
                />
              ))}
              {provided.placeholder}

            </TaskList>
          )}
        </Droppable>
        {
          allowEditing ?

          <Tooltip arrow placement="top" title="Add new step">
          <IconButton onClick={() => handleClick(column.id)} style={{ margin:2, width:"100%", background: "rgba(34, 51, 84, 0.1)", padding:8, borderRadius:6}} aria-label="delete" >
            <AddIcon style={{color: "rgb(110, 117, 159)"}} fontSize="inherit" />
          </IconButton>
          </Tooltip>

            // <div className="csm__add-step-container">
            //   <Button handleClick={() => handleClick(column.id)} buttonClass="csm__button-add" />
            // </div>
             :
            null
        }

      </Paper >);
  }
}

export default (withStyles(styles)(Column))
