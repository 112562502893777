import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useField } from 'formik';
import { Typography, Grid } from '@material-ui/core';
import propTypes from 'prop-types';

export default function MaterialSwitch({name, label}) {
  const [field, meta, helpers] = useField(name);
  return (
    <Grid container alignItems='center' justifyContent='space-between'>
      <Typography variant='subtitle1'>{label}</Typography>
      <FormGroup {...field}>
        <FormControlLabel
          labelPlacement='start'
          control={<Switch color='primary'
            checked={meta.value}
            onChange={()=> helpers.setValue(meta.value ? false : true)}
          />}
        />
      </FormGroup>
    </Grid>
  );
}

MaterialSwitch.propTypes = {
  name: propTypes.string,
  label: propTypes.string,
};
