import React, { Component } from 'react';
import Utils from '../../../common/utils/Utils';


class AttentionTableExplanations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            explanation: [ "Number of viewable script executed impressions \u00F7 number of total participants", "Total Page dwell time (s) \u00F7 number of valid impressions", "Number of viewable impressions \u00F7 number of valid impressions.", "Total viewable time (s) \u00F7 number of viewable impressions.", "Number of viewed impressions \u00F7 number of viewable impressions", "Total dwell stim time (s) \u00F7 number of viewed impressions"]
        }
    }
    render() {
        const { loading } = this.props;

        if (loading) {
            return <div>Loading...</div>;
        }
        const data = this.props.data.data;

        const mySet = new Set();
        mySet.add("panelist_viewable_script_percent");
        mySet.add("avg_page_dwell_time");
        mySet.add("panelist_viewable_percent");
        mySet.add("avg_stim_viewable_time");
        mySet.add("panelist_viewed_percent");
        mySet.add("avg_stim_dwell_time");

        const mySetArray = Array.from(mySet);

        return (
            <table className="table study">
                <tbody><tr>
                    <td></td>
                    {data.data.map((item, index) => {
                        return (
                            <td key={index} colSpan="2">{item.stim_name}</td>
                        );
                    })}
                </tr>
                    <tr>
                        <td></td>
                        {data.data.map((item1, index) => {
                            return ([
                                <td key={2 + index + item1}>Actual</td>,
                                <td key={1 + index + item1}>Norm</td>
                            ]);
                        })}
                    </tr>

                    {mySetArray.map((key, index) => {
                        if (key === 'panelist_total_count' || key === 'panelist_viewed_count' || key === 'panelist_viewable_count') {
                            return null;
                        }
                        return (
                            <tr key={key}>
                                <td>{Utils.Capitalize(key.split('_').join(' '))}</td>
                                <td colSpan={data.data.length * 2}>{this.state.explanation[index]}</td>
                            </tr>

                        );
                    })}




                </tbody></table>
        );
    }
}

export default AttentionTableExplanations;

AttentionTableExplanations.propTypes = {
};

