import types from './types';

const featureAnalysis = (state = {
  featureData: [],
  loading: true,
  filter: '',
  rawFeatureData: [],
  rawLoading: true
}, action) => {
  switch (action.type) {
    case types.FETCH_FEATURE_BEGIN: {
      return {
        ...state,
        featureData:[],
        loading: true,
        filter: "",
        error: null
      };
    }
    case types.FETCH_FEATURE_SUCCESS: {
      return {
        ...state,
        featureData: [...state.featureData, action.payload],
        filter: [...state.filter, "Stim Source"]
        // loading: false
      };
    }

    case types.FETCH_ALL_FEATURE_SUCCESS: {
      return {
        ...state,
        loading: false,
        rawLoading: false
      };
    }

    case types.FETCH_FEATURE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: []
      };
    case types.SET_FEATURE_ANALYSIS_FILTER: {
      return {
        ...state,
        filter: state.filter.map((item, index) => {
          if (index === action.payload.index) {
            return action.payload.filter
          }
          return item
        })          
      };
    }
    case types.FETCH_FEATURE_RAW_BEGIN: {
      return {
        ...state,
        rawLoading: true,
        error: null
      };
    }
    case types.FETCH_FEATURE_RAW_SUCCESS: {
      return {
        ...state,
        rawFeatureData: action.payload.data.data,
        // rawLoading: false
      };
    }
    case types.FETCH_FEATURE_RAW_ERROR:
      return {
        ...state,
        rawLoading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};


export default featureAnalysis;



