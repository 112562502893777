import React, { useState, useEffect } from 'react';
import {
  Button,
  CircularProgress,
  Step,
  StepLabel,
  Stepper,
  Box,
  Paper,
  Typography,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import propTypes from 'prop-types';

import FullScreenDialog from './MaterialComponents/FullScreenDialog';
import SureYouWantToCancel from './MaterialComponents/SureYouWantToCancel';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
    flexDirection: 'column',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  socialPageSetupLayout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: '75vw',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  title: {
    marginTop: '24px',
    fontSize:'1.8rem'
  }
}));

export function FormStepper({
  children,
  stepImperatively,
  setStepImperatively,
  onSubmit,
  initialValues,
}) {
  const childrenArray = React.Children.toArray(children);
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];
  const [penultimateSubmission, setPenultimateSubmission] = useState(false);
  const classes = useStyles();

  const isLastStep = () => {
    return step === childrenArray.length - 1;
  };

  const isPenultimateStep = () => {
    return step === childrenArray.length - 2;
  };

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, [step]);

  useEffect(() => {
    if (step !== 0 && stepImperatively !== null) {
      setStep(stepImperatively);
    }
    if (stepImperatively !== null){
      setStepImperatively(null);
    }
  }, [stepImperatively]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (penultimateSubmission || isLastStep()) {
          await onSubmit(values, helpers);
        } else {
          setStep((s) => s + 1);
          helpers.setTouched({});
        }
      }}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Form autoComplete='off'>
          <Box className={currentChild.props.label === 'Social Page Setup' ? classes.socialPageSetupLayout : classes.layout}>
            <Paper elevation={2} className={classes.root}>
              <Typography className={classes.title} align='center' color='primary' variant='h5'>
                {childrenArray[step].props.title}
              </Typography>
              <Stepper alternativeLabel activeStep={step}>
                {childrenArray.map((child, index) => (
                  <Step
                    key={child.props.label}
                    completed={step > index}
                  >
                    <StepLabel>{child.props.label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              {currentChild}
              <div className={classes.buttons}>
                <FullScreenDialog icon={false} />
                <div>
                  <SureYouWantToCancel/>
                  {step > 0 ? (
                    <Button
                      className={classes.button}
                      disabled={isSubmitting}
                      onClick={() => setStep((s) => s - 1)}
                    >
                      Back
                    </Button>
                  ) : null}
                  {isPenultimateStep() ? (
                    <Button
                      className={classes.button}
                      startIcon={
                        isSubmitting && penultimateSubmission ? (
                          <CircularProgress size='1rem' />
                        ) : null
                      }
                      onClick={() => {
                        setPenultimateSubmission(true);
                        handleSubmit();
                      }}
                      disabled={isSubmitting}
                      variant='contained'
                      color='primary'
                      type='submit'
                    >
                      Create
                    </Button>
                  ) : (
                    ''
                  )}
                  <Button
                    className={classes.button}
                    startIcon={
                      isSubmitting && !penultimateSubmission ? (
                        <CircularProgress size='1rem' />
                      ) : null
                    }
                    disabled={isSubmitting}
                    variant='contained'
                    color='primary'
                    type='submit'
                  >
                    {
                      isSubmitting ?
                        'Submitting' : isLastStep() ?
                          'Create' : isPenultimateStep() ?
                            'Review' : 'next'
                    }
                  </Button>
                </div>
              </div>
            </Paper>
          </Box>
        </Form>
      )}
    </Formik>
  );
}

FormStepper.propTypes = {
  stepImperatively: propTypes.number,
  setStepImperatively: propTypes.func,
  onSubmit: propTypes.func,
  children: propTypes.arrayOf(propTypes.element),
  initialValues: propTypes.object,
};
