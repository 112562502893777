import React, { useState, useEffect, Children, cloneElement, isValidElement } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './MaterialStyling';
import { CssBaseline } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import { dashboardOperations } from '../Dashboard/duck/index';
import LoadingSpinner from '../common/components/LoadingSpinner';
import { stimsOperations } from '../Stims/duck/index';
import {
  basicDetailsValidationSchema,
  panelDetailsValidationSchema,
  questionDetailsValidationSchema,
} from './ValidationSchema';
import { useDefaultValues } from './DefaultValues';

const CreateStudyContainer = ({
  onAddNewStudySubmit,
  typesLoading,
  languageTypes,
  questionTypes,
  locationTypes,
  brandTypes,
  adTypes,
  environmentData,
  environmentLoading,
  studyTypes,
  mediaData,
  studySubmitError,
  inContextTypes,
  resetError,
  panelTypes,
  panelistRolesTypes,
  getTypes,
  onAddNewStudy,
  children,
}) => {
  const [loading, setLoading] = useState(true);
  const respondentTypes = {
    data: [
      {
        id: 1,
        value: 'Cell',
        description: 'Cell',
      },
      {
        id: 2,
        value: 'Study',
        description: 'Study',
      },
    ]
  };

  const defaultValues = useDefaultValues({
    environmentData,
    languageTypes,
    locationTypes,
    respondentTypes,
  });

  useEffect(() => {
    const initCreateStudy = async () => {
      await getTypes();
      await onAddNewStudy();
      setLoading(false);
    };

    initCreateStudy();

    return () => {
      setLoading(false);
    };
  }, [getTypes, onAddNewStudy]);

  if ( environmentLoading || typesLoading || loading) {
    return <LoadingSpinner />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Snackbar
        open={!studySubmitError.success}
        autoHideDuration={6000}
        onClose={resetError}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      >
        <Alert variant = 'filled' onClose={resetError} severity='error'>
          {studySubmitError.error}
        </Alert>
      </Snackbar>

      {
        Children.map(children, (child) => {
          if (!isValidElement(child)) return null;

          return cloneElement(child, {
            onAddNewStudySubmit: (data, history) => onAddNewStudySubmit(data, history),
            environmentData,
            loading: environmentLoading,
            mediaData,
            studyTypes,
            languageTypes,
            locationTypes,
            questionTypes,
            brandTypes,
            adTypes,
            inContextTypes,
            respondentTypes,
            panelTypes,
            panelistRolesTypes,
            defaultValues,
            basicDetailsValidationSchema,
            panelDetailsValidationSchema,
            questionDetailsValidationSchema,
          });
        })
      }
    </ThemeProvider>
  );
};

CreateStudyContainer.propTypes = {
  onAddNewStudySubmit: PropTypes.func,
  resetError: PropTypes.func,
  typesLoading: PropTypes.bool,
  languageTypes: PropTypes.object,
  questionTypes: PropTypes.object,
  locationTypes: PropTypes.object,
  brandTypes: PropTypes.object,
  adTypes: PropTypes.object,
  environmentData: PropTypes.object,
  environmentLoading: PropTypes.bool,
  studyTypes: PropTypes.object,
  mediaData: PropTypes.object,
  inContextTypes: PropTypes.object,
  panelTypes: PropTypes.object,
  panelistRolesTypes: PropTypes.object,
  studySubmitError: PropTypes.object,
  getTypes: PropTypes.func,
  onAddNewStudy: PropTypes.func,
  children: PropTypes.element,
};

function mapStateToProps(state) {
  return {
    value: state,
    environmentLoading: state.studyData.environmentLoading,
    environmentData: state.studyData.environmentData,
    mediaData: state.studyData.mediaData,
    studyTypes: state.studyData.studyTypes,
    languageTypes: state.studyData.languageTypes,
    questionTypes: state.studyData.questionTypes,
    locationTypes: state.studyData.locationTypes,
    brandTypes: state.studyData.brandTypes,
    adTypes: state.studyData.adTypes,
    panelTypes: state.studyData.panelTypes,
    panelistRolesTypes: state.studyData.panelistRolesTypes,
    inContextTypes: state.studyData.inContextTypes,
    studySubmitError: state.studyData.studySubmitError,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetError: () => dispatch(dashboardOperations.submittingStudyError({error: '', success:true})),
    onAddNewStudy: () => dispatch(dashboardOperations.onAddNewStudy()),
    onAddNewStudySubmit: (data, history) => dispatch(dashboardOperations.onAddNewStudySubmit(data, history)),
    getTypes: () => dispatch(stimsOperations.getTypes()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateStudyContainer);
