import { connect } from 'react-redux';
import React from 'react';
import classNames from 'classnames'
import Button from '../../common/components/Button';
import URL_REGEX from '../../CellStepManagement/duck/constants';
import External from '../../CellStepManagement/components/External';
import CachePage from '../../CellStepManagement/components/CachePage';
import CachePageModal from '../../CellStepManagement/components/CachePageModal';

import StimTable from '../../CellStepManagement/components/StimTable';
import QuestionTable from '../../CellStepManagement/components/QuestionTable';
import InstructionTable from '../../CellStepManagement/components/InstructionTable';
import ExternalTable from '../../CellStepManagement/components/ExternalTable';
import EmbeddedTable from '../../CellStepManagement/components/EmbeddedTable';
import { CellStepManagementOperations } from '../../CellStepManagement/duck/index';
import CommandType from '../../CellStepManagement/components/CommandType';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import StepTypesRadioButtons from '../../CellStepManagement/components/StepTypesRadioButtons';
import { Typography, Paper} from '@mui/material';

import TopOfAddStepTables from "../../CellStepManagement/components/TopOfAddStepTables"


class Form extends React.Component {

  state = {
    value: 'Please Select',
    stepType: '',
    error: false,
    step: 0,
    param: '',
    secondaryValue: 'Please Select',
    url: '',
    name: '',
    submitted: false,
    newItem: false,
    studyId: undefined
  }

  componentDidMount() {
    this.setState({ submitted: false })
  }

  onChange = (e) => {
    const url = e.target.value
    this.setState({ url })
  }

  onParamChange = (e) => {
    const param = e.target.value
    this.setState({ param })
  }

  onNameChange = (e) => {
    const name = e.target.value
    this.setState({ name })
  }

  handleClick = (id, value, studyId, cacheId) => {

    const { stepType, param, url, name } = this.state;

    switch (stepType) {
      case ('Validation'):
        this.onHandleClickComplete(id, value, studyId, stepType, null)
        break;
      case ('Calibration'):
        this.onHandleClickComplete(id, value, studyId, stepType, null)
        break;
      case ('Question'):
        value = `question:${id}`
        this.onHandleClickComplete(id, value, studyId, stepType, cacheId)
        break;
      case ('Embedded'):
        if (this.state.newItem === true) {
          if (!this.validateUrl(url)) {
            this.setState({ error: true })
          } else {
            value = url;
            this.onHandleClickComplete(id, value, studyId, stepType, param, name, this.state.newItem);
          }
          break;
        } else {
          value = `embedded:${id}`
          this.onHandleClickComplete(id, value, studyId, stepType, cacheId)
          break;
        }
      case ('External'):
        if (this.state.newItem === true) {
          if (!this.validateUrl(url)) {
            this.setState({ error: true })
          } else {
            value = url;
            this.onHandleClickComplete(id, value, studyId, stepType, param, name, this.state.newItem);
          }
          break;
        } else {
          value = `external:${id}`
          this.onHandleClickComplete(id, value, studyId, stepType, cacheId)
          break;
        }
      case 'Instruction':
        value = `instruction:${id}`
        this.onHandleClickComplete(id, value, studyId, stepType, cacheId)
        break;
      case 'Cache Page':
        value = `cache:${id}`
        this.onHandleClickComplete(id, value, studyId, stepType, cacheId)
        break;
      case "Stim":
        value = `stim:${id}`
        this.onHandleClickComplete(id, value, studyId, stepType, cacheId)
        break;

      case "Command":
        value = `command:${id}`
        this.onHandleClickComplete(id, value, studyId, stepType, cacheId)
        break
      default:
        this.setState({ error: true })
    }
  }

  onHandleClickComplete(id, value, studyId, stepType, cacheId, name, newItem=false) {
    this.props.addTask(id, value, studyId, stepType, cacheId, name, newItem)
    this.resetForm()
  }


  onSelectItem = (stepType) => {
    this.setState({ submitted: false})
    var elems = document.getElementsByTagName("input");
    var l = elems.length;
    for (var i = 0; i < l; ++i){
      elems[i].value="";
    }
    this.props.onSearch("");
    this.props.resetStateToLoading();
    const studyId = localStorage.getItem('studyId');
    this.setState({ stepType: stepType, value: stepType, studyId: studyId })
    this.props.onSelectItem(stepType, studyId)
    if (stepType === 'External' || 'Instuction') {
      this.setState({ error: false })
    }

  }

  validateUrl(url) {
    return URL_REGEX.test(url);
  }

  resetForm() {
    this.props.closeModal()
    this.setState({ stepType: '', value: 'Please Select', url: '', error: false, submitted: true, newItem: false, secondaryValue: 'Please Select'})
  }

  toggleItem(){
    this.setState({
      newItem: !this.state.newItem
    })
  }



  onCachePageSumbit = (cacheUrl, cacheName) => {
    this.props.onCachePageSumbit(cacheUrl, cacheName, this.state.studyId)
  }

  render() {
    const { modalOpen, id, dataFromPrimarySelection, studyId, loading, onSearch, openCacheModal, closeCacheModal, cachePageModalOpen } = this.props;
    const { value, stepType, error, url } = this.state;
    const showSubmitButton = (stepType === 'Calibration') || (stepType === 'Validation') ? true : false;
    return (
      // <Backdrop  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      // open={modalOpen}   >
        <Dialog  open={modalOpen} onClose={() => this.resetForm()}  >
      <form style={{border:"unset", maxWidth:"1100px", width:"70vw", borderRadius: "4px", overflowY: "hidden", boxShadow: "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)" }} className={classNames('csm-modal-form large cell-type', { 'active': modalOpen })}>

        <DialogTitle style={{textAlign: "left", fontSize: "1.25rem", fontWeight:500,  padding:"20px 32px", background: "#00badb", color: "white"}}>Create new step</DialogTitle>
        <Button handleClick={() => this.resetForm()} buttonClass="filter__exit-button" />
   
        <div style={{ background:"white",  overflowY: "scroll", padding:"20px 24px" }}  className="csm-modal-form__content-container">
        <Typography variant="subtitle1" style={{margin:"8px 0px 24px", alignSelf: "flex-start"}}>Please choose a step you would like to add </Typography>
  <StepTypesRadioButtons onSelectItem={(item) => this.onSelectItem(item, studyId)} />
 
          {/* <PrettyDropDown submitted={this.state.submitted} title={this.state.value} onSelectItem={(item) => this.onSelectItem(item, studyId)} options={options} /> */}
          {
            (() => {
              switch (stepType) {
                case ('External'):
                 
                    return (<React.Fragment>
                         <div style={{width:"100%"}}>
                         <div style={{padding:"8px 0px 24px", width:"100%"}}>
    <Typography variant="subtitle1">External step</Typography>
    <Typography variant="caption" color="textSecondary">An external step is a redirection within the study to a website outside of Lumen's domain (e.g. a study platform like Alchemer)</Typography>
    </div> 
                    <Paper style={{ marginBottom:24, padding: "16px 0px", borderRadius: 16}} variant="outlined">
                    {!this.state.newItem ? <>  <TopOfAddStepTables button buttonText ="Create new external step" buttonClick={() => this.toggleItem()} textFieldLabel= "Search external steps" textFieldChange={onSearch} />
                    <ExternalTable onClick={(cacheId) => this.handleClick(id, stepType, studyId, cacheId)} loading={loading} data={dataFromPrimarySelection} /> </> :
                  <><External title="Add external step" addEmbeddedStep = {() => this.handleClick(id, value, studyId)} toggleItem={() => this.toggleItem()} showParamInput={true} value={url} onChange={this.onChange} onParamChange={this.onParamChange} onNameChange={this.onNameChange} />
                 </> }
                    </Paper>
                    </div>
                     </React.Fragment>);
                
                case ('Embedded'):
                
                    return (<React.Fragment>
                       <div style={{width:"100%"}}>
                       <div style={{padding:"8px 0px 24px", width:"100%"}}>
    <Typography variant="subtitle1">Embedded step</Typography>
    <Typography variant="caption" color="textSecondary">An embedded step shows a webpage created by a third party (including Lumen's eye-tracking script), not hosted on a Lumen domain, but shown as part of a Lumen study</Typography>
    </div> 
                    <Paper style={{ marginBottom:24, padding: "16px 0px", borderRadius: 16}} variant="outlined">
                    {!this.state.newItem ? <><TopOfAddStepTables button buttonText ="Add Embedded step" buttonClick={() => this.toggleItem()} textFieldLabel= "Search embedded steps" textFieldChange={onSearch} />
                  <EmbeddedTable onClick={(cacheId) => this.handleClick(id, stepType, studyId, cacheId)} loading={loading} data={dataFromPrimarySelection} /> </> :
                  <><External title="Add embedded step" addEmbeddedStep = {() => this.handleClick(id, value, studyId)} toggleItem={() => this.toggleItem()} showParamInput={true} value={url} onChange={this.onChange} onParamChange={this.onParamChange} onNameChange={this.onNameChange} />
                 </> }
                      </Paper>
                      </div></React.Fragment>);
               
                case "Instruction":
                  return (<React.Fragment> <InstructionTable onSearch={onSearch} onClick={(cacheId) => this.handleClick(id, stepType, studyId, cacheId)} loading={loading} data={dataFromPrimarySelection} /></React.Fragment>)
                case 'Cache Page':
                  return (<React.Fragment>
                    <div style={{width:"100%"}}>
                    <div style={{padding:"8px 0px 24px", width:"100%"}}>
    <Typography variant="subtitle1">Cache page step</Typography>
    <Typography variant="caption" color="textSecondary">A cache page step is a webpage that has been copied and hosted by Lumen, usually containing one or more ads which will be reported on</Typography>
    </div>
<Paper style={{padding: "16px 0px", borderRadius: 16, marginBottom:24}} variant="outlined">
                  
                    <CachePageModal onCachePageSumbit={this.onCachePageSumbit} closeCacheModal={closeCacheModal} active={cachePageModalOpen} />
                   <TopOfAddStepTables button buttonText ="Create new Page" buttonClick={() => openCacheModal()} textFieldLabel= "Search cache pages" textFieldChange={onSearch} />
                    <CachePage onClick={(cacheId) => this.handleClick(id, stepType, studyId, cacheId)} loading={loading} cachePage={dataFromPrimarySelection} />
                    </Paper>
      </div>
                  </React.Fragment>)
                case "Stim":
                  return (
                    <div style={{width:"100%"}}>
                                        <div style={{padding:"8px 0px 24px", width:"100%"}}>
    <Typography variant="subtitle1">Stim step</Typography>
    <Typography variant="caption" color="textSecondary">A stim step exclusively shows a single piece of stimulus</Typography>
    </div> 
                    <Paper style={{padding: "16px 0px", borderRadius: 16}} variant="outlined">
                      <TopOfAddStepTables  textFieldLabel= "Search stims" textFieldChange={onSearch} />
                  <StimTable onClick={(cacheId) => this.handleClick(id, stepType, studyId, cacheId)} loading={loading} data={dataFromPrimarySelection} />
                  </Paper>
                  </div>)
                case "Question":
                  return (<React.Fragment>
                     <div style={{width:"100%"}}>
                     <div style={{padding:"8px 0px 24px", width:"100%"}}>
    <Typography variant="subtitle1">Question step</Typography>
    <Typography variant="caption" color="textSecondary">A question step shows either a question or set of questions hosted within the Lumen Research Question Platform (LRQP)</Typography>
    </div> 
                    <Paper style={{ marginBottom:24, padding: "16px 0px", borderRadius: 16}} variant="outlined">
                    
                    <TopOfAddStepTables  textFieldLabel= "Search question steps" textFieldChange={onSearch} />
                     <QuestionTable onClick={(cacheId) => this.handleClick(id, stepType, studyId, cacheId)} loading={loading} data={dataFromPrimarySelection} />
                    </Paper>
                    </div>
                    </React.Fragment>)
                  case "Command":
                    return (<React.Fragment><CommandType data={dataFromPrimarySelection} loading={loading}  onClick={(cacheId) => this.handleClick(id, stepType, studyId, cacheId)} /></React.Fragment>)
                   
                 case "Validation":
                    return (<React.Fragment>
                    <div style={{padding:"16px 0px", width:"100%"}}>
                    <Typography variant="subtitle1">Validation step</Typography>
                    <Typography variant="caption" color="textSecondary">A Validation step confirms the accuracy and precision of the respondent's eye-tracking</Typography>
                    </div>
                    </React.Fragment>)
                default:
                  return null
              }
            }
            )()
          }
          <div className={classNames('csm__error-message', { 'active': error })}>Please enter a valid URL</div>
          {showSubmitButton ?
            <Button handleClick={() => this.handleClick(id, value, studyId)} text={`Add ${this.state.stepType} step`} buttonClass="button-primary show-study__button" /> :
            null
          }

        </div>
      </form>
      </Dialog>
      // </Backdrop>
    );
  }
}



const sortFuncs = {
  instruction: (data, term, loading) => {
    return sortFuncs.filter(data, term, 'instruction_name', loading)
  },
  default: (data) => {
    return data
  },
  stim: (data, term) => {
    return sortFuncs.cache(data, term)
  },
  cache: (data, term, loading) => {
    return sortFuncs.filter(data, term, 'name', loading)
  },
  validation: (data) => {
    return data
  },
  calibration: (data) => {
    return data
  },
  question: (data, term, loading) => {
    return sortFuncs.cache(data, term)
  },
  external: (data, term, loading) => {
    return sortFuncs.cache(data, term)
  },
  embedded: (data, term) => {
    return sortFuncs.cache(data, term)
  },
  command: (data) => {
    return data
  },
  filter: (data, term, obj) => {
    try {
      return data.filter((val) => val[obj].toUpperCase().includes(term.toUpperCase()));
    } catch (e) {
      return data
    }
  }
}


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    closeModal: () => (dispatch(CellStepManagementOperations.closeModal())),
    resetStateToLoading: () => dispatch(CellStepManagementOperations.resetStateToLoading()),
    onSelectItem: (item, id, studyId) => (dispatch(CellStepManagementOperations.onSelectItem(item, id, studyId))),
    addTask: (id, value, studyId, item, cacheId, name, newItem) => (dispatch(CellStepManagementOperations.addTask(id, value, studyId, item, cacheId, name, newItem))),
    onSearch: (value) => (dispatch(CellStepManagementOperations.onSearch(value))),
    closeCacheModal: () => (dispatch(CellStepManagementOperations.closeCacheModal())),
    openCacheModal: () => (dispatch(CellStepManagementOperations.openCacheModal())),
    onCachePageSumbit: (url, name, studyId) => (dispatch(CellStepManagementOperations.onCachePageSumbit(url, name, studyId))),

  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    modalOpen: state.cellStepManagement.modalOpen,
    id: state.cellStepManagement.id,
    loading: state.cellStepManagement.dropdownLoading,
    options: state.cellStepManagement.initialDropDown,
    dataFromPrimarySelection: sortFuncs[state.cellStepManagement.stepType](state.cellStepManagement.dataFromPrimarySelection, state.cellStepManagement.searchTerm),
    cachePageModalOpen: state.cellStepManagement.cachePageModalOpen
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);


