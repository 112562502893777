import React from 'react';
import PropTypes from 'prop-types';

import DigitalRichStepper from './DigitalRichStepper';
import CreateStudyContainer from '../CreateStudyContainer';

const DigitalRichContainer = ({history}) => {
  return (
    <CreateStudyContainer>
      <DigitalRichStepper {...{history}} />
    </CreateStudyContainer>
  );
};

DigitalRichContainer.propTypes = {
  history: PropTypes.object,
};

export default DigitalRichContainer;
