import React, { useState } from 'react';
import { object, string, mixed } from 'yup';
import propTypes from 'prop-types';

import Review from '../ReviewPage/Review';
import { FormStepper } from '../FormStepper';
import { BasicDetails } from '../Steps/BasicDetails';
import { QuestionDetails } from '../Steps/QuestionDetails';
import { PanelDetails } from '../Steps/PanelDetails';
import RichWebPageSetup from '../Steps/RichWebPageSetup';

export default function DigitalRichStepper({
  onAddNewStudySubmit,
  environmentData,
  locationTypes,
  languageTypes,
  questionTypes,
  brandTypes,
  respondentTypes,
  defaultValues,
  basicDetailsValidationSchema,
  panelDetailsValidationSchema,
  questionDetailsValidationSchema,
  history,
}) {

  const [stepImperatively, setStepImperatively] = useState(null);

  return (
    <FormStepper
      stepImperatively={stepImperatively}
      setStepImperatively={setStepImperatively}
      initialValues={{
        ...defaultValues,
        panel_seconds_before_move_next: -1,
        incontext_primary_site: '',
        stim_script: '',
        incontext_distraction_site: '',
        brand_id: null,
        study_type_id: 7,
      }}
      onSubmit={async (values) => {
        await onAddNewStudySubmit(values, history);
      }}
    >
      <BasicDetails
        title='Tell us about your study'
        label='Basic Details'
        validationSchema={basicDetailsValidationSchema}
        {...{
          environmentData,
          locationTypes,
          languageTypes,
          respondentTypes,
        }}
      />
      <QuestionDetails
        validationSchema={questionDetailsValidationSchema}
        title='What surveys will you use?'
        label='Question Details'
        {...{questionTypes}}
      />
      <PanelDetails
        validationSchema={panelDetailsValidationSchema}
        title='Tell us about your recruitment'
        label='Panel Details'
      />
      <RichWebPageSetup
        validationSchema={object().shape({
          incontext_primary_site: string()
            .url('Must be a valid URL')
            .max(512, 'Too Long!')
            .required('Required'),
          stim_script: string()
            .max(3000, 'Too Long!')
            .required('Required'),
          incontext_distraction_site: string()
            .url('Must be a valid URL')
            .max(512, 'Too Long!')
            .required('Required'),
          brand_id: mixed().required('Required'),
        })}
        title={`Great! Now let's setup your page`}
        label='Web Page Setup'
        {...{brandTypes}}
      />
      <Review
        title='Your project is ready for review'
        label='Review'
        setStepImperatively={setStepImperatively}
        labels={[
          'Basic Details',
          'Question Details',
          'Panel Details',
          'Cache Page Setup',
        ]}
      />
    </FormStepper>
  );
}

DigitalRichStepper.propTypes = {
  onAddNewStudySubmit: propTypes.func,
  languageTypes: propTypes.object,
  environmentData: propTypes.object,
  locationTypes: propTypes.object,
  questionTypes: propTypes.object,
  brandTypes: propTypes.object,
  respondentTypes: propTypes.object,
  defaultValues: propTypes.object,
  basicDetailsValidationSchema: propTypes.object,
  panelDetailsValidationSchema: propTypes.object,
  questionDetailsValidationSchema: propTypes.object,
  history: propTypes.object,
};
