import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import PropTypes from 'prop-types';

export default function SplitButton(props) {
  const {
    options,
    downloadParticipantData,
    downloadStimReport,
    downloadFeatureReport,
    downloadDemographicData,
  } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClick = () => {
    if (options[selectedIndex] === 'Download Participant Data'){
      downloadParticipantData();
    }
    else if (options[selectedIndex] === 'Download Stim Data') {
      downloadStimReport();
    }
    else if (options[selectedIndex] === 'Download Feature Report') {
      downloadFeatureReport();
    }
    else if (options[selectedIndex] === 'Download age data') {
      downloadDemographicData('age');
    }
    else if (options[selectedIndex] === 'Download earnings data') {
      downloadDemographicData('earnings');
    }
    else if (options[selectedIndex] === 'Download gender data') {
      downloadDemographicData('gender');
    }
    else if (options[selectedIndex] === 'Download family data') {
      downloadDemographicData('family');
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Grid container direction='column' alignItems='flex-end'>
      <Grid item xs={12}>
        <ButtonGroup variant='contained' color='primary' ref={anchorRef} aria-label='split button'>
          <Button onClick={handleClick}>{options[selectedIndex]}</Button>
          <Button
            color='primary'
            size='small'
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label='select merge strategy'
            aria-haspopup='menu'
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper style={{zIndex:2}} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'top' : 'bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id='split-button-menu'>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
}

SplitButton.propTypes = {
  options: PropTypes.object,
  downloadParticipantData: PropTypes.func,
  downloadStimReport: PropTypes.func,
  downloadFeatureReport: PropTypes.func,
  downloadDemographicData: PropTypes.func,
};