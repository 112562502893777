import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { Field, useFormikContext } from 'formik';
import MoreInfo from '../../MaterialComponents/MoreInfo';
import MoreInfoPictureContent from '../../MaterialComponents/MoreInfoPictureContent';
import CallToActionCheckBox from '../../MaterialComponents/CallToActionCheckbox';
import Divider from '@material-ui/core/Divider';
import BrandAutoComplete from '../../MaterialComponents/BrandAutoComplete';
import validator from 'validator';
import { InstagramPost } from '@lumen-developer/lumen-lookalikes/dist/Previews';
import propTypes from 'prop-types';

export default function InstagramStandard({brandTypes}) {
  const { values } = useFormikContext();

  function InstagramStandardValidationSchema(value, name) {
    let error;
    if (name === 'stim_url') {
      if (!value) {
        error = 'Required';
      } else if (!validator.isURL(value)) {
        error = 'Must be a valid URL';
      } else if (value.length > 512) {
        error = 'Too long';
      }
    }
    if (name === 'in_profile_src') {
      if (!value) {
        error = 'Required';
      } else if (!validator.isURL(value)) {
        error = 'Must be a valid URL';
      } else if (value.length > 511) {
        error = 'Too long';
      }
    }

    if (name === 'in_channel_name') {
      if (!value) {
        error = 'Required';
      } else if (value.length > 50) {
        error = 'Too long';
      }
    }
    if (name === 'in_description') {
      if (value.length > 511) {
        error = 'Too long';
      }
    }

    return error;
  }

  return (
    <>
      <Divider variant='middle' />

      <Grid
        style={{ margin: '24px 0px 12px' }}
        container
        wrap='nowrap'
        justifyContent='center'
        item
        xs={12}
      >
        <Typography align='center' variant='h6'>
          Instagram news feed
        </Typography>
        <MoreInfo
          outerContainerClassName='alignToTop'
          widePaper
          popup={
            <MoreInfoPictureContent
              standardDescription='This ad will appear as the third post in an Instagram news feed'
              illustration='instagramStandard'
            />
          }
        />
      </Grid>
      <div style={{ overflow: 'hidden', position: 'relative', width: '100%' }}>
        <div style={{ height: '100%', width: '50%', padding: 16 }}>
          <div style={{ minHeight: 500 }}>
            <InstagramPost
              lang_code={values.language.code.toUpperCase()}
              in_profile_src={values.in_profile_src}
              in_channel_name={values.in_channel_name}
              in_description={values.in_description}
              in_promoted_domain={values.in_promoted_domain}
              in_promoted_description={values.in_promoted_description}
              in_promoted_button_text={values.in_promoted_button_text}
              stim_url={values.stim_url}
            />
          </div>
        </div>
        <div
          style={{
            overflowY: 'scroll',
            height: '100%',
            width: '50%',
            position: 'absolute',
            right: 0,
            top: 0,
            padding: 16,
          }}
        >
          <Grid style={{ width: '100%' }} container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='subtitle1'>
                Please enter the ad URL and choose the brand name
              </Typography>
            </Grid>

            <Grid container wrap='nowrap' item xs={12}>
              <Field
                fullWidth
                name='stim_url'
                component={TextField}
                label='Ad URL'
                variant='filled'
                validate={(value) =>
                  InstagramStandardValidationSchema(value, 'stim_url')
                }
              />
              <MoreInfo
                outerContainerClassName='moreInfo'
                popup='A link to a webpage only featuring the test ad'
              />
            </Grid>
            <Grid container wrap='nowrap' item xs={12}>
              <BrandAutoComplete brandTypes={brandTypes} />
              <MoreInfo
                outerContainerClassName='moreInfo'
                popup='The brand name of the company featured in the test ad '
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='subtitle1'>
                Now customize the post
              </Typography>
            </Grid>
            <Grid container wrap='nowrap' item xs={12}>
              <Field
                fullWidth
                name='in_profile_src'
                component={TextField}
                label='Profile photo URL'
                variant='filled'
                validate={(value) =>
                  InstagramStandardValidationSchema(value, 'in_profile_src')
                }
              />
              <MoreInfo
                outerContainerClassName='moreInfo'
                popup='A link to a webpage only featuring the profile photo'
              />
            </Grid>
            <Grid container wrap='nowrap' item xs={12}>
              <Field
                fullWidth
                name='in_channel_name'
                component={TextField}
                label='Profile name'
                variant='filled'
                validate={(value) =>
                  InstagramStandardValidationSchema(value, 'in_channel_name')
                }
              />
              <MoreInfo
                outerContainerClassName='moreInfo'
                popup='The profile name as you would like it to appear'
              />
            </Grid>
            <Grid container wrap='nowrap' item xs={12}>
              <Field
                fullWidth
                name='in_description'
                component={TextField}
                label='Description'
                variant='filled'
                validate={(value) =>
                  InstagramStandardValidationSchema(value, 'in_description')
                }
              />
              <MoreInfo
                outerContainerClassName='moreInfo'
                popup='The text appearing below the image or video'
              />
            </Grid>
            <Grid container wrap='nowrap' item xs={12}>
              <CallToActionCheckBox name='in_promoted_button_text' />
              <MoreInfo
                outerContainerClassName='alignToTop'
                popup='Text in the main call to action button'
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

InstagramStandard.propTypes = {
  brandTypes: propTypes.object,
};
