import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { CellStepManagementOperations } from '../duck/index';
import { connect } from 'react-redux';
import EditDialogue from '../components/EditDialogue';
import DeleteDialogue from '../components/DeleteDialogue';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import PropTypes from 'prop-types';

function EditStepModal(props) {
  const { onDeleteClick, nextButtonOptions, editStepData, stepData} = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const studyId = parseInt(localStorage.getItem('studyId'));
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (option) => {
    if (option === 'edit') {
      setOpenEditModal(true);
      setAnchorEl(null);
    } else if (option === 'delete') {
      setOpenDeleteModal(true);
      setAnchorEl(null);
    } else {
      setAnchorEl(null);
    }
  };

  return (
    <div>
      <Tooltip arrow title='menu'>
        <IconButton
          id='basic-button'
          aria-controls='basic-menu'
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          size='small'
          aria-label='delete'
        >
          <MoreHorizIcon />
        </IconButton>
      </Tooltip>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem style={{paddingRight:24}} onClick={() => handleClose('edit')}>
          <ListItemIcon>
            <EditIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Edit step</ListItemText>
        </MenuItem>
        <MenuItem style={{paddingRight:24}} onClick={() => handleClose('delete')}>
          <ListItemIcon>
            <DeleteIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Delete step</ListItemText>
        </MenuItem>
      </Menu>
      <DeleteDialogue
        onDeleteStep={onDeleteClick}
        setOpenModal={setOpenDeleteModal}
        openModal={openDeleteModal}
      />

      <EditDialogue
        nextButtonOptions={nextButtonOptions}
        onEditStepData={(showNext, moveNext, nextNext) =>
          editStepData(
            showNext,
            moveNext,
            nextNext,
            stepData.step_order_id,
            studyId
          )
        }
        stepData={stepData}
        setOpenModal={setOpenEditModal}
        openModal={openEditModal}
      />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: (id) =>
      dispatch(CellStepManagementOperations.closeHamburgerModal(id)),
    deleteTask: (id, columnId, studyId) =>
      dispatch(CellStepManagementOperations.deleteTask(id, columnId, studyId)),
    openConfirmBox: () =>
      dispatch(CellStepManagementOperations.openConfirmBox()),
    closeConfirmBox: () =>
      dispatch(CellStepManagementOperations.closeConfirmBox()),
    openEditBox: () => dispatch(CellStepManagementOperations.openEditBox()),
    closeEditBox: () => dispatch(CellStepManagementOperations.closeEditBox()),
    getStepData: (id) => dispatch(CellStepManagementOperations.getStepData(id)),
    editStepData: (id, showNext, moveNext, nextNext, studyId) =>
      dispatch(
        CellStepManagementOperations.editStepData(
          id,
          showNext,
          moveNext,
          nextNext,
          studyId
        )
      ),
  };
};

const mapStateToProps = (state) => {
  return {
    modalOpen: state.cellStepManagement.hamburgerModalOpen,
    id: state.cellStepManagement.id,
    columnId: state.cellStepManagement.columnId,
    confirmBoxOpen: state.cellStepManagement.confirmBoxOpen,
    editBoxOpen: state.cellStepManagement.editBoxOpen,
    nextButtonOptions: state.cellStepManagement.nextButtonOptions,
  };
};

EditStepModal.propTypes = {
  onDeleteClick: PropTypes.func,
  nextButtonOptions: PropTypes.array,
  editStepData: PropTypes.func,
  stepData: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditStepModal);
