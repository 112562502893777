import React, { useState } from 'react';
import { object, string, array } from 'yup';
import propTypes from 'prop-types';

import Review from '../ReviewPage/Review';
import { FormStepper } from '../FormStepper';
import { BasicDetails } from '../Steps/BasicDetails';
import { PanelDetails } from '../Steps/PanelDetails';
import ContextDetails from '../Steps/ContextDetails';

const DecipherStepper = ({
  onAddNewStudySubmit,
  isSubmitting,
  languageTypes,
  environmentData,
  locationTypes,
  mediaData,
  studyTypes,
  respondentTypes,
  defaultValues,
  basicDetailsValidationSchema,
  panelDetailsValidationSchema,
  history,
}) => {
  const [stepImperatively, setStepImperatively] = useState(null);

  return (
    <FormStepper
      stepImperatively={stepImperatively}
      setStepImperatively={setStepImperatively}
      initialValues={{
        ...defaultValues,
        study_type_id: 10,
        embedded_page_list: [],
        panel_seconds_before_move_next: -1,
      }}
      onSubmit={async (values) => {
        await onAddNewStudySubmit(values, history);
      }}
    >
      <BasicDetails
        title='Tell us about your study'
        label='Basic Details'
        validationSchema={basicDetailsValidationSchema}
        {...{
          environmentData,
          locationTypes,
          languageTypes,
          studyTypes,
          mediaData,
          respondentTypes,
        }}
      />
      <PanelDetails
        validationSchema={panelDetailsValidationSchema}
        title='Tell us about your recruitment'
        label='Redirect Details'
      />
      <ContextDetails
        title='Lets assign a quick step list'
        label='Context Details'
        validationSchema={object().shape({
          embedded_page_list: array(
            object({
              url: string()
                .url('Must be a valid URL')
                .max(512, 'Too Long!')
                .required('Required'),
            })
          ),
        })}
        isSubmitting={isSubmitting}
      />
      <Review
        title='Your project is ready for review'
        label='Review'
        setStepImperatively={setStepImperatively}
        labels={[
          'Basic Details',
          'Redirect Details',
          'Context Details',
        ]}
        isSubmitting={isSubmitting}
      />
    </FormStepper>
  );
};

DecipherStepper.propTypes = {
  onAddNewStudySubmit: propTypes.func,
  isSubmitting: propTypes.bool,
  languageTypes: propTypes.object,
  environmentData: propTypes.object,
  locationTypes: propTypes.object,
  mediaData: propTypes.object,
  questionTypes: propTypes.object,
  studyTypes: propTypes.object,
  respondentTypes: propTypes.object,
  defaultValues: propTypes.object,
  basicDetailsValidationSchema: propTypes.object,
  panelDetailsValidationSchema: propTypes.object,
  history: propTypes.object,
};

export default DecipherStepper;
