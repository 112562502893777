import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { ErrorMessage, useFormikContext } from 'formik';
import {
  faSnapchat,
  faPinterest,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Switch from '@mui/material/Switch';

import Pinterest from '../SocialMedia/SocialMediaTypes/Pinterest';
import TikTok from '../SocialMedia/SocialMediaTypes/TikTok';
import VKStandard from '../SocialMedia/SocialMediaTypes/VkStandard';
import VkStoryForm from '../SocialMedia/SocialMediaTypes/VkStory';
import MoreInfo from '../MaterialComponents/MoreInfo';
import FacebookCarousel from '../SocialMedia/SocialMediaTypes/FacebookCarousel';
import FacebookStandard from '../SocialMedia/SocialMediaTypes/FacebookStandard';
import FacebookStory from '../SocialMedia/SocialMediaTypes/FacebookStory';
import FacebookWatchFeed from '../SocialMedia/SocialMediaTypes/FacebookWatchFeed';
import InstagramStandard from '../SocialMedia/SocialMediaTypes/InstagramStandard';
import InstagramStory from '../SocialMedia/SocialMediaTypes/InstagramStory';
import Snapchat from '../SocialMedia/SocialMediaTypes/Snapchat';

const SocialPageSetup = ({brandTypes}) => {
  const { values, setFieldValue, resetForm } = useFormikContext();
  const [initialData] = useState({
    ...values,
    stim_url: '',
    brand_id: null,
    fb_channel_name: '',
    fb_profile_src: '',
    fb_description: '',
    fb_promoted_button_text: 'Learn more',
    promoted: true,
    has_sound: false,
    fb_promoted_description: '',
    fb_promoted_description2: '',
    fb_promoted_domain: '',
    fb_call_to_action: '',
    in_channel_name: '',
    in_profile_src: '',
    in_promoted_button_text: 'Learn more',
    in_description: '',
    in_call_to_action: 'Learn more',
    sc_channel_name: '',
    sc_description: '',
    sc_profile_src: '',
    sc_cta: '',
    sc_promo_text: '',
    sc_promo_desc: '',
    sc_promo_top: '',
    sc_promo_rating: 0,
  });

  const socialPostSetup = () => {
    let socialSite = values.incontext_site.code;
    let socialPostForm = values.ad_type;

    switch (socialSite) {
      case 'fb':
        if (socialPostForm === 'standard') {
          socialSite = <FacebookStandard {...{brandTypes}} />;
          break;
        } else if (socialPostForm === 'story') {
          socialSite = <FacebookStory {...{brandTypes}} />;
          break;
        } else if (socialPostForm === 'watch') {
          socialSite = <FacebookWatchFeed {...{brandTypes}} />;
          break;
        } else if (socialPostForm === 'carousel') {
          socialSite = <FacebookCarousel />;
          break;
        } else {
          socialSite = '';
          break;
        }
      case 'sc':
        if (socialPostForm === 'standard') {
          socialSite = <Snapchat {...{brandTypes}} />;
          break;
        } else {
          socialSite = '';
          break;
        }
      case 'in':
        if (socialPostForm === 'standard') {
          socialSite = <InstagramStandard {...{brandTypes}} />;
          break;
        } else if (socialPostForm === 'story') {
          socialSite = <InstagramStory {...{brandTypes}} />;
          break;
        } else {
          socialSite = '';
          break;
        }
      case 'pin':
        if (socialPostForm === 'standard') {
          socialSite = <Pinterest brandTypes={brandTypes} />;
          break;
        } else {
          socialSite = '';
          break;
        }
      case 'tt':
        if (socialPostForm === 'standard') {
          socialSite = <TikTok brandTypes={brandTypes} />;
          break;
        } else {
          break;
        }
      case 'vk':
        if (socialPostForm === 'standard') {
          socialSite = <VKStandard {...{brandTypes}} />;
        } else if (socialPostForm === 'story'){
          socialSite = <VkStoryForm {...{brandTypes}} />;
        }
        break;
      default:
        socialSite = '';
    }

    socialSite = socialSite === '' ? '' : <>{socialSite}</>;

    return socialSite;
  };

  const handleChange = (newValue, socialMedia) => {
    let isNewSocialSystem = values.is_new_social_system;
    resetForm({ values: initialData });
    setFieldValue('ad_type', newValue);
    setFieldValue('incontext_site', socialMedia);
    setFieldValue('is_new_social_system', isNewSocialSystem);
  };

  const gridItems = [
    {
      title: 'Facebook',
      code: 'fb',
      icon: (
        <FacebookIcon
          fontSize='large'
          style={{ color: '#3B5998', fontSize: '64px' }}
        />
      ),
      radioButtons: [
        { value: 'standard', label: 'News feed' },
        { value: 'story', label: 'Story' },
        {
          value: 'watch',
          label: 'Watch feed',
          hideOption: values.is_new_social_system,
        },
      ],
    },
    {
      title: 'Instagram',
      code: 'in',
      icon: <InstagramIcon style={{ color: '#833AB4', fontSize: '64px' }} />,
      radioButtons: [
        { value: 'standard', label: 'News feed' },
        { value: 'story', label: 'Story' },
      ],
    },
    {
      title: 'Snapchat',
      code: 'sc',
      icon: (
        <FontAwesomeIcon
          style={{
            color: '#F7F400',
            fontSize: '64px',
            padding: 2,
          }}
          icon={faSnapchat}
        />
      ),
      radioButtons: [{ value: 'standard', label: 'Commercial' }],
    },
    {
      title: 'Pinterest',
      code: 'pin',
      icon: (
        <FontAwesomeIcon
          style={{
            color: '#e60023',
            fontSize: '64px',
            padding: 2,
          }}
          icon={faPinterest}
        />
      ),
      radioButtons: [{ value: 'standard', label: 'Commercial' }],
    },
    {
      title: 'TikTok',
      code: 'tt',
      icon: (
        <FontAwesomeIcon
          style={{
            color: 'black',
            fontSize: '64px',
            padding: 2,
          }}
          icon={faTiktok}
        />
      ),
      radioButtons: [{ value: 'standard', label: 'Commercial' }],
    },
    {
      title: 'VK',
      code: 'vk',
      icon: (
        <svg
          width='64'
          height='64'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect width='24' height='24' rx='5.76' fill='#07F'></rect>
          <path
            d='M12.82 17.5c-5.42 0-8.7-3.76-8.83-10h2.74c.09 4.59 2.17 6.53 3.77 6.93V7.5h2.63v3.96c1.54-.17 3.16-1.97 3.7-3.96h2.59A7.62 7.62 0 0116 12.49a7.9 7.9 0 014.01 5.01h-2.84c-.6-1.9-2.07-3.37-4.05-3.57v3.57h-.31z'
            fill='#fff'
          ></path>
        </svg>
      ),
      radioButtons: [
        { value: 'standard', label: 'News feed' },
        { value: 'story', label: 'Story' },
      ],
    },
  ];

  const handlenewSocialSystemChange = (event) => {
    if (values.ad_type === 'watch') {
      setFieldValue('incontext_site', {code: 'fb', description: 'Facebook'});
      setFieldValue('ad_type', 'standard');
    }
    setFieldValue('is_new_social_system', event.target.checked);
  };

  return (
    <>
      <Grid
        style={{ margin: '24px 0px 12px' }}
        container
        wrap='nowrap'
        justifyContent='center'
        item
        xs={12}
      >
        <Typography align='center' variant='h6'>
          Please choose an ad type
        </Typography>
        <MoreInfo
          outerContainerClassName='alignToTop'
          popupArray={[
            'From the options below, choose the ad type you would like to test under the social platform you would like to test it on.',
            'You will be able to upload a video or image  for the relevant ad type',
          ]}
        />
        <div
          style={{
            display: 'flex',
            position: 'absolute',
            right: '8vw',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Typography variant='caption' style={{ whiteSpace: 'nowrap' }} nowrap='true' >
            {values.is_new_social_system
              ? 'New social system'
              : 'Social system'}
          </Typography>
          <Switch
            size='small'
            checked={values.is_new_social_system}
            onChange={handlenewSocialSystemChange}
          />
        </div>
      </Grid>
      <div style={{ justifyContent: 'center', display: 'flex', width: '100%' }}>
        <ErrorMessage component='div' style={{ color: 'red' }} name='ad_type' />
      </div>

      <Grid container justifyContent={'center'} spacing={3}>
        {gridItems.map((gridItem, index) => (
          <GridItem key={index} gridItem={gridItem} handleChange={handleChange} />
        ))}
      </Grid>

      {socialPostSetup()}
    </>
  );
};

const GridItem = ({ gridItem, handleChange }) => {
  const { values } = useFormikContext();
  const { title, code, icon, radioButtons } = gridItem;

  return (
    <>
      {gridItem.title && (
        <Grid item xs={2}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 16,
              alignItems: 'center',
            }}
          >
            {icon}
            <Typography variant='h6'>{title}</Typography>
          </div>
          <FormControl
            style={{ padding: '0px 16px', alignItems: 'center' }}
            component='fieldset'
          >
            <RadioGroup
              aria-label={`${title.toLowerCase()}RadioGroup`}
              name={`${title.toLowerCase()}1`}
              value={values.incontext_site.code === code ? values.ad_type : ''}
              onChange={(e) =>
                handleChange(e.target.value, {
                  code: code,
                  description: title,
                })
              }
            >
              {radioButtons
                .filter((radioButton) => !radioButton.hideOption)
                .map((radioButton, index) => (
                  <FormControlLabel
                    value={radioButton.value}
                    control={<Radio color='primary' />}
                    label={radioButton.label}
                    key={index}
                  />
                ))}
            </RadioGroup>
          </FormControl>
        </Grid>
      )}
    </>
  );
};

SocialPageSetup.propTypes = {
  brandTypes: PropTypes.object,
};

GridItem.propTypes = {
  gridItem: PropTypes.object,
  handleChange: PropTypes.func,
};

export default SocialPageSetup;
