import React, { Component } from 'react';
import TableCell from './TableCell';
import PropTypes from 'prop-types';
import TableSorter from "../../common/components/TableSorter"

class DashboardTable extends Component {

  render() {
    const { handleSortChange, currentOrderBy } = this.props;

    return (
      <table className="table">
        <tbody>
          <tr>
            <th  style={{ paddingLeft: "32px", width: "25%", borderTopLeftRadius: 8, borderBottomLeftRadius:8, boxShadow: "rgb(255 255 255) 16px 0px 0px inset" }}>
            <h4 style={{display: "inline"}}>Study name</h4>
            { currentOrderBy ? <TableSorter name="Name" handleSortChange = {handleSortChange} currentOrderBy={currentOrderBy} /> : ""}
       
            </th>
            <th>
            <h4 style={{display: "inline"}}>Environment</h4>
            { currentOrderBy ? <TableSorter name="Environment" handleSortChange = {handleSortChange} currentOrderBy={currentOrderBy} /> : ""}
           
            </th>
            <th >
            <h4 style={{display: "inline"}} >Media</h4>
            { currentOrderBy ? <TableSorter name="Media" handleSortChange = {handleSortChange} currentOrderBy={currentOrderBy} /> : ""}
        
            </th>
            <th style={{textAlign: "center"}} ><h4>Live</h4>
            </th>
            <th style={{textAlign: "center"}} ><h4>Completed</h4>
            </th>
            <th >
            <h4 style={{whiteSpace: "nowrap", display: "inline"}}>No. cells</h4>           
            </th>
            <th className="paddingRightWhen1200">
            <h4 style={{display: "inline"}}>Participants <br/> Returned</h4>          
            </th>
            <th className="RemoveColumnWhen1200" ><h4>Fieldwork Start</h4>
            </th>
            <th className="RemoveColumnWhen1350" style={{paddingRight: "32px", borderTopRightRadius: 8, borderBottomRightRadius:8, boxShadow: "rgb(255 255 255) -16px 0px 0px inset", textAlign:"right" }}><h4>Fieldwork End</h4>
            </th>
        
          </tr>
          {this.props.studies.map((item, key) =>
            <TableCell
              onClick={this.onClick}
              id={item.study_id}
              title={item.name}
              environment={item.environment}
              media={item.media}
              live={item.live}
              completed={item.completed}
              cells={item.cell_count}
              participants_requested={item.participants_requested}
              participants_returned={item.participants_returned}
              data_collection_start={item.data_collection_start}
              data_collection_end={item.data_collection_end}
              key={key}
            />
          )}
     
        </tbody>
      </table>
    );
  }
}

DashboardTable.propTypes = {
  name: PropTypes.string,
  id: PropTypes.number,
  title: PropTypes.string,
  environment: PropTypes.string,
  media: PropTypes.string,
  live: PropTypes.bool,
  completed: PropTypes.bool,
  cells: PropTypes.number,
  participants_requested: PropTypes.number,
  participants_returned: PropTypes.number,
  data_collection_start: PropTypes.string,
  data_collection_end: PropTypes.string,
  studies: PropTypes.array,

};

export default DashboardTable;
