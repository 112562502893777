import { Grid, Typography } from "@mui/material";
import { Field } from "formik";
import { TextField } from "formik-mui";
import React from "react";
import MoreInfo from "../../../MaterialComponents/MoreInfo";
import validator from "validator";
import BrandAutoComplete from "../../../MaterialComponents/BrandAutoComplete";

const Form = ({ brandTypes }) => {
  const validateField = (value, name) => {
    switch (name) {
      case "tt_profile_src":
      case "stim_url":
        if (!value) {
          return "Required";
        } else if (!validator.isURL(value)) {
          return "Must be a valid URL";
        } else if (value.length > 512) {
          return "Too long";
        }
        break;
      case "tt_profile_name":
      case "tt_desc":
      case "tt_song_name":
      case "tt_promo_text":
        if (!value) {
          return "Required";
        }
        break;
      default:
        return;
    }
    return;
  };

  return (
    <Grid style={{ width: "100%" }} container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          Please enter the ad URL and choose the brand name
        </Typography>
      </Grid>

      <Grid container wrap="nowrap" item xs={12}>
        <Field
          fullWidth
          name="stim_url"
          component={TextField}
          label="Ad URL"
          variant="filled"
          validate={(value) => validateField(value, "stim_url")}
        />
        <MoreInfo
          outerContainerClassName="moreInfo"
          popup="A link to a webpage only featuring the test ad. We recommend 9 x 16 aspect ratio for TikTok commercials"
        />
      </Grid>
      <Grid container wrap="nowrap" item xs={12}>
        <BrandAutoComplete brandTypes={brandTypes} />
        <MoreInfo
          outerContainerClassName="moreInfo"
          popup="The brand name of the company featured in the test ad "
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          Now customize the commercial
        </Typography>
      </Grid>
      <Grid container wrap="nowrap" item xs={12}>
        <Field
          fullWidth
          name="tt_profile_src"
          component={TextField}
          label="Profile photo URL"
          variant="filled"
          validate={(value) => validateField(value, "tt_profile_src")}
        />
        <MoreInfo
          outerContainerClassName="moreInfo"
          popup="A link to a webpage only featuring the profile photo"
        />
      </Grid>
      <Grid container wrap="nowrap" item xs={12}>
        <Field
          fullWidth
          name="tt_profile_name"
          component={TextField}
          label="Profile name"
          variant="filled"
          validate={(value) => validateField(value, "tt_profile_name")}
        />
        <MoreInfo
          outerContainerClassName="moreInfo"
          popup="The profile name as you would like it to appear"
        />
      </Grid>
      <Grid container wrap="nowrap" item xs={12}>
        <Field
          fullWidth
          name="tt_desc"
          component={TextField}
          label="Description"
          variant="filled"
          validate={(value) => validateField(value, "tt_desc")}
        />
        <MoreInfo
          outerContainerClassName="moreInfo"
          popup="The post description as you would like it to appear"
        />
      </Grid>
      <Grid container wrap="nowrap" item xs={12}>
        <Field
          fullWidth
          name="tt_song_name"
          component={TextField}
          label="Song Name"
          variant="filled"
          validate={(value) => validateField(value, "tt_song_name")}
        />
        <MoreInfo
          outerContainerClassName="moreInfo"
          popup="The post song name as you would like it to appear"
        />
      </Grid>
      <Grid container wrap="nowrap" item xs={12}>
        <Field
          fullWidth
          name="tt_promo_text"
          component={TextField}
          label="Promotional Text"
          variant="filled"
          validate={(value) => validateField(value, "tt_promo_text")}
        />
        <MoreInfo
          outerContainerClassName="moreInfo"
          popup="The promotional text as you would like it to appear"
        />
      </Grid>
    </Grid>
  );
};

export default Form;
