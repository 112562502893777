import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { Field} from 'formik';
import PropTypes from 'prop-types';

import BrandAutoComplete from '../MaterialComponents/BrandAutoComplete';
import MoreInfo from '../MaterialComponents/MoreInfo';

const VideoSetup = ({ brandTypes }) => {

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant='subtitle1'>
            Please enter the URL reference of the video you want to test and the
            brand name associated with it
          </Typography>
        </Grid>
        <Grid container wrap = 'nowrap' item xs={12} >
          <Field
            fullWidth
            name='stim_url'
            component={TextField}
            label='Video ad URL'
            variant='filled'
          />
          <MoreInfo outerContainerClassName = 'moreInfo' popup='A link to a webpage only featuring your test video (Please do not upload to YouTube)' />
        </Grid>
        <Grid container wrap = 'nowrap' item xs={12} >
          <BrandAutoComplete brandTypes={brandTypes} />
          <MoreInfo outerContainerClassName = 'moreInfo' popup='The brand name of the company featured in your test ad ' />
        </Grid>
      </Grid>
    </>
  );
};

VideoSetup.propTypes = {
  brandTypes: PropTypes.object,
};

export default VideoSetup;
