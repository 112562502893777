import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import LoadingSpinner from '../../common/components/LoadingSpinner';
import HttpService from '../../Dashboard/duck/HttpService';
import 'react-datetime/css/react-datetime.css';
import { string, object, } from 'yup';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { createTheme, ThemeProvider, darken } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import { KeyboardArrowDown } from '@material-ui/icons';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00badb'
    },
    secondary : {
      main:'#FF0000'
    },
  },
  typography: {
    fontFamily: 'Roboto'
  },
  overrides: {
    MuiFormControl: {
      root: {
        width:'100%'
      },
    },
    MuiInputBase: {
      input: {
        bomdhadow: 'unset',
        borderBottomColor: '#00badb',
      },
      root: {
        MuiOutlinedInput: {
          root: {
            width: '100%',
          },
        },
      },
    },
  },
});

const EditStudyDetailsForm = ({
  mediaLoading,
  environmentLoading,
  studyDetailsLoading,
  studyDetails,
  languageOptions,
  locationTypes,
  questionTypes,
  onSubmit,
  id,
  history,
}) => {

  const basePath = `/my_studies/overview/${id}`;

  if (environmentLoading || mediaLoading || studyDetailsLoading || !studyDetails) {
    return <LoadingSpinner />;
  }

  const questionOptions = questionTypes.concat([{ id: 0, group_name: 'None'}]);

  const handleSubmit = async (values, actions) => {
    const proposal_signoff_date = values.proposal_signoff_date?.isValid() ? values.proposal_signoff_date.toDate() : null;
    const proposed_data_collection_start = values.proposed_data_collection_start?.isValid() ? values.proposed_data_collection_start.toDate() : null;
    const proposed_data_collection_end = values.proposed_data_collection_end?.isValid() ? values.proposed_data_collection_end.toDate() : null;
    const debrief_date = values.debrief_date?.isValid() ? values.debrief_date.toDate() : null;
    const pre_assign_question_group = values.pre_assign_question_group.id;
    const project_location = values.project_location.code;
    const language = values.language.name;

    try {
      await HttpService.postStudyDetailsEdit(
        id,
        {
          ...values,
          proposal_signoff_date,
          proposed_data_collection_start,
          proposed_data_collection_end,
          debrief_date,
          pre_assign_question_group,
          project_location,
          language,
        }
      );
      actions.setSubmitting(false);
      onSubmit();
      history.push(basePath);
    } catch (err) {
      actions.setSubmitting(false);
      throw err;
    }
  };

  return (
    <Formik
      initialValues={{
        name: studyDetails.name,
        project_location: locationTypes.find((value) => value.code === studyDetails.project_location) || '',
        language: languageOptions.find((value) => value.name === studyDetails.language) || '',
        media: studyDetails.media,
        environment: studyDetails.environment,
        proposal_signoff_date: studyDetails.proposal_signoff_date ? dayjs(studyDetails.proposal_signoff_date) : null,
        proposed_data_collection_start: studyDetails.data_collection_start ? dayjs(studyDetails.data_collection_start) : null,
        proposed_data_collection_end: studyDetails.data_collection_end ? dayjs(studyDetails.data_collection_end) : null,
        debrief_date: studyDetails.debrief_date ? dayjs(studyDetails.debrief_date) : null,
        methodology: studyDetails.methodology,
        study_duration: studyDetails.study_duration || '',
        incentive: studyDetails.incentive || '',
        objective: studyDetails.objective || '',
        decision_to_inform: studyDetails.decision_to_inform || '',
        notes: studyDetails.notes || '',
        brief: studyDetails.brief || '',
        pre_assign_question_group: questionOptions.find((value) => value.id === (studyDetails.pre_assign_question_group || 0)),
        demographic_survey_url: studyDetails.demographic_survey_url,
        demographic_survey_insert_parameter: studyDetails.demographic_survey_insert_parameter,
        min_connection_speed: parseFloat(studyDetails.min_connection_speed).toFixed(1),
        min_calibration_frame_rate: parseFloat(studyDetails.min_calibration_frame_rate).toFixed(1),
        max_accuracy: parseFloat(studyDetails.max_accuracy).toFixed(1),
        max_precision: parseFloat(studyDetails.max_precision).toFixed(1),
        max_gaze_duration: parseFloat(studyDetails.max_gaze_duration).toFixed(1),
        max_dwell_ms: studyDetails.max_dwell_ms,
        min_dwell_ms: studyDetails.min_dwell_ms,
      }}
      validationSchema={object().shape({
        name: string().max(50, 'Too Long!').required('Name required'),
        language: string().required('Language required'),
        demographic_survey_url: string().url('* Must be a valid URL'),
        study_duration: string().max(50, 'Too Long!'),
        incentive: string().max(50, 'Too Long!'),
        project_location: string().required('Location required'),
      })}
      onSubmit={handleSubmit}
    >{({
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        errors,
      }) => (
        <form>
          <ThemeProvider theme={theme}>
            <Grid container columnSpacing={3} rowSpacing={1}>
              <Grid item md={9} xs={12}>
                <Typography variant='h4' gutterBottom>Edit Study Details</Typography>
              </Grid>
              <Grid item md={3} xs={12}>
                <Box display='flex' justifyContent='flex-end'>
                  <Button
                    type='button'
                    disabled={isSubmitting}
                    variant='contained'
                    sx={{
                      marginRight: '16px',
                      bgcolor: '#FFFFFF',
                      ':hover': {
                        background: darken('#FFFFFF', 0.1),
                      }
                    }}
                    onClick={() => history.push(basePath)}
                  >Cancel</Button>
                  <Button
                    type='submit'
                    disabled={isSubmitting}
                    variant='contained'
                    sx={{
                      color: '#FFFFFF',
                      ':hover': {
                        background: darken('#00badb', 0.1),
                      }
                    }}
                    onClick={handleSubmit}
                  >Save</Button>
                </Box>
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography variant='body1' gutterBottom>Basic details</Typography>
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  name='name'
                  label='Name'
                  value={values.name}
                  onChange={handleChange}
                  variant='standard'
                  required
                  fullWidth
                  autoFocus
                  error={!!errors.name}
                  helperText={errors.name}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  id='project_location'
                  name='project_location'
                  label='Project Location'
                  variant='standard'
                  select
                  fullWidth
                  value={values.project_location}
                  onChange={handleChange}
                  required
                  error={!!errors.project_location}
                  helperText={errors.project_location}
                >
                  {locationTypes.map((option, index) => {
                    return <MenuItem key={index} value={option}>{option.name}</MenuItem>;
                  })}
                </TextField>
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  id='language'
                  name='language'
                  label='Language'
                  variant='standard'
                  select
                  fullWidth
                  value={values.language}
                  onChange={handleChange}
                  required
                  error={!!errors.language}
                  helperText={errors.language}
                >
                  {languageOptions.map((option, index) => {
                    return <MenuItem key={index} value={option}>{option.name}</MenuItem>;
                  })}
                </TextField>
              </Grid>
              <Grid item md={1.5} xs={12}>
                <TextField
                  id='media'
                  name='media'
                  label='Media'
                  variant='standard'
                  fullWidth
                  value={values.media}
                  disabled
                />
              </Grid>
              <Grid item md={1.5} xs={12}>
                <TextField
                  id='environment'
                  name='environment'
                  label='Environment'
                  variant='standard'
                  fullWidth
                  value={values.environment}
                  disabled
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography variant='body1' gutterBottom mt={2} style={{ marginBottom: 0}}>Dates</Typography>
              </Grid>
              <Grid item md={3} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs} theme={theme}>
                  <DateTimePicker
                    value={values.proposal_signoff_date}
                    onChange={(e) => setFieldValue('proposal_signoff_date', e)}
                    label='Proposed Sign-off'
                    name='proposal_signoff_date'
                    format='DD/MM/YYYY h:mm a'
                    slots={{
                      textField: TextField,
                    }}
                    slotProps= {{
                      textField: { variant: 'standard', fullWidth: true },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={3} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs} theme={theme}>
                  <DateTimePicker
                    value={values.proposed_data_collection_start}
                    onChange={(e) => setFieldValue('proposed_data_collection_start', e)}
                    label='Proposed Data Collection Start'
                    name='proposed_data_collection_start'
                    format='DD/MM/YYYY h:mm a'
                    slots={{
                      textField: TextField,
                    }}
                    slotProps= {{
                      textField: { variant: 'standard', fullWidth: true },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={3} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs} theme={theme}>
                  <DateTimePicker
                    value={values.proposed_data_collection_end}
                    onChange={(e) => setFieldValue('proposed_data_collection_end', e)}
                    label='Proposed Data Collection End'
                    name='proposed_data_collection_end'
                    format='DD/MM/YYYY h:mm a'
                    slots={{
                      textField: TextField,
                    }}
                    slotProps= {{
                      textField: { variant: 'standard', fullWidth: true },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={3} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs} theme={theme}>
                  <DateTimePicker
                    value={values.debrief_date}
                    onChange={(e) => setFieldValue('debrief_date', e)}
                    label='Proposed Debreif'
                    name='debrief _date'
                    format='DD/MM/YYYY h:mm a'
                    slots={{
                      textField: TextField,
                    }}
                    slotProps= {{
                      textField: { variant: 'standard', fullWidth: true },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography variant='body1' gutterBottom mt={2}>More Details</Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  name='methodology'
                  label='Methodology'
                  value={values.methodology}
                  onChange={handleChange}
                  variant='standard'
                  fullWidth
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  name='study_duration'
                  label='Study Duration'
                  value={values.study_duration}
                  onChange={handleChange}
                  variant='standard'
                  fullWidth
                  error={!!errors.study_duration}
                  helperText={errors.study_duration}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  name='incentive'
                  label='Incentive'
                  value={values.incentive}
                  onChange={handleChange}
                  variant='standard'
                  fullWidth
                  multiline
                  error={!!errors.incentive}
                  helperText={errors.incentive}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  name='objective'
                  label='Objective'
                  value={values.objective}
                  onChange={handleChange}
                  variant='standard'
                  fullWidth
                  multiline
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  name='decision_to_inform'
                  label='Decision to Inform'
                  value={values.decision_to_inform}
                  onChange={handleChange}
                  variant='standard'
                  fullWidth
                  multiline
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  name='notes'
                  label='Notes'
                  value={values.notes}
                  onChange={handleChange}
                  variant='standard'
                  fullWidth
                  multiline
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  name='brief'
                  label='Brief'
                  value={values.brief}
                  onChange={handleChange}
                  variant='standard'
                  fullWidth
                  multiline
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography variant='body1' gutterBottom mt={2}>Questions</Typography>
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  id='pre_assign_question_group'
                  name='pre_assign_question_group'
                  label='Pre Study Questions'
                  variant='standard'
                  select
                  fullWidth
                  value={values.pre_assign_question_group}
                  onChange={handleChange}
                >
                  {questionOptions.map((option, index) => {
                    return <MenuItem key={index} value={option}>{option.group_name}</MenuItem>;
                  })}
                </TextField>
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  name='demographic_survey_url'
                  label='Demographic Survey URL'
                  value={values.demographic_survey_url}
                  onChange={handleChange}
                  variant='standard'
                  fullWidth
                  error={!!errors.demographic_survey_url}
                  helperText={errors.demographic_survey_url}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  name='demographic_survey_insert_parameter'
                  label='Demographic Survey Parameter'
                  value={values.demographic_survey_insert_parameter}
                  onChange={handleChange}
                  variant='standard'
                  fullWidth
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Accordion sx={{ boxShadow: 'none'}}>
                  <AccordionSummary
                    expandIcon={<KeyboardArrowDown />}
                    sx={{ padding: 0 }}
                  >
                    <Grid item md={12} xs={12}>
                      <Typography variant='body1' gutterBottom mt={2}>Advanced Settings</Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item md={3} xs={12}>
                        <TextField
                          name='min_connection_speed'
                          label='Minimum Connection Speed (kbps)'
                          value={values.min_connection_speed}
                          onChange={handleChange}
                          variant='standard'
                          fullWidth
                          type='number'
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          name='min_calibration_frame_rate'
                          label='Minimum Calibration Frame Rate (fps)'
                          value={values.min_calibration_frame_rate}
                          onChange={handleChange}
                          variant='standard'
                          fullWidth
                          type='number'
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          name='max_accuracy'
                          label='Max Accuracy (Pixels)'
                          value={values.max_accuracy}
                          onChange={handleChange}
                          variant='standard'
                          fullWidth
                          type='number'
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          name='max_precision'
                          label='Max Precision (Pixels)'
                          value={values.max_precision}
                          onChange={handleChange}
                          variant='standard'
                          fullWidth
                          type='number'
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          name='max_gaze_duration'
                          label='Max Gaze Duration (ns)'
                          value={values.max_gaze_duration}
                          onChange={handleChange}
                          variant='standard'
                          fullWidth
                          type='number'
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          name='max_dwell_ms'
                          label='Max Dwell (ns)'
                          value={values.max_dwell_ms}
                          onChange={handleChange}
                          variant='standard'
                          fullWidth
                          type='number'
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          name='min_dwell_ms'
                          label='Min Dwell (ms)'
                          value={values.min_dwell_ms}
                          onChange={handleChange}
                          variant='standard'
                          fullWidth
                          type='number'
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </ThemeProvider>
        </form>
      )}
    </Formik>
  );
};

EditStudyDetailsForm.propTypes = {
  mediaLoading: PropTypes.bool,
  environmentLoading: PropTypes.bool,
  studyDetailsLoading: PropTypes.bool,
  studyDetails: PropTypes.object,
  languageOptions: PropTypes.array,
  locationTypes: PropTypes.array,
  questionTypes: PropTypes.array,
  onSubmit: PropTypes.func,
  id: PropTypes.string,
  history: PropTypes.object,
};

export default EditStudyDetailsForm;
