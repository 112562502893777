import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { Field, useFormikContext } from 'formik';
import BrandAutoComplete from '../../MaterialComponents/BrandAutoComplete';
import MoreInfo from '../../MaterialComponents/MoreInfo';
import Divider from '@material-ui/core/Divider';
import validator from 'validator';
import FaceboookWatchFeed from '../../SocialMediaPostTypes/FacebookWatchFeed';
import MoreInfoPictureContent from '../../MaterialComponents/MoreInfoPictureContent';
import propTypes from 'prop-types';

export default function FacebookWatchFeed({brandTypes}) {
  const { values } = useFormikContext();

  function FacebookWatchFeedValidationSchema(value, name) {
    let error;
    if (name === 'stim_url'){
      if (!value) {
        error = 'Required';
      } else if (!validator.isURL(value)) {
        error = 'Must be a valid URL';
      }
      else if (value.length > 512) {
        error= 'Too long';
      }
      else if (value.split('.')[value.split('.').length - 1] !== 'mp4'){
        error= 'Must be an mp4';
      }
    }

    return error;
  }

  return (
    <>
      <Divider variant='middle' />
      <Grid style={{ margin: '24px 0px 12px' }} container wrap='nowrap' justifyContent='center' item xs={12} >
        <Typography align='center' variant='h6'>
          Facebook watch feed
        </Typography>
        <MoreInfo outerContainerClassName='alignToTop'
          widePaper
          popup={
            <MoreInfoPictureContent
              standardDescription={`Facebook Watch includes a mixture of content from users and professionally-produced content Facebook pays to have produced. It's a lot like the news feed but features only video content, usually making use of long-form video.
              You can find it by clicking the tab at the top of the feed to the right of the home tab.`}
              illustration='faceBookWatch'
            />
          }
        />
      </Grid>
      <div style={{ overflow: 'hidden', position: 'relative', width: '100%' }}>
        <div style={{ height: '100%', width: '50%', padding: 16 }}>
          <FaceboookWatchFeed {...{values}} />
        </div>
        <div style={{ overflowY: 'scroll', height: '100%', width: '50%', position: 'absolute', right: 0, top: 0, padding: 16 }}>
          <Grid style={{ width: '100%' }} container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='subtitle1'>
              Please enter the ad URL and choose the brand name
              </Typography>
            </Grid>
            <Grid container wrap='nowrap' item xs={12}>
              <Field
                fullWidth
                name='stim_url'
                component={TextField}
                label='Ad URL'
                variant='filled'
                validate = {(value) => FacebookWatchFeedValidationSchema(value, 'stim_url')}
              />
              <MoreInfo
                outerContainerClassName='moreInfo'
                popup='A link to a webpage only featuring the test ad in mp4 format'
              />
            </Grid>
            <Grid container wrap='nowrap' item xs={12}>
              <BrandAutoComplete brandTypes={brandTypes} />
              <MoreInfo
                outerContainerClassName='moreInfo'
                popup='The brand name of the company featured in the test ad '
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <Grid item xs={12}>
        <Divider variant='middle' />
      </Grid>
    </>
  );
}

FacebookWatchFeed.propTypes = {
  brandTypes: propTypes.object,
};
