export function getHeaders() {
  const user = localStorage.getItem('user');
  const companyInternalId = localStorage.getItem('companyInternalId');
  const hasCompanyInternalId = !!companyInternalId && companyInternalId !== 'null';
  if (hasCompanyInternalId && location.hostname !== 'localhost') {

    return {
      headers: {
        authorization: `token ${user}`,
        EXTRACOMPANYGUID: companyInternalId,
      }
    };
  }

  return {
    headers: {
      authorization: `token ${user}`,
    }
  };
}