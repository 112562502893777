const FETCH_STIM_BEGIN = 'FETCH_STIM_BEGIN';
const FETCH_STIM_SUCCESS = 'FETCH_STIM_SUCCESS';
const FETCH_STUDIES_OVERVIEW_SUCCESS = 'FETCH_STUDIES_OVERVIEW_SUCCESS';
const FETCH_STIM_ERROR = 'FETCH_STIM_ERROR';
const ADD_COMPARE_STUDY = 'ADD_COMPARE_STUDY';
const REMOVE_COMPARE_STUDY = 'REMOVE_COMPARE_STUDY';
const CLEAR_CHECKED_STUDIES = 'CLEAR_CHECKED_STUDIES';
const CHECK_ALL_IN_STEP = 'CHECK_ALL_IN_STEP';
const REMOVE_ALL_IN_STEP = 'REMOVE_ALL_IN_STEP';
const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';
const ADD_STUDIES_OBJECT = 'ADD_STUDIES_OBJECT';
const ADD_STUDIES_STRING = 'ADD_STUDIES_STRING';
const SET_REPORT_TYPE = 'SET_REPORT_TYPE';
const INITIAL_LOAD_FINISH = 'INITIAL_LOAD_FINISH';
const INITIAL_LOAD_BEGIN = 'INITIAL_LOAD_BEGIN';
const INITIAL_LOAD_ERROR = 'INITIAL_LOAD_ERROR';
const INITIAL_LOAD_RESET = 'INITIAL_LOAD_RESET';
const FETCH_STUDY_SPECIFIC_STIMS_BEGIN = 'FETCH_STUDY_SPECIFIC_STIMS_BEGIN';
const FETCH_STUDY_SPECIFIC_STIMS_ERROR = 'FETCH_STUDY_SPECIFIC_STIMS_ERROR';
const FETCH_STUDY_SPECIFIC_STIMS_SUCCESS = 'FETCH_STUDY_SPECIFIC_STIMS_SUCCESS';

  
  


export default {
  FETCH_STIM_BEGIN,
  FETCH_STIM_ERROR,
  FETCH_STIM_SUCCESS,
  FETCH_STUDIES_OVERVIEW_SUCCESS,
  ADD_COMPARE_STUDY,
  REMOVE_COMPARE_STUDY,
  CLEAR_CHECKED_STUDIES,
  CHECK_ALL_IN_STEP,
  REMOVE_ALL_IN_STEP,
  AUTHENTICATION_ERROR,
  ADD_STUDIES_OBJECT,
  ADD_STUDIES_STRING,
  SET_REPORT_TYPE,
  INITIAL_LOAD_BEGIN,
  INITIAL_LOAD_FINISH,
  INITIAL_LOAD_ERROR,
  INITIAL_LOAD_RESET,
  FETCH_STUDY_SPECIFIC_STIMS_BEGIN,
  FETCH_STUDY_SPECIFIC_STIMS_ERROR,
  FETCH_STUDY_SPECIFIC_STIMS_SUCCESS
};
