import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import QuestionAndAnswers from "./QuestionAndAnswers";
import { questionAnswerOperations } from "./duck/index";
import LoadingSpinner from "../common/components/LoadingSpinner";
import ErrorImage from "../../assets/images/ErrorImage.png";

function QuestionAndAnswersContainer(props) {
  useEffect(() => {

    props.fetchQuestionAndAnswersOverview(props.isolationData.data.study_id);

    return () => {
      props.getQuestionDataBegin()
      props.setNewQuestionSelected(null)
      props.fetchQuestionAnswerOverviewBegin()
  }
  }, []);

  return (
    <>
      {props.questionAnswerOverviewLoading ? (
        <LoadingSpinner style={{ margin: "20vh auto" }} />
      ) : props.questionAnswerOverviewError ? (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img alt="Error loading your data"  src={ErrorImage} />
          </div>
        </>
      ) : (
        <div style={{ padding: 24 }}>
          <div
            style={{ position: "relative", right: "8px", marginBottom: "12px" }}
          >

          </div>
          <QuestionAndAnswers />
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isolationData: state.isolationData,
    questionAnswerOverviewLoading:
      state.questionAnswerData.questionAnswerOverviewLoading,
    questionAnswerOverviewError:
      state.questionAnswerData.questionAnswerOverviewError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchQuestionAndAnswersOverview: (studyID) =>
      dispatch(
        questionAnswerOperations.fetchQuestionAndAnswersOverview(studyID)
      ),
    getQuestionDataBegin: () =>
      dispatch(
        questionAnswerOperations.getQuestionDataBegin()
      ),
    setNewQuestionSelected: () =>
      dispatch(
        questionAnswerOperations.setNewQuestionSelected()
      ),
    fetchQuestionAnswerOverviewBegin: () =>
      dispatch(
        questionAnswerOperations.fetchQuestionAnswerOverviewBegin()
      ),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(QuestionAndAnswersContainer)
);
