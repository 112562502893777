import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { string, object } from 'yup';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import { overviewOperations } from '../../Overview/duck/index';
import { studiesOperations } from '../../Studies/duck/index';
import { LinksOperations } from '../../Links/duck/index';
import DataService from '../../CellStepManagement/duck/DataService';
import './styles.css';
import { TextInput } from '../../common/components/TextInput';

export const AudienceModal = (props) => {
  const {
    type,
    id,
    editPanelDetails,
    panelId,
    modalOpen,
    closeModal,
    createPanel,
    panelOverview,
    panelLoading,
    fetchStudy,
    getLinksData,
  } = props;

  useEffect(() => {
    if (type === 'EDIT_AUDIENCE') editPanelDetails(panelId, id);
  }, []);

  if (type === 'EDIT_AUDIENCE' && panelLoading) {
    return null;
  }

  const handleSubmit = async (values, actions) => {
    try {
      if (type === 'EDIT_AUDIENCE') {
        await DataService.editPanelDetails(id, panelId, values);
      } else {
        await createPanel(id, values);
      }
      fetchStudy({id});
      getLinksData(id);
    } catch {
      actions.setSubmitting(false);
    }
    closeModal();
  };

  return (
    <Formik
      initialValues={
        type === 'EDIT_AUDIENCE' ? {
          name: panelOverview?.panel?.name,
          code: panelOverview?.panel?.code,
          success_redirect_url: panelOverview?.panel?.success_redirect_url,
          screenout_redirect_url: panelOverview?.panel?.screenout_redirect_url,
          quota_full_redirect_url: panelOverview?.panel?.quota_full_redirect_url,
          participant_insert_parameter: panelOverview?.panel?.participant_insert_parameter,
          id: panelId
        } : {
          name: '',
          code: '',
          success_redirect_url: '',
          screenout_redirect_url: '',
          quota_full_redirect_url: '',
          participant_insert_parameter: '',
          id: '',

        }}
      validationSchema={object().shape({
        name: string()
          .required('Name required'),
        code: string()
          .required('Code required'),
        success_redirect_url: string()
          .required('Complete redirect required'),
        screenout_redirect_url: string()
          .required('disqualify redirect required'),
        quota_full_redirect_url: string()
          .required('Overquota redirect required'),
        participant_insert_parameter: string()
          .required('Parameter required')

      })}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSubmit}

    >{({
        handleSubmit,
        isSubmitting,
        errors,
      }) => (
        <Modal open={modalOpen}>
          <div className='create_audience_modal'>
            <div className='panel_header'>
              {panelId ? 'Edit' : 'Create'} Panel
            </div>
            <form
              onSubmit={handleSubmit}
              style={{
                background: 'rgb(243 244 246)',
                padding: '1rem 2rem',
              }}
            >
              <TextInput
                name='name'
                error={errors.name}
                label='Name'
                required
              />
              <TextInput
                name='code'
                error={errors.code}
                label='Code'
                required
              />
              <TextInput
                name='success_redirect_url'
                error={errors.success_redirect_url}
                label='Complete Redirect'
                required
              />
              <TextInput
                name='screenout_redirect_url'
                error={errors.screenout_redirect_url}
                label='Disqualify Redirect'
                required
              />
              <TextInput
                name='quota_full_redirect_url'
                error={errors.quota_full_redirect_url}
                label='Overquota Redirect'
                required
              />
              <TextInput
                name='participant_insert_parameter'
                error={errors.participant_insert_parameter}
                label='Parameter'
                required
              />
              <div style={{ marginTop: '1rem', textAlign: 'right'}}
              >
                <button
                  className='modal_button cancel_button'
                  disabled={isSubmitting}
                  onClick={closeModal}
                >Cancel</button>
                <button
                  className='modal_button submit_button'
                  type='submit'
                  disabled={isSubmitting}
                >Submit</button>
              </div>
            </form>
          </div>
        </Modal>
      )}
    </Formik>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: (id) => (dispatch(overviewOperations.closeModal(id))),
    createPanel: (id, data) => (dispatch(overviewOperations.createPanel(id, data))),
    fetchStudy: (study) => (dispatch(studiesOperations.fetchStudy(study))),
    editPanelDetails: (id, studyId) => (dispatch(overviewOperations.editPanelDetails(id, studyId))),
    getLinksData: (data) => dispatch(LinksOperations.getLinksData(data)),
  };
};

AudienceModal.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  editPanelDetails: PropTypes.func,
  panelId: PropTypes.number,
  modalOpen: PropTypes.bool,
  panelLoading: PropTypes.bool,
  closeModal: PropTypes.func,
  createPanel: PropTypes.func,
  handleUpdate: PropTypes.func,
  panelOverview: PropTypes.object,
  fetchStudy: PropTypes.func,
  getLinksData: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    modalOpen: state.overview.modalOpen,
    id: state.overview.id,
    panelOverview: state.overview.panelOverview,
    panelLoading: state.overview.panelLoading
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AudienceModal);
