import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { makeStyles } from "@material-ui/core/styles";
import { Redirect } from 'react-router-dom'
import EmailIcon from '@mui/icons-material/Email';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const useStyles = makeStyles((theme) => ({
    background: {
      overflowY: "scroll"
    },
  
  }));


export default function AccountMenu() {
    const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [redirectTo, setredirectTo] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  

  return (
    <div style={{position: "fixed", right: "24px", paddingRight: Boolean(anchorEl) ? "17px" : "0px", top:6}}>
        {redirectTo ==="contact us" ? <Redirect to="/contact_us" /> : ""}
        {redirectTo ==="signout" ? <Redirect to="/signout" /> : ""}
        {redirectTo ==="my account" ? <Redirect to="/my_account" /> : ""}
        <Tooltip title="Account settings">
          <IconButton size="large" onClick={handleClick}  >
            <AccountCircle style={{color: "white", fontSize: 28}} />
          </IconButton>

        </Tooltip>

      <Menu transitionDuration={{exit: 0}}
              classes={{
                root: classes.background,
              }}
      
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
              
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 20,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
      
        <MenuItem         style={{width:220}} onClick={() => setredirectTo("my account")}>
        <ListItemIcon>
        <AccountCircleIcon style={{fontSize: 24, position:"relative", right:2}}  fontSize="small" />
          </ListItemIcon>
          My account
        </MenuItem>
       
        <MenuItem onClick={() => setredirectTo("contact us")} >
          <ListItemIcon>
            <EmailIcon fontSize="small" />
          </ListItemIcon>
          Contact us
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => setredirectTo("signout")}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Signout
        </MenuItem>
      </Menu>
    </div>
  );
}
