import React from 'react';
import PropTypes from 'prop-types';

import YouTubeStepper from './YouTubeStepper';
import CreateStudyContainer from '../CreateStudyContainer';

const YouTubeContainer = ({history}) => {
  return (
    <CreateStudyContainer>
      <YouTubeStepper {...{history}} />
    </CreateStudyContainer>
  );
};

YouTubeContainer.propTypes = {
  history: PropTypes.object,
};

export default YouTubeContainer;
