import React from 'react';
import PropTypes from 'prop-types';

import LumenOnlyStepper from './LumenPanelStepper';
import CreateStudyContainer from '../CreateStudyContainer';

const LumenOnlyContainer = ({history}) => {
  return (
    <CreateStudyContainer>
      <LumenOnlyStepper {...{history}} />
    </CreateStudyContainer>
  );
};

LumenOnlyContainer.propTypes = {
  history: PropTypes.object,
};

export default LumenOnlyContainer;
