import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
// import Paper from "@material-ui/core/Paper";
// import TagFacesIcon from "@material-ui/icons/TagFaces";
// import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
    background: "unset !important",
  },
  listItem: {
    padding: "3px 16px 3px 0px",
  },
}));

export default function ChipsArray(props) {
  const classes = useStyles();
  // const [chipData, setChipData] = React.useState(props.chips);

  // const handleDelete = (chipToDelete) => () => {
  //   setChipData((chips) =>
  //     chips.filter((chip) => chip.key !== chipToDelete.key)
  //   );
  // };

  return (
    <ul className={classes.root}>
      {props.chips.map((data, index) => {
        // let icon;

        // if (data === "React") {
        //   icon = <TagFacesIcon />;
        // }

        return (
          <li className={classes.listItem} key={index}>
            <Chip
              size="small"
              // icon={icon}
              label={data}
              // onDelete={data === "React" ? undefined : handleDelete(data)}
              className={classes.chip}
              // clickable
            />
          </li>
        );
      })}

      {/* <li className={classes.listItem}>
        <Chip
          variant="outlined"
          size="small"
          deleteIcon={<AddIcon fontSize="small" />}
          label="Add comparison +"
          className={classes.chip}
          color="primary"
          clickable
        />
      </li> */}
    </ul>
  );
}
