import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import TreeMenu from 'react-simple-tree-menu';
import Paper from '@material-ui/core/Paper';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { lookerReportOperations } from './duck/index';
import LoadingSpinner from '../common/components/LoadingSpinner';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Typography } from '@mui/material';

const LookerReport = ({ report, url, fetchReportDetails, fetchLookerUrl, match }) => {
  const studyId = match.params.id;
  const [panelUrl, setPanelUrl] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initLooker = async () => {
      await fetchReportDetails(studyId);
      setLoading();
    };

    initLooker();

    return () => setLoading(false);
  }, []);

  useEffect(() => {
    if (panelUrl) {
      fetchLookerUrl(panelUrl, studyId);
    }
  }, [panelUrl]);

  useEffect(() => {
    if (!report) return;
    const hasPanel = !!(report.rows?.length && report.rows[0].panels?.length && report.rows[0].panels[0]);
    if (hasPanel) {
      setPanelUrl(report.rows[0].panels[0].url);
    }
  }, [report]);

  if (report && !report.success) return (
    <Paper style={{ borderRadius:16, height: '81vh' }}>
      <p style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', fontSize: '1.5rem', color: '#808080' }} >
        This {studyId ? 'study' : 'account'} has no reports
      </p>
    </Paper>
  );

  if (!report || !url || loading) return (
    <Paper style={{ borderRadius:16, height: '81vh' }}>
      <LoadingSpinner style={{ padding: '32vh', margin: 'auto' }}/>
    </Paper>
  );

  const { rows } = report;

  return (
    <Paper style={{ borderRadius:16, height: 'calc(100vh - 120px)' }}>
      <div
        style={{
          display: 'flex',
          minHeight: '100%',
          padding: '16px',
          fontSize: '0.8rem',
        }}
      >
        <div
          id='dashboard'
          style={{
            display: 'flex',
            flex: '1 1 0%',
            flexGrow: '1',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: '#d1d5db',
            position: 'relative'
          }}
        >
          <Tooltip
            placement='left'
            title={
              <Typography variant='body1'>If you are not authenticated to view the report please review
                <a
                  href='https://help.looker.com/hc/en-us/articles/4420207906835-Why-isn-t-embed-working-in-Safari-Are-there-browser-settings-we-need-to-set-for-SSO-embed-content-Community-'
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'rgb(0,186,219)', textDecoration: 'none'}}
                > this page.</a>
              </Typography>
            }>
            <InfoOutlinedIcon style={{ fontSize: 24, position: 'absolute', top: 3, right: 3, cursor: 'pointer', color: '#00badb' }} />
          </Tooltip>
          <iframe
            src={url}
            style={{width: '100%', height: '100%', border: 'none'}}
          >
          </iframe>
        </div>
        <div style={{
          overflowY: 'scroll',
          flex: '0 1 auto',
          borderStyle: 'solid',
          borderWidth: '1px',
          borderLeft: 'none',
          borderColor: '#d1d5db',
        }}>
          <TreeMenu
            cacheSearch
            data={rows?.map(row => {
              return {
                key: row?.name,
                label: row?.name,
                nodes: row?.panels?.map(panel => {
                  return {
                    key: panel?.name,
                    label: panel?.name,
                    url: panel?.url,
                  };
                }),
              };
            })}
            initialFocusKey={`${rows?.[0]?.name}/${rows?.[0]?.panels?.[0]?.name}`}
            initialActiveKey={`${rows?.[0]?.name}/${rows?.[0]?.panels?.[0]?.name}`}
            initialOpenNodes={[rows?.[0]?.name]}
            debounceTime={125}
            disableKeyboard={false}
            hasSearch
            onClickItem={(item) => {setPanelUrl(item?.url);}}
            resetOpenNodesOnDataUpdate={false}
          />
        </div>
      </div>
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    report: state.lookerReportReducer.report,
    url: state.lookerReportReducer.url,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchReportDetails: (studyId) => dispatch(lookerReportOperations.fetchReportDetails(studyId)),
    fetchLookerUrl: (panelUrl, studyId) => dispatch(lookerReportOperations.fetchLookerUrl(panelUrl, studyId)),
  };
};

LookerReport.propTypes = {
  report: PropTypes.object,
  url: PropTypes.string,
  fetchReportDetails: PropTypes.func,
  fetchLookerUrl: PropTypes.func,
  match: PropTypes.object,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LookerReport));
