import React from 'react';
import { Field, useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FormControlLabel, Radio } from '@material-ui/core';
import { RadioGroup } from 'formik-material-ui';
import MoreInfo from '../MaterialComponents/MoreInfo';

export const PanelDetails = () => {
  const {
    values,
    errors,
    touched,
    isSubmitting,
  } = useFormikContext();

  return (
    <>
      <Grid container spacing={3}>
        <Grid container wrap = 'nowrap' item xs={12} >
          <Typography variant='h6'>
           Panel details
          </Typography>
          <MoreInfo
            outerContainerClassName='alignToTop'
            popup='Your panel is your sample provider'
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            value={values.include_panel_details}
            component={RadioGroup}
            name='include_panel_details'
          >
            <FormControlLabel
              checked={values.include_panel_details === '0'}
              value={0}
              control={<Radio disabled={isSubmitting} color='primary' />}
              label='I would not like to include panel details'
              disabled={isSubmitting}
            />
            <FormControlLabel
              checked={values.include_panel_details === '1'}
              value={1}
              control={<Radio disabled={isSubmitting} color='primary' />}
              label='I would like to include panel details'
              disabled={isSubmitting}
            />
            <FormControlLabel
              checked={values.include_panel_details === '2'}
              value={2}
              control={<Radio disabled={isSubmitting} color='primary' />}
              label='I would like to include a basic redirect'
              disabled={isSubmitting}
            />
            <FormHelperText
              error
            >{touched['include_panel_details'] && !!errors['include_panel_details'] ?
                errors['include_panel_details'] :
                ''}
            </FormHelperText>
          </Field>
        </Grid>
        <Fade
          mountOnEnter
          unmountOnExit
          in={
            values.include_panel_details === '1'
          }
        >
          <Grid container item spacing={3}>
            <Grid item xs={12}>
              <Typography variant='subtitle1'>
                How will your respondents be redirected once they have completed
                your study?
              </Typography>
            </Grid>
            <Grid container wrap = 'nowrap' item xs={12} >
              <Field
                fullWidth
                name='panel_code'
                component={TextField}
                label='Panel code'
                variant='filled'
              />
              <MoreInfo
                outerContainerClassName='moreInfo'
                popup='A code to help you identify the panel used'
              />
            </Grid>
            <Grid container wrap = 'nowrap' item xs={12} >
              <Field
                fullWidth
                name='panel_success'
                component={TextField}
                label='Success redirect URL'
                variant='filled'
              />
              <MoreInfo
                outerContainerClassName='moreInfo'
                popup='The URL provided by your panel to return respondents who have successfully completed your study'
              />
            </Grid>
            <Grid container wrap = 'nowrap' item xs={12} >
              <Field
                fullWidth
                name='panel_screen'
                component={TextField}
                label='Screenout redirect URL'
                variant='filled'
              />

              <MoreInfo
                outerContainerClassName='moreInfo'
                popup='The URL provided by your panel to return respondents who have not met the screening criteria in your study'
              />
            </Grid>
            <Grid container wrap = 'nowrap' item xs={12} >
              <Field
                fullWidth
                name='panel_quotafull'
                component={TextField}
                label='Quota full redirect URL'
                variant='filled'
              />
              <MoreInfo
                outerContainerClassName='moreInfo'
                popup='The URL provided by your panel to return respondents for already complete demographic group'
              />
            </Grid>
            <Grid container wrap = 'nowrap' item xs={12} >
              <Field
                fullWidth
                name='panel_insert_param'
                component={TextField}
                label='Participant insert parameter'
                variant='filled'
              />
              <MoreInfo
                outerContainerClassName='moreInfo'
                popup={`The variable name the panel gives to the unique respondent IDs (e.g. 'RID', 'Pid', 'participant' or 'member')`}
              />
            </Grid>
          </Grid>
        </Fade>
        <Fade
          mountOnEnter
          unmountOnExit
          in={
            values.include_panel_details === '2'
          }
        >
          <Grid container item spacing={3}>
            <Grid item xs={12}>
              <Typography variant='subtitle1'>
                How will your respondents be redirected once they have completed
                your study?
              </Typography>
            </Grid>
            <Grid container wrap = 'nowrap' item xs={12} >
              <Field
                fullWidth
                name='panel_success'
                component={TextField}
                label='Success redirect URL'
                variant='filled'
              />
              <MoreInfo
                outerContainerClassName='moreInfo'
                popup='The URL provided by your panel to return respondents who have successfully completed your study'
              />
            </Grid>
          </Grid>
        </Fade>
      </Grid>
    </>
  );
};
