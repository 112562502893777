import React from "react";
import { connect } from "react-redux";
import { demographicOperations } from "./duck";
import { withRouter } from "react-router-dom";
import Utils from "../common/utils/Utils";
import PropTypes from "prop-types";
import LoadingSpinner from "../common/components/LoadingSpinner";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { theme } from "../CreateStudyPage/MaterialStyling";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import DownLoadButton from "../Kpi/Components/DownloadButton";
import { Typography } from "@material-ui/core";
// import MoreMenu from "../Kpi/Components/MoreMenu";
import PieChart from "./Components/PieChart";
import noDataImage from "../../assets/images/NoDataImage.png";
import Checkbox from '@mui/material/Checkbox';

class Demographics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      limitToEyetracking: true,
    }
    this.handleSetLimitEyeTrackingChange = this.handleSetLimitEyeTrackingChange.bind(this)
    this.DownloadData = this.DownloadData.bind(this)
  }
  componentDidMount() {
    this.props.fetchDemographicData(this.props.match.params.id, this.state.limitToEyetracking);
  }

  handleSetLimitEyeTrackingChange(event) {
    this.setState({limitToEyetracking: event.target.checked})
    this.props.fetchDemographicData(this.props.match.params.id, event.target.checked);
  };

  DownloadData(demographicType) {
    if (demographicType === "age") {
      const download = Utils.ConvertArrayOfObjectsToCSV(
        this.props.demographicData[0].age
      );
      Utils.createDownloadLink(download, "stim-data");
    } else if (demographicType === "earnings") {
      const download = Utils.ConvertArrayOfObjectsToCSV(
        this.props.demographicData[0].earnings
      );
      Utils.createDownloadLink(download, "stim-data");
    } else if (demographicType === "gender") {
      const download = Utils.ConvertArrayOfObjectsToCSV(
        this.props.demographicData[0].gender
      );
      Utils.createDownloadLink(download, "stim-data");
    } else if (demographicType === "family") {
      const download = Utils.ConvertArrayOfObjectsToCSV(
        this.props.demographicData[0].family
      );
      Utils.createDownloadLink(download, "stim-data");
    }
  }

  render() {
    const { loading, demographicData } = this.props;

    if (loading) {
      return <LoadingSpinner />;
    }





    return (
      <React.Fragment>
        <ThemeProvider theme={theme}>
          <Paper style={{ marginTop: 40, marginBottom: 40, padding: 24 }} elevation={4}>
            <Grid container spacing={3}>
          
              <Grid
                alignContent="flex-end"
                container
                wrap="nowrap"
                justify="space-between"
                item
                sm={12}
              >
                <Grid item sm={12}>
                  <Typography
                    style={{ overflowWrap: "anywhere" }}
                    color="textSecondary"
                    variant="h4"
                  >
                    {this.props.isolationData.data.study_name}
                  </Typography>
                </Grid>
                <div>
                <DownLoadButton
                  options={[
                    "Download age data",
                    "Download earnings data",
                    "Download gender data",
                    "Download family data",
                  ]}
                  downloadDemographicData={(downloadType) =>
                    this.DownloadData(downloadType)
                  }
                />
                                <div style={{ display: "flex", alignItems: "center", whiteSpace: "nowrap", left: 10, position: "relative" }}>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    style={{ display: "block", fontSize: 14 }}
                  >
                    Limit to eyetracking
                  </Typography>
              
                  <Checkbox
                  size="small"
                      checked={this.state.limitToEyetracking}
                      onChange={this.handleSetLimitEyeTrackingChange}
                      />
                </div>
                </div>
              </Grid>
              <Grid item sm={12} md={6}>
                <Paper variant="outlined" style={{ padding: 16, height: "100%" }}>
                  <Grid
                    style={{ flexBasis: "unset" }}
                    container
                    wrap="nowrap"
                    item
                    sm={12}
                  >
                    <Grid container wrap="nowrap">
                      <Grid container wrap="nowrap" item sm={12}>
                        <Typography variant="h6">Gender</Typography>
                      </Grid>
                      {/* <MoreMenu
                            initialTitle="Pie chart"
                             onSelectItem={(filter) => setKpiFilter(filter, this.props.index)}
                            options={["Pie chart", "Bar chart"]}
                          /> */}
                    </Grid>
                  </Grid>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    gutterBottom
                  >
                    What gender are your respondents?
                  </Typography>
                  <div className="pie-container">
                    {demographicData[0].gender.filter((object) => object.count > 0).length > 1 ?
                      <PieChart
                        labels={demographicData[0].gender
                          .filter((object) => object.count > 0)
                          .map((filteredObject) => filteredObject.gender_group)}
                        data={demographicData[0].gender
                          .filter((object) => object.count > 0)
                          .map((filteredObject) => filteredObject.count)}
                      />
                      : <img style={{ height: "100%", width: "100%" }} src={noDataImage} alt="error" />
                    }
                  </div>
                </Paper>
              </Grid>
              <Grid item sm={12} md={6}>
                <Paper variant="outlined" style={{ padding: 16, height: "100%" }}>
                  <Grid
                    style={{ flexBasis: "unset" }}
                    container
                    wrap="nowrap"
                    item
                    sm={12}
                  >
                    <Grid container wrap="nowrap">
                      <Grid container wrap="nowrap" item sm={12}>
                        <Typography variant="h6">Age</Typography>
                      </Grid>
                      {/* <MoreMenu
                            initialTitle="Pie chart"
                             onSelectItem={(filter) => setKpiFilter(filter, this.props.index)}
                            options={["Pie chart", "Bar chart"]}
                          /> */}
                    </Grid>
                  </Grid>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    gutterBottom
                  >
                    How old are your respondents?
                  </Typography>
                  <div className="pie-container">
                    {demographicData[0].age.filter((object) => object.count > 0).length > 1 ?
                      <PieChart
                        labels={demographicData[0].age
                          .filter((object) => object.count > 0)
                          .map((filteredObject) => filteredObject.age_group)}
                        data={demographicData[0].age
                          .filter((object) => object.count > 0)
                          .map((filteredObject) => filteredObject.count)}
                      />
                      : <img style={{ height: "100%", width: "100%" }} src={noDataImage} alt="error" />
                    }
                  </div>
                </Paper>
              </Grid>
              <Grid item sm={12} md={6}>
                <Paper variant="outlined" style={{ padding: 16, height: "100%" }}>
                  <Grid
                    style={{ flexBasis: "unset" }}
                    container
                    wrap="nowrap"
                    item
                    sm={12}
                  >
                    <Grid container wrap="nowrap">
                      <Grid container wrap="nowrap" item sm={12}>
                        <Typography variant="h6">Family</Typography>
                      </Grid>
                      {/* <MoreMenu
                            initialTitle="Pie chart"
                             onSelectItem={(filter) => setKpiFilter(filter, this.props.index)}
                            options={["Pie chart", "Bar chart"]}
                          /> */}
                    </Grid>
                  </Grid>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    gutterBottom
                  >
                    How many children do your respondents have?
                  </Typography>
                  <div className="pie-container">
                    {demographicData[0].family.filter((object) => object.count > 0).length > 1 ?
                      <PieChart
                        labels={demographicData[0].family
                          .filter((object) => object.count > 0)
                          .map((filteredObject) => filteredObject.family_group)}
                        data={demographicData[0].family
                          .filter((object) => object.count > 0)
                          .map((filteredObject) => filteredObject.count)}
                      />
                      : <img style={{ height: "100%", width: "100%" }} src={noDataImage} alt="error" />
                    }
                  </div>
                </Paper>
              </Grid>
              <Grid item sm={12} md={6}>
                <Paper variant="outlined" style={{ padding: 16, height: "100%" }}>
                  <Grid
                    style={{ flexBasis: "unset" }}
                    container
                    wrap="nowrap"
                    item
                    sm={12}
                  >
                    <Grid container wrap="nowrap">
                      <Grid container wrap="nowrap" item sm={12}>
                        <Typography variant="h6">Earnings</Typography>
                      </Grid>
                      {/* <MoreMenu
                            initialTitle="Pie chart"
                             onSelectItem={(filter) => setKpiFilter(filter, this.props.index)}
                            options={["Pie chart", "Bar chart"]}
                          /> */}
                    </Grid>
                  </Grid>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    gutterBottom
                  >
                    How much do your respondents earn?
                  </Typography>
                  <div className="pie-container">
                    {demographicData[0].earnings.filter((object) => object.count > 0).length > 1 ?
                      <PieChart
                        labels={demographicData[0].earnings
                          .filter((object) => object.count > 0)
                          .map(
                            (filteredObject) => filteredObject.earnings_group
                          )}
                        data={demographicData[0].earnings
                          .filter((object) => object.count > 0)
                          .map((filteredObject) => filteredObject.count)}
                      />
                      : <img style={{ height: "100%", width: "100%" }} src={noDataImage} alt="error" />
                    }
                  </div>
                </Paper>
              </Grid>
              <Grid item sm={12} md={6}>
                <Paper variant="outlined" style={{ padding: 16, height: "100%" }}>
                  <Grid
                    style={{ flexBasis: "unset" }}
                    container
                    wrap="nowrap"
                    item
                    sm={12}
                  >
                    <Grid container wrap="nowrap">
                      <Grid container wrap="nowrap" item sm={12}>
                        <Typography variant="h6">Social grade</Typography>
                      </Grid>
                      {/* <MoreMenu
                            initialTitle="Pie chart"
                             onSelectItem={(filter) => setKpiFilter(filter, this.props.index)}
                            options={["Pie chart", "Bar chart"]}
                          /> */}
                    </Grid>
                  </Grid>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    gutterBottom
                  >
                    What is the background of your respondents?
                  </Typography>
                  <div className="pie-container">
                    {demographicData[0].socialgrade.filter((object) => object.count > 0).length > 1 ?
                      <PieChart
                        labels={demographicData[0].socialgrade
                          .filter((object) => object.count > 0)
                          .map((filteredObject) => filteredObject.socialgrade_group)}
                        data={demographicData[0].socialgrade
                          .filter((object) => object.count > 0)
                          .map((filteredObject) => filteredObject.count)}
                      />
                      : <img style={{ height: "100%", width: "100%" }} src={noDataImage} alt="error" />
                    }
                  </div>
                </Paper>
              </Grid>
              <Grid item sm={12} md={6}>
                <Paper variant="outlined" style={{ padding: 16, height: "100%" }}>
                  <Grid
                    style={{ flexBasis: "unset" }}
                    container
                    wrap="nowrap"
                    item
                    sm={12}
                  >
                    <Grid container wrap="nowrap">
                      <Grid container wrap="nowrap" item sm={12}>
                        <Typography variant="h6">Ethnicity</Typography>
                      </Grid>
                      {/* <MoreMenu
                            initialTitle="Pie chart"
                             onSelectItem={(filter) => setKpiFilter(filter, this.props.index)}
                            options={["Pie chart", "Bar chart"]}
                          /> */}
                    </Grid>
                  </Grid>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    gutterBottom
                  >
                    What is the ethnicity of your respondents?
                  </Typography>
                  <div className="pie-container">
                    {demographicData[0].ethnicity.filter((object) => object.count > 0).length > 1 ?
                      <PieChart
                        labels={demographicData[0].ethnicity
                          .filter((object) => object.count > 0)
                          .map((filteredObject) => filteredObject.ethnicity_group)}
                        data={demographicData[0].ethnicity
                          .filter((object) => object.count > 0)
                          .map((filteredObject) => filteredObject.count)}
                      />
                      : <img style={{ height: "100%", width: "100%" }} src={noDataImage} alt="error" />
                    }
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

Demographics.propTypes = {
  loading: PropTypes.bool,
  demographicData: PropTypes.array,
  fetchDemographicData: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      experiment: PropTypes.string,
    }),
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  fetchDemographicData: (id, limitToEyetracking) =>
    dispatch(demographicOperations.fetchDemographicData(id, limitToEyetracking)),
});

const mapStateToProps = (state) => {
  return {
    demographicData: state.demographicData.demographicData,
    loading: state.demographicData.loading,
    isolationData: state.isolationData,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Demographics)
);
