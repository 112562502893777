import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Modal } from '@mui/material';

const styles = {
  EndStudy: {
    backgroundColor: '#00badb',
    whiteSpace: 'nowrap',
    color: '#ffffff',
    border: 'unset',
    borderRadius: 4,
    textTransform: 'capitalize',
    padding: '3px 16px',
  },
  SubscribeToStudy: {
    backgroundColor: 'rgb(0, 171, 85)',
    whiteSpace: 'nowrap',
    color: '#ffffff',
    border: 'unset',
    borderRadius: 4,
    textTransform: 'capitalize',
    padding: '6px 16px',
  },
};

//Props this component takes:
//openButtonText, title, mainText, actionButtonText, action, className
//Need to add your own styling and className prop

const ConfirmActionModal = ({
  className,
  action,
  openButtonText,
  title,
  mainText,
}) => {
  const [open, setOpen] = useState(false);

  const handleSubmit = async () => {
    await action();
    setOpen(false);
  };

  return (
    <>
      <Button
        variant='contained'
        style={styles[className]}
        onClick={() => setOpen(true)}
      >
        {openButtonText}
      </Button>
      <Modal open={open}>
        <div className='create_audience_modal'>
          <div className='panel_header'>
            {title}
          </div>
          <div
            style={{
              background: 'rgb(243 244 246)',
              padding: '1rem 2rem',
            }}
          >
            <p>{mainText}</p>
            <div style={{ marginTop: '1rem', textAlign: 'right'}}>
              <button
                className='modal_button cancel_button'
                onClick={() => setOpen(false)}
              >Cancel</button>
              <button
                className='modal_button submit_button'
                type='submit'
                onClick={handleSubmit}
              >Confirm</button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

ConfirmActionModal.propTypes = {
  className: PropTypes.string,
  action: PropTypes.func,
  openButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  title: PropTypes.string,
  mainText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default ConfirmActionModal;
