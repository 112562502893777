import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography"
import ErrorImage from "../../../assets/images/ErrorImage.png"

export default function Image({
  setimageIsLoading,
  source,
  shape,
  name,
}) {
  const [imageSource, setImageSource] = useState(source)
  useEffect(
    () => {
      setimageIsLoading(true);
      setImageSource(source)
    },
    [source]
  );
  return shape === "tall" && imageSource !== ErrorImage ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignSelf: "flex-start",
   
        borderRadius: 8,
        padding: "16px 56px",
        background: "FAFAFA",
        maxWidth: "100%",
      }}
    >
      <img
        style={{
          maxHeight: "100%",
          borderRadius: 8,
          maxWidth: "100%",
          padding: 0,
        }}
        onLoad={() => setimageIsLoading(false)}
        alt="lumen dashboard"
        src={imageSource}
        onError={() => setImageSource(ErrorImage)}
      />
      <Typography style={{marginTop:"8px"}} color="textSecondary" variant="caption">{name}</Typography>
    </div>
  ) : (
    <div style={{
      display: "flex",
      flexDirection: "column",
    }}>
      <img
        style={{
          maxHeight: "100%",
          borderRadius: 8,
          width: "100%",
          padding: 0,
        }}
        onLoad={() => setimageIsLoading(false)}
        alt="lumen dashboard"
        src={imageSource}
        onError={() => setImageSource(ErrorImage)}
      />
      <Typography style={{marginTop:"8px"}} color="textSecondary" variant="caption">{name}</Typography>
    </div>
  );
}

Image.propTypes = {
  source: PropTypes.string,
};
