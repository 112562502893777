import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useField, useFormikContext } from 'formik';
import CardButton from './CardButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import propTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  root: {
    marginLeft: '0px',
    marginRight: '0px',
  },
  flexContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    gap:'28px'
  },
}));

export default function EnvironmentButtons({ cards }) {
  const [field, meta, helpers] = useField('environment_id');
  const {touched, errors} = useFormikContext();
  const classes = useStyles();
  const currentValue = meta.value;

  const formControlInfo = () => {
    return cards.map((card, index) => (
      <FormControlLabel
        key={index}
        className={classes.root}
        control={
          <CardButton
            label={card.description}
            iconName={card.description}
            color={parseInt(currentValue.code) === parseInt(card.code)}
            onClick={() => helpers.setValue(card)}
          />
        }
      />
    ));
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography gutterBottom align='center' variant='subtitle1' >
          What device/environment do you want your respondents to use?
        </Typography>
      </Grid>
      <Grid {...field} item xs={12}>
        <FormControl>
          <FormGroup className={classes.flexContainer}>
            {formControlInfo()}
          </FormGroup>
          <FormHelperText error variant='filled'> {errors.environment_id && touched.environment_id ? errors.environment_id.code : ''}</FormHelperText>
        </FormControl>
      </Grid>
    </>
  );
}

EnvironmentButtons.propTypes = {
  cards: propTypes.array,
};
