import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import propTypes from 'prop-types';

function Row({ lumenRow, previewLinks, liveLinks, cellLinks}) {
  const [open, setOpen] = React.useState(false);
  const panel = lumenRow ? 'Lumen' : previewLinks.panel_name;

  return (
    <React.Fragment>
      <TableRow style={{ background: 'white' }} sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell style={{ borderBottom: 'unset' }}  >
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </IconButton>
        </TableCell>
        <TableCell style={{ borderBottom: 'unset', textTransform: 'capitalize' }} scope='row'>
          {panel}
        </TableCell>
        <TableCell align='center' style={{ borderBottom: 'unset' }}  >
          <a target='_blank' rel='noreferrer' href={previewLinks.standard} >
            Preview
          </a>
        </TableCell>
        <TableCell align='center' style={{ borderBottom: 'unset' }}  >
          <a target='_blank' rel='noreferrer' href={liveLinks.standard} >
            Live
          </a>
        </TableCell>
      </TableRow>
      <TableRow style={{ background: 'white' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 'unset' }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box style={{ padding: '16px 0px' }} sx={{ margin: 1 }}>
              <Typography variant='h6' gutterBottom component='div' style={{textTransform: 'capitalize'}}>
                {panel} direct cell assignment
              </Typography>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell style={{whiteSpace: 'nowrap'}} align='center'>Preview Links</TableCell>
                    <TableCell  style={{whiteSpace: 'nowrap'}} align='center'>Live Links</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {previewLinks.cell_links.map((cellRow, index) => (
                    <TableRow style={{ background: 'white' }} key={index}>
                      <TableCell scope='row'>
                        Cell {cellLinks[index].short_name}
                      </TableCell>
                      <TableCell align='center'>
                        <a target='_blank' rel='noreferrer' href={cellRow.standard} >
                          Preview
                        </a>
                      </TableCell>
                      <TableCell align='center'>
                        <a target='_blank' rel='noreferrer' href={liveLinks.cell_links[index].standard} >
                          Live
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  previewLinks: propTypes.object,
  liveLinks: propTypes.object,
  lumenRow: propTypes.bool,
  cellLinks: propTypes.array
};

export default function NewLinksTable({ data }) {
  if (!data) {
    return '';
  }

  return (
    <TableContainer >
      <Table aria-label='collapsible table'>
        <TableHead>
          <TableRow style={{ background: 'white' }}>
            <TableCell style={{ borderTopLeftRadius: 16, borderBottomLeftRadius: 16, borderBottom: 'unset' }} />
            <TableCell style={{ borderBottom: 'unset' }} >Panel</TableCell>
            <TableCell style={{ borderBottom: 'unset', whiteSpace: 'nowrap' }} align='center'>Preview links</TableCell>
            <TableCell style={{ borderTopRightRadius: 16, whiteSpace: 'nowrap', borderBottomRightRadius: 16, borderBottom: 'unset' }} align='center'>Live links</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Row
            lumenRow
            previewLinks={data.testing_links}
            liveLinks={data.live_links}
            cellLinks={data.testing_links.cell_links}
          />
          {data.testing_links.panel_links.map((previewLinks, index) => (
            <Row
              key={previewLinks.panel_name}
              previewLinks={previewLinks}
              liveLinks={data.live_links.panel_links[index]}
              cellLinks={data.testing_links.cell_links}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

NewLinksTable.propTypes = {
  data: propTypes.object,
};
