import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { overviewOperations } from '../../Overview/duck/index';
import { Modal } from '@mui/material';
import './styles.css';

const DeleteConfirmationModal = ({
  confirmationModalOpen,
  closeConfirmationModal,
  deleteId,
  panel,
  onDeleteClick,
}) => {

  return (
    <Modal open={confirmationModalOpen}>
      <div className='create_audience_modal'>
        <div className='panel_header'>
          Delete Panel
        </div>
        <div
          style={{
            background: 'rgb(243 244 246)',
            padding: '1rem 2rem',
          }}
        >
          <p>Are you sure you want to delete <span style={{ fontStyle: 'italic' }}>{panel.name}</span> panel?</p>
          <div style={{ marginTop: '1rem', textAlign: 'right'}}>
            <button
              className='modal_button cancel_button'
              onClick={() => closeConfirmationModal()}
            >Cancel</button>
            <button
              className='modal_button submit_button'
              type='submit'
              onClick={() => onDeleteClick(panel.id.toString(), deleteId)}
            >Submit</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteClick: (id, deleteId) => (dispatch(overviewOperations.onDeleteClick(id, deleteId))),
    closeConfirmationModal: () => (dispatch(overviewOperations.closeConfirmationModal())),
    editPanelDetails: (id, studyId) => (dispatch(overviewOperations.editPanelDetails(id, studyId))),
  };
};

const mapStateToProps = (state) => {
  return {
    confirmationModalOpen: state.overview.confirmationModalOpen,
    deleteId: state.overview.deleteId,
    panelOverview: state.overview.panelOverview,
  };
};

DeleteConfirmationModal.propTypes = {
  confirmationModalOpen: PropTypes.bool,
  closeConfirmationModal: PropTypes.func,
  deleteId: PropTypes.string,
  panel: PropTypes.object,
  onDeleteClick: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteConfirmationModal);
