import React, {useEffect} from 'react';
import propTypes from "prop-types";
import { withRouter } from "react-router";
import { featureAnalysisOperations } from "../../FeatureAnalysis/duck/index";
import { connect } from "react-redux";
import { studiesOperations } from "../../Studies/duck/index";
import { filterOperations } from "../../Filter/duck/index";
import { makeStyles } from '@material-ui/core/styles';
import ReportsTreeSelector from './ReportsTreeSelector';
import Grid from "@material-ui/core/Grid"
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import MoreInfo from "../../CreateStudyPage/MaterialComponents/MoreInfo";


const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    gap: "16px",
    flexDirection: "column",
    // marginTop: theme.spacing(3),
    marginBottom: 8,
    padding: theme.spacing(2),
    paddingRight: theme.spacing(0),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(2),
    },
  },

  paperPadding: {
    display: "flex",
    padding: 16,
    justifyContent: "center"
  }
}));

function StudyGenerator(props) {
  const classes = useStyles();
  const [stimsSelected, setStimsSelected] = React.useState([]);
  const [reportType, setreportType] = React.useState('compare');
  const [selectStimValidation, setSelectStimValidation] = React.useState(false)
  const [IsURLDefined, setIsURLDefined] = React.useState(false)

  useEffect(() => {
    if (IsURLDefined){
      if(stimsSelected.length > 0 || reportType === "demographics" || reportType === "questionAnswers"){
        generateReport()
        setIsURLDefined(false)
      }
   
    }
  }, [stimsSelected]);

  useEffect(() => {
    let parameter = new URLSearchParams(window.location.search)
    let URLreportType = parameter.get("report_type")
    if (URLreportType){
      setreportType(URLreportType)
      setIsURLDefined(true)
    }
  }, []);


  

  const handleChange = (event) => {
    setreportType(event.target.value);
  };

  const generateReport = () => {
    if (stimsSelected.length === 0 && reportType !== "demographics" && reportType !== "questionAnswers") {
      setSelectStimValidation(true)
    }
    else {
      setSelectStimValidation(false)
      const studyId = props.match.params.id;
      props.setNewReportCriteria(stimsSelected, studyId, reportType)
    }
  };



  return (
    <Paper elevation={2} className={classes.root}>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Create your reports</Typography>
        </Grid>


        <Grid style={{zIndex: 2}} item xs={12}>
          <Grid container wrap="nowrap" item xs={12} >
            <Typography color="textSecondary" variant="subtitle2">Choose stims </Typography>
            <MoreInfo outerContainerClassName="upALittleToTheRight" popup={<div style={{ padding: 8 }}><Typography style={{ display: "block" }} variant='body2'>Study heirarchy:</Typography><Typography variant="caption" >Study</Typography> <NavigateNextIcon style={{ fontSize: "0.7rem" }} /> <Typography variant="caption" >Cell</Typography> <NavigateNextIcon style={{ fontSize: "0.7rem" }} /><Typography variant="caption" > Step</Typography> <NavigateNextIcon style={{ fontSize: "0.7rem" }} /><Typography variant="caption" > Stim</Typography></div>} />
          </Grid>
          <ReportsTreeSelector error={selectStimValidation} setStimsSelected={(stims) => setStimsSelected(stims)} />
          {selectStimValidation ? <Typography style={{ marginLeft: 8 }} variant="caption" color="error" >Please select at least one stim</Typography> : ""}
        </Grid>

        <Grid item xs={12}>
          <Typography color="textSecondary" variant="subtitle2">Choose report type</Typography>
          <FormControl component="fieldset" style={{ marginLeft: 8 }}>
            <RadioGroup aria-label="reporttype" name="reporttype" value={reportType} onChange={handleChange}>
              <FormControlLabel value="compare" control={<Radio color="primary" />} label="Compare report" />
              <FormControlLabel value="kpi" control={<Radio color="primary" />} label="KPI report" />
              <FormControlLabel value="feature" control={<Radio color="primary" />} label="Feature report" />
              <FormControlLabel value="demographics" control={<Radio color="primary" />} label="Demographics report" />
              {props.isolationData.data.has_survey ? <FormControlLabel value="questionAnswers" control={<Radio color="primary" />} label="Question report" /> : ""}
            </RadioGroup>
          </FormControl>

        </Grid>

        <Grid style={{ paddingRight: "0px", display: "flex", justifyContent: "center" }} item xs={12}>
          <Button variant="contained" color="primary" onClick={generateReport}>Generate Report</Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

StudyGenerator.propTypes = {
  match: propTypes.object.isRequired,
  location: propTypes.object.isRequired,
  history: propTypes.object.isRequired,
};


const mapStateToProps = (state) => {
  return {
    studyData: state.studyData,
    isolationData: state.isolationData,
    whichStudiesAreChecked: state.isolationData.whichStudiesAreChecked,
    isModalOpen: state.filter.modalOpen,
    loading: state.isolationData.loading,
  };
};



const mapDispatchToProps = (dispatch) => {
  return {
    fetchStudy: (study) => dispatch(studiesOperations.fetchStudy(study)),
    goToAttentionReportPage: (whichStudiesAreChecked, { history, match }) =>
      dispatch(
        studiesOperations.goToAttentionReportPage(whichStudiesAreChecked, {
          history,
          match,
        })
      ),
    compareAllStudies: (allStudies, { history, match }) =>
      dispatch(
        studiesOperations.compareAllStudies(allStudies, { history, match })
      ),
    openModal: () => dispatch(filterOperations.openModal()),
    // downloadStimReport: (cellId, stepId, studyId, stimId, download) =>
    //   dispatch(
    //     kpiOperations.fetchKpiRawData(cellId, stepId, studyId, stimId, download)
    //   ),
    setNewReportCriteria: (checked, studyId, reportType) => dispatch(studiesOperations.setNewReportCriteria(checked, studyId, reportType)),
    downloadFeatureData: (cellId, stepId, studyId, stimId, download) =>
      dispatch(
        featureAnalysisOperations.fetchFeatureRawData(
          cellId,
          stepId,
          studyId,
          stimId,
          download
        )
      ),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(StudyGenerator)
);

