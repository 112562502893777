import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';

export const TextInput = ({ name, error, label, required }) => {

  return (
    <div style={{ margin: '0.5rem 0'}}>
      <div style={{ display: 'flex', justifyContent: 'space-between'}}>
        <label
          style={{ fontSize: '0.9rem', color: '#374151'}}
          htmlFor={name}
        >{required && <span style={{ color: 'red'}}>* </span>}{label}</label>
        { error && <span style={{ color: 'red', fontSize: '0.7rem'}}>{error}</span>}
      </div>
      <Field
        autoFocus={name === 'name'}
        name={name}
        type='text'
        style={{
          width: '100%',
          padding: '12px 20px',
          margin: '8px 0',
          background: 'white',
          boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
          borderRadius: '4px',
        }}/>
    </div>
  );
};

TextInput.propTypes = {
  name: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
};
