import React, { useState, useEffect } from 'react';
import Image from '../../../common/components/Image';
import Video from '../../../common/components/Video';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import classNames from 'classnames';

function Visuals({ loading, filter, data }) {
  const [imageIsLoading, setimageIsLoading] = useState(false)

  useEffect(() => {
    setimageIsLoading(false)
  }, []);

  if (loading) {
    return (
      <LoadingSpinner />
    )
  }


  const whatShape = (stimHeight, stimWidth) => {
    if (stimWidth /stimHeight > 2){
      return "wide"
    }
    else if (stimWidth /stimHeight <= 0.5){
      return "tall"
    }
    else{
      return "square"
    }
  }

  return (
    <React.Fragment>
      <div style={{ display: imageIsLoading ? "block" : "none"}}>
        <LoadingSpinner style={{margin:"10vh auto"}}/>
      </div>
      <div style={{ display:  imageIsLoading ? "none" : "grid", gap: "16px", gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr)", justifyItems: "center"}}>
        {data.data.map((item, index) => {
          const shape = whatShape(item.stim_height, item.stim_width)
          const imageOrVideo = item.stim_type === 'video' ?
            <Video className="visuals__video-container"
              key={index}
              videoSource={item.original_url}
              name={item.stim_name} /> :
            <Image 
              setimageIsLoading={(trueOrFalse) => {setimageIsLoading(trueOrFalse)}}
              imageClass={classNames('study__image-container')}
              shape={shape}
              key={index}
              name={item.stim_name}
              source={item[filter]} />;
          return (
            imageOrVideo
          );

        })}

      </div>
    </React.Fragment>
  );

}

export default Visuals;


