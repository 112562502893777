
const FETCH_AUDIENCE_BEGIN = 'FETCH_AUDIENCE_BEGIN';
const FETCH_AUDIENCE_SUCCESS = 'FETCH_AUDIENCE_SUCCESS';
const FETCH_AUDIENCE_ERROR = 'FETCH_AUDIENCE_ERROR';

export default {
  FETCH_AUDIENCE_BEGIN,
  FETCH_AUDIENCE_SUCCESS,
  FETCH_AUDIENCE_ERROR,

};

