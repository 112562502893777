import DataService from '../../common/services/dataService';
import HttpService from '../../Dashboard/duck/HttpService';

import Creators from './actions';

const fetchStimsBegin = Creators.fetchStimsBegin;
const fetchStimsSuccess = Creators.fetchStimsSuccess;
const fetchStimsError = Creators.fetchStimsError;
const setStimFilters = Creators.setStimFilter;
const getTypesBegin = Creators.getTypesBegin;
const getTypesSuccess = Creators.getTypesSuccess;
const setStimTypeFilter = Creators.setStimTypeFilter;
const setMediaFilter = Creators.setMediaFilter;
const sortByAscendingOrder = Creators.sortByAscendingOrder;
const sortByDecendingOrder = Creators.sortByDecendingOrder;

const getStimsData = (stimsPerPage, pageNum, orderBy, filterName, filterMediaId, filterStimType ) => {

  return async dispatch => {
    dispatch(fetchStimsBegin());
    try {
      let data = {
        results_per_page: stimsPerPage,
        page_num: pageNum,
        order_by: orderBy,
        filter_name: filterName,
        filter_media_id: parseInt(filterMediaId),
        filter_stimtype: filterStimType,
      };

      dispatch(getTypes());
      const res = await DataService.fetchStimsData(data);
      dispatch(fetchStimsSuccess(res.data));
    }
    catch (err) {
      dispatch(fetchStimsError(err));
      throw (err);
    }
  };
};

const getTypes = () => {

  return async dispatch => {
    dispatch(getTypesBegin());

    const envResults = await HttpService.getEnvironmentList();
    const mediaResults = await HttpService.getMediaList();
    const stimTypesResults = await HttpService.getStimTypesList();
    const languageResults = await HttpService.getLanguageList();
    const locationResults = await HttpService.getLocationsList();
    const questionResults = await HttpService.getQuestionsList();
    dispatch(getTypesSuccess(envResults.data, mediaResults.data, stimTypesResults.data, languageResults.data, locationResults.data, questionResults.data));
  };
};

const setStimFilter = (e, type) => {
  return dispatch => {
    if (type === 'media') {
      dispatch(setMediaFilter(e));
    } else if (type === 'stimType') {
      dispatch(setStimTypeFilter(e));
    } else {
      dispatch(setStimFilters(e, type));
    }
  };
};

const sortAscendingData = (item, type) => {
  if (type === 'DECENDING') {
    return dispatch => {
      dispatch(sortByDecendingOrder(item, type));
    };
  } else {
    return dispatch => {
      dispatch(sortByAscendingOrder(item, type));
    };
  }
};

export default {
  getStimsData,
  getTypes,
  setStimFilter,
  sortAscendingData,
  fetchStimsBegin,
};
