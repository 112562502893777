
const SEARCH = 'SEARCH';
const IS_LIVE = 'IS_LIVE';
const COMPLETED = 'COMPLETED';
const MEDIA_TYPE = 'MEDIA_TYPE';
const ENVIRONMENT_TYPE = 'ENVIRONMENT_TYPE';
const FETCH_STUDIES_BEGIN = 'FETCH_STUDIES_BEGIN';
const FETCH_STUDIES_SUCCESS = 'FETCH_STUDIES_SUCCESS';
const FETCH_STUDIES_ERROR = 'FETCH_STUDIES_ERROR';
const SORT_BY_ASCENDING_ORDER = 'SORT_BY_ASCENDING_ORDER';
const SORT_BY_DECENDING_ORDER = 'SORT_BY_DECENDING_ORDER';
const ON_ADD_NEW_STUDY = 'ON_ADD_NEW_STUDY';
const CANCEL_ON_ADD_NEW_STUDY = 'CANCEL_ON_ADD_NEW_STUDY';
const ON_ADD_NEW_STUDY_SUBMIT = 'ON_ADD_NEW_STUDY_SUBMIT';
const SUBMITTING_STUDY_ERROR = 'SUBMITTING_STUDY_ERROR';
const SUBMITTING_STUDY = 'SUBMITTING_STUDY';
const NEW_STUDY_SUCCESS_MESSAGE = 'NEW_STUDY_SUCCESS_MESSAGE';

export default {
  SEARCH,
  IS_LIVE,
  COMPLETED,
  MEDIA_TYPE,
  ENVIRONMENT_TYPE,
  FETCH_STUDIES_BEGIN,
  FETCH_STUDIES_SUCCESS,
  FETCH_STUDIES_ERROR,
  SORT_BY_DECENDING_ORDER,
  SORT_BY_ASCENDING_ORDER,
  ON_ADD_NEW_STUDY,
  CANCEL_ON_ADD_NEW_STUDY,
  ON_ADD_NEW_STUDY_SUBMIT,
  SUBMITTING_STUDY_ERROR,
  SUBMITTING_STUDY,
  NEW_STUDY_SUCCESS_MESSAGE
};
