import React from "react";
import propTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { studiesOperations } from "../../Studies/duck/index";
import { filterOperations } from "../../Filter/duck/index";
import ErrorWidget from "../../common/components/Error";
import LoadingSpinner from "../../common/components/LoadingSpinner";
import { withStyles } from "@material-ui/core/styles";
import AttentionReportContainer from "../../AttentionReport/AttentionReportContainer"
import Kpi from "../../Kpi/KpiContainer"
import FeatureAnalysis from "../../FeatureAnalysis/FeatureAnalysisContainer"
import { featureAnalysisOperations } from "../../FeatureAnalysis/duck/index";
import ReportCreatorExplanation from "../../../assets/images/ReportCreatorExplanation.png"
import Demographics from "../../Demographics/DemographicsContainer"
// import BrandRecall from "../../RichMediaReport/BrandRecall"
// import QuestionsAndAnswers from "../../QuestionAndAnswersReport/QuestionAndAnswers"
import QuestionAndAnswersContainer from "../../QuestionAndAnswersReport/QuestionAndAnswersContainer";
import Paper from "@material-ui/core/Paper";

const styles = {
    container: {
        maxWidth: "90%",
        marginTop: "40px",
    },
};


class ReportsDisplay extends React.Component {

    render() {
        
        if (this.props.initialReportLoad) {
            return <LoadingSpinner />
        }
        const noStudies = this.props.isolationData.data.data.length === 0;
        if (noStudies) {
            return <ErrorWidget />;
        }
        else if (this.props.error) {
            return <img alt="explanation" style={{maxWidth:"100%", margin: "16px 0px"}} src={ReportCreatorExplanation}  />
        }
        if (this.props.reportType === "compare") {
            return <><AttentionReportContainer /></>
        }
        else if (this.props.reportType === "questionAnswers") {
            return <QuestionAndAnswersContainer />
        }
        else if (this.props.reportType === "kpi") {
            let studiesList = JSON.parse(JSON.stringify(this.props.whichStudiesAreChecked));
            studiesList = studiesList.map((study, index) => {
                return  <Kpi key={index} index={index} />
            })
            return <>{studiesList}</>
        }
        else if (this.props.reportType === "feature") {
            let studiesList = JSON.parse(JSON.stringify(this.props.whichStudiesAreChecked));
            studiesList = studiesList.map((study, index) => {
                return  <FeatureAnalysis key={index} index={index}  />
            })
            return <>{studiesList}</>
        }
        else if (this.props.reportType === "demographics") {
            return <Demographics />
        }
        else {
            return <Paper elevation={2} style={{maxWidth:"fit-conetent", marginTop: 24}}><img style={{maxWidth:"100%", margin: "16px 0px"}} alt="explanation"  src={ReportCreatorExplanation}  /></Paper>
        }
    }
}


ReportsDisplay.propTypes = {
    match: propTypes.object.isRequired,
    location: propTypes.object.isRequired,
    history: propTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        studyData: state.studyData,
        isolationData: state.isolationData,
        whichStudiesAreChecked: state.isolationData.whichStudiesAreChecked,
        isModalOpen: state.filter.modalOpen,
        reportType: state.isolationData.reportType,
        initialReportLoad: state.isolationData.initialReportLoad,
        error: state.isolationData.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchStudy: (study) => dispatch(studiesOperations.fetchStudy(study)),
        goToAttentionReportPage: (whichStudiesAreChecked, { history, match }) =>
            dispatch(
                studiesOperations.goToAttentionReportPage(whichStudiesAreChecked, {
                    history,
                    match,
                })
            ),
        compareAllStudies: (allStudies, { history, match }) =>
            dispatch(
                studiesOperations.compareAllStudies(allStudies, { history, match })
            ),
        openModal: () => dispatch(filterOperations.openModal()),
        // downloadStimReport: (cellId, stepId, studyId, stimId, download) => (dispatch(kpiOperations.fetchKpiRawData(cellId, stepId, studyId, stimId, download))),
        downloadFeatureData: (cellId, stepId, studyId, stimId, download) => (dispatch(featureAnalysisOperations.fetchFeatureRawData(cellId, stepId, studyId, stimId, download))),
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withStyles(styles)(ReportsDisplay))
);
