
const FETCH_BATCH_STIMS_BEGIN = 'FETCH_BATCH_STIMS_BEGIN';
const FETCH_BATCH_STIMS_SUCCESS = 'FETCH_BATCH_STIMS_SUCCESS';
const FETCH_BATCH_STIMS_ERROR = 'FETCH_BATCH_STIMS_ERROR';

const FETCH_BATCH_DATA_BEGIN = 'FETCH_BATCH_DATA_BEGIN';
const FETCH_BATCH_DATA_SUCCESS = 'FETCH_BATCH_DATA_SUCCESS';
const FETCH_BATCH_DATA_ERROR = 'FETCH_BATCH_DATA_ERROR';

const FETCH_VISUALS_BEGIN = 'FETCH_VISUALS_BEGIN';
const FETCH_VISUALS_SUCCESSS = 'FETCH_VISUALS_SUCCESSS';
const FETCH_VISUALS_ERROR = 'FETCH_VISUALS_ERROR';

const FETCH_REPORT_BEGIN = 'FETCH_REPORT_BEGIN';
const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS';
const FETCH_REPORT_ERROR = 'FETCH_REPORT_ERROR';

const FETCH_REPORT_STATS_BEGIN = 'FETCH_REPORT_STATS_BEGIN';
const FETCH_REPORT_STATS_SUCCESS = 'FETCH_REPORT_STATS_SUCCESS';
const FETCH_REPORT_STATS_ERROR = 'FETCH_REPORT_STATS_ERROR';

const FETCH_REPORT_FEATURE_BEGIN = 'FETCH_REPORT_FEATURE_BEGIN';
const FETCH_REPORT_FEATURE_SUCCESS = 'FETCH_REPORT_FEATURE_SUCCESS';
const FETCH_REPORT_FEATURE_ERROR = 'FETCH_REPORT_FEATURE_ERROR';

const FETCH_BATCH_GRAPH_BEGIN = 'FETCH_BATCH_GRAPH_BEGIN';
const FETCH_BATCH_GRAPH_SUCCESS = 'FETCH_BATCH_GRAPH_SUCCESS';
const FETCH_BATCH_GRAPH_ERROR = 'FETCH_BATCH_GRAPH_ERROR';

const SET_BATCH_STIM_FILTER = 'SET_BATCH_STIM_FILTER';
const SET_BATCH_MEDIA_FILTER = 'SET_BATCH_MEDIA_FILTER';
const ON_SEARCH_BY_ID = 'ON_SEARCH_BY_ID'


const FETCH_BATCH_STIMS_LIST_BEGIN = 'FETCH_BATCH_STIMS_LIST_BEGIN';
const FETCH_BATCH_STIMS_LIST_SUCCESS = 'FETCH_BATCH_STIMS_LIST_SUCCESS';
const FETCH_BATCH_STIMS_LIST_ERROR = 'FETCH_BATCH_STIMS_LIST_ERROR';

export default {

  FETCH_BATCH_STIMS_BEGIN,
  FETCH_BATCH_STIMS_SUCCESS,
  FETCH_BATCH_STIMS_ERROR,
  FETCH_BATCH_DATA_BEGIN,
  FETCH_BATCH_DATA_SUCCESS,
  FETCH_BATCH_DATA_ERROR,
  FETCH_VISUALS_BEGIN,
  FETCH_VISUALS_SUCCESSS,
  FETCH_VISUALS_ERROR,
  FETCH_REPORT_BEGIN,
  FETCH_REPORT_SUCCESS,
  FETCH_REPORT_ERROR,
  FETCH_REPORT_STATS_BEGIN,
  FETCH_REPORT_STATS_SUCCESS,
  FETCH_REPORT_STATS_ERROR,
  FETCH_REPORT_FEATURE_BEGIN,
  FETCH_REPORT_FEATURE_SUCCESS,
  FETCH_REPORT_FEATURE_ERROR,
  FETCH_BATCH_GRAPH_BEGIN,
  FETCH_BATCH_GRAPH_SUCCESS,
  FETCH_BATCH_GRAPH_ERROR,
  SET_BATCH_STIM_FILTER,
  SET_BATCH_MEDIA_FILTER,
  ON_SEARCH_BY_ID,
  FETCH_BATCH_STIMS_LIST_BEGIN,
  FETCH_BATCH_STIMS_LIST_SUCCESS,
  FETCH_BATCH_STIMS_LIST_ERROR,
};
