import React from 'react';
import PropTypes from 'prop-types';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';

function MenuButton(props) {
  const { handleClick, className} = props
   return (
  <button
    onClick={handleClick}
    className={className}
    type="button"
  >
  {props.isActive ?  <ArrowBackIosNewRoundedIcon  style={{color:"white", paddingTop: 4}}  /> : < MenuIcon style={{color:"white"}}  />}
  </button>
  )
}

MenuButton.propTypes = {
  handleClick: PropTypes.func,
};


export default MenuButton;
