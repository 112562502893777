const SHOW_MODAL = 'SHOW_MODAL';
const HIDE_MODAL = 'HIDE_MODAL';
const DELETE_POST = 'DELETE_POST';


export default {
  SHOW_MODAL,
  HIDE_MODAL,
  DELETE_POST
};
