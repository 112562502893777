import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { Field, useFormikContext } from 'formik';
import BrandAutoComplete from '../../MaterialComponents/BrandAutoComplete';
import MoreInfo from '../../MaterialComponents/MoreInfo';
import { VKStory } from '@lumen-developer/lumen-lookalikes/dist/Previews';
import Divider from '@material-ui/core/Divider';
import validator from 'validator';
import propTypes from 'prop-types';

export default function VkStoryForm({brandTypes}) {
  const { values } = useFormikContext();

  function VkStoryValidationSchema(value, name) {
    let error;
    if (name === 'stim_url') {
      if (!value) {
        error = 'Required';
      } else if (!validator.isURL(value)) {
        error = 'Must be a valid URL';
      } else if (value.length > 512) {
        error = 'Too long';
      }
    }
    if (name === 'fb_profile_src') {
      if (!value) {
        error = 'Required';
      } else if (!validator.isURL(value)) {
        error = 'Must be a valid URL';
      } else if (value.length > 512) {
        error = 'Too long';
      }
    }
    if (name === 'fb_channel_name') {
      if (!value) {
        error = 'Required';
      } else if (value.length > 50) {
        error = 'Too long';
      }
    }
    return error;
  }

  return (
    <>
      <Divider variant='middle' />
      <Grid
        style={{ margin: '24px 0px 12px' }}
        container
        wrap='nowrap'
        justifyContent='center'
        item
        xs={12}
      >
        <Typography align='center' variant='h6'>
          VK story
        </Typography>
      </Grid>

      <div style={{ overflow: 'hidden', position: 'relative', width: '100%' }}>
        <div
          style={{
            height: '100%',
            width: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{ height: '667px', width: '375px' }}>
            <VKStory
              stim_url={values.stim_url}
              vk_profile_src={values.fb_profile_src}
              vk_channel_name={values.fb_channel_name}
            />
          </div>
        </div>
        <div
          style={{
            overflowY: 'scroll',
            height: '100%',
            width: '50%',
            position: 'absolute',
            right: 0,
            top: 0,
            padding: 16,
          }}
        >
          <Grid style={{ width: '100%' }} container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='subtitle1'>
                Please enter the ad URL and choose the brand name
              </Typography>
            </Grid>

            <Grid container wrap='nowrap' item xs={12}>
              <Field
                fullWidth
                name='stim_url'
                component={TextField}
                label='Ad URL'
                variant='filled'
                validate={(value) =>
                  VkStoryValidationSchema(value, 'stim_url')
                }
              />
              <MoreInfo
                outerContainerClassName='moreInfo'
                popup='A link to a webpage only featuring the test ad. We recommend 9 x 16 aspect ratio for stories'
              />
            </Grid>

            <Grid container wrap='nowrap' item xs={12}>
              <BrandAutoComplete brandTypes={brandTypes} />
              <MoreInfo
                outerContainerClassName='moreInfo'
                popup='The brand name of the company featured in the test ad '
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant='subtitle1'>
                Now customize the story
              </Typography>
            </Grid>

            <Grid container wrap='nowrap' item xs={12}>
              <Field
                fullWidth
                name='fb_profile_src'
                component={TextField}
                label='Profile photo URL'
                variant='filled'
                validate={(value) =>
                  VkStoryValidationSchema(value, 'fb_profile_src')
                }
              />
              <MoreInfo
                outerContainerClassName='moreInfo'
                popup='A link to a webpage only featuring the profile photo'
              />
            </Grid>

            <Grid container wrap='nowrap' item xs={12}>
              <Field
                fullWidth
                name='fb_channel_name'
                component={TextField}
                label='Profile name'
                variant='filled'
                validate={(value) =>
                  VkStoryValidationSchema(value, 'fb_channel_name')
                }
              />
              <MoreInfo
                outerContainerClassName='moreInfo'
                popup='The profile name as you would like it to appear'
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <Grid item xs={12}>
        <Divider variant='middle' />
      </Grid>
    </>
  );
}

VkStoryForm.propTypes = {
  brandTypes: propTypes.object,
};
