import { Grid, MenuItem, Typography } from '@material-ui/core';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import MoreInfo from '../../../MaterialComponents/MoreInfo';
import validator from 'validator';
import BrandAutoComplete from '../../../MaterialComponents/BrandAutoComplete';
import { Select } from 'formik-mui';
import propTypes from 'prop-types';

const Form = ({ brandTypes }) => {
  const validateField = (value, name) => {
    switch (name) {
      case 'pn_profile_src':
      case 'stim_url':
        if (!value) {
          return 'Required';
        } else if (!validator.isURL(value)) {
          return 'Must be a valid URL';
        } else if (value.length > 512) {
          return 'Too long';
        }
        break;
      case 'pn_type':
      case 'pn_stim_profile':
      case 'pn_stim_title':
        if (!value) {
          return 'Required';
        }
        break;
      default:
        return;
    }
    return;
  };

  return (
    <Grid style={{ width: '100%' }} container spacing={3}>
      <Grid item xs={12}>
        <Typography variant='subtitle1'>
          Please enter the ad URL and choose the brand name
        </Typography>
      </Grid>

      <Grid container wrap='nowrap' item xs={12}>
        <Field
          fullWidth
          name='stim_url'
          component={TextField}
          label='Ad URL'
          variant='filled'
          validate={(value) => validateField(value, 'stim_url')}
        />
        <MoreInfo
          outerContainerClassName='moreInfo'
          popup='A link to a webpage only featuring the test ad. We recommend 9 x 16 aspect ratio for Snapchat commercials'
        />
      </Grid>
      <Grid container wrap='nowrap' item xs={12}>
        <BrandAutoComplete brandTypes={brandTypes} />
        <MoreInfo
          outerContainerClassName='moreInfo'
          popup='The brand name of the company featured in the test ad '
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='subtitle1'>
          Now customize the commercial
        </Typography>
      </Grid>
      <Grid container wrap='nowrap' item xs={12}>
        <Field
          fullWidth
          name='pn_stim_title'
          component={TextField}
          label='Title'
          variant='filled'
          validate={(value) => validateField(value, 'pn_stim_title')}
        />
        <MoreInfo
          outerContainerClassName='moreInfo'
          popup='The title as you would like it to appear'
        />
      </Grid>
      <Grid container wrap='nowrap' item xs={12}>
        <Field
          fullWidth
          name='pn_profile_src'
          component={TextField}
          label='Profile photo URL'
          variant='filled'
          validate={(value) => validateField(value, 'pn_profile_src')}
        />
        <MoreInfo
          outerContainerClassName='moreInfo'
          popup='A link to a webpage only featuring the profile photo'
        />
      </Grid>
      <Grid container wrap='nowrap' item xs={12}>
        <Field
          fullWidth
          name='pn_stim_profile'
          component={TextField}
          label='Profile name'
          variant='filled'
          validate={(value) => validateField(value, 'pn_stim_profile')}
        />
        <MoreInfo
          outerContainerClassName='moreInfo'
          popup='The profile name as you would like it to appear'
        />
      </Grid>
      <Grid container wrap='nowrap' item xs={12}>
        <Field
          component={Select}
          fullWidth
          variant='filled'
          id='pn_type'
          name='pn_type'
          labelId='pn_type'
          label='Type'
          validate={(value) => validateField(value, 'pn_type')}
        >
          <MenuItem value='s'>Standard</MenuItem>
          <MenuItem value='m'>Full width</MenuItem>
        </Field>
        <MoreInfo
          outerContainerClassName='moreInfo'
          popup='Hyperlink which guides the user to the next desired step after seeing the ad'
        />
      </Grid>
    </Grid>
  );
};

export default Form;

Form.propTypes = {
  brandTypes: propTypes.object,
};
