const SET_DEMOGRAPHIC_FILTER = 'SET_DEMOGRAPHIC_FILTER';
const FETCH_DEMOGRAPHIC_BEGIN   = 'FETCH_DEMOGRAPHIC_BEGIN';
const FETCH_DEMOGRAPHIC_SUCCESS = 'FETCH_DEMOGRAPHIC_SUCCESS';
const FETCH_DEMOGRAPHIC_ERROR = 'FETCH_DEMOGRAPHIC_ERROR';
const FETCH_GRAPH_DEMOGRAPHIC_BEGIN   = 'FETCH_GRAPH_DEMOGRAPHIC_BEGIN';
const FETCH_GRAPH_DEMOGRAPHIC_SUCCESS = 'FETCH_GRAPH_DEMOGRAPHIC_SUCCESS';
const FETCH_GRAPH_DEMOGRAPHIC_ERROR = 'FETCH_GRAPH_DEMOGRAPHIC_ERROR';
const FETCH_DEMOGRAPHIC_RAW_BEGIN   = 'FETCH_DEMOGRAPHIC_RAW_BEGIN';
const FETCH_DEMOGRAPHIC_RAW_SUCCESS = 'FETCH_DEMOGRAPHIC_RAW_SUCCESS';
const FETCH_DEMOGRAPHIC_RAW_ERROR = 'FETCH_DEMOGRAPHIC_RAW_ERROR';


export default {
  FETCH_DEMOGRAPHIC_BEGIN,
  FETCH_DEMOGRAPHIC_SUCCESS,
  FETCH_DEMOGRAPHIC_ERROR,
  SET_DEMOGRAPHIC_FILTER,
  FETCH_GRAPH_DEMOGRAPHIC_BEGIN,
  FETCH_GRAPH_DEMOGRAPHIC_SUCCESS,
  FETCH_GRAPH_DEMOGRAPHIC_ERROR,
  FETCH_DEMOGRAPHIC_RAW_BEGIN,
  FETCH_DEMOGRAPHIC_RAW_SUCCESS,
  FETCH_DEMOGRAPHIC_RAW_ERROR,
};
