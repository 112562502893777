import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { Field, useFormikContext } from 'formik';
import { DateTimePicker } from 'formik-material-ui-pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import propTypes from 'prop-types';

import MoreInfo from '../MaterialComponents/MoreInfo';
import SelectMenu from '../MaterialComponents/SelectMenu';

const LumenPanelData = ({
  panelistRolesTypes,
  panelTypes,
}) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant='subtitle1'>
            Please define how to assign the panelists when the study goes live
          </Typography>
        </Grid>

        <Grid container wrap = 'nowrap' item xs={12} >

          <SelectMenu
            name='quick_task_data.panelist_role_id'
            label='Panelist role id'
            options={panelistRolesTypes}
          />
          <MoreInfo outerContainerClassName = 'moreInfo' popup='Type of panelist assigned to this task' />
        </Grid>
        <Grid container wrap = 'nowrap' item xs={12} >
          <SelectMenu
            name='quick_task_data.panel_id'
            label='Panel ID'
            options={panelTypes}
          />
          <MoreInfo outerContainerClassName = 'moreInfo' popup='Assign to desktop or mobile panel' />
        </Grid>
        <Grid container wrap = 'nowrap' item xs={12} >
          <Field
            fullWidth
            multiline
            name='quick_task_data.short_description'
            component={TextField}
            label='Short description'
            variant='filled'
          />
          <MoreInfo outerContainerClassName = 'moreInfo' popup='Description of the dates when it is supposed to go live' />
        </Grid>
        <Grid container wrap = 'nowrap' item xs={12} >
          <Field
            fullWidth
            name='quick_task_data.reward'
            component={TextField}
            label='Reward'
            variant='filled'
          />
          <MoreInfo outerContainerClassName = 'moreInfo' popup='What the panelist gets for completing the task (typically 100)' />
        </Grid>

        <Grid container wrap = 'nowrap' item xs={12} >
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Field
              inputVariant='filled'
              value={
                values.quick_task_data.start_date == null
                  ? null
                  : new Date(values.quick_task_data.start_date)
              }
              onChange={(time) => {
                if (typeof time === 'object') {
                  setFieldValue(
                    'quick_task_data.start_date',
                    time.format('YYYY-MM-DD HH:mm:ss')
                  );
                }
              }}
              component={DateTimePicker}
              label='Start date'
              name='quick_task_data.start_date'
            />
          </MuiPickersUtilsProvider>
          <MoreInfo outerContainerClassName = 'moreInfo' popup='When the task goes live' />
        </Grid>
        <Grid container wrap = 'nowrap' item xs={12} >
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Field
              inputVariant='filled'
              value={
                values.quick_task_data.expiry_date == null
                  ? null
                  : new Date(values.quick_task_data.expiry_date)
              }
              onChange={(time) => {
                if (typeof time === 'object') {
                  setFieldValue(
                    'quick_task_data.expiry_date',
                    time.format('YYYY-MM-DD HH:mm:ss')
                  );
                }
              }}
              component={DateTimePicker}
              label='End date'
              name='expiry_date'
            />
          </MuiPickersUtilsProvider>
          <MoreInfo outerContainerClassName = 'moreInfo' popup='When the task ends' />
        </Grid>
      </Grid>
    </>
  );
};

LumenPanelData.propTypes = {
  panelistRolesTypes: propTypes.object,
  panelTypes: propTypes.object,
};

export default LumenPanelData;
