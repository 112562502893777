import React, { Fragment, Component } from 'react';


import {
  Grid,
  Fab,
  InputAdornment,
  TextField,
  FormControl,
  Typography,
  SvgIcon
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ReactComponent as CopyIconToClipboard } from '../../../assets/images/copy-to-clipboard.svg';

class URLCopyToClipBoard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: 'Input value to be copied here...',
      copied: false,
    };
  }
  componentDidMount() {
    let selectedStims = this.props.selectedStims.join("|").replace(/ /g, ".")
    //https://dashboard.lumen-research.com/my_studies/overview
    if (this.props.currentReportType === "demographics" || this.props.currentReportType === "questionAnswers") {
      this.setState({"value": `${window.location.origin}/my_studies/overview/${this.props.currentReportStudyId}/reports?report_type=${this.props.currentReportType}`}) 
      window.history.replaceState({}, '', `${window.location.origin}/my_studies/overview/${this.props.currentReportStudyId}/reports?report_type=${this.props.currentReportType}`); 
    }
    else {
      this.setState({"value": `${window.location.origin}/my_studies/overview/${this.props.currentReportStudyId}/reports?stims=${selectedStims}&report_type=${this.props.currentReportType}`}) 
      window.history.replaceState({}, '', `${window.location.origin}/my_studies/overview/${this.props.currentReportStudyId}/reports?stims=${selectedStims}&report_type=${this.props.currentReportType}`); 
    }
  }


render() {
  return (
    <Fragment>
      {this.state.copied ? (
        <MuiAlert  onClose={() => {this.setState({copied: false})}} className="mb-4" severity="success">
          <span>
            Copied successfully
           
          </span>
        </MuiAlert>
      ) : null}
    

      <Grid style={{padding:8,}} container spacing={12}>
        <Grid style={{ zIndex:0, }} item xs={12} md={12}>
          <Typography variant="caption">
            Copy this report's unique URL here
          </Typography>
          <FormControl className="w-100" variant="outlined">
            <TextField
              variant="outlined"
              value={this.state.value}
              fullWidth
              // onChange={({ target: { value } }) =>
              //   this.setState({ value, copied: false })
              // }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CopyToClipboard
                      text={this.state.value}
                      onCopy={() => this.setState({ copied: true })}>
                      <Fab size="small" color="primary">
                       
                        <SvgIcon style={{ color: "white", fontSize: 18 }} component={CopyIconToClipboard} viewBox="0 0 699.428 699.428" />
                      </Fab>
                    </CopyToClipboard>
                  </InputAdornment>
                )
              }}
            />
          </FormControl>
        </Grid>

      </Grid>
    </Fragment>
  );
}
}

const mapStateToProps = (state) => {
  return {
    currentReportStudyId: state.isolationData.currentReportTypeStudyAndStims.studyId,
    selectedStims: state.isolationData.currentReportTypeStudyAndStims.checked,
    currentReportType: state.isolationData.currentReportTypeStudyAndStims.reportType,
  };
};



export default connect(mapStateToProps)(URLCopyToClipBoard);

