import { Button, Box, Paper, Typography, Grid } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './MaterialStyling';
import LinksTable from './MaterialComponents/LinksTable';
import propTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
    flexDirection: 'column',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),

    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 630,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  container: {
    height: '100vh',
    width: '100vw -54px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function ThankYouPage({match, newStudySuccessMessage}) {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.container}>
        <Box className={classes.layout}>
          <Paper elevation={2} className={classes.root}>
            <React.Fragment>
              <Typography color='primary' variant='h5' gutterBottom>
                Thank you for your order.
              </Typography>

              <Typography variant='subtitle1'>
                Your project number is {match.params.id}. We will send you
                an update when your order has been setup.
              </Typography>
              <div style={{padding:16}}>
                <LinksTable newURLs={newStudySuccessMessage} />
              </div>
              <Grid container spacing={2} justifyContent='flex-end'>
                <Button component={Link} to='/' className={classes.button}>
                  Back to home
                </Button>
                <Button
                  component={Link}
                  to={`/my_studies/overview/${match.params.id}/`}
                  className={classes.button}
                >
                  Continue to study details
                </Button>
              </Grid>
            </React.Fragment>
          </Paper>
        </Box>
      </div>
    </ThemeProvider>
  );
}

ThankYouPage.propTypes = {
  match: propTypes.object,
  newStudySuccessMessage: propTypes.object,
};
