import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import LoadingSpinner from "../common/components/LoadingSpinner";
import KPIBillboardStructure from "./containers/KPIBillboardStructure";
import KPIOrdinaryStructure from "./containers/KPIOrdinaryStructure";



class Kpi extends React.Component {


  isBillBoard = (data) => {
    const height = data.stim_height;
    const width = data.stim_width;
    if (width / height > 2) {
      return true;
      // wide
    } else if (width / height < 0.5) {
      return false;
      // tall
    } else {
      return false;
      // square
    }
  };

  render() {
    const { kpiDataloading, index } = this.props;
    if (kpiDataloading) {
      return <LoadingSpinner />;
    }
    return this.isBillBoard(this.props.kpiData[index]) ? <KPIBillboardStructure index = {index} /> : <KPIOrdinaryStructure index = {index} />;
  }
}




const mapStateToProps = (state) => {
  return {
    kpiData: state.kpiReducer.kpiData,
    kpiDataloading: state.kpiReducer.kpiDataloading,
  };
};

Kpi.propTypes = {
  loading: PropTypes.bool,
  fetchKpiData: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      cellId: PropTypes.string,
      stepId: PropTypes.string,
      studyId: PropTypes.string,
    }),
  }),
};

export default withRouter(
  connect(
    mapStateToProps
  )(Kpi)
);
