const FETCH_SURVEY_BEGIN = 'FETCH_SURVEY_BEGIN';
const FETCH_SURVEY_SUCCESS = 'FETCH_SURVEY_SUCCESS';
const FETCH_SURVEY_ERROR = 'FETCH_SURVEY_ERROR';

export default {
  FETCH_SURVEY_BEGIN,
  FETCH_SURVEY_ERROR,
  FETCH_SURVEY_SUCCESS,

};
