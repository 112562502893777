import { number, object, string, mixed } from 'yup';

export const basicDetailsValidationSchema = object().shape({
  name: string()
    .max(100, 'Too Long!')
    .required('Required'),
  environment_id: object({
    code: number()
      .required('Environment required')
      .min(1, 'Environment required'),
  }),
  language: mixed().required('Required'),
  project_location: mixed().required('Required'),
  number_of_cells: mixed().required('Required'),
  panel_size: mixed().required('Required'),
});

export const panelDetailsValidationSchema = object().shape({
  panel_code: mixed().when(
    'include_panel_details',
    {
      is: '1',
      then: string()
        .max(50, 'Too Long!')
        .required('Required'),
    }
  ),
  panel_success: mixed().when(
    'include_panel_details',
    {
      is: '1',
      then: string()
        .url('Must be a valid URL')
        .max(200, 'Too Long!')
        .required('Required'),
    },
  ).when(
    'include_panel_details',
    {
      is: '2',
      then: string()
        .url('Must be a valid URL')
        .max(200, 'Too Long!')
        .required('Required'),
    }
  ),
  panel_screen: mixed().when(
    'include_panel_details',
    {
      is: '1',
      then: string()
        .url('Must be a valid URL')
        .max(512, 'Too Long!')
        .required('Required'),
    }
  ),
  panel_quotafull: mixed().when(
    'include_panel_details',
    {
      is: '1',
      then: string()
        .url('Must be a valid URL')
        .max(512, 'Too Long!')
        .required('Required'),
    }
  ),
  panel_insert_param: mixed().when(
    'include_panel_details',
    {
      is: '1',
      then: string()
        .max(50, 'Too Long!')
        .required('Required'),
    }
  ),
});

export const questionDetailsValidationSchema = object().shape({
  demographic_survey_or_lumen_questions: mixed().required('Required'),
  demographic_survey_url: string().when(
    'demographic_survey_or_lumen_questions',
    {
      is: '1',
      then: string()
        .required('Required')
        .url('Must be a valid URL')
        .max(512, 'Too Long!'),
    }
  ),
  demographic_survey_insert_parameter: string().when(
    'demographic_survey_or_lumen_questions',
    {
      is: '1',
      then: string()
        .required('Required')
        .max(50, 'Too Long!'),
    }
  ),
  pre_assign_question_group: mixed().when(
    'demographic_survey_or_lumen_questions',
    {
      is: '2',
      then: mixed().required('Required'),
    }
  ),
  include_external_survey: mixed().required('Required'),
  external_survey_step_url: mixed().when(
    'include_external_survey',
    {
      is: '1',
      then: string()
        .required('Required')
        .max(512, 'Too Long!')
        .url('Must be a valid URL')
    }
  ),
  external_survey_step_insert_parameter: mixed().when(
    'include_external_survey',
    {
      is: '1',
      then: string()
        .required('Required')
        .max(50, 'Too Long!'),
    }
  ),
});