
const FETCH_USER_DATA_BEGIN = 'FETCH_USER_DATA_BEGIN';
const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS';
const FETCH_USER_DATA_ERROR = 'FETCH_USER_DATA_ERROR';
const AUTHENTICATED = 'AUTHENTICATED_USER';
const UNAUTHENTICATED = 'UNAUTHENITCATED_USER';
const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';

export  default {
  FETCH_USER_DATA_BEGIN,
  FETCH_USER_DATA_SUCCESS,
  FETCH_USER_DATA_ERROR,
  AUTHENTICATED,
  UNAUTHENTICATED,
  AUTHENTICATION_ERROR
};

