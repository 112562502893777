import React from 'react';

const MiniCard = ({ title, value, suffix, color }) => (
  <div style={{margin:4, width:"100%"}} className="mini-card">
    <div style={{borderRadius:16, background: color=== "green" ? "rgb(200, 250, 205)" : "rgb(208, 242, 255)" }} className="mini-card__container">
      <div className="mini-card__value-container">
        <h2 style={{color: color=== "green" ? "rgb(0, 82, 73)" : "rgb(4, 41, 122)"}} className="mini-card__value">{value}<span className="mini-card__suffix">{suffix}</span></h2>
      </div>
      <div className="mini-card__title-container">
        <h3 style={{color: color=== "green" ? "rgb(0, 82, 73)" : "rgb(4, 41, 122)"}} className="mini-card__title">{title}</h3>
      </div>
    </div>
  </div>
);

export default MiniCard;

