import React from "react";
import propTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { studiesOperations } from "../Studies/duck/index";
import { filterOperations } from "../Filter/duck/index";
import ErrorWidget from "../common/components/Error";
import LoadingSpinner from "../common/components/LoadingSpinner";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { theme } from "../CreateStudyPage/MaterialStyling";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import StudyGenerator from "./ReportCreator/StudyGenerator";
import { featureAnalysisOperations } from "../FeatureAnalysis/duck/index";
import ReportDisplay from "./ReportDisplay/ReportDisplay";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import URLCopyToClipBoard from "./Components/URLCopyToClipBoard";

const styles = {
  container: {
    maxWidth:"92vw",
    marginTop: "8px",
  },
};

class ReportsContainer extends React.Component {
  componentDidMount() {
    const studyId = this.props.match.params.id;
    const payload = {
      id: studyId,
    };
    this.props.fetchStudy(payload);
  }
  
  componentWillUnmount() {
    this.props.setReportType("")
  }

  render() {
    const { classes, reportType } = this.props;
    if (this.props.loadingStudyData) {
      return <LoadingSpinner />;
    }
    const noStudies = this.props.isolationData.data.data.length === 0;
    if (noStudies) {
      return <ErrorWidget />;
    }

    return (
      <>
        <CssBaseline />
        <ThemeProvider theme={theme}>
        <Snackbar open={this.props.error} autoHideDuration={6000} onClose={this.props.setInitialLoadReset} anchorOrigin={{vertical: 'top', horizontal: 'right'}} >
            <Alert variant = "filled" onClose={this.props.setInitialLoadReset} severity="error">
              Sorry there was an error loading your data
            </Alert>
          </Snackbar>
          <Container maxWidth="xl" className={classes.container}>

            <Grid container spacing={3}>
              <Grid item xs={12} md={3} lg={3}>
                <StudyGenerator />
               {this.props.initialReportLoad || this.props.error || this.props.isolationData.data.data.length === 0 || !this.props.reportType ? <LoadingSpinner /> : <URLCopyToClipBoard /> }
              </Grid>
              <Grid item xs={12} md={9} lg={9}>
              <Grid
              alignContent="flex-end"
              container
              wrap="nowrap"
              justify="space-between"
              item
              xs={12}
            >
              <Grid item xs={12}>
                <Typography color="primary" align="center" variant="h2">{reportType === "compare" ? "Compare Report" : reportType === "kpi" ? "KPI Report" : reportType === "feature" ? "Feature Report" : reportType === "demographics" ? "Demographic Report" : reportType === "questionAnswers" ? "Question Report" : "Choose your report"}</Typography>
              </Grid>
            </Grid>
                <ReportDisplay  />
              </Grid>
            </Grid>
          </Container>
        </ThemeProvider>
      </>
    );
  }
}

ReportsContainer.propTypes = {
  match: propTypes.object.isRequired,
  location: propTypes.object.isRequired,
  history: propTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    error: state.isolationData.error,
    studyData: state.studyData,
    isolationData: state.isolationData,
    whichStudiesAreChecked: state.isolationData.whichStudiesAreChecked,
    isModalOpen: state.filter.modalOpen,
    loadingStudyData: state.isolationData.loadingStudyData,
    initialReportLoad: state.isolationData.initialReportLoad,
    reportType: state.isolationData.reportType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setInitialLoadReset: () => dispatch(studiesOperations.setInitialLoadReset()),
    fetchStudy: (study) => dispatch(studiesOperations.fetchStudy(study)),
    setReportType: (reportType) => dispatch(studiesOperations.setReportType(reportType)),
    compareAllStudies: (allStudies, { history, match }) =>
      dispatch(
        studiesOperations.compareAllStudies(allStudies, { history, match })
      ),
    openModal: () => dispatch(filterOperations.openModal()),
    downloadFeatureData: (cellId, stepId, studyId, stimId, download) =>
      dispatch(
        featureAnalysisOperations.fetchFeatureRawData(
          cellId,
          stepId,
          studyId,
          stimId,
          download
        )
      ),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ReportsContainer))
);
