
const FETCH_MEDIA_BEGIN = 'FETCH_MEDIA_BEGIN';
const FETCH_MEDIA_SUCCESS = 'FETCH_MEDIA_SUCCESS';
const FETCH_MEDIA_ERROR = 'FETCH_MEDIA_ERROR';

const FETCH_ENVIRONMENT_BEGIN = 'FETCH_ENVIRONMENT_BEGIN';
const FETCH_ENVIRONMENT_SUCCESS = 'FETCH_ENVIRONMENT_SUCCESS';
const FETCH_ENVIRONMENT_ERROR = 'FETCH_ENVIRONMENT_ERROR';

const FETCH_STUDY_DETAILS_BEGIN = 'FETCH_STUDY_DETAILS_BEGIN';
const FETCH_STUDY_DETAILS_SUCCESS = 'FETCH_STUDY_DETAILS_SUCCESS';
const FETCH_STUDY_DETAILS_ERROR = 'FETCH_STUDY_DETAILS_ERROR';

export default {
  FETCH_MEDIA_BEGIN,
  FETCH_MEDIA_SUCCESS,
  FETCH_MEDIA_ERROR,
  FETCH_ENVIRONMENT_BEGIN,
  FETCH_ENVIRONMENT_SUCCESS,
  FETCH_ENVIRONMENT_ERROR,
  FETCH_STUDY_DETAILS_BEGIN,
  FETCH_STUDY_DETAILS_SUCCESS,
  FETCH_STUDY_DETAILS_ERROR
};

