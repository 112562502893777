import * as React from "react";
import { default as ButtonMui } from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";


export default function DeleteDialogue(props) {
  const [open, setOpen] = React.useState(false);

  React.useEffect(
    () => {
      if (props.openModal) {
        setOpen(true);
      }
      return () => {
        setOpen(false);
      };
    },
    [props.openModal]
  );

  const handleClose = () => {
    props.setOpenModal(false);
    setOpen(false);
  };

  const handleDelete = () => {
    props.onDeleteStep();
    handleClose();
  };

  return (
    <div>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle style={{ background: "#00badb", color: "white" }}>
          Delete step
        </DialogTitle>
        <DialogContent>
          <Typography color="textSecondary" style={{ paddingTop: 24 }}>
          Are you sure you want to delete this step?
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonMui onClick={handleClose}>Cancel</ButtonMui>
          <ButtonMui onClick={handleDelete}>Delete step</ButtonMui>
        </DialogActions>
      </Dialog>
    </div>
  );
}
