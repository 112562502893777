import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useField } from 'formik';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';

export default function SelectMenu({ label, name, options, variant}) {
  const [field, meta, helpers] = useField(name);

  return (
    <>
      <FormControl error = {Boolean(meta.error) && meta.touched}>
        { label && (
          <InputLabel variant='filled' id={name}>
            {label}
          </InputLabel>
        )}
        <Select
          {...field}
          name={name}
          labelId={name}
          id={name}
          variant={variant ? variant : 'filled'}
          onBlur={(e) => helpers.setTouched(e)}
          onChange={(e) => helpers.setValue(e.target.value)}
        >
          {options.data.map((option) => (
            <MenuItem id={ option.id || option.code} key={ option.id || option.code} value={option}>
              {option.description || option.name || option.group_name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText variant='filled' error >{Boolean(meta.error) && meta.touched ? meta.error : ''}</FormHelperText>
      </FormControl>
    </>
  );
}

SelectMenu.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  variant: PropTypes.string,
  options: PropTypes.object,
};
