import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import Utils from '../common/utils/Utils';
import { batchStimsOperations } from '../Batches/duck/index';
import Image from '../common/components/Image';
import DropDown from '../EditStudyDetails/components/DropDown';
import LoadingSpinner from '../common/components/LoadingSpinner';
import Table from './components/Table';
// import Linegraph from '../AttentionReport/components/Graph/Graph';
import ErrorWidget from '../common/components/Error';
// import Button from '../common/components/Button';
import { Typography } from '@material-ui/core';
import DownloadBatchDataButton from "../BatchReport/components/DownloadBatchDataButton"
import AttentionGraph from "./components/AttentionGraph"
// import { kpiOperations } from '../Kpi/duck/index';


class BatchKpi extends React.Component {
  state = { media: ['Original', 'Heatmap', 'Stim Analysis', 'Feature Analysis'], filter: 'stim_source', loaded: false, imageisLoading: false }

  componentDidUpdate() {
    const { filter } = this.state;
    this.setState((prevState) => {
      if (filter !== prevState.filter) {
        return this.setState({ imageisLoading: true })
      }
    })
  }

  setFilter(filter) {
    switch (filter) {
      case 'Original':
        filter = 'stim_source';
        break
      case 'Heatmap':
        filter = 'heatmap_urls';
        break
      case 'Stim Analysis':
        filter = 'analysis_urls';
        break
      case 'Feature Analysis':
        filter = 'feature_analysis_urls';
        break
      case 'View Order':
        filter = 'view_order_urls';
        break
      default:
        filter = 'heatmap_source';
    }
    this.setState({ filter: filter })
  }

  whatShape = (stimHeight, stimWidth) => {
    if (stimWidth /stimHeight > 2){
      return "wide"
    }
    else if (stimWidth /stimHeight <= 0.5){
      return "tall"
    }
    else{
      return "square"
    }
  }


  // onLoad = () => {
  //   this.setState({ loaded: true });
  // }
  setimageIsLoading =(imageisLoaded) => {
    this.setState({ imageisLoading: imageisLoaded });
  }



  render() {
    const { batchesLoading, reportData, visualsData, visualsLoading, reportLoading, batchGraphData, batchGraphLoading, downloadParticipantData, downloadFeatureData, downloadStimData, downloadTableData, reportFeatureData } = this.props;
    // const { loaded } = this.state
    if (batchesLoading || reportLoading || batchGraphLoading || visualsLoading) {
      return <LoadingSpinner />
    }
    const reportDataObj = reportData.data[0];
    // const ids = queryString.parse(location.search)
    const isNoReportData = reportData.data.length === 0 || reportDataObj.stim_data.length === 0;
    return (
      <React.Fragment>
        {isNoReportData ? <ErrorWidget />
          :

          <div className="batch-kpi">
            <div style={{ display: "flex", padding: "0px 8px", justifyContent: "space-between" }}>
              <div>
                <Typography variant="h4">KPI Report</Typography>
                <Typography variant="subtitle1">{reportDataObj.stim_name} : {reportDataObj.stim_type} </Typography>
              </div>
              <DownloadBatchDataButton
                buttonVersion="secondary"
                tooltip="Download all data from this study"
                downloadParticipantData={() => downloadParticipantData(reportData.internal_vendor_id, reportData.internal_batch_id, reportDataObj.internal_step_id, reportDataObj.internal_stim_id, 'DOWNLOAD')}
                downloadFeatureData={() => downloadFeatureData(reportData.internal_vendor_id, reportData.internal_batch_id, reportDataObj.internal_step_id, reportDataObj.internal_stim_id, 'DOWNLOAD')}
                downloadStimData={() => downloadStimData(reportData.internal_vendor_id, reportData.internal_batch_id, reportDataObj.internal_step_id, reportDataObj.internal_stim_id, 'DOWNLOAD')}
                downloadGeneralKpiData={() => downloadTableData(reportData, "kpi")}
                downloadFeatureKpiData={() => downloadTableData(reportFeatureData, "feature")}
              />
            </div>
            <div style={{ gap: 24, margin: "24px 0px" }} className="kpi__table-graph-container">

              <div style={{ boxShadow: "unset", border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: 16 }} className="kpi__table-container">
                <Typography style={{ padding: "4px 8px" }} variant="h6">Key Attention Metrics</Typography>
                <Table headings={['Metric', 'Actual', 'Norms']} reportData={reportDataObj} />

              </div>
              {batchGraphData.data.data[0].graph_data.length === 0 ? "" : 
              <div style={{ boxShadow: "unset", border: "1px solid rgba(0, 0, 0, 0.12)", textAlign: "left", padding: 10, margin: 0, borderRadius: 16 }} className="attention-report__graph-container">
                <Typography style={{ padding: "4px 8px" }} variant="h6">Attention Curve</Typography>
                <div style={{ margin: "4px 0px", position: "relative", right: 10 }} className="linegraph__container">
                  <AttentionGraph
                    stimName={batchGraphData.data.data[0].stim_name}
                    graphData={batchGraphData.data.data[0]}
                    loading={batchGraphLoading}
                  />
                </div>
              </div>
            }

            </div>
            <div style={{ gap: 24, }} className="kpi__image-container-wrapper">

              <div style={{ boxShadow: "unset", border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: 16 }} className="kpi__image-dropdown-container">
                <div className="kpi__title-dropdown-container">
                  <Typography style={{ padding: "4px 8px 16px" }} variant="h6">Attention Visuals</Typography>
                  <DropDown
                    onSelectItem={(filter) => this.setFilter(filter)}
                    options={visualsData.data[0].view_order_urls ? ['Original', 'Heatmap', "View Order",'Stim Analysis', 'Feature Analysis'] : ['Original', 'Heatmap','Stim Analysis', 'Feature Analysis']}
                    value="Original "
                  />
                </div>
                <div className="kpi_images-container">
 

                  <div className="full-width" style={{ display: this.state.imageIsLoading  ? "block" : "none" }}>
                    <LoadingSpinner style={{margin:"10vh auto"}} />
                  </div>
                  <div style={{ display: this.state.imageIsLoading ? "none" : "grid", gap: "16px" }}>
                    {visualsData.data.map((item, index) => {
                      let shape = this.whatShape(item.stim_height, item.stim_width)
                      const isLarge = Utils.isHeightGreaterThanWidth(item, 'height', 'width');
                      return (
                        <Image

                        setimageIsLoading={(trueOrFalse) => {this.setimageIsLoading(trueOrFalse)}}
                      
                        shape={shape}
                       
                        name={item.stim_name}
                      

                          imageClass={classNames('kpi__image-container batch-kpi___image', { 'large': isLarge })}
                          key={index}
                          source={item[this.state.filter]['low_quality']}
                          // onLoad={this.onLoad}

                        />)
                    })
                    }
                  </div>
                </div>

              </div>

              {/* <div className="button-container">
                <Button
                  buttonClass="button-primary with-margin"
                  text="Download Feature Data"
                  handleClick={() => downloadFeatureData(reportData.internal_vendor_id, reportData.internal_batch_id, reportDataObj.internal_step_id, reportDataObj.internal_stim_id, 'DOWNLOAD')}
                  title="Download eye tracking data for each AOI."
                />
                <Button
                  buttonClass="button-primary with-margin"
                  text="Download Participant Data"
                  handleClick={() => downloadParticipantData(reportData.internal_vendor_id, reportData.internal_batch_id, reportDataObj.internal_step_id, reportDataObj.internal_stim_id, 'DOWNLOAD')}
                  title="Download questionnaire data."
                />
              </div> */}
            </div>
          </div>}
      </React.Fragment>
    );
  }
}




const mapStateToProps = (state) => {
  return {
    visualsLoading: state.batchStimsReducer.visualsLoading,
    visualsData: state.batchStimsReducer.visualsData,
    reportLoading: state.batchStimsReducer.reportLoading,
    reportData: state.batchStimsReducer.reportData,
    batchStims: state.batchStimsReducer.batchStims,
    batchesLoading: state.batchStimsReducer.batchesLoading,
    batchGraphLoading: state.batchStimsReducer.batchGraphLoading,
    batchGraphData: state.batchStimsReducer.batchGraphData,
    reportFeatureData: state.batchStimsReducer.reportFeatureData,
  };

};

const mapDispatchToProps = (dispatch) => {
  return {
    onClick: (id, vendorId, item, type) => dispatch(batchStimsOperations.onClick(id, vendorId, item, type)),
    downloadParticipantData: (cellId, stepId, studyId, stimId, download) => (dispatch(batchStimsOperations.downloadParticipantData(cellId, stepId, studyId, stimId, download))),
    downloadFeatureData: (cellId, stepId, studyId, stimId, download) => (dispatch(batchStimsOperations.downloadFeatureData(cellId, stepId, studyId, stimId, download))),
    downloadStimData: (cellId, stepId, studyId, stimId, download) => (dispatch(batchStimsOperations.downloadStimData(cellId, stepId, studyId, stimId, download))),
    downloadTableData: (tableData, tableType) => (dispatch(batchStimsOperations.downloadTableData(tableData, tableType))),
  }
}



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BatchKpi));




