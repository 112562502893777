
const FETCH_ALL_FILES_BEGIN = 'FETCH_ALL_FILES_BEGIN';
const FETCH_ALL_FILES_SUCCESS = 'FETCH_ALL_FILES_SUCCESS';
const FETCH_ALL_FILES_ERROR = 'FETCH_ALL_FILES_ERROR';


export default {
  FETCH_ALL_FILES_BEGIN,
  FETCH_ALL_FILES_SUCCESS,
  FETCH_ALL_FILES_ERROR,

};
