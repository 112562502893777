import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Alert from '@material-ui/lab/Alert';
import CheckIcon from '@material-ui/icons/Check';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { studiesOperations } from '../../Studies/duck/index';

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    top: '14px',
    zIndex: 1,
    right: '144px',
    width: 'fit-content',
  },
  StudyFinished: {
    padding: '2px 4px !important',
    color: '#4caf50',
  },
  badge: {
    position: 'relative',
    top: '11px',
    right: '98px',
  },
}));

const Status = ({ studiesOverview }) => {
  const classes = useStyles();
  const { status, live, completed } = studiesOverview;

  return (
    <>
      <Grid
        alignItems='center'
        className={classes.container}
        container
        spacing={2}
        style={{ position: 'fixed'}}
      >
        {
          status === 'Live' && live === true && completed === false ? (
            <>
              <Grid item>
                <Alert
                  variant='outlined'
                  icon={false}
                  severity='error'
                  style={{ padding: '0px 8px'}}
                >
                  <Badge
                    classes={{
                      badge: classes.badge,
                    }}
                    variant='dot'
                    color='error'
                    style={{ paddingLeft: '20px'}}
                  >
                    <Typography color='error' variant='button'>
                      LIVE STUDY
                    </Typography>
                  </Badge>
                </Alert>
              </Grid>
            </>
          ) : (
            <Grid className={classes.StudyFinished} item>
              <Alert
                variant='filled'
                icon={<CheckIcon fontSize='inherit' />}
                severity='success'
              >
                {completed ? 'Study completed' : 'Lumen is processing your results!'}
              </Alert>
            </Grid>
          )}
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    studiesOverview: state.isolationData.studiesOverview,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postEndLiveStudy: (id) => (dispatch(studiesOperations.postEndLiveStudy(id))),
  };
};

Status.propTypes = {
  studiesOverview: propTypes.object,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Status));
