
import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from "@material-ui/core/Tooltip"
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import  TextField from '@material-ui/core/TextField';
import  Button from '@material-ui/core/Button';

const External = ({ value, paramValue, onChange, onParamChange, onNameChange, name, showParamInput, toggleItem, addEmbeddedStep, title}) => (
  <div style={{padding:"0px 0px 24px", position:"relative"}} >
    <div style={{position: "absolute", left: 16}}>
      
     <Tooltip title="Back to table" >
                        
                        <IconButton  onClick={toggleItem} aria-label="delete">
                          <ArrowBackRoundedIcon />
                        </IconButton>
                        </Tooltip>
                        </div>
                        <div style={{margin:"auto", width: "fit-content", display: "grid", gap: 16 }}>
                        <Typography style={{paddingTop:16}} variant="subtitle1" >{title}</Typography>
    <div>
 
      <TextField label="Step name" value={name} onChange={onNameChange} />
    </div>
    <div>

      <TextField label="Step URL" type="text" className="csm-modal-form__text-input" value={value} onChange={onChange} />
    </div>
    {showParamInput ?
      <div>
    
        <TextField label="Member insert param" type="text" className="csm-modal-form__text-input" value={paramValue} onChange={onParamChange} />
      </div>
      :
      null}
     <div > <Button style={{background: "#00badb"}} variant="contained" onClick ={addEmbeddedStep}>{title}</Button></div>
 </div>
  </div>
);

export default External;

External.propTypes = {
  value: PropTypes.string,
  paramValue: PropTypes.string,
  onChange: PropTypes.func,
  onParamChange: PropTypes.func
};
