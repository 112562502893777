import React, { useEffect, useState, useRef } from 'react';
import StimsTableContainer from './containers/BatchesTableContainer';
import { connect } from 'react-redux';
import { batchStimsOperations } from './duck/index';
import LoadingSpinner from '../common/components/LoadingSpinner';
import PropTypes from 'prop-types'
import Paper from "@material-ui/core/Paper"
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import TablePagination from '../common/components/Pagination';

function Batches({ getBatchesData, allBatchData }) {
  const myTimer = useRef(null)
  const [initialized, setInitialized] = useState(false);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [filterId, setfilterId] = React.useState("");
  const [filterName, setfilterName] = React.useState("");
  const [orderBy, setorderBy] = React.useState("name_asc");
  useEffect(() => {
    if (!initialized) {
      getBatchesData(filterId, filterName, orderBy, rowsPerPage, page );
    }
    setInitialized(true)

  });

  const handleSearchByIdChange = (newfilterId) => {
    setfilterId(newfilterId)
    clearTimeout(myTimer.current)
    myTimer.current = setTimeout(() => {
      getBatchesData(newfilterId, filterName, orderBy, rowsPerPage, page )
    }, 500);
  }

  const handleSearchByNameChange = (newfilterName) => {
    setfilterName(newfilterName)
    clearTimeout(myTimer.current)
    myTimer.current = setTimeout(() => {
      getBatchesData(filterId, newfilterName, orderBy, rowsPerPage, page )
    }, 500);
  }

  const handleSortChange = (newOrderBy) => {
    setorderBy(newOrderBy)
   getBatchesData(filterId, filterName, newOrderBy, rowsPerPage, page )
  }

  
  const handleChangePage = (event, newPage) => {
    if (newPage) {
      setPage(newPage);
      getBatchesData(filterId, filterName, orderBy, rowsPerPage, newPage )
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    getBatchesData(filterId, filterName, orderBy, event.target.value, page )
  };

  return (
    <React.Fragment>
      {!initialized ? <LoadingSpinner /> :
  <Paper  style={{borderRadius:16,}}>
        <div style={{paddingBottom:16}} className="stims">
        <div style={{padding: 16, gap:16,  borderRadius:16, border: "none", justifyContent: "flex-start"}} className="dashboard__filter-container">
            <TextField
            style={{zIndex:0}}
            onChange={(e) => handleSearchByNameChange(e.target.value)}
            value={filterName}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            id="outlined-basic" label="Search by name" variant="outlined" />       
            <TextField
            style={{zIndex:0}}
            onChange={(e) => handleSearchByIdChange(e.target.value)}
            value={filterId}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            id="outlined-basic" label="Search by Id" variant="outlined" />       
          </div>
         {allBatchData ?  <TablePagination   rowsPerPage={rowsPerPage}  onRowsPerPageChange={handleChangeRowsPerPage} numberOfPages={allBatchData.total_page_num} currentPage={allBatchData.page_num} onPageChange={handleChangePage}  /> : ""}
          <div className="stims__table-container">
            <StimsTableContainer  handleSortChange={handleSortChange} currentOrderBy={orderBy} />
          </div>
          {allBatchData ?  <TablePagination   rowsPerPage={rowsPerPage}  onRowsPerPageChange={handleChangeRowsPerPage} numberOfPages={allBatchData.total_page_num} currentPage={allBatchData.page_num} onPageChange={handleChangePage}  /> : ""}
        </div>
        </Paper>
      }
    </React.Fragment>
  );
}

function countNumberOfX(data, type) {
  const amount = data.filter(item => item[type] === true)
  return amount.length
}


function mapDispatchToProps(dispatch) {
  return {
    getBatchesData: (filterId, filterName, orderBy, rowsPerPage, page ) => dispatch(batchStimsOperations.getBatchesData(filterId, filterName, orderBy, rowsPerPage, page )),
    onSearchByIdChange: (e) => dispatch(batchStimsOperations.onSearchByIdChange(e))
  };
}

function mapStateToProps(state) {
  return ({
    reportStatsLoading: state.batchStimsReducer.reportStatsLoading,
    reportStatsData: state.batchStimsReducer.reportStatsData,
    completedStudies: countNumberOfX(state.batchStimsReducer.batchData, 'completed'),
    liveStudies: countNumberOfX(state.batchStimsReducer.batchData, 'live'),
    batchData: state.batchStimsReducer,
    allBatchData: state.batchStimsReducer.allBatchData,
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(Batches);

Batches.propTypes = {
  getBatches: PropTypes.func,

};
