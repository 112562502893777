const SET_FEATURE_ANALYSIS_FILTER = 'SET_FEATURE_ANALYSIS_FILTER';
const FETCH_FEATURE_BEGIN = 'FETCH_FEATURE_BEGIN';
const FETCH_FEATURE_SUCCESS = 'FETCH_FEATURE_SUCCESS';
const FETCH_FEATURE_ERROR = 'FETCH_FEATURE_ERROR';
const FETCH_FEATURE_RAW_BEGIN = 'FETCH_FEATURE_RAW_BEGIN';
const FETCH_FEATURE_RAW_SUCCESS = 'FETCH_FEATURE_RAW_SUCCESS';
const FETCH_ALL_FEATURE_SUCCESS = 'FETCH_ALL_FEATURE_SUCCESS';
const FETCH_FEATURE_RAW_ERROR = 'FETCH_FEATURE_RAW_ERROR';


export default {
  FETCH_FEATURE_BEGIN,
  FETCH_FEATURE_SUCCESS,
  FETCH_ALL_FEATURE_SUCCESS,
  FETCH_FEATURE_ERROR,
  SET_FEATURE_ANALYSIS_FILTER,
  FETCH_FEATURE_RAW_BEGIN,
  FETCH_FEATURE_RAW_SUCCESS,
  FETCH_FEATURE_RAW_ERROR
};
