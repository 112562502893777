const FETCH_FEATURE_REPORT_BEGIN= 'FETCH_FEATURE_REPORT_BEGIN';
const FETCH_FEATURE_REPORT_SUCCESS = 'FETCH_FEATURE_REPORT_SUCCESS';
const FETCH_FEATURE_REPORT_ERROR = 'FETCH_FEATURE_REPORT_ERROR';
const FETCH_ATTENTION_BEGIN= 'FETCH_ATTENTION_BEGIN';
const FETCH_ATTENTION_SUCCESS = 'FETCH_ATTENTION_SUCCESS';
const FETCH_ATTENTION_ERROR = 'FETCH_ATTENTION_ERROR';
const FETCH_GRAPH_BEGIN= 'FETCH_GRAPH_BEGIN';
const FETCH_GRAPH_SUCCESS = 'FETCH_GRAPH_SUCCESS';
const FETCH_GRAPH_ERROR = 'FETCH_GRAPH_ERROR';
const FETCH_VISUALS_BEGIN= 'FETCH_VISUALS_BEGIN';
const FETCH_VISUALS_SUCCESS = 'FETCH_VISUALS_SUCCESS';
const FETCH_VISUALS_ERROR = 'FETCH_VISUALS_ERROR';
const SET_VISUALS_FILTER = 'SET_VISUALS_FILTER';
const SET_FEATURE_FILTER = 'SET_FEATURE_FILTER';
const SET_ATTENTION_FILTER = 'SET_ATTENTION_FILTER';
const FETCH_DEMOGRAPHIC_BEGIN= 'FETCH_DEMOGRAPHIC_BEGIN';
const FETCH_DEMOGRAPHIC_SUCCESS = 'FETCH_DEMOGRAPHIC_SUCCESS';
const FETCH_DEMOGRAPHIC_ERROR = 'FETCH_DEMOGRAPHIC_ERROR';
const SET_ATTENTION_REPORT_COMPLETED = 'SET_ATTENTION_REPORT_COMPLETED';
const SET_ATTENTION_REPORT_ERROR = 'SET_ATTENTION_REPORT_ERROR';
const SET_ATTENTION_REPORT_LOADING = 'SET_ATTENTION_REPORT_LOADING';



export default {
  FETCH_FEATURE_REPORT_BEGIN,
  FETCH_FEATURE_REPORT_SUCCESS,
  FETCH_FEATURE_REPORT_ERROR,
  FETCH_ATTENTION_BEGIN,
  FETCH_ATTENTION_SUCCESS,
  FETCH_ATTENTION_ERROR,
  FETCH_GRAPH_BEGIN,
  FETCH_GRAPH_SUCCESS,
  FETCH_GRAPH_ERROR,
  FETCH_VISUALS_BEGIN,
  FETCH_VISUALS_SUCCESS,
  FETCH_VISUALS_ERROR,
  SET_VISUALS_FILTER,
  SET_FEATURE_FILTER,
  SET_ATTENTION_FILTER,
  FETCH_DEMOGRAPHIC_BEGIN,
  FETCH_DEMOGRAPHIC_SUCCESS,
  FETCH_DEMOGRAPHIC_ERROR,
  SET_ATTENTION_REPORT_COMPLETED,
  SET_ATTENTION_REPORT_ERROR,
  SET_ATTENTION_REPORT_LOADING,

};
