import types from './types.js';

const fetchQuestionAnswerOverviewBegin = () => ({
  type: types.FETCH_QUESTION_RESPONSE_BEGIN
});
const fetchQuestionAnswerOverviewSuccess = data => ({
  type: types.FETCH_QUESTION_RESPONSE_SUCCESS,
  payload: { data }
});
const fetchQuestionAnswerOverviewError = () => ({
  type: types.FETCH_QUESTION_RESPONSE_ERROR
});
const setNewQuestionSelected = data => ({
  type: types.SET_NEW_QUESTION_SELECTED,
  payload: { data }
});
const getQuestionDataBegin = () => ({
  type: types.GET_QUESTION_DATA_BEGIN
});
const getQuestionDataSuccess = data => ({
  type: types.GET_QUESTION_DATA_SUCCESS,
  payload: { data }
});
const getQuestionDataError = () => ({
  type: types.GET_QUESTION_DATA_ERROR
});

const getPromptedSpontaneousBegin = () => ({
  type: types.GET_PROMPTED_SPONTANEOUS_BEGIN
});
const getPromptedSpontaneousSuccess = data => ({
  type: types.GET_PROMPTED_SPONTANEOUS_SUCCESS,
  payload: { data }
});
const getPromptedSpontaneousError = () => ({
  type: types.GET_PROMPTED_SPONTANEOUS_ERROR
});

const getParticipantsResultsBegin = () => ({
  type: types.GET_PARTICIPANTS_RESULTS_BEGIN
});
const getParticipantsResultsError = () => ({
  type: types.GET_PARTICIPANTS_RESULTS_ERROR
});


export default {
  fetchQuestionAnswerOverviewBegin,
  fetchQuestionAnswerOverviewSuccess,
  fetchQuestionAnswerOverviewError,
  setNewQuestionSelected,
  getQuestionDataBegin,
  getQuestionDataSuccess,
  getQuestionDataError,
  getPromptedSpontaneousBegin,
  getPromptedSpontaneousSuccess,
  getPromptedSpontaneousError,
  getParticipantsResultsBegin,
  getParticipantsResultsError
};


