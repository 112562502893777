import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useFormikContext } from 'formik';
import propTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(0, 1, 1, 3),
    display: 'flex',
    flexDirection: 'row',
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },

  rightItem: {
    width: '50%',
    padding: theme.spacing(2),
    overflowWrap: 'break-word',
  },
  listItemText:{
    alignItems:'center'
  }
}));

const ReviewList = ({ list }) => {
  const classes = useStyles();
  const { values, initialValues } = useFormikContext();
  const [reviewItems, setReviewItems] = useState([]);

  useEffect(() => {
    const items = [];
    switch (list) {
      case 'Basic Details':
        items.push(
          {
            parameter: 'name',
            name: 'Study name',
            desc: '',
            userInput: values.name,
          },
          {
            parameter: 'environment_id',
            name: 'Device/environment',
            desc: 'The device/environment your respondents will use to complete your study',
            userInput: values.environment_id.description === 'Lab' ? 'All Devices' : values.environment_id.description,
          },
          {
            parameter: 'language',
            name: 'Language',
            desc: 'The language the study will be conducted in',
            userInput: values.language.description,
          },
          {
            parameter: 'project_location',
            name: 'Country',
            desc: 'The Country where the research will be conducted',
            userInput: values.project_location.description,
          },
          {
            parameter: 'number_of_cells',
            name: 'Number of cells',
            desc: '',
            userInput: values.number_of_cells,
          },
          {
            parameter: 'panel_size',
            name: `Respondents per ${values.respondents.id === 1 ? 'cell' : 'study'}`,
            desc: '',
            userInput: values.panel_size,
          }
        );

        if (values.media_id?.code) {
          items.push({
            parameter: 'media_id',
            name: 'Type of Media',
            desc: '',
            userInput: values.media_id.description,
          });
        }
        break;

      case 'Question Details':
        switch (values.demographic_survey_or_lumen_questions) {
          default:
          case ('0'):
            items.push({
              parameter: 'demographic_survey_or_lumen_questions',
              name: 'Pre-exposure survey',
              desc: '',
              userInput: 'Not included',
            });
            break;
          case ('1'):
            items.push(
              {
                parameter: 'demographic_survey_url',
                name: 'Screening survey URL',
                desc: 'Link to your custom survey',
                userInput: values.demographic_survey_url,
              },
              {
                parameter: 'demographic_survey_insert_parameter',
                name: 'Screening survey parameter',
                desc: '',
                userInput: values.demographic_survey_insert_parameter,
              },
            );
            break;
          case ('2'):
            items.push(
              {
                parameter: 'pre_assign_question_group',
                name: 'Lumen Question Group',
                desc: 'Which survey set did you use?',
                userInput: values.pre_assign_question_group.group_name
              },
            );
            break;
        }

        switch (values.include_external_survey) {
          default:
          case ('0'):
            items.push({
              parameter: 'include_external_survey',
              name: 'Post exposure survey',
              desc: 'Survey shown after test subjects are exposed to ad',
              userInput: 'Not included',
            });
            break;
          case ('1'):
            items.push(
              {
                parameter: 'external_survey_step_url',
                name: 'End survey URL',
                desc: 'Link to your custom survey',
                userInput: values.external_survey_step_url,
              },
              {
                parameter: 'external_survey_step_insert_parameter',
                name: 'End survey parameter',
                desc: '',
                userInput: values.external_survey_step_insert_parameter,
              }
            );
            break;
        }
        break;

      case 'Panel Details':
      case 'Redirect Details':
        switch (values.include_panel_details) {
          default:
          case ('0'):
            items.push({
              parameter: 'include_panel_details',
              name: 'Panel details',
              desc: 'How you will redirect your panel',
              userInput: 'Not included',
            });
            break;

          case ('1'):
            if (values.panel_code) {
              items.push({
                parameter: 'panel_code',
                name: 'Panel code',
                desc: 'The code to help you identify your panel',
                userInput: values.panel_code,
              });
            }

            if (values.panel_success) {
              items.push({
                parameter: 'panel_success',
                name: 'Successful redirect URL',
                desc: 'To return respondents who have successfully completed your study',
                userInput: values.panel_success,
              });
            }

            if (values.panel_screen) {
              items.push({
                parameter: 'panel_screen',
                name: 'Screenout redirect URL',
                desc: 'To return respondents who have not met the screening criteria',
                userInput: values.panel_screen,
              });
            }

            if (values.panel_quotafull) {
              items.push({
                parameter: 'panel_quotafull',
                name: 'Quota full redirect',
                desc: 'To return respondents for already complete demographic group',
                userInput: values.panel_quotafull,
              });
            }
            if (values.panel_insert_param) {
              items.push({
                parameter: 'panel_insert_param',
                name: 'Participant insert parameter',
                desc: 'The variable name the panel gives to the unique respondent IDs',
                userInput: values.panel_insert_param,
              });
            }
            break;
          case ('2'):
            items.push({
              parameter: 'panel_success',
              name: 'Panel details',
              desc: 'How you will redirect your panel',
              userInput: values.panel_success,
            });
            break;
        }
        break;

      case 'Social Page Setup':
        items.push(
          {
            parameter: 'incontext_site',
            name: 'Study context',
            desc: 'The site your ad will be displayed in',
            userInput: values.incontext_site.description,
          }
        );

        if (values.stim_url) {
          items.push({
            parameter: 'stim_url',
            name: 'Ad URL',
            desc: 'Link to a webpage only featuring your test ad',
            userInput: values.stim_url,
          });
        }

        if (values.brand_id) {
          items.push({
            parameter: 'brand_id',
            name: 'Brand name',
            desc: 'The brand name of the company featured in your test ad',
            userInput: values.brand_id ? values.brand_id.name : '',
          });
        }

        if (values.fb_profile_src) {
          items.push({
            parameter: 'fb_profile_src',
            name: 'Profile URL',
            desc: `Link to a webpage only featuring your channel's profile photo`,
            userInput: values.fb_profile_src,
          });
        }

        if (values.fb_channel_name){
          items.push({
            parameter: 'fb_channel_name',
            name: 'Facebook profile name',
            desc: 'The channel posting the ad',
            userInput: values.fb_channel_name,
          });
        }

        if (values.fb_description){
          items.push({
            parameter: 'fb_description',
            name: 'Post description',
            desc: 'Text to appear on your tested social media post',
            userInput: values.fb_description,
          });
        }

        if (values.in_channel_name){
          items.push({
            parameter: 'in_channel_name',
            name: 'Instagram channel name',
            desc: 'The channel posting the ad',
            userInput: values.in_channel_name,
          });
        }

        if (values.sc_channel_name){
          items.push({
            parameter: 'sc_channel_name',
            name: 'Snapchat channel name',
            desc: 'The channel posting the ad',
            userInput: values.sc_channel_name,
          });
        }
        break;

      case 'Video Setup':
        items.push(
          {
            parameter: 'incontext_site',
            name: 'Study context',
            desc: 'The site your ad will be displayed in',
            userInput: 'YouTube',
          },
          {
            parameter: 'stim_url',
            name: 'Ad URL',
            desc: 'Link to a webpage only featuring your test video',
            userInput: values.stim_url,
          },
          {
            parameter: 'brand_id',
            name: 'Brand name',
            desc: 'The brand name of the company featured in your test ad',
            userInput: values.brand_id ? values.brand_id.name : '',
          },
        );
        break;

      case 'Context Details': {
        const cells = values.embedded_page_list.map((page) => (
          {
            parameter: '',
            name: `Cell ${page.cell_index} study page URL`,
            desc: '',
            userInput: page.url,
          }
        ));

        cells.forEach((cell) => items.push(cell));
        break;
      }

      case 'External Pages': {
        const urls = values.quick_step_list.map((_url, index) => (
          {
            parameter: 'quick_step_list[index].url',
            name: `Quick Step ${index + 1} URL`,
            desc: '',
            userInput: values.quick_step_list[index].url,
          }
        ));
        urls.forEach((cell) => items.push(cell));
        break;
      }

      case 'Cache Page Setup':
        items.push(
          {
            parameter: 'incontext_primary_site',
            name: 'Main page URL',
            desc: 'The site your ad will be displayed on in the study',
            userInput: values.incontext_primary_site,
          },
          {
            parameter: 'stim_script',
            name: 'Ad script',
            desc: 'The javascript which inserts your rich media into the webpage',
            userInput: values.stim_script,
          },
          {
            parameter: 'incontext_distraction_site',
            name: 'Distraction page URL',
            desc: 'The site that will host your comparison ads',
            userInput: values.incontext_distraction_site,
          },
          {
            parameter: 'brand_id',
            name: 'Brand name',
            desc: 'The brand name of the company featured in your test ad',
            userInput: values.brand_id ? values.brand_id.name : '',
          },
        );
        break;

      case 'Lumen Panel Data':
        items.push(
          {
            parameter: 'quick_task_data.panelist_role_id',
            name: 'Panelist role ID',
            desc: '',
            userInput: values.quick_task_data.panelist_role_id.name,
          },
          {
            parameter: 'quick_task_data.panel_id',
            name: 'Panel ID',
            desc: '',
            userInput: values.quick_task_data.panel_id.name,
          },
          {
            parameter: 'quick_task_data.short_description',
            name: 'Short description',
            desc: '',
            userInput: values.quick_task_data.short_description,
          },
          {
            parameter: 'quick_task_data.reward',
            name: 'Reward',
            desc: '',
            userInput: values.quick_task_data.reward,
          },
        );

        if (values.quick_task_data.start_date){
          items.push({
            parameter: 'quick_task_data.start_date',
            name: 'Start date',
            desc: '',
            userInput: values.quick_task_data.start_date,
          });
        }

        if (values.quick_task_data.expiry_date){
          items.push({
            parameter: 'quick_task_data.expiry_date',
            name: 'End date',
            desc: '',
            userInput: values.quick_task_data.expiry_date,
          });
        }

        break;

      case 'Advanced Settings': {
        if (values.notes !== initialValues.notes) {
          items.push({
            parameter: 'notes',
            name: 'Notes',
            desc: '',
            userInput: values.notes,
          });
        }

        if (values.objective !== initialValues.objective) {
          items.push({
            parameter: 'objective',
            name: 'Objective',
            desc: '',
            userInput: values.objective,
          });
        }

        if (values.brief !== initialValues.brief) {
          items.push({
            parameter: 'brief',
            name: 'Brief',
            desc: '',
            userInput: values.brief,
          });
        }

        if (values.methodology !== initialValues.methodology) {
          items.push({
            parameter: 'methodology',
            name: 'Methodology',
            desc: '',
            userInput: values.methodology,
          });
        }

        if (values.study_duration !== initialValues.study_duration) {
          items.push({
            parameter: 'study_duration',
            name: 'Study duration',
            desc: '',
            userInput: values.study_duration,
          });
        }

        if (values.decision_to_inform !== initialValues.decision_to_inform) {
          items.push({
            parameter: 'decision_to_inform',
            name: 'Decision to inform',
            desc: '',
            userInput: values.decision_to_inform,
          });
        }

        if (values.incentive !== initialValues.incentive) {
          items.push({
            parameter: 'incentive',
            name: 'Incentive',
            desc: '',
            userInput: values.incentive,
          });
        }

        if (values.proposal_signoff_date !== initialValues.proposal_signoff_date) {
          items.push({
            parameter: 'proposal_signoff_date',
            name: 'Proposal signoff date',
            desc: '',
            userInput: values.proposal_signoff_date,
          });
        }

        if (values.proposed_data_collection_start !== initialValues.proposed_data_collection_start) {
          items.push({
            parameter: 'proposed_data_collection_start',
            name: 'Proposed data collection start',
            desc: '',
            userInput: values.proposed_data_collection_start,
          });
        }

        if (values.proposed_data_collection_end !== initialValues.proposed_data_collection_end) {
          items.push({
            parameter: 'proposed_data_collection_end',
            name: 'Proposed data collection end',
            desc: '',
            userInput: values.proposed_data_collection_end,
          });
        }

        if (values.debrief_date !== initialValues.debrief_date) {
          items.push({
            parameter: 'debrief_date',
            name: 'Debrief date',
            desc: '',
            userInput: values.debrief_date,
          });
        }

        if (items.length === 0) {
          items.push({
            parameter: 'proposed_data_collection_end',
            name: 'You have not made any changes to the advanced settings',
            desc: '',
            userInput: '',
          });
        }

        break;
      }
    }
    setReviewItems(items);

  }, [list, setReviewItems, values]);

  return (
    <List>
      {reviewItems.map((input, index) => (
        <div key={index}>
          <ListItem className={classes.listItem} >
            <ListItemText inset={input.inset} className={classes.listItemText} primary={input.name} secondary={input.desc} />
            <Typography
              className={classes.rightItem}
              align='right'
              variant='body2'
            >
              {input.userInput.toString().substring(0, 60)}
            </Typography>
          </ListItem>
        </div>
      ))}
    </List>
  );
};

ReviewList.propTypes = {
  list: propTypes.string,
};

export default ReviewList;
