import React, { useState } from 'react';
import propTypes from 'prop-types';

import Review from '../ReviewPage/Review';
import { FormStepper } from '../FormStepper';
import { BasicDetails } from '../Steps/BasicDetails';
import { PanelDetails } from '../Steps/PanelDetails';
import { QuestionDetails } from '../Steps/QuestionDetails';

export default function CustomStudyStepper({
  onAddNewStudySubmit,
  languageTypes,
  environmentData,
  locationTypes,
  mediaData,
  questionTypes,
  studyTypes,
  respondentTypes,
  defaultValues,
  basicDetailsValidationSchema,
  panelDetailsValidationSchema,
  questionDetailsValidationSchema,
  history,
}) {
  const [stepImperatively, setStepImperatively] = useState(null);

  return (
    <FormStepper
      stepImperatively={stepImperatively}
      setStepImperatively={setStepImperatively}
      initialValues={{
        ...defaultValues,
        media_id: mediaData.data.find((media) => media.code === 4),
        study_type_id: studyTypes.data.find((study) => study.code === 0),
        panel_seconds_before_move_next: -1,
      }}
      onSubmit={async (values) => {
        await onAddNewStudySubmit(values, history);
      }}
    >
      <BasicDetails
        title='Tell us about your study'
        label='Basic Details'
        validationSchema={basicDetailsValidationSchema}
        {...{
          environmentData,
          locationTypes,
          languageTypes,
          studyTypes,
          mediaData,
          respondentTypes,
          includeStudyType: true,
          includeMediaType: true,
        }}
      />
      <QuestionDetails
        validationSchema={questionDetailsValidationSchema}
        title='What surveys will you use?'
        label='Question Details'
        {...{questionTypes }}
      />
      <PanelDetails
        title='Tell us about your recruitment'
        label='Panel Details'
        validationSchema={panelDetailsValidationSchema}
      />
      <Review
        title='Your project is ready for review'
        label='Review'
        setStepImperatively={setStepImperatively}
        labels={[
          'Basic Details',
          'Question Details',
          'Panel Details',
        ]}
      />
    </FormStepper>
  );
}

CustomStudyStepper.propTypes = {
  onAddNewStudySubmit: propTypes.func,
  languageTypes: propTypes.object,
  environmentData: propTypes.object,
  locationTypes: propTypes.object,
  mediaData: propTypes.object,
  questionTypes: propTypes.object,
  studyTypes: propTypes.object,
  respondentTypes: propTypes.object,
  defaultValues: propTypes.object,
  basicDetailsValidationSchema: propTypes.object,
  panelDetailsValidationSchema: propTypes.object,
  questionDetailsValidationSchema: propTypes.object,
  history: propTypes.object,
};
