const FETCH_TOTAL_INFO_BEGIN = 'FETCH_TOTAL_INFO_BEGIN';
const FETCH_TOTAL_INFO_SUCCESS = 'FETCH_TOTAL_INFO_SUCCESS';
const FETCH_TOTAL_INFO_ERROR = 'FETCH_TOTAL_INFO_ERROR';
const TOGGLE_SIDE_BAR = 'TOGGLE_SIDE_BAR';

export default {
    FETCH_TOTAL_INFO_BEGIN,
    FETCH_TOTAL_INFO_SUCCESS,
    FETCH_TOTAL_INFO_ERROR,
    TOGGLE_SIDE_BAR
};
