import React from 'react';
import PropTypes from 'prop-types';

function Table({ files }) {

  return (
    <table className="stims table">
      <tbody>
        <tr>
          <th style={{ paddingLeft: "32px", borderTopLeftRadius: 8, borderBottomLeftRadius:8, boxShadow: "rgb(255 255 255) 16px 0px 0px inset" }}>Study Name</th>
          <th>Description</th>
          <th>Type</th>
          <th style={{paddingRight: "32px", borderTopRightRadius: 8, borderBottomRightRadius:8, boxShadow: "rgb(255 255 255) -16px 0px 0px inset" }}>URL</th>


        </tr>
        {files.map((item, index) => {

          return (
            <tr key={index}>
              <td>{item.name}</td>
              <td>{item.description}</td>
              <td>{item.type}</td>
              <td><a rel="noopener noreferrer" target="_blank" href={item.url}>Link</a></td>

            </tr>
          )
        })}
      </tbody>
    </table>
  );
}

export default Table;


Table.propTypes = {
  files: PropTypes.array

};
