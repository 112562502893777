import React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Typography } from '@material-ui/core';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import DoneIcon from '@mui/icons-material/Done';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import ConfirmActionModal from '../../Modal/components/ConfirmActionModal';

export default function StudyTimeline({ phaseId, studyId, postEndLiveStudy }) {
  const phases = [
    {
      id: 1,
      title: 'Setup',
    },
    {
      id: 2,
      title: 'Quality Assurance',
    },
    {
      id: 3,
      title: 'Data Collection',
    },
    {
      id: 4,
      title: 'Analysis & Reporting',
    },
    {
      id: 5,
      title: 'Study Complete',
    },
  ];

  return (
    <>
      <Typography style={{ padding: '0px 8px 0px', width: '100%', display: 'flex', justifyContent: 'space-between' }} variant='h6' >
        Order timeline
        {phaseId > 4 ?
          <Tooltip title='Study completed' >
            <DoneIcon style={{color: 'rgb(67, 160, 71)', margin:6}} />
          </Tooltip>
          : (
            <ConfirmActionModal
              openButtonText={'Complete study'}
              action={() => postEndLiveStudy(studyId)}
              className="EndStudy"
              title="Complete this study?"
              mainText="Data for this project will stop being gathered and Lumen will begin processing and analysing your results"
              actionButtonText="Yes, complete this Study"
            />
          )}
      </Typography>
      <Timeline style={{margin:0}} >
        {
          phases.map((phase, index) => (
            <TimelineItem key={phase.id} style={{ minHeight: phases.length === index + 1 ? 0 : '4rem' }}>
              <TimelineSeparator >
                <TimelineDot style={{background: phaseId >= phase.id ? '#43a047' : '' }} />
                { index + 1 < phases.length && <TimelineConnector /> }
              </TimelineSeparator>
              <TimelineContent style={{whiteSpace: 'nowrap'}} >{phase.title}</TimelineContent>
              <TimelineOppositeContent></TimelineOppositeContent>
            </TimelineItem>
          ))
        }
      </Timeline>
    </>
  );
}

StudyTimeline.propTypes = {
  phaseId: PropTypes.number,
  studyId: PropTypes.string,
  postEndLiveStudy: PropTypes.func,
};
