import React, { Component } from 'react';
import classNames from 'classnames';
import Button from './Button';


class PrettyDropDown2 extends Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      active: true,
      title: "select",
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    if (this.props.value) {
        let optionSelected = this.props.options.filter((option, index) => {
            return option.code === this.props.value
        } )
        this.setState({title: optionSelected[0].description})
    }

  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.state.active === false) {
        this.setState(prevState => ({
          active: !prevState.active
        }));
      }
    }
  }

  onDropDownClick = () => {
    this.setState(prevState => ({
      active: !prevState.active
    }));
  }

  onMenuItemClick = (e) => {
    this.onDropDownClick();
    this.props.onSelectItem(e.target.value);
  }


  render() {
    const { active } = this.state;
   
    return (
      <div ref={this.setWrapperRef} className="pretty-dropdown__container">
        <Button buttonClass="pretty-dropdown__button" text={this.state.title}  handleClick={this.onDropDownClick} />
        <ul className={classNames('pretty-dropdown', { 'active': active })} name="dropdown">
           
         {
            this.props.options.map((option, index) => {
             return <li key={index} value={option.code} onClick={this.onMenuItemClick} className={classNames("pretty-dropdown__list", { 'active': active })} tabIndex={index} >{option.description}</li>
            }  )
 }
         
        </ul>
      </div>
    );
  }
}

export default PrettyDropDown2;


