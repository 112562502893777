import React, { Component } from 'react';
import { URL } from '../common/constants';
import { LumenFeatureEditor } from '@lumen-developer/lumen-feature-editor-component';
import queryString from 'query-string'

class AoiContainer extends Component {
  render() {
    const id = this.props.match.params.id;
    const type = queryString.parse(this.props.location.search)
    const user = localStorage.getItem('user');

    return (
      <div className="aoi">
        <LumenFeatureEditor 
          stimulusId={id}
          endpoint={`${URL}/v1.0/analysis/` + (type.type === 'stim' ? "stimuli" : "tpstimuli")}
          tagsEndpoint={`${URL}/v1.0/analysis/tags2`}
          token={user}
          enforceMinimumSizeOverride={true}
        />
      </div>
    );
  }
}


export default AoiContainer;
