import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import propTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  tableBluebar: {
    background: 'rgba(239, 239, 247, 0.7)',
    borderBottom: '2px solid #dcdef1',
    borderTop: '1px solid #dcdef1',
  },
  columnTitle: {
    fontWeight: 700,
    padding: 12,
    fontSize: '0.83125rem',
    textTransform: 'uppercase',
    color: 'rgb(59, 62, 102)',
  },
  actionButton: {
    color: '#5383ff',
    border: '1px solid rgba(83, 131, 255, 0.5)',
    borderRadius: '0.5rem',
    fontSize: 13,
    fontWeight: 400,
    textTransform: 'none',
    whiteSpace: 'nowrap'
  },
}));

function numberToWord(number) {
  switch (number) {
    case 1:
      return 'first';
    case 2:
      return 'second';
    case 3:
      return 'third';
    case 4:
      return 'fourth';
    case 5:
      return 'fifth';
    case 6:
      return 'sixth';
    case 7:
      return 'seventh';
    case 8:
      return 'eigth';
    case 9:
      return 'ninth';
    case 10:
      return 'tenth';
    case 11:
      return 'eleventh';
    case 12:
      return 'twelfth';
    case 13:
      return 'thirteenth';
    case 14:
      return 'fourteenth';
    case 15:
      return 'fifteenth';
    default:
      return 'first';
  }
}

function LinksTable({
  newStudySuccessMessage,
}) {
  const classes = useStyles();

  if (!newStudySuccessMessage) return null;

  return (
    <Paper variant='outlined' style={{ width: '100%' }}>
      <TableContainer>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead className={classes.tableBluebar}>
            <TableRow>
              <TableCell
                style={{ paddingLeft: 20 }}
                className={classes.columnTitle}
                align='left'
              >
                Study Previews
              </TableCell>
              <TableCell className={classes.columnTitle} align='center' />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key='testStudy'>
              <TableCell component='th' scope='row'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>
                    <b style={{ color: '#3b3e66', display: 'block' }}>
                      Take a test study
                    </b>
                    <span
                      style={{ color: 'rgba(7,9,25,.5)', display: 'block' }}
                    >
                      This link will allow you to take the study but your results
                      will not be counted
                    </span>
                  </div>
                </div>
              </TableCell>
              <TableCell align='center'>
                <Button
                  size='small'
                  variant='outlined'
                  className={classes.actionButton}

                  href={newStudySuccessMessage.start_url_test.replace('&participant=', '')}
                  target='_blank'
                >
                  Test study
                </Button>
              </TableCell>
            </TableRow>
            <TableRow key='realStudy'>
              <TableCell component='th' scope='row'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>
                    <b style={{ color: '#3b3e66', display: 'block' }}>
                        Take a real study
                    </b>
                    <span
                      style={{ color: 'rgba(7,9,25,.5)', display: 'block' }}
                    >
                      This link will allow you to take the study and your data
                      will be included in the final results
                    </span>
                  </div>
                </div>
              </TableCell>
              <TableCell align='center'>
                <Button
                  size='small'
                  variant='outlined'
                  className={classes.actionButton}

                  href={newStudySuccessMessage.start_url.replace('&participant=', '')}
                  target='_blank'
                >
                  Take study
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
          {newStudySuccessMessage.incontext_urls.length > 0 ? (
            <>
              <TableHead className={classes.tableBluebar}>
                <TableRow>
                  <TableCell
                    style={{ paddingLeft: 24 }}
                    className={classes.columnTitle}
                    align='left'
                  >
                    Page Previews
                  </TableCell>
                  <TableCell className={classes.columnTitle} align='center' />
                </TableRow>
              </TableHead>
              <TableBody>
                {newStudySuccessMessage.incontext_urls.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component='th' scope='row'>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>
                          <b style={{ color: '#3b3e66', display: 'block' }}>
                            View Page {index + 1}
                          </b>
                          <span
                            style={{ color: 'rgba(7,9,25,.5)', display: 'block' }}
                          >
                            This will be the {numberToWord(index + 1)} test page your respondents will see. Please check everything is just how you want it
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align='center'>
                      <Button
                        size='small'
                        variant='outlined'
                        className={classes.actionButton}
                        href={row.step_url}
                        target='_blank'
                      >
                        View page
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </>
          ) : null}
        </Table>
      </TableContainer>
    </Paper>
  );
}

LinksTable.propTypes = {
  newStudySuccessMessage: propTypes.object,
};

const mapStateToProps = (state) => {
  return {
    newStudySuccessMessage: state.studyData.newStudySuccessMessage,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
  )(LinksTable)
);
