import DataService from '../../common/services/dataService';
import HttpService from './HttpService';
import Creators from './actions';
const setEnvironmentFilter = Creators.setEnvironmentFilter;
const setMediaFilter = Creators.setMediaFilter;
const toggleCompleted = Creators.toggleCompleted;
const toggleLive = Creators.toggleLive;
const search = Creators.search;
const sortByAscendingOrder = Creators.sortByAscendingOrder;
const sortByDecendingOrder = Creators.sortByDecendingOrder;
const fetchStudiesBegin = Creators.fetchStudiesBegin;
const fetchStudiesSuccess = Creators.fetchStudiesSuccess;
const fetchStudiesError = Creators.fetchStudiesError;
const onAddNewStudyWithEnvironments = Creators.onAddNewStudy;
const closeModal = Creators.cancelOnAddNewStudy;
const cancelOnAddNewStudy = Creators.cancelOnAddNewStudy;
const submittingStudyError = Creators.submittingStudyError;
const submittingStudy = Creators.submittingStudy;
const newStudySuccessMessage = Creators.newStudySuccessMessage;

const getStudyData = (
  studiesPerPage,
  pageNum,
  orderBy,
  filterName,
  filterLive,
  filterIsCompleted,
  mediaId,
  environmentId
) => {
  return async (dispatch) => {
    dispatch(fetchStudiesBegin());
    try {
      let data = {
        results_per_page: studiesPerPage,
        page_num: pageNum,
        order_by: orderBy,
        filter_name: filterName,
        filter_live: filterLive,
        filter_completed: filterIsCompleted,
        filter_media_id: mediaId,
        filter_environment_id: environmentId,
      };
      const res = await DataService.fetchAllStudyData(data);
      dispatch(fetchStudiesSuccess(res));
    } catch (err) {
      dispatch(fetchStudiesError(err));
      throw err;
    }
  };
};

const sortAscendingData = (item, type) => {
  if (type === 'DECENDING') {
    return (dispatch) => {
      dispatch(sortByDecendingOrder(item, type));
    };
  } else {
    return (dispatch) => {
      dispatch(sortByAscendingOrder(item, type));
    };
  }
};

const onAddNewStudySubmit = (referencedValues, history) => {
  const values = JSON.parse(JSON.stringify(referencedValues));
  const data = {
    name: values.name,
    environment_id: values.environment_id.code,
    language_id: values.language.id,
    project_location: values.project_location.code,
    number_of_cells: values.number_of_cells,
    panel_size: values.respondents.id === 1 ? values.panel_size * values.number_of_cells : values.panel_size,
    media_id: values.media_id.code,
    study_type_id: typeof values.study_type_id === 'number' ? values.study_type_id : values.study_type_id.code,

    panel_code: values.panel_code,
    panel_success: values.panel_success,
    panel_screen: values.panel_screen,
    panel_quotafull: values.panel_quotafull,
    panel_insert_param: values.panel_insert_param,
    panel_seconds_before_move_next: values.panel_seconds_before_move_next,

    incontext_primary_site: values.incontext_primary_site || null,
    stim_script: values.stim_script || null,
    incontext_distraction_site: values.incontext_distraction_site || null,
    brand_id: values.brand_id?.id || null,

    stim_url: values.stim_url || null,
    ad_type: values.ad_type?.id ? values.ad_type.id : values.ad_type,
    cachepage_stim_list: values.cachepage_stim_list || null,

    notes: values.notes,
    objective: values.objective,
    brief: values.brief,
    methodology: values.methodology,
    study_duration: values.study_duration,
    decision_to_inform: values.decision_to_inform,
    incentive: values.incentive,
    proposal_signoff_date: values.proposal_signoff_date,
    proposed_data_collection_start: values.proposed_data_collection_start,
    proposed_data_collection_end: values.proposed_data_collection_end,
    debrief_date: values.debrief_date,
  };

  if (values.include_panel_details === '1') {
    data.panel_type = 0;
  }

  if (values.include_panel_details === '2') {
    data.panel_type = 1;
    data.panel_code = 'rd';
  }

  if (values.demographic_survey_or_lumen_questions === '1') {
    data.demographic_survey_url = values.demographic_survey_url;
    data.demographic_survey_insert_parameter = values.demographic_survey_insert_parameter;
  }
  if (values.demographic_survey_or_lumen_questions === '2') {
    data.pre_assign_question_group = values.pre_assign_question_group.id;
  }

  if (values.include_external_survey === '1') {
    data.external_survey_step_url = values.external_survey_step_url;
    data.external_survey_step_insert_parameter = values.external_survey_step_insert_parameter;
  }

  if (data.study_type_id === 4) {
    values.quick_task_data.panelist_role_id = values.quick_task_data.panelist_role_id.id;
    values.quick_task_data.panel_id = values.quick_task_data.panel_id.id;
    data.quick_step_list = values.quick_step_list;
    data.quick_task_data = values.quick_task_data;
  }

  if (data.study_type_id === 5) {
    data.incontext_site = values.incontext_site.code;
    if (values.incontext_site.code === 'fb' || values.incontext_site.code === 'vk') {
      if (values.is_new_social_system) {
        data.incontext_site = 'rfb';
      }
      data.fb_channel_name = values.fb_channel_name;
      data.fb_profile_src = values.fb_profile_src;
      data.fb_description = values.fb_description;
      data.fb_promoted_button_text = values.fb_promoted_button_text;
      data.promoted = values.promoted;
      data.has_sound = values.has_sound;
      data.fb_promoted_description = values.fb_promoted_description;
      data.fb_promoted_description2 = values.fb_promoted_description2;
      data.fb_promoted_domain = values.fb_promoted_domain;
      data.fb_call_to_action = values.fb_call_to_action;
    } else if (values.incontext_site.code === 'in') {
      if (values.is_new_social_system) {
        data.incontext_site = 'rin';
      }
      data.in_channel_name = values.in_channel_name;
      data.in_profile_src = values.in_profile_src;
      data.in_promoted_button_text = values.in_promoted_button_text;
      data.in_call_to_action = values.in_call_to_action;
      data.in_description = values.in_description;
    } else if (values.incontext_site.code === 'sc') {
      data.sc_channel_name = values.sc_channel_name;
      data.sc_description = values.sc_description;
      data.sc_promo_text = values.sc_promo_text;
      data.sc_promo_desc = values.sc_promo_desc;
      data.sc_promo_top = values.sc_promo_top;
      data.sc_promo_rating = `${'* '.repeat(values.sc_promo_rating)} 42K Ratings`;
      data.sc_cta = values.sc_cta;
    } else if (values.incontext_site.code === 'pin') {
      data.pn_type = values.pn_type;
      data.pn_stim_title = values.pn_stim_title;
      data.pn_profile_src = values.pn_profile_src;
      data.pn_stim_profile = values.pn_stim_profile;
    }
    else if (values.incontext_site.code === 'tt') {
      data.tt_profile_src = values.tt_profile_src;
      data.tt_profile_name = values.tt_profile_name;
      data.tt_desc = values.tt_desc;
      data.tt_song_name = values.tt_song_name;
      data.tt_promo_text = values.tt_promo_text;
    }
  }

  if (data.cachepage_stim_list) {
    data.cachepage_stim_list.forEach((cache) => {
      cache.stim_list.pop();
    });
    data.cachepage_stim_list.forEach((cache) => {
      cache.stim_list.forEach((stim) => {
        stim.brand_id = stim.brand_id.id;
        stim.ad_type_id = stim.ad_type_id.id;
      });
    });
  }

  if (data.study_type_id === 10) {
    data.embedded_page_list = values.embedded_page_list;
  }

  return async (dispatch) => {
    try {
      dispatch(submittingStudy(true));
      const res = await DataService.onAddNewStudySubmit(data);
      const studyId = res.data.study_id;
      if (res.data.success) {
        await dispatch(newStudySuccessMessage(res.data));
        if (
          data.study_type_id === 4 ||
          data.study_type_id === 5 ||
          data.study_type_id === 7 ||
          data.study_type_id === 8 ||
          data.study_type_id === 10
        ) {
          history.push(`/my_studies/overview/${studyId}/thank_you`);
        } else {
          history.push(`/my_studies/overview/${studyId}/cell_step_management`);
        }
      } else if (!res.data.success) {
        dispatch(submittingStudyError(res.data));
      }
      dispatch(submittingStudy(false));
    } catch (error) {
      dispatch(submittingStudy(false));
      dispatch(submittingStudyError(true));
    }
  };
};

const onAddNewStudy = () => {
  return async (dispatch) => {
    const results = await HttpService.getEnvironmentList();
    const mediaResults = await HttpService.getMediaList();
    const studyTypesResults = await HttpService.getStudyTypesList();
    const languageResults = await HttpService.getLanguageList();
    const questionResults = await HttpService.getQuestionsList();
    const locationResults = await HttpService.getLocationsList();
    const brandResults = await HttpService.getBrandTypes();
    const AdTypesResults = await HttpService.getAdTypes();
    const InContextResults = await HttpService.getInContextTypes();
    const PanelsList = await HttpService.getPanelsList();
    const CompaniesList = await HttpService.getCompaniesList();
    const PanelistRoles = await HttpService.getPanelistRoles();
    dispatch(
      onAddNewStudyWithEnvironments(
        results,
        mediaResults,
        studyTypesResults,
        languageResults,
        questionResults,
        locationResults,
        brandResults,
        AdTypesResults,
        InContextResults,
        PanelsList,
        CompaniesList,
        PanelistRoles
      )
    );
  };
};

export default {
  sortAscendingData,
  getStudyData,
  setEnvironmentFilter,
  setMediaFilter,
  toggleCompleted,
  toggleLive,
  search,
  sortByAscendingOrder,
  sortByDecendingOrder,
  fetchStudiesBegin,
  fetchStudiesSuccess,
  fetchStudiesError,
  onAddNewStudy,
  cancelOnAddNewStudy,
  onAddNewStudySubmit,
  closeModal,
  submittingStudyError,
  newStudySuccessMessage,
};
