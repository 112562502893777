import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { filesOperations } from './duck/index';
import Table from './components/Table'
import PropTypes from 'prop-types'
import Paper from "@material-ui/core/Paper"


function Files({ getFiles, files }) {

  useEffect(() => {
    getFiles()
  }, [])

  return (
    <Paper  style={{ borderRadius:16, padding: "16px 0px"}}>
      <Table files={files} />
    </Paper>
  );
}

function mapStateToProps(state) {
  return {
    files: state.files.files
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getFiles: () => dispatch(filesOperations.getFilesData()),

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Files);

Files.propTypes = {
  getFiles: PropTypes.func,
};
