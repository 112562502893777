const FETCH_QUESTION_RESPONSE_BEGIN = 'FETCH_QUESTION_RESPONSE_BEGIN';
const FETCH_QUESTION_RESPONSE_SUCCESS = 'FETCH_QUESTION_RESPONSE_SUCCESS';
const FETCH_QUESTION_RESPONSE_ERROR = 'FETCH_QUESTION_RESPONSE_ERROR';
const SET_NEW_QUESTION_SELECTED = 'SET_NEW_QUESTION_SELECTED';
const GET_QUESTION_DATA_BEGIN = 'GET_QUESTION_DATA_BEGIN';
const GET_QUESTION_DATA_SUCCESS = 'GET_QUESTION_DATA_SUCCESS';
const GET_QUESTION_DATA_ERROR = 'GET_QUESTION_DATA_ERROR';
const GET_PROMPTED_SPONTANEOUS_BEGIN = 'GET_PROMPTED_SPONTANEOUS_BEGIN';
const GET_PROMPTED_SPONTANEOUS_SUCCESS = 'GET_PROMPTED_SPONTANEOUS_SUCCESS';
const GET_PROMPTED_SPONTANEOUS_ERROR = 'GET_PROMPTED_SPONTANEOUS_ERROR';
const GET_PARTICIPANTS_RESULTS_BEGIN = 'GET_PARTICIPANTS_RESULTS_BEGIN';
const GET_PARTICIPANTS_RESULTS_ERROR = 'GET_PARTICIPANTS_RESULTS_ERROR';




export default {
  FETCH_QUESTION_RESPONSE_BEGIN,
  FETCH_QUESTION_RESPONSE_SUCCESS,
  FETCH_QUESTION_RESPONSE_ERROR,
  SET_NEW_QUESTION_SELECTED,
  GET_QUESTION_DATA_BEGIN,
  GET_QUESTION_DATA_SUCCESS,
  GET_QUESTION_DATA_ERROR,
  GET_PROMPTED_SPONTANEOUS_BEGIN,
  GET_PROMPTED_SPONTANEOUS_SUCCESS,
  GET_PROMPTED_SPONTANEOUS_ERROR,
  GET_PARTICIPANTS_RESULTS_BEGIN,
  GET_PARTICIPANTS_RESULTS_ERROR
};
