import React, { useState, useEffect } from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { TreeItem, TreeView } from "@material-ui/lab";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { studiesOperations } from "../../Studies/duck/index";
import { kpiOperations } from "../../Kpi/duck";
import { featureAnalysisOperations } from "../../FeatureAnalysis/duck";
import LoadingSpinner from "../../common/components/LoadingSpinner";
import MoreInfoPictureContent from "../../CreateStudyPage/MaterialComponents/MoreInfoPictureContent";
import MoreInfo from "../../CreateStudyPage/MaterialComponents/MoreInfo";


const useStyles = makeStyles((theme) => ({
  condensed: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(1),
  },

  checkbox: {
    overflow: "hidden",
    marginRight:0,
    '&:hover': {
      minWidth: "fit-content",
      background:"white",
   },
  },

  label: {
    width:"100%",
    zIndex:2,
  }
}));

function leafIds(node) {
  return !node.children || node.children.length === 0
    ? [node.value]
    : node.children.map(leafIds).flat();
}

function ReportsTreeSelector(props) {
  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);

  const { loading, isolationData } = props;

  useEffect(
    () => {
      props.setStimsSelected(checked)
    },
    [checked]
  );

  useEffect(() => {
    let parameter = new URLSearchParams(window.location.search);
    let URLstimsSelected = parameter.get("stims");
    let allStims = [];
    isolationData.forEach((cell) =>
      cell.step_list.forEach((step) =>
        step.stimulus_list.forEach((stim) =>
          allStims.push(`${cell.cell_id} ${step.step_id} ${stim.stimulus_id}`)
        )
      )
    );
    if (URLstimsSelected) {
      URLstimsSelected = URLstimsSelected.split("|").map((stim) => {
        return stim.replace(/\./g, " ");
      });
      URLstimsSelected = URLstimsSelected.filter((stim) =>
        allStims.includes(stim)
      );
      setChecked(URLstimsSelected);
    }
  }, []);

  const labelWithIcon = (label, url) => {
    return (
      <div style={{display: "flex", justifyContent: "space-between"}}>
        <div>{label}</div>
        <MoreInfo
          popup={
            <MoreInfoPictureContent
              standardDescription="Your stim: "
              url={
                url.stimulus_type === "image"
                  ? url.stimulus_source
                  : url.stimulus_source_alternate
              }
              illustration="url"
            />
          }
        />
      </div>
    );
  };

  



  //   const foodExample = [
  //     ["fruits", ["melon", "orange"]],
  //     ["meat", ["saussage", "beef"]]
  //   ];

  const nodes = [
    {
      value: "this_study",
      label: props.studyName,
      children: isolationData.map((cell) => ({
        value: cell.cell_id,
        label: cell.cell_name,
        children: cell.step_list.map((step, index) => ({
          value: `${cell.cell_id} ${step.step_id}`,
          label: `Step ${index + 1}`,
          children: step.stimulus_list.map((stim) => ({
            value: `${cell.cell_id} ${step.step_id} ${stim.stimulus_id}`,
            label: stim.stimulus_name,
            moreInfo: stim,
          })),
        })),
      })),
    },
  ];
  // const nodes = [
  //     {value:"Cell 1", label : "Cell 1", children: [
  //       {value: "Cell_1_Step_1", label: "Step 1"},
  //       {value: "Cell_1_Step_2", label: "Step 2"}
  //     ]},
  //     {value:"Cell 2", label : "Cell 2", children: [
  //       {value: "Cell_2_Step_1", label: "Step 1", children: [
  //           {value: "Cell_2_Step_1_Stim_1", label: "Stim 1"},
  //           {value: "Cell_2_Step_1_Stim_2", label: "Stim 2"}
  //         ]},
  //       {value: "Cell_2_Step_2", label: "Step 2", children: [
  //           {value: "Cell_2_Step_2_Stim_1", label: "Stim 1"},
  //           {value: "Cell_2_Step_2_Stim_2", label: "Stim 2"}
  //         ]}
  //     ]}
  // ]

  //   const nodes = foodExample.map(([type, names]) => ({
  //     value: type,
  //     label: type,
  //     children: names.map(name => ({ value: name, label: name }))
  //   }));

  const handleCheck = (node, newValue) => {
    const value = checked.includes(node.value);
    if (!node.children || node.children.length === 0) {
      if (value === newValue) return;
      setChecked(
        newValue
          ? [...checked, node.value]
          : checked.filter((id) => id !== node.value)
      );
    } else {
      const ids = leafIds(node);
      const remaining = checked.filter((id) => !ids.includes(id));
      setChecked(newValue ? [...remaining, ...ids] : remaining);
    }
  };

  function TreeNode({ node }) {
    const isChecked = leafIds(node).every((id) => checked.includes(id));
    const isIndeterminate =
      !isChecked && leafIds(node).some((id) => checked.includes(id));
    const onChange = () => {
      handleCheck(node, !isChecked);
    };


    return (
      <TreeItem
        key={node.value}
        nodeId={node.value.toString()}
        className={classes.checkbox}
        label={
          <FormControlLabel
            color="white"
            style={{
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
            classes={{
              label: classes.label,
            }}
            control={
              <Checkbox
                color="primary"
                className={classes.condensed}
                checked={isChecked}
                onChange={onChange}
                onClick={(e) => e.stopPropagation()}
                indeterminate={isIndeterminate}
              />
            }
            label={
              node.moreInfo
                ? labelWithIcon(node.label, node.moreInfo)
                : node.label
            }
            onClick={(e) => e.stopPropagation()}
          />
       
        }
      >
        {node.children && <TreeNodes nodes={node.children} />}
      </TreeItem>
    );
  }

  function TreeNodes({ nodes }) {
    return (
      <>
        {nodes.map((node) => (
          <TreeNode  node={node} key={node.label} />
        ))}
      </>
    );
  }

  return loading ? (
    <LoadingSpinner />
  ) : (
    <>
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      defaultEndIcon={<ChevronRightIcon style={{ visibility: "hidden" }} />}
      expanded={expanded}
      onNodeToggle={(_, nodes) => setExpanded(nodes)}
    >
      <TreeNodes nodes={nodes} />
    </TreeView>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    isChecked: (studyId, cellId, stepId, stimulusId) =>
      dispatch(
        studiesOperations.addOrRemoveStudy(studyId, cellId, stepId, stimulusId)
      ),
    clearCheckedValues: () => dispatch(studiesOperations.clearCheckedStudies()),
    checkAll: ({ index, studyId, cell_id, history }, stimsInStep) =>
      dispatch(
        studiesOperations.checkAllinStep(
          { index, studyId, cell_id, history },
          stimsInStep
        )
      ),
    setNewReportCriteria: (checked, studyId, reportType) =>
      dispatch(
        studiesOperations.setNewReportCriteria(checked, studyId, reportType)
      ),
    goToAttentionReportPage: (whichStudiesAreChecked, { history, match }) =>
      dispatch(
        studiesOperations.goToAttentionReportPage(whichStudiesAreChecked, {
          history,
          match,
        })
      ),
    downloadParticipantData: (studyId, cellId, stepId, download) =>
      dispatch(
        kpiOperations.getParticipantReportData(
          studyId,
          cellId,
          stepId,
          download
        )
      ),
    downloadStepData: (studyId, cellId, stepId, download) =>
      dispatch(
        kpiOperations.downloadStepData(studyId, cellId, stepId, download)
      ),
    // downloadStimReport: (cellId, stepId, studyId, stimId, download) =>
    //   dispatch(
    //     kpiOperations.fetchKpiRawData(cellId, stepId, studyId, stimId, download)
    //   ),
    downloadFeatureData: (cellId, stepId, studyId, stimId, download) =>
      dispatch(
        featureAnalysisOperations.fetchFeatureRawData(
          cellId,
          stepId,
          studyId,
          stimId,
          download
        )
      ),
  };
};

const mapStateToProps = (state) => {
  return {
    studyName: state.isolationData.data.study_name,
    isolationData: state.isolationData.data.data,
    loading: state.isolationData.loading,
    tickCheckBox: state.isolationData.checked,
    whichStudiesAreChecked: state.isolationData.whichStudiesAreChecked,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ReportsTreeSelector)
);
