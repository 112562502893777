const FETCH_REPORT_DETAILS_BEGIN = 'FETCH_REPORT_DETAILS_BEGIN';
const FETCH_REPORT_DETAILS_SUCCESS = 'FETCH_REPORT_DETAILS_SUCCESS';
const FETCH_REPORT_DETAILS_ERROR = 'FETCH_REPORT_DETAILS_ERROR';
const FETCH_LOOKER_URL_BEGIN = 'FETCH_LOOKER_URL_BEGIN';
const FETCH_LOOKER_URL_SUCCESS = 'FETCH_LOOKER_URL_SUCCESS';
const FETCH_LOOKER_URL_ERROR = 'FETCH_LOOKER_URL_ERROR';

export default {
  FETCH_REPORT_DETAILS_BEGIN,
  FETCH_REPORT_DETAILS_SUCCESS,
  FETCH_REPORT_DETAILS_ERROR,
  FETCH_LOOKER_URL_BEGIN,
  FETCH_LOOKER_URL_SUCCESS,
  FETCH_LOOKER_URL_ERROR,
};
