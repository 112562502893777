import React, { useState } from 'react';
import { array, object, string } from 'yup';
import propTypes from 'prop-types';

import Review from '../ReviewPage/Review';
import { FormStepper } from '../FormStepper';
import { BasicDetails } from '../Steps/BasicDetails';
import { PanelDetails } from '../Steps/PanelDetails';
import { QuestionDetails } from '../Steps/QuestionDetails';
import StandardWebPageSetup from '../Steps/StandardWebPageSetup';

export default function DigitalStandardStepper({
  onAddNewStudySubmit,
  environmentData,
  locationTypes,
  languageTypes,
  questionTypes,
  brandTypes,
  adTypes,
  respondentTypes,
  defaultValues,
  basicDetailsValidationSchema,
  panelDetailsValidationSchema,
  questionDetailsValidationSchema,
  history,
}) {
  const [stepImperatively, setStepImperatively] = useState(null);
  return (
    <FormStepper
      stepImperatively={stepImperatively}
      setStepImperatively={setStepImperatively}
      initialValues={{
        ...defaultValues,
        panel_seconds_before_move_next: -1,
        cachepage_stim_list: [{
          cachepage_url: '',
          stim_list: [
            {
              stim_url: '',
              brand_id: null,
              ad_type_id: '',
            },
          ],
        }],
        study_type_id: 8,
      }}
      onSubmit={async (values) => {
        await onAddNewStudySubmit(values, history);
      }}
    >
      <BasicDetails
        title='Tell us about your study'
        label='Basic Details'
        validationSchema={basicDetailsValidationSchema}
        {...{environmentData, locationTypes, languageTypes, respondentTypes}}
      />
      <QuestionDetails
        validationSchema={questionDetailsValidationSchema}
        title='What surveys will you use?'
        label='Question Details'
        {...{questionTypes}}
      />
      <PanelDetails
        validationSchema={panelDetailsValidationSchema}
        title='Tell us about your recruitment'
        label='Panel Details'
      />
      <StandardWebPageSetup
        validationSchema={object().shape({
          cachepage_stim_list: array(
            object({
              cachepage_url: string()
                .url('Must be a valid URL')
                .max(512, 'Too Long!')
                .required('Required'),
              stim_list: array(
                object({
                  stim_url: string()
                    .url('Must be a valid URL')
                    .max(512, 'Too Long!'),
                })
              ),
            })
          ),
        })}
        title='Great! Now setup your your page'
        label='Web Page Setup'
        {...{adTypes, brandTypes}}
      />
      <Review
        title='Your project is ready for review'
        label='Review'
        setStepImperatively={setStepImperatively}
        {...{
          setStepImperatively,
          labels: [
            'Basic Details',
            'Question Details',
            'Panel Details',
            'Web Page Setup',
          ],
        }}
      />
    </FormStepper>
  );
}

DigitalStandardStepper.propTypes = {
  onAddNewStudySubmit: propTypes.func,
  languageTypes: propTypes.object,
  environmentData: propTypes.object,
  locationTypes: propTypes.object,
  questionTypes: propTypes.object,
  mediaData: propTypes.object,
  brandTypes: propTypes.object,
  adTypes: propTypes.object,
  respondentTypes: propTypes.object,
  defaultValues: propTypes.object,
  basicDetailsValidationSchema: propTypes.object,
  panelDetailsValidationSchema: propTypes.object,
  questionDetailsValidationSchema: propTypes.object,
  history: propTypes.object,
};
