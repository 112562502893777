import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useFormikContext } from "formik";

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(0, 1, 1, 3),
    display: "flex",
    flexDirection: "row",
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
  smallFont: {
    fontSize: "0.93rem",
  },
  rightItem: {
    width: "50%",
    padding: theme.spacing(2),
    overflowWrap: "break-word",
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
}));

export default function StandardCachePageReview(props) {
  const classes = useStyles();
  const { values } = useFormikContext();

  return (
    <React.Fragment>
      <List>
        {values.cachepage_stim_list.map((cache_page, index) => (
          <React.Fragment key={index}>
            <ListItem className={classes.listItem}>
              <ListItemText
                primary="Page URL"
                secondary={`Additional page ${index + 1}`}
              />
              <Typography
                align="right"
                className={classes.rightItem}
                variant="body2"
              >
                {cache_page.cachepage_url.toString().substring(0, 60)}...
              </Typography>
            </ListItem>
            {cache_page.stim_list.map((stim, index) => {
              if (stim.brand_id && stim.stim_url) {
                return (
                  <ListItem className={classes.listItem} key={index}>
                    <ListItemText
                      classes={{ primary: classes.smallFont }}
                      inset
                      primary={`${stim.brand_id.name} ad URL ${index + 1}`}
                      secondary={`${stim.ad_type_id.description}`}
                    />
                    <Typography
                      className={classes.rightItem}
                      align="right"
                      variant="body2"
                    >
                      {stim.stim_url.toString().substring(0, 40)}...
                    </Typography>
                  </ListItem>
                );
              } else {
                return <React.Fragment key = {index}></React.Fragment>;
              }
            })}
          </React.Fragment>
        ))}
      </List>
    </React.Fragment>
  );
}
