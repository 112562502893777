import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from "@material-ui/core/Tooltip"




const StyledSwitch = withStyles((theme) => ({
    // switch: {
    //     margin:" 0px 8px",
    //     boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    //   },

    //   switchBase: {
    //     padding:"3px !important",
    //     '&$unChecked': {
    //         color: "#80deea",
           
    //       },

    //     '&$checked': {
    //       color: "#80deea",
    //     },
    //     '&$checked + $track': {
    //       backgroundColor: "#00bcd4",
    //     },
    //   },
    root: {
        width: 44,
        height: 20,
        padding: 0,
        display: 'flex',
      },
      switchBase: {
        padding: 1,
        color: theme.palette.grey[500],
        '&$checked': {
          transform: 'translateX(24px)',
          color: theme.palette.common.white,
          '& + $track': {
            opacity: 1,
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
          },
        },
      },
      thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
      },
      track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 20 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
      },
      checked: {},
  }))(Switch);

export default function MoreInfoSwitch(props) {
  const [checked, setChecked] = React.useState(false);


  const toggleChecked = () => {
    props.toggle(!checked)
        setChecked((prev) => !prev);
  };

  return (
    <Tooltip placement="right-start" title="More info" >
    <FormGroup>
      <FormControlLabel   style={{margin:"0px 8px"}}
        control={<StyledSwitch  color="primary" checkedIcon={<InfoIcon  style={{ fontSize: 18, margin:0, padding: 0, }} />} icon={<InfoIcon style={{ fontSize: 18, margin:0, padding: 0, }}/>}  checked={checked} onChange={toggleChecked} />}
      />
    </FormGroup>
    </Tooltip>
  );
}