export const useDefaultValues = ({
  environmentData,
  languageTypes,
  locationTypes,
  respondentTypes,
}) => {
  if (!environmentData || !languageTypes || !locationTypes || !respondentTypes) return null;

  const basicDetailsDefaultValues = {
    environment_id: environmentData.data.find((environment) => environment.code === 1),
    name: '',
    language: languageTypes.data.find(({code}) => code === 'en'),
    project_location: locationTypes.data.find(({code}) => code === 'gb'),
    number_of_cells: 1,
    panel_size: 100,
    respondents: respondentTypes.data.find(({id}) => id === 1),
    media_id: {code: 0, description: 'None'},
  };

  const questionDetailsDefaultValues = {
    demographic_survey_or_lumen_questions: '0',
    demographic_survey_url: '',
    demographic_survey_insert_parameter: '',
    pre_assign_question_group: '',
    include_external_survey: '0',
    external_survey_step_url: '',
    external_survey_step_insert_parameter: '',
  };

  const panelDetailsDefaultValues = {
    include_panel_details: '0',
    panel_code: '',
    panel_success: '',
    panel_screen: '',
    panel_quotafull: '',
    panel_insert_param: '',
  };

  const advancedSettingsDefaultValues = {
    notes: '',
    objective: '',
    brief: '',
    methodology: '',
    study_duration: '',
    decision_to_inform: '',
    incentive: '',
    proposal_signoff_date: null,
    proposed_data_collection_start: null,
    proposed_data_collection_end: null,
    debrief_date: null,
  };

  return {
    ...basicDetailsDefaultValues,
    ...questionDetailsDefaultValues,
    ...panelDetailsDefaultValues,
    ...advancedSettingsDefaultValues,
  };
};
