import { useField } from 'formik';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import FormHelperText from '@material-ui/core/FormHelperText';
import propTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(0, 5)
  },
  margin: {
    height: theme.spacing(3),
  },
}));

export default function MaterialSlider(props) {
  const classes = useStyles();
  const [field, meta, helpers] = useField(props.name);

  return (
    <div className={classes.root}>

      <Typography
        color={meta.touched && meta.error ? 'error' : 'textPrimary'}
        id='input-slider'
        gutterBottom
        style={{ display: 'flex', alignItems: 'center' }}
        component='span'
      >
        {props.label}: {meta.value ? meta.value : ''}
      </Typography>
      <Slider
        {...field}
        aria-labelledby={field.id}
        value={typeof meta.value === 'number' ? meta.value : 1}
        onBlur={(e) => helpers.setTouched(e)}
        onChange={(e, v) => helpers.setValue(v)}
        min={props.min}
        max={props.max}
        marks={props.marks}
        step={props.step}
      />
      {
        meta.touched && meta.error ? (
          <FormHelperText error >{meta.error}</FormHelperText>
        ) : null
      }
    </div>
  );
}

MaterialSlider.propTypes = {
  name: propTypes.string,
  label: propTypes.oneOfType([propTypes.string, propTypes.element]),
  min: propTypes.number,
  max: propTypes.number,
  step: propTypes.number,
  marks: propTypes.array,
};
