import React from 'react'
import BatchKpi from './BatchKpiContainer';
import BatchFeature from './../BatchFeature/BatchFeatureContainer';



export default function BatchKpiAndFeature(props) {

    return (
        <div style={{ padding:24}}>
                <BatchKpi />
                <BatchFeature />
        </div>
    )
}


