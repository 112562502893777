import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import LoadingSpinner from '../../common/components/LoadingSpinner';
import Typography from "@material-ui/core/Typography"


export default function CommandType(props) {
  const [value, setValue] = React.useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  if (props.loading || !props.data[0]) {
    return <LoadingSpinner  style={{margin:"80px auto", position: "relative", top: "unset"}} />
}

if (!props.data[0].name) {
  return <LoadingSpinner  style={{margin:"80px auto", position: "relative", top: "unset"}} />
}
  return (
    <>
    <div style={{padding:"16px 0px", width:"100%"}}>
    <Typography variant="subtitle1">Command step</Typography>
    <Typography variant="caption" color="textSecondary">A command step is a step unseen by the respondent, that can trigger a calibration, recalibration, start or stop the camera</Typography>
    </div>
    <div style={{ margin: "24px auto", display: "grid" }}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Command step type</FormLabel>
        <RadioGroup
          aria-label="command-step"
          name="controlled-radio-buttons-group"
          value={parseInt(value)}
          onChange={handleChange}
        >
          {props.data.map(commandType => {
            return <FormControlLabel value={parseInt(commandType.id)} control={<Radio />} label={commandType.name} />
          })}
          {/* <FormControlLabel value={1}  control={<Radio />} label="Calibration" />
        <FormControlLabel value={2}  control={<Radio />} label="Recalibration" />
           <FormControlLabel value={3} control={<Radio />} label="Start camera" />
           <FormControlLabel value={4} control={<Radio />} label="Stop camera" /> */}

        </RadioGroup>
      </FormControl>
      <Button style={{ marginTop: 16 }} color="info" disabled={!value} onClick={() => props.onClick(value)} variant="contained">Add step</Button>
    </div>
    </>
  );
}