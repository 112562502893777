import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AddStepModal from './components/AddStepModal';
import EditDeleteModal from './components/EditDeleteModal';
import AudienceModal from './components/AudienceModal';
import Confirmation from './components/DeleteConfirmationModal';
import FilesModal from './components/FilesModal';
import EditStudyModal from './components/EditStudyModal';

const MODAL_COMPONENTS = {
  'ADD_STEP': AddStepModal,
  'EDIT_DELETE_MODAL': EditDeleteModal,
  'CREATE_AUDIENCE': AudienceModal,
  'EDIT_AUDIENCE': AudienceModal,
  'DELETE_CONFIRMATION': Confirmation,
  'FILES_MODAL': FilesModal,
  'EDIT_STUDY': EditStudyModal,
};

const ModalRoot = ({ modalType, modalProps }) => {
  if (!modalType) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[modalType];

  return <SpecificModal {...modalProps} />;
};

ModalRoot.propTypes = {
  modalType: PropTypes.string,
  modalProps: PropTypes.object,
};

export default connect(
  state => state.modal
)(ModalRoot);
