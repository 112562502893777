import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { questionAnswerOperations } from "../duck/index";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "fit-content",
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
}));

function QuestionsMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();

    useEffect(() => {
     if (Object.keys(props.arrayOfQuestions).length > 0) {
         if (props.arrayOfQuestions["Screening questions"].length > 0) {
            props.setNewQuestionSelected(props.arrayOfQuestions["Screening questions"][0]);
            props.getQuestionData(props.isolationData.data.study_id, props.arrayOfQuestions["Screening questions"][0].question_system, props.arrayOfQuestions["Screening questions"][0].question_id, props.limit_to_eyetracking);
         }
         else if (props.arrayOfQuestions["Study questions"].length > 0) {
            props.setNewQuestionSelected(props.arrayOfQuestions["Study questions"][0]);
            props.getQuestionData(props.isolationData.data.study_id, props.arrayOfQuestions["Study questions"][0].question_system, props.arrayOfQuestions["Study questions"][0].question_id, props.limit_to_eyetracking);
         }
         else {
            props.getQuestionDataError() 
         }
     }
     else {
         props.getQuestionDataError()
     }
       
    }, [])

    useEffect(() => {
        if (props.currentQuestionSelected) {
        props.setNewQuestionSelected(props.currentQuestionSelected);
        props.getQuestionData(props.isolationData.data.study_id, props.currentQuestionSelected.question_system, props.currentQuestionSelected.question_id, props.limit_to_eyetracking);
          
        }
       }, [props.limit_to_eyetracking])



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleItemSelected =(question) => {
        props.setNewQuestionSelected(question);
        props.getQuestionData(props.isolationData.data.study_id, question.question_system, question.question_id, props.limit_to_eyetracking);
        setAnchorEl(null);
    };

    return (
        <div>
            {!props.questionAnswerResultsError && !props.questionAnswerResultsLoading  ? 
            <div style={{ padding: "16px 24px 0px", textAlign: "right" }}>


                <Button endIcon={<KeyboardArrowDownRoundedIcon />} variant="outlined" size="small" style={{ padding: "3px 9px", fontSize: "0.8125rem", color: "#3d4977", border: "1px solid rgba(61, 73, 119, 0.5)", lineHeight: 1.75, fontWeight: "normal", borderRadius: "0.5rem", letterSpacing: "0.02857em", textTransform: "unset", whiteSpace: "nowrap" }} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    Select question
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <List className={classes.root} subheader={<li />}>
                        {Object.keys(props.arrayOfQuestions).map((questionSetKey) => (
                            <li key={`section-${questionSetKey}`} className={classes.listSection}>
                                <ul className={classes.ul}>
                                    <ListSubheader>{questionSetKey}</ListSubheader>
                                    {props.arrayOfQuestions[questionSetKey].map((item, index) => (
                                        <MenuItem selected={item.name === props.currentQuestionSelected.name} onClick={() => handleItemSelected(item, questionSetKey)} key={`item-${questionSetKey}-${item.name}-${index}`}>
                                            <ListItemText inset primary={`${item.formatted_type}: ${item.name}`} />
                                        </MenuItem>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </List>
                </Menu>

            </div>
                : ""}
        </div>
    );
}


function swapQuestionCodeForName(code) {
    if (code === "ex_preassign_questions" || code ===  "lu_preassign_questions") {
        return "Screening questions"
    }
    else if (code === "ex_questions"  || code ===  "lu_questions") {
        return "Study questions"
    }
};

function makeArrayOfQuestions(rawOverview) {
    let arrayOfQuestions = {
        "Screening questions": [],
        "Study questions": []
    }

    if (rawOverview) {
        Object.keys(rawOverview).forEach(questionSetKey => {
            if (rawOverview[questionSetKey].length > 0) {
                arrayOfQuestions[swapQuestionCodeForName(questionSetKey)] = arrayOfQuestions[swapQuestionCodeForName(questionSetKey)].concat(rawOverview[questionSetKey]);
            }
        })
        Object.keys(arrayOfQuestions).forEach(screeningOrStudy => {
            arrayOfQuestions[screeningOrStudy].forEach((question, index) => {
                arrayOfQuestions[screeningOrStudy][index].name = question.name.replace(/(<([^>]+)>)/gi, "")
            })
        })
    }
    return arrayOfQuestions
}

const mapStateToProps = (state) => {
    return {
        questionAnswerOverview: state.questionAnswerData.questionAnswerOverview,
        questionAnswerOverviewLoading: state.questionAnswerData.questionAnswerOverviewLoading,
        arrayOfQuestions: makeArrayOfQuestions(state.questionAnswerData.questionAnswerOverview),
        currentQuestionSelected: state.questionAnswerData.currentQuestionSelected,
        questionAnswerResultsError: state.questionAnswerData.questionAnswerResultsError,
        questionAnswerResultsLoading: state.questionAnswerData.questionAnswerResultsLoading,
        isolationData: state.isolationData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchQuestionAndAnswersOverview: () => dispatch(questionAnswerOperations.fetchQuestionAndAnswersOverview()),
        setNewQuestionSelected: (newQuestion) => dispatch(questionAnswerOperations.setNewQuestionSelected(newQuestion)),
        getQuestionData: (studyID, questionSetCode, questionID, limitToEyetracking) => dispatch(questionAnswerOperations.getQuestionData(studyID, questionSetCode, questionID, limitToEyetracking)),
        getQuestionDataError: () => dispatch(questionAnswerOperations.getQuestionDataError())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(QuestionsMenu)
);