import React, { useState, useEffect } from 'react'
import IconButton from '@mui/material/IconButton';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Tooltip from "@material-ui/core/Tooltip"


// handleSortChange = {handleSortChange} currentOrderBy={currentOrderBy} name={item.name}

export default function TableSorter(props) {
    const [thisColumnStateIfClicked, setthisColumnStateIfClicked] = useState("name_asc")
    const [thisColumnState, setthisColumnState] = useState(null)
    const [noSort, setnoSort] = useState(false)

    useEffect(() => {
        updateColumnState(props.name, props.currentOrderBy)
    }, [props.currentOrderBy])

    const updateColumnState = (thisColumnName, columnCurrentlySelected) => {
        switch (thisColumnName) {
            case 'Name':
                if (columnCurrentlySelected === "name_asc") {
                    setthisColumnState("asc")
                    setthisColumnStateIfClicked("name_desc")
                }
                else if (columnCurrentlySelected === "name_desc") {
                    setthisColumnState("desc")
                    setthisColumnStateIfClicked("name_asc")
                }
                else {
                    setthisColumnState(null)
                    setthisColumnStateIfClicked("name_asc")
                }

                break;
        case 'Identifier':
            if (columnCurrentlySelected === "identifier_asc") {
                setthisColumnState("asc")
                setthisColumnStateIfClicked("identifier_desc")
            }
            else if (columnCurrentlySelected === "identifier_desc") {
                setthisColumnState("desc")
                setthisColumnStateIfClicked("identifier_asc")
            }
            else {
                setthisColumnState(null)
                setthisColumnStateIfClicked("identifier_asc")
            }

            break;
            case 'Media':
                if (columnCurrentlySelected === "media_asc") {
                    setthisColumnState("asc")
                    setthisColumnStateIfClicked("media_desc")
                }
                else if (columnCurrentlySelected === "media_desc") {
                    setthisColumnState("desc")
                    setthisColumnStateIfClicked("media_asc")
                }
                else {
                    setthisColumnState(null)
                    setthisColumnStateIfClicked("media_asc")
                }
                break;
            case 'Brand':
                if (columnCurrentlySelected === "brand_asc") {
                    setthisColumnState("asc")
                    setthisColumnStateIfClicked("brand_desc")
                }
                else if (columnCurrentlySelected === "brand_desc") {
                    setthisColumnState("desc")
                    setthisColumnStateIfClicked("brand_asc")
                }
                else {
                    setthisColumnState(null)
                    setthisColumnStateIfClicked("brand_asc")
                }
                break;
            case 'Id':
                if (columnCurrentlySelected === "id_asc") {
                    setthisColumnState("asc")
                    setthisColumnStateIfClicked("id_desc")
                }
                else if (columnCurrentlySelected === "id_desc") {
                    setthisColumnState("desc")
                    setthisColumnStateIfClicked("id_asc")
                }
                else {
                    setthisColumnState(null)
                    setthisColumnStateIfClicked("id_asc")
                }
                break;
        case 'Environment':
            if (columnCurrentlySelected === "environment_asc") {
                setthisColumnState("asc")
                setthisColumnStateIfClicked("environment_desc")
            }
            else if (columnCurrentlySelected === "environment_desc") {
                setthisColumnState("desc")
                setthisColumnStateIfClicked("environment_asc")
            }
            else {
                setthisColumnState(null)
                setthisColumnStateIfClicked("environment_asc")
            }
            break;

        case 'Batch Id':
            if (columnCurrentlySelected === "id_asc") {
                setthisColumnState("asc")
                setthisColumnStateIfClicked("id_desc")
            }
            else if (columnCurrentlySelected === "id_desc") {
                setthisColumnState("desc")
                setthisColumnStateIfClicked("id_asc")
            }
            else {
                setthisColumnState(null)
                setthisColumnStateIfClicked("id_asc")
            }
            break;
            default:
                setnoSort(true)
        }
    }

    return (


        <div className="show-hide-sort-arrows-parent" style={{ position: "relative", display: thisColumnState ? "inline" : "" }}>
            {noSort ? "" :
                <div style={{ position: "absolute", left: "2px", top:0, bottom:0, transform: "translateY(-45%)" }}>
                    <Tooltip placement="right" title={`  ${thisColumnState === "asc" ? "Sorted in ascending order" : thisColumnState === "desc" ? "Sorted in descending order" : `Sort by ${props.name}`} `}  >
                        <IconButton onClick={() => props.handleSortChange(thisColumnStateIfClicked)} aria-label="delete" size="small">
                            {thisColumnState ==="asc" ? <ArrowUpwardIcon fontSize="small" /> : thisColumnState ? <ArrowDownwardIcon fontSize="small" /> : <ArrowUpwardIcon fontSize="small" />}
                        </IconButton>
                    </Tooltip>
                </div>
            }
        </div>
    )
}




