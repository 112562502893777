import React from "react";

import styles from "./pinterest.module.css";

const FeedItem = ({ height, marginTop }) => {
  return (
    <div className={styles.FeedItem} style={{ height: `${height}%` }}>
      <div
        className={styles.FeedItemImage}
        style={{
          marginTop: `${marginTop ? marginTop : 0}px`,
        }}
      />
      <div className={styles.FeedItemText} />
    </div>
  );
};

export default FeedItem;
