import React from 'react';
import PropTypes from 'prop-types';

import CustomStudyStepper from './CustomStudyStepper';
import CreateStudyContainer from '../CreateStudyContainer';

const CustomStudyContainer = ({history}) => {
  return (
    <CreateStudyContainer>
      <CustomStudyStepper {...{history}} />
    </CreateStudyContainer>
  );
};

CustomStudyContainer.propTypes = {
  history: PropTypes.object,
};

export default CustomStudyContainer;
