import React from 'react';
import { Field, useFormikContext } from 'formik';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from 'formik-material-ui';
import { DateTimePicker } from 'formik-material-ui-pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '2.125rem',
  },
}));

export default function AdvancedSettings() {
  const { values, setFieldValue } = useFormikContext();
  const classes = useStyles();

  return (
    <>
      <Typography variant='h2' className={classes.title} gutterBottom>
        Advanced Settings
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant='subtitle2' color='textSecondary' >
            Please tell us more about your project
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Field
            name='notes'
            component={TextField}
            label='Project notes'
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name='objective'
            component={TextField}
            label='Objective'
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name='brief'
            component={TextField}
            label='Brief'
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name='methodology'
            component={TextField}
            label='Methodology'
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name='study_duration'
            component={TextField}
            label='Study duration'
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name='decision_to_inform'
            component={TextField}
            label='Decision to inform'
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name='incentive'
            component={TextField}
            label='Incentive'
          />
        </Grid>
        <Grid item xs={12}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Field
              value={
                values.proposal_signoff_date === null
                  ? null
                  : new Date(values.proposal_signoff_date)
              }
              onChange={(time) => {
                if (typeof time === 'object') {
                  setFieldValue(
                    'proposal_signoff_date',
                    time.format('YYYY-MM-DD HH:mm:ss')
                  );
                }
              }}
              component={DateTimePicker}
              label='Proposal sign-off date'
              name='proposal_signoff_date' />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Field
              value={
                values.proposed_data_collection_start === null
                  ? null
                  : new Date(values.proposed_data_collection_start)
              }
              onChange={(time) => {
                if (typeof time === 'object') {
                  setFieldValue(
                    'proposed_data_collection_start',
                    time.format('YYYY-MM-DD HH:mm:ss')
                  );
                }
              }}
              component={DateTimePicker}
              label='Proposed data collection start'
              name='proposed_data_collection_start' />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Field
              value={
                values.proposed_data_collection_end === null
                  ? null
                  : new Date(values.proposed_data_collection_end)
              }
              onChange={(time) => {
                if (typeof time === 'object') {
                  setFieldValue(
                    'proposed_data_collection_end',
                    time.format('YYYY-MM-DD HH:mm:ss')
                  );
                }
              }}
              component={DateTimePicker}
              label='Proposed data collection end'
              name='proposed_data_collection_end' />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Field
              value={
                values.debrief_date === null
                  ? null
                  : new Date(values.debrief_date)
              }
              onChange={(time) => {
                if (typeof time === 'object') {
                  setFieldValue(
                    'debrief_date',
                    time.format('YYYY-MM-DD HH:mm:ss')
                  );
                }
              }}
              component={DateTimePicker}
              label='Debrief date'
              name='debrief_date' />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>

    </>
  );
}
