import React from "react";
import Chart from "react-apexcharts";

export default class MultipleAttentionCurves extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: this.returnSeriesData(),

      options: {
        theme: {
          palette: "palette2",
        },
        tooltip: {
          shared:true,
          y: this.props.graphData.data.current_graph_type_id === 1 ? {
            formatter: function(value) {
              return `${value}% still viewing`;
            },
          }: 
          this.props.graphData.data.current_graph_type_id === 2 ?
          {
            formatter: function(value) {
              return `${value}% still viewing`;
            },
          }:
          {
            formatter: function(value) {
              return `${value}% could still view`;
            },
          },
          x: this.props.graphData.data.current_graph_type_id === 1 ? {
            formatter: function(value) {
              return `${value} seconds dwell time`;
            },
          }: 
          this.props.graphData.data.current_graph_type_id === 2 ?
          {
            formatter: function(value) {
              return `${value} seconds page dwell time`;
            },
          }:
          {
            formatter: function(value) {
              return `${value} seconds viewable time`;
            },
          },
        },
        chart: {
          type: "area",

          zoom: {
            enabled: true,
          },
          toolbar:{
            tools:{
              download: false,
            }
          }
        },
        xaxis: {
          type: "numeric",
          min: 0,
          max: this.props.graphData.data.current_graph_type_id === 1 ? 5 : this.props.graphData.data.current_graph_type_id ===2 ? 60 : 15,
          labels: {
            // tickAmount: 10,
          
            // forceNiceScale: true,
          },

          // labels: {
          //   formatter: function(value) {
          //     return `${Math.round(value)}`;
          //   },
          // },
          tooltip: {
            enabled: false,
          },
          title: {
            text: this.props.graphData.data.current_graph_type_id === 3 ? "Ad viewable time (s)" : this.props.graphData.data.current_graph_type_id === 2 ? "Page dwell time (s)" : "Ad dwell time (s)",
            style: {
              fontWeight: 500,
              fontFamily: "Roboto",
              color: "rgba(0, 0, 0, 0.54)",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },

        yaxis: {
          labels: {
            formatter: function(value) {
              return `${value}%`;
            },
          },
          // forceNiceScale: true,
          title: {
            text: this.props.graphData.data.current_graph_type_id === 3 ? "% of people with ad on screen" : this.props.graphData.data.current_graph_type_id === 2 ? "% of people viewing page" : "% of people viewing ad",
            style: {
              fontWeight: 500,
              fontFamily: "Roboto",
              color: "rgba(0, 0, 0, 0.54)",
            },
            offsetX: 8,
          },
        },
        legend: {
          horizontalAlign: "left",
        },
      },
    };
  }

  returnSeriesData() {
    let AllStims = JSON.parse(JSON.stringify(this.props.graphData.data.data));
    let seriesStructure = [
      {
        data: [],
        name: this.props.stimName,
      },
    ];
    AllStims.forEach((stim, index) => {
      let graphData = AllStims[index].graph_data;
      let newGraphData = [];
      for (let i = 0; i < graphData.length; i = i + 1) {
        graphData[i].y = Math.round(graphData[i].y / 100) / 10;
        graphData[i].x = Math.round(graphData[i].x);
        let yval = graphData[i].y;
        graphData[i].y = graphData[i].x;
        graphData[i].x = yval;
        newGraphData.push(graphData[i]);
      }

      seriesStructure[index] = {
        data: newGraphData,
        name: AllStims[index].stim_name,
      };
    });
    return seriesStructure;
  }

  render() {
    return (
      <div id="chart" style={{ width: "100%", height: 330, left: -8, top: -8 }}>
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="line"
          height={330}
        />
      </div>
    );
  }
}
