import React, { Component } from 'react';
import Chart from 'react-apexcharts'

class PieChart extends Component {

  constructor(props) {
    super(props);

    this.state = {
      options: {
          labels: [...props.labels,  "Other"]
        },
      series: [...props.data, 0],
    }
  }

  render() {

    return (
      <div className="donut">
        <Chart options={this.state.options} series={this.state.series} type="donut" width="100%" />
      </div>
    );
  }
}

export default PieChart;