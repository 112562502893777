import { Typography } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import React from "react";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
    maxWidth:"450px"
  },
  widePaper: {
    padding: theme.spacing(1),
    width:"60vw",
    position: "absolute",
    top: "50% !important",
    left: "50% !important",
    transform: "translate(-50%, -50%) !important"

  },
  margin: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(7),
  },
  relativeContainer: {
    position: "relative",
    width: 0,
    height: 0,
  },
  absolutePosition: {
    position: "absolute",
    left: 8,
    top: -100,
  },
  moreInfo: {
    alignSelf: "center",
    position: "relative",
    left:"8px"
  },
  alignToTop: {
    position: "relative",
    left:"8px"
  },
  cacheSetup:{
    position: "relative",
  },
  createCustomStudy:{
    position: "relative",
    right: theme.spacing(3),
    top: theme.spacing(0.5),
  },
  upALittleToTheRight: {
    position: "relative",
    left:"4px",
    bottom:"10px"
  },
  createStudy: {
    position: "absolute",
    left: 10,
    top: 10,
  },
}));

export default function MoreInfo(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };


  const open = Boolean(anchorEl);
  return (
    <div className={props.outerContainerClassName ?  classes[props.outerContainerClassName] : ""} >
      <div className={props.middleContainerClassName ? classes[props.middleContainerClassName] : ""}>
        <div className={props.innerContainerClassName ? classes[props.innerContainerClassName] : ""}>
      <InfoOutlinedIcon
        color="primary"
        fontSize="small"
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      </div>
      </div>

      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: props.widePaper ? classes.widePaper : classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: !props.leftRight ? "right" : props.leftRight ==="right"? "left": "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: props.leftRight ? props.leftRight : "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >{
        props.popupArray ? props.popupArray.map((text,index) => {
          return     <Typography key={index} paragraph >{text}</Typography>
        })
        : ""
      } 
        <span>{props.popup}</span>
      </Popover>
    </div>
  );
}
