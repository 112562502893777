import React, { Component } from 'react';
import StimsTableContainer from './containers/StimsTableContainer';
import { connect } from 'react-redux';
import { stimsOperations } from './duck/index';
import LoadingSpinner from '../common/components/LoadingSpinner';
import DropDown from '../EditStudyDetails/components/DropDown';
import { addAllType } from '../Dashboard/DashboardContainer';
import ToolTip from '../common/components/Tooltip';
import Paper from "@material-ui/core/Paper"
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import Tooltip from "@material-ui/core/Tooltip"
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
// import TablePagination from '@mui/material/TablePagination';
import TablePagination from "../common/components/Pagination"
import PrettyDropdown2 from "../common/components/PrettyDropDown2"


class Stims extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilter: false,
      stimsPerPage: 25,
      pageNum: 1,
      orderBy:"name_asc",
      filterName: "",
      filterMediaId: 0,
      filterStimType: ""
    }
    this.myTimer = React.createRef(null)
  }

  componentDidMount() {
    this.props.getStims(this.state.stimsPerPage, this.state.pageNum, this.state.orderBy, this.state.filterName, this.state.filterMediaId, this.state.filterStimType);
  }

  componentWillUnmount() {
    this.props.fetchStimsBegin()
  }

  // onChange = (e) => {
  //   const { setStimFilter } = this.props
  //   const target = e.target.value
  //   setStimFilter(target, 'search')
  // }

  // onSelectItem = (item, type) => {
  //   const { setStimFilter } = this.props
  //   setStimFilter(item, type)
  // }
  handlefilterMediaChange = (filterMediaId) => {
    console.log(filterMediaId)
    this.setState({filterMediaId: filterMediaId})
    this.props.getStims(parseInt(this.state.stimsPerPage), 1, this.state.orderBy, this.state.filterName, filterMediaId, this.state.filterStimType);
  }

  handlefilterStimTypeChange = (filterStimType) => {
    console.log(filterStimType)
    this.setState({filterStimType: filterStimType})
    this.props.getStims(parseInt(this.state.stimsPerPage), 1, this.state.orderBy, this.state.filterName, this.state.filterMediaId, filterStimType);
  }

  handlepageNumChange = (event, newPage) => {
  if (newPage) {
    this.setState({pageNum: newPage})
    this.props.getStims(parseInt(this.state.stimsPerPage), parseInt(newPage), this.state.orderBy, this.state.filterName, this.state.filterMediaId, this.state.filterStimType);
  }
  }

  handleStimsPerPageChange = (event) => {
    this.setState({pageNum: 1})
    this.setState({stimsPerPage: event.target.value})
    this.props.getStims(event.target.value, 1, this.state.orderBy, this.state.filterName, this.state.filterMediaId, this.state.filterStimType);
  }

 
  handleSortChange = (orderBy) => {
    this.setState({orderBy: orderBy})
    this.props.getStims(parseInt(this.state.stimsPerPage), parseInt(this.state.pageNum), orderBy, this.state.filterName, this.state.filterMediaId, this.state.filterStimType);
  }

  handleSearchChange = (filterName) => {
    this.setState({filterName: filterName})
    clearTimeout(this.myTimer)
    this.myTimer = setTimeout(() => {
      this.props.getStims(parseInt(this.state.stimsPerPage), 1, this.state.orderBy, filterName, this.state.filterMediaId, this.state.filterStimType);
    }, 500);
  }

  render() {
    const { loading, mediaTypes, stimTypes } = this.props;
  
    return (
      <Paper  style={{ borderRadius:16,}}>
        <div style={{display:"flex", justifyContent: "space-between", padding: "16px", alignItems: "center" }}>
        <TextField
            style={{zIndex:0}}
      
            autoFocus
            onChange={(e) => this.handleSearchChange(e.target.value)}
            value={this.state.filterName}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            id="outlined-basic" label="Search my stims..." variant="outlined" />
            <div>
        <Tooltip placement="bottom" title="Filter stims">
                <IconButton  onClick ={() => {this.setState({showFilter: !this.state.showFilter})}}  aria-label="filter results" >
                    <FilterListIcon style={{color: "rgb(99, 115, 129)"}} />
                  </IconButton>
                  </Tooltip>
                  </div>
        </div>
        { loading? <LoadingSpinner style={{padding: "25vh", margin: "auto"}} /> :
         <>
           {this.state.showFilter ?  <div  style={{padding:8, borderBottom: "none", borderRadius:16, }} className="dashboard__filter-container">
          <div style={{padding:8}} className="dashboard__filter-container--item">
            <ToolTip content="Filter by media channel." />
            <label>Media</label>
            <PrettyDropdown2 options={mediaTypes}  value={this.state.filterMediaId}  onSelectItem={this.handlefilterMediaChange} />
          </div>
          <div style={{padding:8}} className="dashboard__filter-container--item">
            <ToolTip content="Filter by stim types." />
            <label>Stim Types</label>
            <DropDown className="left" value={this.state.filterStimType ? this.state.filterStimType : "Select"} onSelectItem={(e) => this.handlefilterStimTypeChange(e)} options={stimTypes.map((item => item.description))} />
          </div>
        </div>
         : ""}
            <div style={{ marginRight: 8}}>
          <TablePagination   rowsPerPage={this.state.stimsPerPage}  onRowsPerPageChange={this.handleStimsPerPageChange} numberOfPages={this.props.stims.total_page_num} currentPage={this.props.stims.page_num } onPageChange={this.handlepageNumChange}  />
        </div>
        <div className="stims__table-container">
          <StimsTableContainer currentOrderBy={this.state.orderBy} handleSortChange={this.handleSortChange}  />
        </div>
        <div style={{ marginRight: 8}}>
        <TablePagination   rowsPerPage={this.state.stimsPerPage}  onRowsPerPageChange={this.handleStimsPerPageChange} numberOfPages={this.props.stims.total_page_num} currentPage={this.props.stims.page_num } onPageChange={this.handlepageNumChange}  />
        </div>
        </>
        }
  
      </Paper>
    );
  }
}

function mapStateToProps(state) {
  return {
    environmentTypes: state.stimsReducer.environmentTypes,
    mediaTypes: addAllType(state.stimsReducer.mediaTypes),
    stimTypes: addAllType(state.stimsReducer.stimTypes),
    languageTypes: state.stimsReducer.languageTypes,
    loading: state.stimsReducer.loading,
    stims: state.stimsReducer.stims,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getStims: (stimsPerPage, pageNum, orderBy, filterName, filterMediaId, filterStimType) => dispatch(stimsOperations.getStimsData(stimsPerPage, pageNum, orderBy, filterName, filterMediaId, filterStimType)),
    setStimFilter: (filter, currentSort) => dispatch(stimsOperations.setStimFilter(filter, currentSort)),
    setMediaFilter: (filter) => dispatch(stimsOperations.setStimFilter(filter)),
    onAscendingOrDecendingButtonClick: (item, type) => dispatch(stimsOperations.sortAscendingData(item, type)),
    fetchStimsBegin: () => dispatch(stimsOperations.fetchStimsBegin()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Stims);
