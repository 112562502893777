import React, { useEffect, useState } from 'react';
import Aside from '../containers/Aside/Aside';
import logo from '../../assets/images/WhiteLogo.png';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Breadcrumbs from '../common/components/Breadcrumb';
import { withStyles } from '@material-ui/core/styles';
import AccountMenu from './AccountMenu';
import PropTypes from 'prop-types';
import HttpService from '../Dashboard/duck/HttpService';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { userOperations } from '../MyDetails/duck';

const styles = {
  mainHeader: {
    zIndex:1, position: 'fixed', borderBottom: 'unset', width:'100%', height: 64, backgroundColor:'#00badb', marginBottom:16, boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
  },
};

const Header = ({ classes, authenticated, companyId, getUserData, location }) => {
  const isSuperUser = localStorage.getItem('superUser');
  const [companies, setCompanies] = useState();
  const [selectedCompany, setSelectedCompany] = useState();
  const locationLength = location.pathname.split('/').length;

  useEffect(() => {
    if (!isSuperUser) return;

    const initialiseData = async () => {
      getUserData();
      const companies = await HttpService.getCompaniesList();
      setCompanies(companies.data);
    };

    initialiseData();
  }, [isSuperUser]);

  useEffect(() => {
    if (!companies || !companyId) return;

    const companyInternalId = localStorage.getItem('companyInternalId');
    const hasCompanyInternalId = !!companyInternalId && companyInternalId !== 'null';
    if (hasCompanyInternalId) {
      const selectedCompany = companies.find(({internal_id}) => internal_id === companyInternalId);
      setSelectedCompany(selectedCompany);
    } else {
      const selectedCompany = companies.find(({id}) => id === companyId);
      setSelectedCompany(selectedCompany);
    }
  }, [companies, companyId]);

  const onCompanyChange = (company) => {
    setSelectedCompany(company);
    const isDefaultCompany = companyId === company.id;
    const companyInternalId = isDefaultCompany ? null : company.internal_id;
    localStorage.setItem('companyInternalId', companyInternalId);
    window.location.reload();
  };

  return (
    <header className={classes.mainHeader}>
      <Breadcrumbs/>
      <img alt='Lumen logo' style={{position:'absolute', zIndex:4, marginLeft: 'auto', marginRight: 'auto', left: 0, right: 0, top: 20, width: 120}} src={logo} />

      {companies && isSuperUser && selectedCompany && (
        <TextField
          id='company'
          name='company'
          hiddenLabel
          variant='filled'
          select
          size='small'
          style={{
            backgroundColor: '#FFFFFF',
            position: 'fixed',
            zIndex:4,
            right: 360,
            top: 14,
            width: 240,
          }}
          value={selectedCompany}
          onChange={(e) => onCompanyChange(e.target.value)}
          disabled={locationLength > 2}
        >
          {companies.map((option, index) => {
            return <MenuItem key={index} value={option}>{option.name}</MenuItem>;
          })}
        </TextField>
      )}
      {authenticated ? <>   <AccountMenu /> <Aside /> </> : ''}
    </header>
  );
};

const mapDispatchToProps = dispatch => ({
  getUserData: () => dispatch(userOperations.getUserData()),
});

const mapStateToProps = (state) => {
  return {
    authenticated: state.userData.authenticated,
    companyId: state.userData.userData.company_id,
  };
};

Header.propTypes = {
  classes: PropTypes.object,
  authenticated: PropTypes.bool,
  companyId: PropTypes.number,
  getUserData: PropTypes.func,
  location: PropTypes.object,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Header)));
