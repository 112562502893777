import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import ButtonBase from '@material-ui/core/ButtonBase';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';


export default function MoreMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [title, setTitle] = React.useState(props.initialTitle || "Select");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onMenuItemClick = (option) => {
    handleClose();
    props.onSelectItem(option);
    setTitle(option);
  };

  return (
    <div>
      <ButtonBase centerRipple  onClick={handleClick} ><div style={{display:"flex"}} ><Typography style={{width: "max-content"}} color="textSecondary" variant="subtitle1" >{title}  </Typography><ArrowDropDownIcon style={{color:"rgba(0, 0, 0, 0.54)"}}/></div></ButtonBase>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.largeContent
          ? props.options
            ? props.options.map((option, key) => (
                <MenuItem
                  selected={option === title}
                  onClick={() => onMenuItemClick(option)}
                  key={key}
                  tabIndex={key}
                  value={option.instruction_name}
                >{`${option.instruction_name}:${option.step_id}`}</MenuItem>
              ))
            : ""
          : props.options
          ? props.options.map((option, key) => (
              <MenuItem
                selected={option === title}
                onClick={() => onMenuItemClick(option)}
                key={key}
                tabIndex={key}
                value={option}
              >
                {option}
              </MenuItem>
            ))
          : ""}
      </Menu>
    </div>
  );
}
