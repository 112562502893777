import React from 'react';
import PropTypes from 'prop-types';

import DecipherStepper from './DecipherStepper';
import CreateStudyContainer from '../CreateStudyContainer';

const DecipherContainer = ({history}) => {
  return (
    <CreateStudyContainer>
      <DecipherStepper {...{history}} />
    </CreateStudyContainer>
  );
};

DecipherContainer.propTypes = {
  history: PropTypes.object,
};

export default DecipherContainer;
