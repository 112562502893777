import types from './types';

const isolationData = (state = {
  data: null,
  initialReportLoad: false,
  whichStudiesAreChecked: [],
  whichStudiesAreCheckedObjectFormat: [],
  loadingStudyData: true,
  checked: false,
  studiesOverview: null,
  studiesOverviewLoading: true,
  reportType: "",
  currentReportTypeStudyAndStims: "",
  StudySpecificStims: null,
  loadingStudySpecificStims: false,
  StudySpecificStimsError: false,
}, action) => {
  switch (action.type) {
    case types.INITIAL_LOAD_BEGIN: {
      return {
        ...state,
        initialReportLoad: true,
        error: null,
        currentReportTypeStudyAndStims: action.payload,
      };
    }
    case types.INITIAL_LOAD_FINISH: {
      return {
        ...state,
        initialReportLoad: false,
        error: false,
      };
    }
    case types.INITIAL_LOAD_RESET: {
      return {
        ...state,
        initialReportLoad: false,
        error: null,
      };
    }
    case types.INITIAL_LOAD_ERROR: {
      return {
        ...state,
        initialReportLoad: false,
        error: true,
        reportType: "",
      };
    }
    case types.ADD_COMPARE_STUDY: {
      return {
        ...state,
        whichStudiesAreChecked: [...state.whichStudiesAreChecked, action.id]
      };
    }
    case types.REMOVE_COMPARE_STUDY: {
      return {
        ...state,
        whichStudiesAreChecked: state.whichStudiesAreChecked.filter(item => item !== action.id)
      };
    }
    case types.ADD_STUDIES_STRING: {
      return {
        ...state,
        whichStudiesAreChecked: action.id
      };
    }
    case types.ADD_STUDIES_OBJECT: {
      return {
        ...state,
        whichStudiesAreCheckedObjectFormat: action.id
      };
    }
    case types.SET_REPORT_TYPE: {
      return {
        ...state,
        reportType: action.id
      };
    }
    case types.CLEAR_CHECKED_STUDIES: {
      return {
        ...state,
        whichStudiesAreChecked: [],
      };
    }
    case types.FETCH_STIM_BEGIN: {
      return {
        ...state,
        loadingStudyData: true,
        error: null
      };
    }
    case types.FETCH_STIM_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loadingStudyData: false
      };
    }
    case types.FETCH_STUDIES_OVERVIEW_SUCCESS: {
      return {
        ...state,
        studiesOverview: action.payload,
        studiesOverviewLoading: false
      };
    }
    case types.FETCH_STIM_ERROR: {
      return {
        ...state,
        loadingStudyData: false,
        error: action.payload.error,
        items: []
      };
    }
    case types.CHECK_ALL_IN_STEP: {
      return {
        ...state,
        checked: true
      };
    }
    case types.REMOVE_ALL_IN_STEP: {
      return {
        ...state,
        checked: false
      };
    }
    case types.FETCH_STUDY_SPECIFIC_STIMS_BEGIN: {
      return {
        ...state,
        loadingStudySpecificStims: true,
        StudySpecificStimsError: false
      };
    }
    case types.FETCH_STUDY_SPECIFIC_STIMS_SUCCESS: {
      return {
        ...state,
        StudySpecificStims: action.payload,
        loadingStudySpecificStims: false,
      };
    }
    case types.FETCH_STUDY_SPECIFIC_STIMS_ERROR: {
      return {
        ...state,
        loadingStudySpecificStims: false,
        StudySpecificStimsError: true
      };
    }







    default:
      return state;
  }
};

export default isolationData;


