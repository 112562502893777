import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useEffect, Fragment } from 'react';

import MoreInfo from '../MaterialComponents/MoreInfo';

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(3, 0),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  iconButton: {
    position: 'relative',
    right: theme.spacing(1),
  },
  webPageURL: {
    marginTop: '8px',
  },
}));

const ContextDetails = () => {
  const classes = useStyles();
  const {
    values: {
      number_of_cells,
      embedded_page_list,
    },
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    const listLength = embedded_page_list.length;
    if (listLength === number_of_cells) return;

    if (listLength > number_of_cells) {
      const embeddedPageList = embedded_page_list.filter((_item, index) => index < number_of_cells);
      setFieldValue('embedded_page_list', embeddedPageList);
    } else {
      const embeddedPageList = embedded_page_list.concat(Array.from(Array(number_of_cells - listLength))
        .map((_item, index) => ({ cell_index: index + listLength + 1, url: ''})));
      setFieldValue('embedded_page_list', embeddedPageList);
    }

  }, [number_of_cells]);

  return (
    <>
      <Grid
        alignContent='flex-end'
        container
        wrap='nowrap'
        justifyContent='space-between'
        item
        xs={12}
      >
        <Grid>
          <Typography variant='h6'>External Pages</Typography>
          <Typography variant='subtitle1'>
            Please enter a study URL for each cell.
          </Typography>
        </Grid>

        <MoreInfo
          outerContainerClassName='cacheSetup'
          popupArray={[
            'Study page URL: Web page you want the panelist to browse.'
          ]}
        />
      </Grid>

      {embedded_page_list.map((_, index) => (
        <Fragment key={index}>
          <Grid
            className={classes.webPageURL}
            container
            wrap='nowrap'
            item
            xs={12}
          >
            <Typography
              style={{ marginRight: '8px', marginTop:'13px'}}
              variant='body1'
            >{`${index + 1}.`}</Typography>
            <Field
              variant='filled'
              fullWidth
              name={`embedded_page_list.${index}.url`}
              component={TextField}
              label='Study page URL'
            />
          </Grid>
        </Fragment>
      ))}
    </>
  );
};

export default ContextDetails;
