import * as React from 'react';
import { default as ButtonMui } from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Formik, Form } from 'formik';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';

export default function EditDialogue({
  openModal,
  setOpenModal,
  stepData,
  onEditStepData,
  nextButtonOptions,
}) {
  const [open, setOpen] = React.useState(false);

  React.useEffect(
    () => {
      if (openModal) {
        setOpen(true);
      }
      return () => {
        setOpen(false);
      };
    },
    [openModal]
  );

  const handleClose = () => {
    setOpenModal(false);
    setOpen(false);
  };

  return (
    <div>
      <Formik
        initialValues={{
          showNext: stepData.seconds_before_show_next,
          moveNext: stepData.seconds_before_move_next,
          nextNext: stepData.next_type_id,
        }}
        onSubmit={async (values, actions, resetForm) => {
          onEditStepData(values.showNext, values.moveNext, values.nextNext);
          actions.setSubmitting(false);
          handleClose();
          resetForm();
        }}
      >
        {(formProps) => {
          return (
            <Dialog fullWidth maxWidth='sm' open={open} onClose={handleClose}>
              <DialogTitle style={{ background: '#00badb', color: 'white'}}>Edit step</DialogTitle>
              <Form onSubmit={formProps.handleSubmit}>
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography id='showNext' gutterBottom>
                        Seconds before show next step: {formProps.values.showNext}s
                      </Typography>
                      <Slider
                        aria-label='showNext'
                        valueLabelDisplay='auto'
                        min={0}
                        max={100}
                        onChange={formProps.handleChange}
                        value={parseInt(formProps.values.showNext)}
                        id='showNext'
                        name='showNext'
                        error={
                          formProps.touched.showNext &&
                          Boolean(formProps.errors.showNext)
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography id='showNext' gutterBottom>
                        Seconds before move next step: {formProps.values.moveNext}s
                      </Typography>
                      <Slider
                        aria-label='moveNext'
                        valueLabelDisplay='auto'
                        min={0}
                        max={100}
                        onChange={formProps.handleChange}
                        value={parseInt(formProps.values.moveNext)}
                        id='moveNext'
                        name='moveNext'
                        error={
                          formProps.touched.moveNext &&
                          Boolean(formProps.errors.moveNext)
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id='nextNext'>Type of next button</InputLabel>
                        <Select
                          labelId='nextNext'
                          id='nextNext'
                          name='nextNext'
                          label='Type of next button'
                          onChange={formProps.handleChange}
                          value={formProps.values.nextNext}

                        >
                          {nextButtonOptions?.map((item, index) => {
                            return (
                              <MenuItem
                                key={index}
                                style={{ padding: '6px 6px 6px 16px', display: 'flex', lineHeight: 1.5, letterSpacing: '0.00938em', justifyContent: 'flex-start' }}
                                value={item.code}>{item.description}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <ButtonMui onClick={handleClose}>Cancel</ButtonMui>
                  <ButtonMui type='submit' disabled={formProps.isSubmitting}>
                    Update step
                  </ButtonMui>
                </DialogActions>
              </Form>
            </Dialog>
          );
        }}
      </Formik>
    </div>
  );
}

EditDialogue.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  onEditStepData: PropTypes.func,
  stepData: PropTypes.object,
  nextButtonOptions: PropTypes.array,
};
