const FETCH_OVERVIEW_BEGIN = 'FETCH_OVERVIEW_BEGIN';
const FETCH_OVERVIEW_SUCCESS = 'FETCH_OVERVIEW_SUCCESS';
const FETCH_OVERVIEW_ERROR = 'FETCH_OVERVIEW_ERROR';
const FETCH_AUDIENCE_OVERVIEW_BEGIN = 'FETCH_AUDIENCE_OVERVIEW_BEGIN';
const FETCH_AUDIENCE_OVERVIEW_SUCCESS = 'FETCH_AUDIENCE_OVERVIEW_SUCCESS';
const FETCH_AUDIENCE_OVERVIEW_ERROR = 'FETCH_AUDIENCE_OVERVIEW_ERROR';
const CREATE_AUDIENCE = 'CREATE_AUDIENCE';
const EDIT_STUDY = 'EDIT_STUDY';
const EDIT_AUDIENCE = 'EDIT_AUDIENCE';
const OPEN_AUDIENCE_MODAL = 'OPEN_AUDIENCE_MODAL';
const CLOSE_AUDIENCE_MODAL = 'CLOSE_AUDIENCE_MODAL';
const CREATE_PANEL_SUCCESS = 'CREATE_PANEL_SUCCESS';
const CREATE_PANEL_ERROR = 'CREATE_PANEL_ERROR';
const DELETE_PANEL_ERROR = 'DELETE_PANEL_ERROR';
const FETCH_PANEL_DETAILS_BEGIN = 'FETCH_PANEL_DETAILS_BEGIN';
const FETCH_PANEL_DETAILS_SUCCESS = 'FETCH_PANEL_DETAILS_SUCCESS';
const FETCH_PANEL_DETAILS_ERROR = 'FETCH_PANEL_DETAILS_ERROR';
const OPEN_CONFIRMATION_MODAL = 'OPEN_CONFIRMATION_MODAL';
const CLOSE_CONFIRMATION_MODAL = 'CLOSE_CONFIRMATION_MODAL';
const DELETE_SUCCESS = 'DELETE_SUCCESS';
const OPEN_FILES_MODAL = 'OPEN_FILES_MODAL';
const CLOSE_FILES_MODAL = 'CLOSE_FILES_MODAL';
const FETCH_STUDY_DETAILS_BEGIN = 'FETCH_STUDY_DETAILS_BEGIN';
const FETCH_STUDY_DETAILS_SUCCESS = 'FETCH_STUDY_DETAILS_SUCCESS';
const FETCH_STUDY_DETAILS_ERROR = 'FETCH_STUDY_DETAILS_ERROR';

export default {
  FETCH_OVERVIEW_BEGIN,
  FETCH_OVERVIEW_ERROR,
  FETCH_OVERVIEW_SUCCESS,
  FETCH_AUDIENCE_OVERVIEW_BEGIN,
  FETCH_AUDIENCE_OVERVIEW_ERROR,
  FETCH_AUDIENCE_OVERVIEW_SUCCESS,
  CREATE_AUDIENCE,
  EDIT_STUDY,
  EDIT_AUDIENCE,
  OPEN_AUDIENCE_MODAL,
  CLOSE_AUDIENCE_MODAL,
  CREATE_PANEL_SUCCESS,
  CREATE_PANEL_ERROR,
  DELETE_PANEL_ERROR,
  FETCH_PANEL_DETAILS_BEGIN,
  FETCH_PANEL_DETAILS_ERROR,
  FETCH_PANEL_DETAILS_SUCCESS,
  OPEN_CONFIRMATION_MODAL,
  CLOSE_CONFIRMATION_MODAL,
  DELETE_SUCCESS,
  OPEN_FILES_MODAL,
  CLOSE_FILES_MODAL,
  FETCH_STUDY_DETAILS_BEGIN,
  FETCH_STUDY_DETAILS_SUCCESS,
  FETCH_STUDY_DETAILS_ERROR,

};
