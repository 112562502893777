import React, { useState } from 'react';
import { Field, useFormikContext } from 'formik';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Fade from '@material-ui/core/Fade';
import Divider from '@material-ui/core/Divider';
import { FormControlLabel, Radio } from '@material-ui/core';
import { RadioGroup } from 'formik-material-ui';
import propTypes from 'prop-types';

import SelectMenu from '../MaterialComponents/SelectMenu';
import MoreInfo from '../MaterialComponents/MoreInfo';

export const QuestionDetails = ({questionTypes}) => {
  const {
    values,
    handleChange,
    errors,
    touched,
    isSubmitting,
  } = useFormikContext();
  const [mySurveyMounted, setMySurveyMounted] = useState(false);
  const [lumenSurveyMounted, setLumenSurveyMounted] = useState(false);

  return (
    <>
      <Grid container spacing={3}>
        <Grid container wrap = 'nowrap' item xs={12} >
          <Typography variant='h6'>
            Demographic/screening survey
          </Typography>
          <MoreInfo outerContainerClassName = 'alignToTop' popup='Survey at the start of your study which categorises or excludes respondents based on their question responses' />
        </Grid>
        <Grid item xs={12}>
          <Field
            value={values.demographic_survey_or_lumen_questions}
            component={RadioGroup}
            name='demographic_survey_or_lumen_questions'
          >
            <FormControlLabel
              checked={values.demographic_survey_or_lumen_questions === '0'}
              value={0}
              control={<Radio disabled={isSubmitting} color='primary' />}
              label='I would not like to include a screening survey.'
              disabled={isSubmitting}
            />
            <FormControlLabel
              checked={values.demographic_survey_or_lumen_questions === '1'}
              value={1}
              control={<Radio disabled={isSubmitting} color='primary' />}
              label='I would like to use my own pre-study survey'
              disabled={isSubmitting}
            />
            <FormControlLabel
              checked={values.demographic_survey_or_lumen_questions === '2'}
              value={2}
              control={<Radio disabled={isSubmitting} color='primary' />}
              label={`I would like to use one of Lumen's surveys`}
              disabled={isSubmitting}
            />
            <FormHelperText error = {true}>
              {
                touched['demographic_survey_or_lumen_questions'] && !!errors['demographic_survey_or_lumen_questions'] ?
                  errors['demographic_survey_or_lumen_questions'] : ''
              }
            </FormHelperText>
          </Field>
        </Grid>
        <Fade
          onEnter={() => setMySurveyMounted(true)}
          onExited={() => setMySurveyMounted(false)}
          mountOnEnter
          unmountOnExit
          in={
            values.demographic_survey_or_lumen_questions === '1' &&
            lumenSurveyMounted === false
          }
        >
          <Grid item xs={12}>
            <Typography variant='subtitle1'>
              Please enter your custom survey address details
            </Typography>
          </Grid>
        </Fade>
        <Fade
          onEnter={() => setMySurveyMounted(true)}
          onExited={() => setMySurveyMounted(false)}
          mountOnEnter
          unmountOnExit
          in={
            values.demographic_survey_or_lumen_questions === '1' &&
            lumenSurveyMounted === false
          }
        >
          <Grid container wrap = 'nowrap' item xs={12} >
            <TextField
              fullWidth
              name='demographic_survey_url'
              label='Demographic survey URL'
              variant='filled'
              value={values.demographic_survey_url}
              onChange={handleChange}
              error={
                touched['demographic_survey_url'] &&
                !!errors['demographic_survey_url']
              }
              helperText={
                touched['demographic_survey_url'] &&
                !!errors['demographic_survey_url']
                  ? errors['demographic_survey_url']
                  : ''
              }
            />
            <MoreInfo outerContainerClassName = 'moreInfo' popup='The primary link to your demographic survey' />
          </Grid>
        </Fade>
        <Fade
          mountOnEnter
          unmountOnExit
          in={
            values.demographic_survey_or_lumen_questions === '1' &&
            lumenSurveyMounted === false
          }
        >
          <Grid container wrap = 'nowrap' item xs={12} >
            <TextField
              fullWidth
              name='demographic_survey_insert_parameter'
              label='Demographic survey insert parameter'
              variant='filled'
              value={values.demographic_survey_insert_parameter}
              onChange={handleChange}
              error={
                touched['demographic_survey_insert_parameter'] &&
                !!errors['demographic_survey_insert_parameter']
              }
              helperText={
                touched['demographic_survey_insert_parameter'] &&
                !!errors['demographic_survey_insert_parameter']
                  ? errors['demographic_survey_insert_parameter']
                  : ''
              }
            />
            <MoreInfo outerContainerClassName = 'moreInfo' popup='The query string field for your respondents ID' />
          </Grid>
        </Fade>
        <Fade
          onEnter={() => setLumenSurveyMounted(true)}
          onExited={() => setLumenSurveyMounted(false)}
          mountOnEnter
          unmountOnExit
          in={
            values.demographic_survey_or_lumen_questions === '2' &&
            mySurveyMounted === false
          }
        >
          <Grid item xs={12}>
            <Typography variant='subtitle1'>
              Please choose the question set you would like to use
            </Typography>
          </Grid>
        </Fade>
        <Fade
          onEnter={() => setLumenSurveyMounted(true)}
          onExited={() => setLumenSurveyMounted(false)}
          mountOnEnter
          unmountOnExit
          in={
            values.demographic_survey_or_lumen_questions === '2' &&
            mySurveyMounted === false
          }
        >
          <Grid container wrap = 'nowrap' item xs={12} >
            <SelectMenu
              name='pre_assign_question_group'
              label='Pre-assign question groups'
              options={questionTypes}
            />
            <MoreInfo outerContainerClassName = 'moreInfo' popup='You can select from a set of pre-defined questions' />
          </Grid>
        </Fade>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid container wrap = 'nowrap' item xs={12} >
          <Typography variant='h6'>Post exposure survey</Typography>
          <MoreInfo outerContainerClassName = 'moreInfo' popup='Questions about the test stim seen after the exposure' />
        </Grid>
        <Grid item xs={12}>
          <Field
            value={values.include_external_survey}
            component={RadioGroup}
            name='include_external_survey'
          >
            <FormControlLabel
              checked={values.include_external_survey === '0'}
              value={0}
              control={<Radio disabled={isSubmitting} color='primary' />}
              label='I would not like to include a post-exposure survey'
              disabled={isSubmitting}
            />
            <FormControlLabel
              checked={values.include_external_survey === '1'}
              value={1}
              control={<Radio disabled={isSubmitting} color='primary' />}
              label='I would like to include my own post-exposure survey'
              disabled={isSubmitting}
            />
            <FormHelperText error={true}>
              {touched['include_external_survey'] && !!errors['include_external_survey'] ?
                errors['include_external_survey'] :
                ''}
            </FormHelperText>
          </Field>
        </Grid>
        <Fade
          mountOnEnter
          unmountOnExit
          in={
            values.include_external_survey === '1'
          }
        >
          <Grid item xs={12}>
            <Typography variant='subtitle1'>
              Please enter the URL of your post-exposure survey in the text underneath
            </Typography>
          </Grid>
        </Fade>
        <Fade

          mountOnEnter
          unmountOnExit
          in={
            values.include_external_survey === '1'
          }
        >
          <Grid container wrap = 'nowrap' item xs={12} >
            <TextField
              fullWidth
              name='external_survey_step_url'
              label='Post exposure survey URL'
              variant='filled'
              value={values.external_survey_step_url}
              onChange={handleChange}
              error={
                touched['external_survey_step_url'] &&
                !!errors['external_survey_step_url']
              }
              helperText={
                touched['external_survey_step_url'] &&
                !!errors['external_survey_step_url']
                  ? errors['external_survey_step_url']
                  : ''
              }
            />
            <MoreInfo outerContainerClassName = 'moreInfo' popup='Primary ink to your post-exposure survey' />
          </Grid>
        </Fade>
        <Fade
          mountOnEnter
          unmountOnExit
          in={values.include_external_survey === '1'}
        >
          <Grid container wrap = 'nowrap' item xs={12} >
            <TextField
              fullWidth
              name='external_survey_step_insert_parameter'
              label='Post exposure survey insert parameter'
              variant='filled'
              value={values.external_survey_step_insert_parameter}
              onChange={handleChange}
              error={
                touched['external_survey_step_insert_parameter'] &&
                !!errors['external_survey_step_insert_parameter']
              }
              helperText={
                touched['external_survey_step_insert_parameter'] &&
                !!errors['external_survey_step_insert_parameter']
                  ? errors['external_survey_step_insert_parameter']
                  : ''
              }
            />
            <MoreInfo outerContainerClassName = 'moreInfo' popup='The query string field for your respondents ID' />
          </Grid>
        </Fade>
      </Grid>
    </>
  );
};

QuestionDetails.propTypes = {
  questionTypes: propTypes.object,
};
