import React, { useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import InputAdornment from '@material-ui/core/InputAdornment';
import CodeIcon from '@material-ui/icons/Code';
import propTypes from 'prop-types';

import BrandAutoComplete from '../MaterialComponents/BrandAutoComplete';
import MoreInfo from '../MaterialComponents/MoreInfo';

const RichWebPageSetup = ({brandTypes}) => {
  const { values } = useFormikContext();

  useEffect(() => {
    values.external_step_name = `${values.name}-${values.external_step_url}`;
  }, [values.external_step_url]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant='subtitle1'>
            Please enter the website you would like us to test your ad on
          </Typography>
        </Grid>
        <Grid container wrap = 'nowrap' item xs={12} >
          <Field
            fullWidth
            name='incontext_primary_site'
            component={TextField}
            label='Test page URL'
            variant='filled'
          />
          <MoreInfo outerContainerClassName = 'moreInfo' popup='The test page is where your test rich media will be displayed' />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle1'>
            The distraction site you would like us to test your comparisons with
          </Typography>
        </Grid>
        <Grid container wrap = 'nowrap' item xs={12} >
          <Field
            fullWidth
            name='incontext_distraction_site'
            component={TextField}
            label='Distraction page URL'
            variant='filled'
          />
          <MoreInfo
            outerContainerClassName='moreInfo'
            popup={`The distraction page features other ads and increases realism for the respondents but isn't part of the dataset`}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle1'>
            The skin&apos;s code that you would like us to test
          </Typography>
        </Grid>
        <Grid container wrap = 'nowrap' item xs={12} >
          <Field
            fullWidth
            name='stim_script'
            component={TextField}
            label='Ad script'
            variant='filled'
            multiline
            InputProps={{
              startAdornment: (
                <InputAdornment variant='standard' position='start'>
                  <CodeIcon
                    fontSize='small'
                    style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <MoreInfo outerContainerClassName = 'moreInfo' popup='The ad script is the javascript which inserts your rich media into the webpage' />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle1'>
            And then, finally, please choose your brand name and we are good to
            go.
          </Typography>
        </Grid>
        <Grid container wrap = 'nowrap' item xs={12} >
          <BrandAutoComplete brandTypes={brandTypes} />
          <MoreInfo outerContainerClassName = 'moreInfo' popup='The brand name of the company featured in your test ad ' />
        </Grid>
      </Grid>
    </>
  );
};

RichWebPageSetup.propTypes = {
  brandTypes: propTypes.object,
};

export default RichWebPageSetup;
