import React, { useState } from 'react'
import CallToAction from "../../../assets/images/Call_to_action_example.png";
import ChannelName from "../../../assets/images/Channel_name.png";
import FacebookPostText from "../../../assets/images/Facebook_post_text.png";
import PostedDate from "../../../assets/images/Posted_date.png";
import ProfilePhoto from "../../../assets/images/profile_photo_url.png";
import PromotedPost from "../../../assets/images/promoted_post.png";
import KeyAreasOfInterest from "../../../assets/images/key_areas_of_interest.png";
import ErrorImage from "../../../assets/images/ErrorImage.png";
import Typography from "@material-ui/core/Typography"

export default function MoreInfoPictureContent(props) {
    const [imageSrc, setimageSrc] = useState(props.url)
    const onError = () => {
        setimageSrc(ErrorImage)
    }
    const selectIllustration = () => {
        let illustration = props.illustration
        switch (illustration) {
            case "callToAction":
                illustration = <img width="100%" src={CallToAction} alt="logo" />
                break;
            case "channelName":
                illustration = <img width="100%" src={ChannelName} alt="logo" />
                break;
            case "postText":
                illustration = <img width="100%" src={FacebookPostText} alt="logo" />
                break;
            case "postedDate":
                illustration = <img width="100%" src={PostedDate} alt="logo" />
                break;
            case "profilePhoto":
                illustration = <img width="100%" src={ProfilePhoto} alt="logo" />
                break;
            case "promotedPost":
                illustration = <img width="100%" src={PromotedPost} alt="logo" />
                break;
            case "keyAreasOfInterest":
                illustration = <img width="100%" src={KeyAreasOfInterest} alt="logo" />
                break;
            case "faceBookStandard":
                illustration = <img width="100%" src="https://s3.eu-west-2.amazonaws.com/content.lumen-research.com/cache_page_data/DashBoardSocialExamples/FacebookStandardWithText.png" alt="logo" />
                break;
            case "faceBookStory":
                illustration = <img width="100%" src="https://s3.eu-west-2.amazonaws.com/content.lumen-research.com/cache_page_data/DashBoardSocialExamples/FacebookStoriesWithText.png" alt="logo" />
                break;
            case "faceBookWatch":
                illustration = <img width="100%" src="https://s3.eu-west-2.amazonaws.com/content.lumen-research.com/cache_page_data/DashBoardSocialExamples/FacebookWatchFeedWithText.png" alt="logo" />
                break;
            case "instagramStandard":
                illustration = <img width="100%" src="https://s3.eu-west-2.amazonaws.com/content.lumen-research.com/cache_page_data/DashBoardSocialExamples/StandardPostInstagramWithText.png" alt="logo" />
                break;
            case "instagramStory":
                illustration = <img width="100%" src="https://s3.eu-west-2.amazonaws.com/content.lumen-research.com/cache_page_data/DashBoardSocialExamples/InstagramStoryWithText.png" alt="logo" />
                break;
            case "snapchat":
                illustration = <img width="100%" src="https://s3.eu-west-2.amazonaws.com/content.lumen-research.com/cache_page_data/DashBoardSocialExamples/SnapChatWithText.png" alt="logo" />
                break;
            case "url":
                illustration = <img style={{ maxWidth: "100%", maxHeight: 400 }} onError={onError} src={imageSrc} alt="logo" />
                break;
            default:
                illustration = ""
        }
        return illustration
    }


    return (
        <>
            <Typography paragraph variant="subtitle1">{props.standardDescription}</Typography>
            {props.illustration === "url" ? "" : <Typography variant="caption">How this will be used:</Typography>}
            {selectIllustration()}
        </>
    )
}
