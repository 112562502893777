import types from './types.js';

const fetchReportDetailsBegin = () => ({
  type: types.FETCH_REPORT_DETAILS_BEGIN
});

const fetchReportDetailsSuccess = report => ({
  type: types.FETCH_REPORT_DETAILS_SUCCESS,
  payload: { report }
});

const fetchReportDetailsError = error => ({
  type: types.FETCH_REPORT_DETAILS_ERROR,
  payload: { error }
});

const fetchLookerUrlBegin = () => ({
  type: types.FETCH_LOOKER_URL_BEGIN
});

const fetchLookerUrlSuccess = url => ({
  type: types.FETCH_LOOKER_URL_SUCCESS,
  payload: { url }
});

const fetchLookerUrlError = error => ({
  type: types.FETCH_LOOKER_URL_ERROR,
  payload: { error }
});

export default {
  fetchReportDetailsBegin,
  fetchReportDetailsSuccess,
  fetchReportDetailsError,
  fetchLookerUrlBegin,
  fetchLookerUrlSuccess,
  fetchLookerUrlError,
};
