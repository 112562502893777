const FETCH_AGE_BEGIN = 'FETCH_AGE_BEGIN';
const FETCH_AGE_SUCCESS = 'FETCH_AGE_SUCCESS';
const FETCH_AGE_ERROR = 'FETCH_AGE_ERROR';
const FETCH_EARNINGS_BEGIN = 'FETCH_EARNINGS_BEGIN';
const FETCH_EARNINGS_SUCCESS = 'FETCH_EARNINGS_SUCCESS';
const FETCH_EARNINGS_ERROR = 'FETCH_EARNINGS_ERROR';
const FETCH_FAMILY_BEGIN = 'FETCH_FAMILY_BEGIN';
const FETCH_FAMILY_SUCCESS = 'FETCH_FAMILY_SUCCESS';
const FETCH_FAMILY_ERROR = 'FETCH_FAMILY_ERROR';
const FETCH_GENDER_BEGIN = 'FETCH_GENDER_BEGIN';
const FETCH_GENDER_SUCCESS = 'FETCH_GENDER_SUCCESS';
const FETCH_GENDER_ERROR = 'FETCH_GENDER_ERROR';
const ADD_TO_FILTERS_ARRAY = 'ADD_TO_FILTERS_ARRAY';
const REMOVE_FILTERS_ARRAY = 'REMOVE_FILTERS_ARRAY';
const SET_FILTERS_TO_LIVE = 'SET_FILTERS_TO_LIVE';
const CLEAR_FILTERS = 'CLEAR_FILTERS';
const OPEN_MODAL = 'OPEN_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';
const CHECKED_ITEMS = 'CHECKED_ITEMS';







export default {
  FETCH_AGE_BEGIN,
  FETCH_AGE_ERROR,
  FETCH_AGE_SUCCESS,
  FETCH_EARNINGS_ERROR,
  FETCH_EARNINGS_BEGIN,
  FETCH_EARNINGS_SUCCESS,
  FETCH_FAMILY_ERROR,
  FETCH_FAMILY_BEGIN,
  FETCH_FAMILY_SUCCESS,
  FETCH_GENDER_ERROR,
  FETCH_GENDER_BEGIN,
  FETCH_GENDER_SUCCESS,
  ADD_TO_FILTERS_ARRAY,
  REMOVE_FILTERS_ARRAY,
  SET_FILTERS_TO_LIVE,
  CLEAR_FILTERS,
  OPEN_MODAL,
  CLOSE_MODAL,
  CHECKED_ITEMS
};
