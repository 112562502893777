export const FETCH_FEATURE_REPORT_BEGIN = 'FETCH_FEATURE_REPORT_BEGIN';
export const FETCH_FEATURE_REPORT_SUCCESS = 'FETCH_FEATURE_REPORT_SUCCESS';
export const FETCH_FEATURE_REPORT_ERROR = 'FETCH_FEATURE_REPORT_ERROR';
export const FETCH_ATTENTION_BEGIN = 'FETCH_ATTENTION_BEGIN';
export const FETCH_ATTENTION_SUCCESS = 'FETCH_ATTENTION_SUCCESS';
export const FETCH_ATTENTION_ERROR = 'FETCH_ATTENTION_ERROR';
export const FETCH_GRAPH_BEGIN = 'FETCH_GRAPH_BEGIN';
export const FETCH_GRAPH_SUCCESS = 'FETCH_GRAPH_SUCCESS';
export const FETCH_GRAPH_ERROR = 'FETCH_GRAPH_ERROR';
export const FETCH_KPI_BEGIN = 'FETCH_KPI_BEGIN';
export const FETCH_KPI_SUCCESS = 'FETCH_KPI_SUCCESS';
export const FETCH_KPI_ERROR = 'FETCH_KPI_ERROR';
export const SEARCH = 'SEARCH';
export const IS_LIVE = 'IS_LIVE';
export const COMPLETED = 'COMPLETED';
export const MEDIA_TYPE = 'MEDIA_TYPE';
export const ENVIRONMENT_TYPE = 'ENVIRONMENT_TYPE';
export const FETCH_STIM_BEGIN = 'FETCH_STIM_BEGIN';
export const FETCH_STIM_SUCCESS = 'FETCH_STIM_SUCCESS';
export const FETCH_STIM_ERROR = 'FETCH_STIM_ERROR';
export const ADD_COMPARE_STUDY = 'ADD_COMPARE_STUDY';
export const REMOVE_COMPARE_STUDY = 'REMOVE_COMPARE_STUDY';
export const UNSELECT_STUDY = 'UNSELECT_STUDY';
export const AUTHENTICATED = 'AUTHENTICATED_USER';
export const UNAUTHENTICATED = 'UNAUTHENITCATED_USER';
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';
export const FETCH_STUDIES_BEGIN = 'FETCH_STUDIES_BEGIN';
export const FETCH_STUDIES_SUCCESS = 'FETCH_STUDIES_SUCCESS';
export const FETCH_TOTAL_INFO_SUCCESS = 'FETCH_TOTAL_INFO_SUCCESS';
export const FETCH_STUDIES_ERROR = 'FETCH_STUDIES_ERROR';
export const FETCH_USER_DATA_BEGIN = 'FETCH_USER_DATA_BEGIN';
export const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS';
export const FETCH_USER_DATA_ERROR = 'FETCH_USER_DATA_ERROR';
export const FETCH_FEATURE_BEGIN = 'FETCH_FEATURE_BEGIN';
export const FETCH_FEATURE_SUCCESS = 'FETCH_FEATURE_SUCCESS';
export const FETCH_FEATURE_ERROR = 'FETCH_FEATURE_ERROR';
export const SORT_BY_ASCENDING_ORDER = 'SORT_BY_ASCENDING_ORDER';
export const SORT_BY_DECENDING_ORDER = 'SORT_BY_DECENDING_ORDER';
export const SEND_CONTACT_FORM_SUCCESS = 'SEND_CONTACT_FORM_SUCCESS';
export const SEND_CONTACT_FORM_ERROR = 'SEND_CONTACT_FORM_ERROR';
export const CLEAR_CHECKED_STUDIES = 'CLEAR_CHECKED_STUDIES';
export const CHECK_ALL_IN_STEP = 'CHECK_ALL_IN_STEP';
export const REMOVE_ALL_IN_STEP = 'REMOVE_ALL_IN_STEP';
export const SET_FEATURE_FILTER = 'SET_FEATURE_FILTER';
export const SET_ATTENTION_FILTER = 'SET_ATTENTION_FILTER';
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
// export const URL = "https://api.lumen-research.com/"  
export const URL = process.env.REACT_APP_API_URL

