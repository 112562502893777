import React from "react";
import Chart from "react-apexcharts";

export default class AttentionGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: this.returnSeriesData(),

      options: {
        theme: {
          palette: "palette3", 
        },
        tooltip: {
          y:  {
            formatter: function(value) {
              return `${value}% still viewing`;
            },
          },

          x: {
            formatter: function(value) {
              return `${value} seconds dwell time`;
            },
          }
        },
        chart: {
          type: "area",
          zoom: {
            enabled: true,
          },
          toolbar:{
            tools:{
              download: true,
            }
          }
        },
        xaxis: {
          // tickAmount: 'dataPoints',
          type: "numeric",
          min:0,
         
          // labels: {
          //   tickAmount: 10,
          //   min:0,
          //   forceNiceScale:true,
          // },

          labels: {
            // show:false,
            // formatter: function(value) {
            //   return `${Math.round(value)}`;
            // },
          },
          tooltip: {
            enabled: false,
          },
          title: {
            text: "Stim dwell time (s)",
            style: {
              fontWeight: 500,
              fontFamily: "Roboto",
              color: "rgba(0, 0, 0, 0.54)",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },

        yaxis: {
          labels: {
            formatter: function(value) {
              return `${value}%`;
            },
          },
          forceNiceScale:true,
          title: {
            text: "% of people viewing stim",
            style: {
              fontWeight: 500,
              fontFamily: "Roboto",
              color: "rgba(0, 0, 0, 0.54)",
            },
            offsetX: 8,
          },
        },
        legend: {
          horizontalAlign: "left",
        },
      },
    };
  }

  returnSeriesData() {
    let graphData = JSON.parse(
      JSON.stringify(this.props.graphData.graph_data)
    );
    let newGraphData = [
      {
        data: [],
        name: this.props.stimName,
      },
    ];
    for (let i = 0; i < graphData.length; i = i + 1) {
      graphData[i].y = Math.round(graphData[i].y / 100) / 10;
      graphData[i].x = Math.round(graphData[i].x);
      let yval = graphData[i].y;
      graphData[i].y = graphData[i].x;
      graphData[i].x = yval;
      newGraphData[0].data.push(graphData[i]);
    }
    return newGraphData;
  }

  render() {
    return (
      <div id="chart" >
         
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="area"
          height={325}
        />
      </div>
    );
  }
}