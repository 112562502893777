import types from './types.js';

const fetchKpiBegin = () => ({
  type: types.FETCH_KPI_BEGIN
});

const fetchKpiSuccess = data => ({
  type: types.FETCH_KPI_SUCCESS,
  payload: data
});

const updateKpiNormSuccess = data => ({
  type: types.UPDATE_KPI_NORM_SUCCESS,
  payload: data
});

const fetchNewKpiNormBegin = () => ({
  type: types.NEW_KPI_NORM_BEGIN,
});



const fetchKpiError = error => ({
  type: types.FETCH_KPI_ERROR,
  payload: { error }
});

const setKpiFilter = (filter, index) => ({
  type: types.SET_KPI_FILTER,
  payload: {filter, index}
});

const fetchKpiGraphBegin = () => ({
  type: types.FETCH_GRAPH_KPI_BEGIN
});

const fetchKpiGraphSuccess = data => ({
  type: types.FETCH_GRAPH_KPI_SUCCESS,
  payload: data
});

const updateKpiGraphSuccess = (newGraphData, index) => ({
  type: types.UPDATE_KPI_GRAPH_SUCCESS,
  payload: {newGraphData, index}
});

const fetchKpiGraphError = error => ({
  type: types.FETCH_GRAPH_KPI_ERROR,
  payload: { error }
});

const fetchKpiRawBegin = () => ({
  type: types.FETCH_KPI_RAW_BEGIN
});

const fetchKpiRawSuccess = data => ({
  type: types.FETCH_KPI_RAW_SUCCESS,
  payload: data
});

const fetchKpiRawError = error => ({
  type: types.FETCH_KPI_RAW_ERROR,
  payload: { error }
});

const fetchParticipantReportBegin = () => ({
  type: types.FETCH_PARTICIPANT_REPORT_BEGIN
});

const fetchParticipantReportSuccess = data => ({
  type: types.FETCH_PARTICIPANT_REPORT_SUCCESS,
  payload: data
});

const fetchParticipantReportError = error => ({
  type: types.FETCH_PARTICIPANT_REPORT_ERROR,
  payload: { error }
});

const setKpiDataLoaded = () => ({
  type: types.KPI_DATA_FINISHED_SUCCESS
});

const setKpiDataError = () => ({
  type: types.KPI_DATA_FINISHED_ERROR
});





export default {
  fetchKpiBegin,
  fetchKpiSuccess,
  updateKpiNormSuccess,
  fetchNewKpiNormBegin,
  fetchKpiError,
  setKpiFilter,
  fetchKpiRawSuccess,
  fetchKpiRawError,
  fetchKpiRawBegin,
  fetchKpiGraphBegin,
  fetchKpiGraphSuccess,
  updateKpiGraphSuccess,
  fetchKpiGraphError,
  fetchParticipantReportBegin,
  fetchParticipantReportSuccess,
  fetchParticipantReportError,
  setKpiDataLoaded,
  setKpiDataError
};


