import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import MoreInfo from "../../CreateStudyPage/MaterialComponents/MoreInfo";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const useStyles = makeStyles({
  evaluationTextPositive: {
    color: "#4caf50",
    backgroundColor: "rgba(76, 175, 80, 0.08)",
    padding: "1px 2px",
    marginLeft: "8px",
  },
  evaluationTextNegative: {
    color: "rgb(244, 67, 54)",
    backgroundColor: "rgba(244, 67, 54, 0.1)",
    padding: "1px 2px",
    marginLeft: "8px",
  },
});
//props: title,figure,evaluation, moreInfo, popupArray
export default function ImportantInfo(props) {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid
        alignContent="flex-end"
        container
        wrap="nowrap"
        justify="space-between"
        item
        xs={12}
      >
        <Typography variant="h6">
          {props.title}
        </Typography>
        {props.popupArray ? 
        <MoreInfo outerContainerClassName = "cacheSetup" popupArray = {props.popupArray}  /> :
        <MoreInfo outerContainerClassName="cacheSetup" popup={props.moreInfo} />
        }
      </Grid>
      <Grid item container alignItems="center">
        <Typography  display="inline" component="p" variant="h4">
          {props.figure}
        </Typography>
        {props.evaluation ? 
        <Typography
          variant="caption"
          className={
            props.color === "green"
              ? classes.evaluationTextPositive
              : classes.evaluationTextNegative
          }
          display="inline"
        >
          { props.color === "green" ? <ArrowUpwardIcon style={{ fontSize: 14, verticalAlign: "middle" }} /> : <ArrowDownwardIcon style={{ fontSize: 14, verticalAlign: "middle"  }} />} {props.evaluation}
        </Typography>
        : ""}
      </Grid>
    </Grid>
  );
}
