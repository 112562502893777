import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import MenuButton from './MenuButton';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import BiotechRoundedIcon from '@mui/icons-material/BiotechRounded';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import BarChartIcon from '@mui/icons-material/BarChart';
import classNames from 'classnames';

const NavigationLinks = ({ handleClick, isActive, stimsUrl, currentUrl }) => {
  const userType = parseInt(localStorage.getItem('userType'));
  const dashboard = userType === 1 ? 'my_studies' : 'my_batches';
  const dashboardText = userType === 1 ? 'My Studies' : 'My Batches';

  return (

    <div className='lu-aside__navigation'>
      <div style={{position: 'fixed', display: 'flex', justifyContent: 'flex-end', top:0, height:64, clipPath: 'inset(0px 0px -15px 0px)', background: '#00badb', width:'100%', boxShadow: 'rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px'}} className='lu-aside__control'>
        <MenuButton accessibleText='Toggle Menu' className='lu-aside__toggle-menu' handleClick={handleClick} isActive={isActive} />
      </div>
      <ul style={{ background:'white', borderRight: '1px solid rgba(0, 0, 0, 0.12)'}} className='lu-aside__menu'>
        {(currentUrl === '/') && <li className='lu-aside__menu-item lu-aside__menu-active'><Link style={{color:'white'}} to='/' className='lu-aside__menu-anchor'>Home</Link><Link to='/' className='lu-aside__menu-image'><HomeOutlinedIcon style={{color: 'white'}} /> </Link></li>}
        {(currentUrl !== '/') && <li className='lu-aside__menu-item'><Link to='/' className='lu-aside__menu-anchor'>Home</Link><Link to='/' className='lu-aside__menu-image'><HomeOutlinedIcon style={{color: 'inherit'}} /></Link></li>}
        {(currentUrl.includes(`/${dashboard}`))
          ? <li className='lu-aside__menu-item lu-aside__menu-active'><Link style={{color:'white'}} to={`/${dashboard}`} className='lu-aside__menu-anchor'>{dashboardText}</Link><Link to={`/${dashboard}`} className='lu-aside__menu-image'><BiotechRoundedIcon style={{color: 'white'}} /></Link></li>
          : <li className='lu-aside__menu-item'><Link to={`/${dashboard}`} className='lu-aside__menu-anchor'>{dashboardText}</Link><Link to={`/${dashboard}`} className='lu-aside__menu-image'><BiotechRoundedIcon style={{color: 'inherit'}} /></Link></li>}
        {(currentUrl.includes('stim'))
          ? <li className='lu-aside__menu-item lu-aside__menu-active'><Link style={{color:'white'}} to={stimsUrl} className='lu-aside__menu-anchor '>My Stimuli</Link><Link to={stimsUrl} className='lu-aside__menu-image'> <VisibilityOutlinedIcon style={{color: 'white'}} /></Link></li>
          : <li className='lu-aside__menu-item'><Link to={stimsUrl} className='lu-aside__menu-anchor '>My Stimuli</Link><Link to={stimsUrl} className='lu-aside__menu-image'><VisibilityOutlinedIcon style={{color: 'inherit'}} /></Link></li>}
        {(currentUrl === '/my_downloads') && <li className='lu-aside__menu-item lu-aside__menu-active'><Link style={{color:'white'}} to='/my_downloads' className='lu-aside__menu-anchor'>My Downloads</Link><Link to='/my_downloads' className='lu-aside__menu-image'><MenuBookRoundedIcon style={{color: 'white'}} /></Link></li>}
        {(currentUrl !== '/my_downloads') && <li className='lu-aside__menu-item'><Link to='/my_downloads' className='lu-aside__menu-anchor'>My Downloads</Link><Link to='/my_downloads' className='lu-aside__menu-image'><MenuBookRoundedIcon style={{color: 'inherit'}} /></Link></li>}
        <li className={classNames('lu-aside__menu-item', { 'lu-aside__menu-active' : currentUrl.includes('/my_reports') })}>
          <Link
            to='/my_reports'
            style={{color: currentUrl.includes('/my_reports') ? 'white' : 'inherit'}}
            className={ classNames('lu-aside__menu-anchor')}
          >
            My Reports
            <div className='lu-aside__menu-image'>
              <BarChartIcon style={{color: currentUrl === '/my_reports' ? 'white' : 'inherit'}}/>
            </div>
          </Link>
        </li>
      </ul>
    </div>
  );
};

NavigationLinks.propTypes = {
  handleClick: PropTypes.func,
  onFilterClick: PropTypes.func,
  currentUrl: PropTypes.string,
  stimsUrl: PropTypes.string,
  isActive: PropTypes.bool,
};

export default NavigationLinks;
