

import React from 'react';
import Button from '../../common/components/Button';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../common/components/LoadingSpinner'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Tooltip from "@material-ui/core/Tooltip"
import {Fab} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
    };
  }

  render() {
    const { loading, data, onClick } = this.props;

    if (loading) {
      return <LoadingSpinner  style={{margin:"80px auto", position: "relative", top: "unset"}} />
    }
    return (
      <table style={{overflow: "initial", padding:0}} className="table study">
        <tbody>
          <tr>
            <th>Id</th>
            <th style={{width:"60%"}}>Name</th>
            <th>Url</th>
            <th style={{width:"35%"}}></th>
            <th>Add step</th>
          </tr>
          {data.map((item, index) => {
            return (
              <tr key={index}>
                <td >{item.id}</td>
                <td >{item.name}</td>
                
                <td >  
                  {this.state.copied === item.url ? (
        <MuiAlert  onClose={() => {this.setState({copied: false})}} className="mb-4" severity="success">
          <span>
            Copied successfully
          </span>
        </MuiAlert>
      ) : null}
<div style={{display:"flex", alignItems: "center", gap:24, justifyContent: "space-between", paddingRight:16 }}>
     <p> {item.url ? item.url.substring(0, 24) + "..." : item.url}</p>
      <Tooltip title="Copy URL to clipboard" >
      <CopyToClipboard
                      text={item.url}
                      onCopy={() => this.setState({ copied: item.url })}>
                      <Fab size="small" style={{background: "#00badb"}}>
                       
                        <ContentCopyIcon style={{ color: "white", fontSize: 18 }} />
                      </Fab>
                    </CopyToClipboard>
                    </Tooltip>
                    </div>
                    </td>
                    <td></td>
                <td ><Button handleClick={() => onClick(item.id)} buttonClass="button-primary" text="Add" /></td>
              </tr>);

          })}
        </tbody>
      </table>
    );
  }
}

export default Table;
Table.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
  onClick: PropTypes.func
};