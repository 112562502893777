import DataService from '../../common/services/dataService';
import Creators from './actions';

const {
  fetchReportDetailsBegin,
  fetchReportDetailsSuccess,
  fetchReportDetailsError,
  fetchLookerUrlBegin,
  fetchLookerUrlSuccess,
  fetchLookerUrlError,
} = Creators;

const fetchReportDetails = (studyId) => {
  return async (dispatch) => {
    try {
      dispatch(fetchReportDetailsBegin());
      const res = await DataService.fetchReportDetails(studyId);
      dispatch(fetchReportDetailsSuccess(res.data));
    }
    catch (err) {
      dispatch(fetchReportDetailsError(err));
      throw (err);
    }
  };
};

const fetchLookerUrl = (PanelUrl, studyId) => {
  return async (dispatch) => {
    try {
      dispatch(fetchLookerUrlBegin());
      const res = await DataService.fetchLookerUrl(PanelUrl, studyId);
      if (res.data.success) {
        dispatch(fetchLookerUrlSuccess(res.data.url));
      } else {
        dispatch(fetchLookerUrlError(res.data));
      }
    }
    catch (err) {
      dispatch(fetchLookerUrlError(err));
      throw (err);
    }
  };
};

export default {
  fetchReportDetails,
  fetchReportDetailsBegin,
  fetchReportDetailsSuccess,
  fetchReportDetailsError,
  fetchLookerUrl,
  fetchLookerUrlBegin,
  fetchLookerUrlSuccess,
  fetchLookerUrlError,
};
