import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';

import DynamicFieldArray from '../MaterialComponents/DynamicFieldArray';

const StandardWebPageSetup = (props) => {
  const { values } = useFormikContext();

  useEffect(() => {
    values.external_step_name = `${values.name}-${values.external_step_url}`;
  }, [values.external_step_url]);

  return (
    <>
      <Grid container spacing={3}>
        <DynamicFieldArray {...props} />
      </Grid>
    </>
  );
};

export default StandardWebPageSetup;
