import React from "react";
import FeedItem from "./FeedItem";
import { PinterestPost } from "@lumen-developer/lumen-lookalikes/dist/Previews";

import styles from "./pinterest.module.css";

const Feed = ({ values }) => {
  return (
    <div className={styles.FeedContainer}>
      <div
        className={styles.Feed}
        style={{
          flexDirection: values && values.pn_type === "m" ? "column" : "row",
        }}
      >
        {values && values.pn_type === "m" ? (
          <>
            <div className={styles.FeedBlock} style={{ flexGrow: 1 }}>
              <FeedItem height={80} marginTop={-50} />
              <FeedItem height={100} marginTop={-50} />
            </div>
            <div className={styles.FeedBlock}>
              <div>
                <PinterestPost
                  stim_url={values.stim_url}
                  pn_stim_title={values.pn_stim_title}
                  pn_profile_src={values.pn_profile_src}
                  pn_stim_profile={values.pn_stim_profile}
                  pn_type={values.pn_type}
                />
              </div>
            </div>
            <div className={styles.FeedBlock} style={{ flexGrow: 1 }}>
              <FeedItem height={200} />
              <FeedItem height={200} />
            </div>
          </>
        ) : (
          <>
            <div className={styles.FeedCol}>
              <FeedItem height={20} marginTop={-50} />
              <div style={{ width: "200%" }}>
                <PinterestPost
                  stim_url={values.stim_url}
                  pn_stim_title={values.pn_stim_title}
                  pn_profile_src={values.pn_profile_src}
                  pn_stim_profile={values.pn_stim_profile}
                  pn_type={values.pn_type}
                />
              </div>
              <FeedItem height={110} />
            </div>
            <div className={styles.FeedCol}>
              <FeedItem height={40} marginTop={-50} />
              <FeedItem height={80} />
              <FeedItem height={110} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Feed;
