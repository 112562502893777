const SET_KPI_FILTER = 'SET_KPI_FILTER';
const FETCH_KPI_BEGIN = 'FETCH_KPI_BEGIN';
const FETCH_KPI_SUCCESS = 'FETCH_KPI_SUCCESS';
const FETCH_KPI_ERROR = 'FETCH_KPI_ERROR';
const FETCH_GRAPH_KPI_BEGIN = 'FETCH_GRAPH_KPI_BEGIN';
const FETCH_GRAPH_KPI_SUCCESS = 'FETCH_GRAPH_KPI_SUCCESS';
const UPDATE_KPI_GRAPH_SUCCESS = 'UPDATE_KPI_GRAPH_SUCCESS';
const FETCH_GRAPH_KPI_ERROR = 'FETCH_GRAPH_KPI_ERROR';
const FETCH_KPI_RAW_BEGIN = 'FETCH_KPI_RAW_BEGIN';
const FETCH_KPI_RAW_SUCCESS = 'FETCH_KPI_RAW_SUCCESS';
const FETCH_KPI_RAW_ERROR = 'FETCH_KPI_RAW_ERROR';
const FETCH_PARTICIPANT_REPORT_BEGIN = 'FETCH_PARTICIPANT_REPORT_BEGIN';
const FETCH_PARTICIPANT_REPORT_SUCCESS = 'FETCH_PARTICIPANT_REPORT_SUCCESS';
const FETCH_PARTICIPANT_REPORT_ERROR = 'FETCH_PARTICIPANT_REPORT_ERROR';
const KPI_DATA_FINISHED_SUCCESS = 'KPI_DATA_FINISHED_SUCCESS';
const KPI_DATA_FINISHED_ERROR = 'KPI_DATA_FINISHED_ERROR';
const UPDATE_KPI_NORM_SUCCESS = 'UPDATE_KPI_NORM_SUCCESS';
const NEW_KPI_NORM_BEGIN = 'NEW_KPI_NORM_BEGIN';



export default {
  FETCH_KPI_BEGIN,
  FETCH_KPI_SUCCESS,
  FETCH_KPI_ERROR,
  SET_KPI_FILTER,
  FETCH_GRAPH_KPI_BEGIN,
  FETCH_GRAPH_KPI_SUCCESS,
  UPDATE_KPI_GRAPH_SUCCESS,
  FETCH_GRAPH_KPI_ERROR,
  FETCH_KPI_RAW_BEGIN,
  FETCH_KPI_RAW_SUCCESS,
  FETCH_KPI_RAW_ERROR,
  FETCH_PARTICIPANT_REPORT_BEGIN,
  FETCH_PARTICIPANT_REPORT_SUCCESS,
  FETCH_PARTICIPANT_REPORT_ERROR,
  KPI_DATA_FINISHED_SUCCESS,
  KPI_DATA_FINISHED_ERROR,
  UPDATE_KPI_NORM_SUCCESS,
  NEW_KPI_NORM_BEGIN,
};


