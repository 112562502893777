import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#00badb'
    },
    secondary : {
      main:'#006883'
    },
  },
  typography: {
    fontFamily: 'Roboto'
  },
  overrides: {
    MuiFormControl: {
      root: {
        width:'100%'
      },
    },
    MuiInputBase: {
      input: {
        boxShadow: 'unset',
        borderBottomColor: '#00badb',
      },
    },
    MuiTableCell: {
      root: {
        background:'unset'
      }
    },
    MuiTableRow: {
      root: {
        background:'unset !important'
      }
    },
    MuiPaper: {
      elevation2: {
        borderRadius:16,
      }
    },
  },
});
