import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { kpiOperations } from '../Kpi/duck/index';
import { featureAnalysisOperations } from './duck/index';
import PropTypes from 'prop-types';
import LoadingSpinner from '../common/components/LoadingSpinner';
import Table from './components/Table';
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { theme } from "../CreateStudyPage/MaterialStyling";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import DownLoadButton from "../Kpi/Components/DownloadButton";
import { Typography } from "@material-ui/core";
import ImportantInfo from "../Kpi/Components/ImportantInfo";
import MoreInfo from "../CreateStudyPage/MaterialComponents/MoreInfo";
import MoreMenu from "../Kpi/Components/MoreMenu";
import ErrorImage from "../../assets/images/ErrorImage.png"


const styles = {
  root: {
    display: "flex",
  },
  appBarSpacer: {
    marginBottom: 24,
  },
  content: {
    flexGrow: 1,
    overflow: "auto",
  },
  container: {
    paddingTop: 16,
    paddingBottom: 32,
  },
  paper: {
    padding: 16,
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
};


class FeatureAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      media: [{ name: 'Stim Source' }, { name: 'Feature Analysis' }, { name: 'View Order' } ],
      stimLoaded: false,
      timedOut:false,
    };
  }

  componentDidMount() {
    this.setState({ timedOut: false, })
  }

  componentDidUpdate(prevProps) {
    const { unsortedFilter, index} = this.props;
    if (unsortedFilter[index] !== prevProps.unsortedFilter[index]) {
      this.setState({ stimLoaded: false, timedOut: false, })
    }
    setTimeout(() => {
      if (!this.state.stimLoaded) {
        this.setState({timedOut: true})
      }
    }, 3000);
  }

  // onLoad = () => {
  //   this.setState({ stimLoaded: true });
  // }



  render() {
    const { loading, downloadFeatureReport, downloadParticipantData } = this.props;
    if (loading) {
      return <LoadingSpinner />
    }
    let featureData = this.props.featureData[this.props.index]
    let isBillboard = onBillboard(featureData)
    let unsortedFilter = this.props.unsortedFilter[this.props.index]
    let filter = sortFilter(unsortedFilter)
    const { stimLoaded } = this.state;
    const { classes } = this.props;
    const { stim_name } = featureData;


    const imageOrVideo =
    featureData.stim_type === 'video' ? (

        <video style={{  padding: 0, boxShadow:"0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%)"}} onLoadStart={() => this.setState({stimLoaded: true})} width="100%" height="100%" id="bgvID" controls >
        <source  src={featureData.stim_source} type="video/mp4" />
        </video>

      ) : (<>
        <img style={{maxWidth:"100%"}} onLoad ={() => this.setState({stimLoaded: true})} alt="lumen dashboard" src={featureData[filter]}/>
        </>
      );


    return (
      <div className={classes.root}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
      <div className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Paper style={{margin: 6,marginBottom: 40, paddingTop:8}} elevation={4}>
            <Container maxWidth="xl" className={classes.container}>
            <Grid container spacing={3}>
                <Grid
                  alignContent="flex-end"
                  container
                  wrap="nowrap"
                  justify="space-between"
                  item
                  xs={12}
                  style={{order:"-1"}}
                >
                  <Grid item xs={12}>
                    <Typography color="textSecondary" variant="h4">
                      {stim_name}
                    </Typography>
                  </Grid>
                  <DownLoadButton
                  options={["Download Feature Report", "Download Participant Data"]}
                  downloadFeatureReport={() =>
                      downloadFeatureReport(this.props.index, 'DOWNLOAD')
                    }
                    downloadParticipantData={() =>
                      downloadParticipantData(
                        this.props.index,
                        "DOWNLOAD"
                      )
                    }
                  />
                </Grid>
{isBillboard ? <>
<Grid container  item md={5} xs={12}  justify="flex-start" >
                <Grid style={{marginBottom:16}} item xs={12} >
                  <Paper variant="outlined" className={classes.paper}>
                    <ImportantInfo
                      title="Average page dwell time"
                      popupArray={[ "Average amount of time respondents spend on the page", "Total Page dwell time (s) \u00F7 number of valid impressions"]}
                      figure={`${Math.round(
                        [featureData.stim_data.avg_page_dwell_time] * 10
                      ) / 10}s`}
                    />
                  </Paper>
                </Grid>
                <Grid style={{marginBottom:16}} item xs={12} >
                  <Paper variant="outlined" className={classes.paper}>
                    <ImportantInfo
                      title="Panelist stim viewed percent"
                      popupArray={[ "The percentage of participants that viewed the stim", "Number of viewable script executed impressions \u00F7 number of total participants"]}
                      figure={`${Math.round(
                        [featureData.stim_data.panelist_viewed_percent] * 10
                      ) / 10}%`}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} >
                  <Paper variant="outlined" className={classes.paper}>
                    <ImportantInfo
                      title="Average stim dwell time"
                      popupArray={[ "Average time participants viewed this stim", "Total dwell stim time (s) \u00F7 number of viewed impressions"]}
                      figure={`${Math.round(
                        [featureData.stim_data.avg_stim_dwell_time] * 10
                      ) / 10}s`}
                    />
                  </Paper>
                </Grid>
                </Grid>
</>
                : <><Grid item xs={12} md={4}>
                <Paper variant="outlined" className={classes.paper}>
                  <ImportantInfo
                    title="Average page dwell time"
                    popupArray={[ "Average amount of time respondents spend on the page", "Total Page dwell time (s) \u00F7 number of valid impressions"]}
                    figure={`${Math.round(
                      [featureData.stim_data.avg_page_dwell_time] * 10
                    ) / 10}s`}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper variant="outlined" className={classes.paper}>
                  <ImportantInfo
                    title="Panelist stim viewed percent"
                    popupArray={[ "The percentage of participants that viewed the stim", "Number of viewable script executed impressions \u00F7 number of total participants"]}
                    figure={`${Math.round(
                      [featureData.stim_data.panelist_viewed_percent] * 10
                    ) / 10}%`}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper variant="outlined" className={classes.paper}>
                  <ImportantInfo
                    title="Average stim dwell time"
                    popupArray={[ "Average time particpants viewed this stim", "Total dwell stim time (s) \u00F7 number of viewed impressions"]}
                    figure={`${Math.round(
                      [featureData.stim_data.avg_stim_dwell_time] * 10
                    ) / 10}s`}
                  />
                </Paper>
              </Grid></>

}
                <Grid style={{order: isBillboard ? "-1": "unset"}} item xs={12} md={isBillboard ? 12: 5}>
        

                 
<Paper variant="outlined" className={classes.paper}>

                    <Grid
                      style={{ flexBasis: "unset" }}
                      container
                      wrap="nowrap"
                      item
                      xs={12}
                    >
                      <Grid container wrap="nowrap">
                        <Grid container wrap="nowrap" item xs={12}>
                          <Typography  variant="h6">
                            Visuals
                          </Typography>
                        </Grid>
                        {featureData.stim_type === "video" ? "" :
                        <MoreMenu 
                        initialTitle={unsortedFilter || "Stim Source" }
                          onSelectItem={(filter) => this.props.setFeatureAnalysisFilter(filter, this.props.index)}
                          options={this.state.media.map(
                            (option) => option.name
                          )}
                        />}
                      </Grid>
                    </Grid>
                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                      gutterBottom
                    >
                      {filter === "stim_source" ? "Here is your your stim" : filter === "view_order_source" ? "Here is the visual representation of the view order" : "Here is the visual representation of the feature analysis"}
                    </Typography>
            

                    <div
                      style={{
                        display: stimLoaded ? "block" : "none",
                        margin: "16px auto",
                     
                      }}
                    >
                      {imageOrVideo}
                    </div>
                    <div
                      className="full-width"
                      style={{ justifyContent: "center", display: !stimLoaded ? "flex" : "none" }}
                    >
                       {this.state.timedOut ? <img style={{maxWidth:"100%", maxHeight: "300px"}} alt="error loading" src={ErrorImage}/>:     <LoadingSpinner style={{padding:"0px", margin:"80px auto"}} />}
                    </div>
          </Paper>
  
</Grid>

<Grid  item xs={12} md={7}>
                <Paper  variant="outlined" className={classes.paper}>
                    <Grid style={{ flexBasis: "unset" }}>
                      <Grid container wrap="nowrap">
                        <Grid container wrap="nowrap" item>
                          <Typography  variant="h6">
                            Feature attention metrics
                          </Typography>
                          <MoreInfo outerContainerClassName = "cacheSetup" popupArray = {[ "Percent panelist viewed: Number of viewed impressions \u00F7 number of viewable impressions", "Avg feature dwell: Total feature dwell time (s) \u00F7 number of viewed impressions"]}  />
                        </Grid>

                        {/* <MoreMenu /> */}
                      </Grid>
                      <Typography
                        variant="subtitle1"
                        color="textSecondary"
                        gutterBottom
                      >
                        This table shows average dwell time and percentage viewed of all your key areas of interest
                      </Typography>
                    </Grid>
                    <Table
                      featureData={featureData}
                    />
                  </Paper>
</Grid>
      </Grid>
      </Container>
      </Paper>
          </div>
      </ThemeProvider>
      </div >

    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchFeatureData: (studyId, cellId, stepId, stimId) => (dispatch(featureAnalysisOperations.fetchFeatureData(studyId, cellId, stepId, stimId))),
    setFeatureAnalysisFilter: (filter, index) => dispatch(featureAnalysisOperations.setFeatureAnalysisFilter(filter, index)),
    fetchKpiGraphData: (cellId, stepId, studyId, stimId) => (dispatch(kpiOperations.fetchKpiGraphData(cellId, stepId, studyId, stimId))),
    fetchFeatureRawData: (cellId, stepId, studyId, stimId) => (dispatch(kpiOperations.fetchFeatureRawData(cellId, stepId, studyId, stimId))),
    downloadFeatureReport: (index, download) => (dispatch(featureAnalysisOperations.fetchFeatureRawData(index, download))),
    downloadParticipantData: (index, download) => (dispatch(kpiOperations.getParticipantReportData(index, download))),

  };
};

const sortFilter = (filter) => {
  switch (filter) {
    case 'Feature Analysis':
      return filter = 'feature_analysis_source';
    case 'View Order':
      return filter = 'view_order_source';
    case 'Stim Source':
      return filter = 'stim_source';
    default:
      return filter = 'stim_source';
  }
};


const onBillboard = (data) => {
  const height = data.stim_height;
  const width = data.stim_width;


  if (width /height > 2){
    return true
    // wide
  }
  else if (width /height < 0.5){
    return false
    // tall
  }
  else{
    return false
    // square
  }
}


const mapStateToProps = (state) => {

  return {
    featureData: state.featureData.featureData,
    loading: state.featureData.loading,
    // filter: sortFilter(state.featureData.filter),
    unsortedFilter: state.featureData.filter,
    graphData: state.kpiReducer.graphData,
    graphLoading: state.kpiReducer.graphLoading,
    featureRawData: state.featureData.rawFeatureData,
    participantReportData: state.kpiReducer.participantReportData,
    participantReportLoading: state.kpiReducer.participantReportLoading



  };
};
FeatureAnalysis.propTypes = {
  fetchFeatureData: PropTypes.func,
  loading: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      cellId: PropTypes.string,
      stepId: PropTypes.string,
      studyId: PropTypes.string,
      stimId: PropTypes.string
    }),
  }),

};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(FeatureAnalysis))
);




