import React, { useState } from 'react';
import { number, object, string, mixed, array } from 'yup';
import propTypes from 'prop-types';

import Review from '../ReviewPage/Review';
import { FormStepper } from '../FormStepper';
import { BasicDetails } from '../Steps/BasicDetails';
import ExternalPages from '../Steps/ExternalPages';
import LumenPanelData from '../Steps/LumenPanelData';

export default function LumenOnlyStepper({
  onAddNewStudySubmit,
  environmentData,
  locationTypes,
  languageTypes,
  studyTypes,
  mediaData,
  respondentTypes,
  panelistRolesTypes,
  panelTypes,
  defaultValues,
  basicDetailsValidationSchema,
  history,
}) {
  const [stepImperatively, setStepImperatively] = useState(null);

  return (
    <FormStepper
      stepImperatively={stepImperatively}
      setStepImperatively={setStepImperatively}
      initialValues={{
        ...defaultValues,
        media_id: mediaData.data.find((media) => media.code === 4),
        quick_step_list: [{type: 'external', url: '' }],
        quick_task_data: {
          panelist_role_id: panelistRolesTypes.data.find((role) => role.id === 1),
          panel_id: panelTypes.data.find((panel) => panel.id === 1),
          short_description: '',
          reward: '',
        },
        study_type_id: 4,
      }}
      onSubmit={async (values) => {
        await onAddNewStudySubmit(values, history);
      }}
    >
      <BasicDetails
        title='Tell us about your study'
        label='Basic Details'
        validationSchema={basicDetailsValidationSchema}
        {...{
          environmentData,
          locationTypes,
          languageTypes,
          studyTypes,
          mediaData,
          respondentTypes,
          includeStudyType: false,
          includeMediaType: true,
        }}
      />
      <ExternalPages
        title='Lets assign a quick step list'
        label='External Pages'
        validationSchema={object().shape({
          quick_step_list: array(
            object({
              url: string()
                .url('Must be a valid URL')
                .max(512, 'Too Long!')
                .required('Required'),
            })
          ),
        })}
      />
      <LumenPanelData
        title={`Let's assign Lumen's panel`}
        label='Lumen Panel Data'
        validationSchema={object().shape({
          quick_task_data:
            object({
              panelist_role_id: mixed()
                .required('Required'),
              panel_id: mixed()
                .required('Required'),
              short_description: string()
                .required('Required')
                .max(512, 'Too Long!'),
              reward: number()
                .typeError('Must be a number')
                .required('Required')
            })
        })}
        {...{panelistRolesTypes, panelTypes}}
      />
      <Review
        title='Your project is ready for review'
        label='Review'
        setStepImperatively={setStepImperatively}
        labels={[
          'Basic Details',
          'External Pages',
          'Lumen Panel Data'
        ]}
      />
    </FormStepper>
  );
}

LumenOnlyStepper.propTypes = {
  onAddNewStudySubmit: propTypes.func,
  languageTypes: propTypes.object,
  environmentData: propTypes.object,
  locationTypes: propTypes.object,
  studyTypes: propTypes.object,
  mediaData: propTypes.object,
  panelistRolesTypes: propTypes.object,
  panelTypes: propTypes.object,
  respondentTypes: propTypes.object,
  defaultValues: propTypes.object,
  basicDetailsValidationSchema: propTypes.object,
  history: propTypes.object,
};
