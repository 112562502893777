
export const FETCH_STIM_BEGIN = 'FETCH_STIM_BEGIN';
export const FETCH_STIM_SUCCESS = 'FETCH_STIM_SUCCESS';
export const FETCH_STIM_ERROR = 'FETCH_STIM_ERROR';
export const ADD_COMPARE_STUDY = 'ADD_COMPARE_STUDY';
export const REMOVE_COMPARE_STUDY = 'REMOVE_COMPARE_STUDY';
export const UNSELECT_STUDY = 'UNSELECT_STUDY';
export const AUTHENTICATED = 'AUTHENTICATED_USER';
export const UNAUTHENTICATED = 'UNAUTHENITCATED_USER';
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';
export const FETCH_STUDIES_BEGIN   = 'FETCH_STUDIES_BEGIN';
export const FETCH_STUDIES_SUCCESS = 'FETCH_STUDIES_SUCCESS';
export const FETCH_TOTAL_INFO_SUCCESS = 'FETCH_TOTAL_INFO_SUCCESS';
export const FETCH_STUDIES_ERROR = 'FETCH_STUDIES_ERROR';
export const FETCH_USER_DATA_BEGIN= 'FETCH_USER_DATA_BEGIN';
export const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS';
export const FETCH_USER_DATA_ERROR = 'FETCH_USER_DATA_ERROR';

export const FETCH_FEATURE_BEGIN   = 'FETCH_FEATURE_BEGIN';
export const FETCH_FEATURE_SUCCESS = 'FETCH_FEATURE_SUCCESS';
export const FETCH_FEATURE_ERROR = 'FETCH_FEATURE_ERROR';
export const SORT_BY_ASCENDING_ORDER = 'SORT_BY_ASCENDING_ORDER';
export const SORT_BY_DECENDING_ORDER = 'SORT_BY_DECENDING_ORDER';

export const CLEAR_CHECKED_STUDIES = 'CLEAR_CHECKED_STUDIES';

// const URL = 'https://api.dev.lumen-research.com/v1.0';


// Please use these credentials: {
//     "username": "ExternalDashboard.User1",
//     "password": "HXNuh7glI741"
// }


