import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { kpiOperations } from "../duck/index";
import PropTypes from "prop-types";
import LoadingSpinner from "../../common/components/LoadingSpinner";
import AttentionCurve from "../Components/AttentionCurve";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import clsx from "clsx";
import ImportantInfo from "../Components/ImportantInfo";
import { Typography } from "@material-ui/core";
import DownLoadButton from "../Components/DownloadButton";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { theme } from "../../CreateStudyPage/MaterialStyling";
// import MoreInfo from "../../CreateStudyPage/MaterialComponents/MoreInfo";
import NewAttentionFunnel from "../Components/NewAttentionFunnel";
import MoreMenu from "../Components/MoreMenu";
import ErrorImage from "../../../assets/images/ErrorImage.png";
import MoreInfoSwitch from "../../AttentionReport/components/GeneralComponents/MoreInfoSwitch";
import differentGraphTypes from "../../../assets/images/differentGraphTypes.png";

const styles = {
  root: {
    display: "flex",
  },
  appBarSpacer: {
    marginBottom: 24,
  },
  content: {
    flexGrow: 1,
    overflow: "auto",
  },
  container: {
    paddingTop: 16,
    paddingBottom: 32,
  },
  paper: {
    padding: 16,
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  largeFixedHeight: {
    height: 420,
    width: "100%",
    marginBottom: "18px",
  },
  infoOverallContainer:{
    [theme.breakpoints.up("sm")]: {
      display:"flex",
      flexGrow:"1",
      flexDirection: "column",
      gap:"16px",
      padding: "8px 12px"
    },
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "12px 12px",
      flexGrow: "0",
      maxWidth: "33.333333%",
      flexBasis: "33.333333%",
      flexDirection: "column",
      alignSelf:"flex-start"
    },
  },
  infoSecondaryContainer:{
    flexBasis:"100%"
  }
};

class KpiBillboardStructure extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      media: [
        { name: "Heatmap" },
        { name: "Stim Source" },
        { name: "Stim Analysis" },
      ],
      norms: [
        "Brand Norm",
        "Brand Category Norm",
        "Base Norm",
        "Ad Category Norm",
      ],

      stimLoaded: false,
      timedOut:false,
      showSurvivalCurvesInfo:false
    };
  }

  componentDidMount() {
    this.setState({ timedOut: false, })
  }

  componentDidUpdate(prevProps) {
    const { filter, index} = this.props;
    if (filter[index] !== prevProps.filter[index]) {
      this.setState({ stimLoaded: false, timedOut: false, });
    }
    setTimeout(() => {
      if (!this.state.stimLoaded) {
        this.setState({timedOut: true})
      }
    }, 3000);

  }

  

  render() {
    const {
      graphLoading,
      setKpiFilter,
      setNewGraph,
      downloadStimReport,
      downloadParticipantData,
      fetchNewNormData,
      newNormLoading
     
    } = this.props;
    let graphData = this.props.graphData[this.props.index]
    let filter = this.props.filter[this.props.index]
    let kpiData = this.props.kpiData[this.props.index]
    let unSortedfilter = this.props.unSortedfilter[this.props.index]
    const { stimLoaded } = this.state;
    const { classes } = this.props;
    const largeFixedHeightPaper = clsx(classes.paper, classes.largeFixedHeight);



    const imageOrVideo =
      kpiData.stim_type === "video" ? (
        <video
          style={{
            padding: 0,
            boxShadow:
              "0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%)",
          }}
          onLoadStart={() => this.setState({ stimLoaded: true })}
          width="100%"
          height="100%"
          id="bgvID"
          controls
        >
          <source src={kpiData.stim_source} type="video/mp4" />
        </video>
      ) : (
        <img
          onLoad={() => this.setState({ stimLoaded: true })}
          alt="lumen dashboard"
          src={kpiData[filter]}
        />
      );
    return (
      <div className={classes.root}>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <div className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Paper style={{margin: 6, marginBottom: 40, paddingTop:8}} elevation={4}>
            <Container maxWidth="xl" className={classes.container}>
              <Grid container spacing={3}>
                <Grid
                  alignContent="flex-end"
                  container
                  wrap="nowrap"
                  justify="space-between"
                  item
                  sm={12}
                >
                  <Grid item sm={12}>
                    <Typography style={{overflowWrap: "anywhere"}} color="textSecondary" variant="h4">
                      {kpiData.stim_name} :{" "}
                      {kpiData.stim_type.charAt(0).toUpperCase() +
                        kpiData.stim_type.slice(1)}
                    </Typography>
                  </Grid>
                  <DownLoadButton
                    options={[
                      "Download Stim Data",
                      "Download Participant Data",
                    ]}
                    downloadStimReport={() =>
                      downloadStimReport(
                        this.props.index,
                        "DOWNLOAD"
                      )
                    }
                    downloadParticipantData={() =>
                      downloadParticipantData(
                        this.props.index,
                        "DOWNLOAD"
                      )
                    }
                  />
                </Grid>

                <Grid item sm={12}>
                  <Paper variant="outlined"  className={classes.paper}>
                    <Grid
                      style={{ flexBasis: "unset" }}
                      container
                      wrap="nowrap"
                      item
                      sm={12}
                    >
                      <Grid container wrap="nowrap">
                        <Grid container wrap="nowrap" item sm={12}>
                          <Typography variant="h6">
                            Visuals
                          </Typography>
                        </Grid>
                        {kpiData.stim_type === "video" ? (
                          ""
                        ) : (
                          <MoreMenu
                           initialTitle={unSortedfilter }
                           onSelectItem={(filter) => setKpiFilter(filter, this.props.index)}
                            options={this.state.media.map(
                              (option) => option.name
                            )}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Typography
                        variant="subtitle1"
                        color="textSecondary"
                        gutterBottom
                      >{filter === "stim_source" ? "Here is your stim" : filter === "analysis_source" ? "Here is the visual representation of the stim analysis" : "Here is the visual representation of the data"}
        
                      </Typography>
                    <div
                      style={{
                        display: stimLoaded ? "block" : "none",
                        margin: "16px auto",
                      }}
                    >
                      {imageOrVideo}
                    </div>
                    <div
                      className="full-width"
                      style={{ justifyContent: "center", display: !stimLoaded ? "flex" : "none" }}
                    >   {this.state.timedOut ? <img style={{maxWidth:"100%", maxHeight:"260px"}} alt="error" src={ErrorImage}/>:     <LoadingSpinner style={{padding:"0px", margin:"80px auto"}} />}
                 
                    </div>
                  </Paper>
                </Grid>




                <div className={classes.infoOverallContainer}>
                  <div className={classes.infoSecondaryContainer}>
                    <Paper
               variant="outlined" 
                      className={classes.paper}
                    >
                      <ImportantInfo 
                        title="Average page dwell time"
                        popupArray={[ "Average amount of time respondents spend on the page", "Total Page dwell time (s) \u00F7 number of valid impressions"]}
                        figure={`${Math.round(
                          [kpiData.stim_data.avg_page_dwell_time] * 10
                        ) / 10}s`}
                        evaluation={`${
                          this.state.norms[kpiData.current_norm_type - 1]
                        }: ${Math.round(
                          [kpiData.norm_data.avg_page_dwell_time] * 10
                        ) / 10}s`}
                        color={
                          kpiData.stim_data.avg_page_dwell_time >=
                          kpiData.norm_data.avg_page_dwell_time
                            ? "green"
                            : "red"
                        }
                      />
                    </Paper>
                  </div>
                  <div className={classes.infoSecondaryContainer}>
                    <Paper
                 variant="outlined" 
                      className={classes.paper}
                    >
                      <ImportantInfo
                        title="Panelist viewable script percent"
                        popupArray={[ "Percent of people where the viewable script executed", "Number of viewable script executed impressions \u00F7 number of total participants"]}
                        figure={`${Math.round([
                          kpiData.stim_data.panelist_viewable_script_percent,
                        ])}%`}
                        evaluation={`${
                          this.state.norms[kpiData.current_norm_type - 1]
                        }: ${Math.round([
                          kpiData.norm_data.panelist_viewable_script_percent,
                        ])}%`}
                        color={
                          Math.round([kpiData.stim_data.panelist_viewable_script_percent]) >=
                            Math.round([kpiData.norm_data.panelist_viewable_script_percent])
                            ? "green"
                            : "red"
                        }
                      />
                    </Paper>
                  </div>
                  <div className={classes.infoSecondaryContainer}>
                    <Paper
             variant="outlined" 
                      className={classes.paper}
                    >
                      <ImportantInfo
                        title="Average time to first view"
                        popupArray={["Average time respondents were browsing before they viewed your stim", "Total stim dwell time \u00F7 number of stim valid impressions "]}
                        figure={`${Math.round(
                          [kpiData.stim_data.avg_time_to_first_view] * 10
                        ) / 10}s`}
                      />
                    </Paper>
                  </div>
                </div>





                <Grid item sm={12} lg={12} xl={8}>
                  <Paper variant="outlined"  className={largeFixedHeightPaper}>
                    <Grid style={{ flexBasis: "unset" }}>
                      <Grid container wrap="nowrap">
                        <Grid container wrap="nowrap" item>
                          <Typography variant="h6">Attention funnel</Typography>
                        </Grid>
                        <MoreMenu
                          initialTitle={
                            this.state.norms[kpiData.current_norm_type - 1]
                          }
                          onSelectItem={(newNorm) => fetchNewNormData(newNorm, this.props.index)}
                          options={Object.values(kpiData.norm_types)}
                        />
                      </Grid>
                      <Typography
                        variant="subtitle1"
                        color="textSecondary"
                        gutterBottom
                      >
                       How your key statistics interact to predict attention per 1000 impressions
                      </Typography>
                    </Grid>
                    {newNormLoading ? <LoadingSpinner style={{padding:"0px", margin:"80px auto"}} />: 
                  
                    <NewAttentionFunnel kpiData={kpiData} />}
                  </Paper>

                  <Paper variant="outlined"  className={largeFixedHeightPaper}>
                    <Grid style={{ flexBasis: "unset" }}>
                      <Grid container wrap="nowrap">
                        <Grid container wrap="nowrap" item>
                          <Typography variant="h6">Survival curves</Typography>
                          <MoreInfoSwitch toggle={(toggleValue) => this.setState({showSurvivalCurvesInfo: toggleValue})}  />
                          {/* <MoreInfo outerContainerClassName = "alignToTop" popupArray = {[ "Dwell time: Number of participants with page dwell >0, viewable time >=viewability threshold, dwell time > 10th/sec, by cell and stim / Viewable impressions.", "Average page dwell time: Number of participants with time on page >= 1/10th sec, by cell and stim \u00F7 Total number of Viewable impressions", "Viewable time: Number of participants with viewable time >=viewability threshold by 1/10th sec, by cell and stim \u00F7 Total number of Viewable impressions", ]}  /> */}
                        </Grid>

                        <MoreMenu
                          initialTitle={graphData.data.data_types[graphData.data.current_graph_type_id]}
                          onSelectItem={(newGraph) => setNewGraph( this.props.index, newGraph)}
                          options={Object.values(graphData.data.data_types)}
                        />
                      </Grid>
                      <Typography
                        variant="subtitle1"
                        color="textSecondary"
                        gutterBottom
                      >
                        {graphData.data.current_graph_type_id ===1 ? "How long your respondents viewed the stim" : graphData.data.current_graph_type_id ===2 ? "How long your respondents viewed the page the stim was on" : "How long the stim was viewable to your respondents as they browsed the page"}
                      </Typography>
                    </Grid>
                    {graphLoading ? <LoadingSpinner style={{padding:"0px", margin:"80px auto"}} />: 
                    
                    this.state.showSurvivalCurvesInfo ? 
                    <div style={{width:"100%", overflow:"auto"}}>
                      <img style={{ maxWidth:"100%", marginTop:"24px"}} src={differentGraphTypes} alt="graph example" />
                    </div>
                    
                    :
                    <AttentionCurve
                      stimName={kpiData.stim_name}
                      loading={graphLoading}
                      graphData={graphData}
                    />}
                  </Paper>
                </Grid>


              </Grid>
            </Container>
            </Paper>
           
          </div>
        </ThemeProvider>
      </div>
    );
  }
}


const sortFilter = (filters) => {
  return filters.map(filter => {
    switch (filter) {
      case "Heatmap":
        return (filter = "heatmap_source");
      case "Stim Source":
        return (filter = "stim_source");
      case "Stim Analysis":
        return (filter = "analysis_source");
      default:
        return (filter = "stim_source");
    }
  })
};

const mapDispatchToProps = (dispatch) => {
  return {
    setKpiFilter: (filter, index) => dispatch(kpiOperations.setKpiFilter(filter, index)),
    setNewGraph: ( index, newGraph) => dispatch(kpiOperations.fetchKpiGraphData(index, newGraph)),
    downloadStimReport: (index, download) =>
      dispatch(
        kpiOperations.fetchKpiRawData(index, download)
      ),
    downloadParticipantData: (index, download) =>
      dispatch(
        kpiOperations.getParticipantReportData(
          index,
          download
        )
      ),
    fetchNewNormData: (
      newNorm, index
    ) =>
      dispatch(
        kpiOperations.fetchNewNormData(
          newNorm, index
        )
      ),
  };
};

const mapStateToProps = (state) => {
  return {
    newNormLoading: state.kpiReducer.newNormLoading,
    kpiData: state.kpiReducer.kpiData,
    loading: state.kpiReducer.loading,
    filter: sortFilter(state.kpiReducer.filter),
    unSortedfilter: state.kpiReducer.filter,
    graphData: state.kpiReducer.graphData,
    graphLoading: state.kpiReducer.graphLoading,
    kpiRawData: state.kpiReducer.rawKpiData,
    participantReportData: state.kpiReducer.participantReportData,
    participantReportLoading: state.kpiReducer.participantReportLoading,
  };
};

KpiBillboardStructure.propTypes = {
  loading: PropTypes.bool,
  panelist_total_count: PropTypes.number,
  avg_page_dwell_time: PropTypes.number,
  avg_stim_dwell_time: PropTypes.number,
  panelist_viewable_count: PropTypes.number,
  panelist_viewable_percent: PropTypes.number,
  panelist_viewed_count: PropTypes.number,
  panelist_viewed_percent: PropTypes.number,
  stim_name: PropTypes.string,
  stim_type: PropTypes.string,
  filter: PropTypes.array,
  unSortedfilter: PropTypes.array,
  setKpiFilter: PropTypes.func,
  setNewGraph: PropTypes.func,
  fetchKpiData: PropTypes.func,
  fetchNewNormData: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      cellId: PropTypes.string,
      stepId: PropTypes.string,
      studyId: PropTypes.string,
    }),
  }),
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(KpiBillboardStructure))
);
