import { Grid, Fab, makeStyles, Typography } from '@material-ui/core';
import { Field, FieldArray, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';

import MoreInfo from '../MaterialComponents/MoreInfo';

const quick_step_list = {
  type: 'external',
  url: '',
  insert_parameter: '',
};

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(3, 0),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  iconButton: {
    position: 'relative',
    right: theme.spacing(1),
  },
  webPageURL: {
    marginTop: '8px',
  },
}));

const ExternalPages = () => {
  const classes = useStyles();
  const { values, isSubmitting } = useFormikContext();

  return (
    <FieldArray name='quick_step_list'>
      {({ push, remove }) => (
        <>
          <Grid
            alignContent='flex-end'
            container
            wrap='nowrap'
            justifyContent='space-between'
            item
            xs={12}
          >
            <Grid>
              <Typography variant='h6'>External Pages</Typography>
              <Typography variant='subtitle1'>
                Please enter the study page URL.
              </Typography>
            </Grid>

            <MoreInfo
              outerContainerClassName='cacheSetup'
              popupArray={[
                'Study page URL: Web page you want the panelist to browse.'
              ]}
            />
          </Grid>
          {values.quick_step_list.map((_, index) => (
            <Grid
              key={index}
              item
              spacing={2}
              container
              justifyContent='flex-end'
            >
              <Grid item className={classes.webPageURL} xs={1}>
                {index === 0 ? '' : (
                  <IconButton
                    className={classes.iconButton}
                    disabled={isSubmitting}
                    onClick={() => remove(index)}
                  >
                    <HighlightOffIcon color='primary' />
                  </IconButton>
                )}
              </Grid>
              <Grid
                className={classes.webPageURL}
                container
                wrap='nowrap'
                item
                xs={11}
              >
                <Typography
                  style={{ marginRight: '8px', marginTop:'13px'}}
                  variant='body1'
                >{`${index + 1}.`}</Typography>
                <Field
                  variant='filled'
                  fullWidth
                  name={`quick_step_list.${index}.url`}
                  component={TextField}
                  label='Study page URL'
                />
              </Grid>
            </Grid>
          ))}
          <Grid
            className={classes.fab}
            container
            justifyContent='center'
            item
            xs={12}
          >
            <Fab
              variant='extended'
              size='medium'
              color='primary'
              aria-label='add'
              disabled={isSubmitting}
              onClick={() => push(quick_step_list)}
              type='button'
            >
              <AddIcon className={classes.extendedIcon} />
              Add Another Page
            </Fab>
          </Grid>
        </>
      )}
    </FieldArray>
  );
};

export default ExternalPages;
