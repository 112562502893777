import React, {useState} from 'react';
import { useFormikContext } from "formik";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import AdvancedSettings from './AdvancedSettings';
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from '@material-ui/core/Tooltip';


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  left_aligned_button: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
  root: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    gap: "16px",
    flexDirection: "column",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    boxShadow: "unset",
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  buttonMargin: {
    marginRight: theme.spacing(2),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const classes = useStyles();
  const { values, setValues } = useFormikContext();
  const [open, setOpen] = React.useState(false);
  const [InitialData, setInitialData] = useState()

  const handleClickOpen = () => {
    setOpen(true);
    setInitialData(values)
  };

  const handleCancel = () => {
    setOpen(false);
    setValues(InitialData)
  };

  const handleSave = () => {
    setOpen(false);
  };

  return (
    <div>
      {props.icon ? 
         <Tooltip title="Edit" placement="left">
           <IconButton
           className={classes.buttonMargin}
           onClick={handleClickOpen}
           onFocus={(event) => event.stopPropagation()}
         >
           <EditIcon fontSize="small" />
         </IconButton></Tooltip> : 
      <Button className={classes.left_aligned_button} variant="text" onClick={handleClickOpen}>
        Advanced Settings
      </Button> 
       
       }
   
      <Dialog fullScreen open={open} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleCancel} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Advanced Settings
            </Typography>
            <Button autoFocus color="inherit" onClick={handleSave}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <Box className={classes.layout}>
            <Paper className={classes.root}>
     <AdvancedSettings/>
     </Paper>
     </Box>
      </Dialog>
    </div>
  );
}