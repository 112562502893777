import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import QuestionAndAnswersGraph from "./Components/QuestionAndAnswersGraph";
import { Divider, Button } from "@material-ui/core";
import { questionAnswerOperations } from "./duck/index";
import QuestionsMenu from "./Components/QuestionsMenu";
// import ErrorImage from "../../assets/images/ErrorImage.png";
import NoDataImage from "../../assets/images/NoDataImage.png";
import LoadingSpinner from "../common/components/LoadingSpinner";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Checkbox from '@mui/material/Checkbox';

function QuestionAndAnswers(props) {

  const [limit_to_eyetracking, setlimit_to_eyetracking] = React.useState(true)

  const handleSetLimitEyeTrackingChange = (event) => {
    setlimit_to_eyetracking(event.target.checked);
  };

  return (
    <Paper elevation={2} style={{ width: 720 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 24,
          width: "100%",
        }}
      >
        <div style={{ padding: "16px 24px" }}>
          <Typography variant="h6" style={{ display: "block", fontSize: 18 }}>
            Question responses
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
            style={{ display: "block", fontSize: 14, whiteSpace: "nowrap"}}
          >
            Choose a question to view your results
          </Typography>
        </div> 
        <div>
          <QuestionsMenu limit_to_eyetracking={limit_to_eyetracking} />
          <div style={{ display: "flex", alignItems: "center", whiteSpace: "nowrap", left: 10, position: "relative" }}>
            <Typography
              color="textSecondary"
              variant="body2"
              style={{ display: "block", fontSize: 14 }}
            >
              Limit to eyetracking
            </Typography>
         
            <Checkbox
            size="small"
                checked={limit_to_eyetracking}
                onChange={handleSetLimitEyeTrackingChange}
                />
          </div>
        </div>
      </div>
      <Divider />
      {props.questionAnswerResultsLoading ? (
        <LoadingSpinner
          style={{ paddingBottom: "20vh", margin: "16vh auto" }}
        />
      ) : props.questionAnswerResultsError ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            alt="No data available"
            style={{ width: "100%", padding: "0px 64px" }}
            src={NoDataImage}
          />
        </div>
      ) : !Array.isArray(props.questionAnswerResults.answer_data) ? (
        <img
          alt="No data available"
          style={{ width: "100%", padding: "0px 64px" }}
          src={NoDataImage}
        />
      ) : !props.questionAnswerResults.answer_data.filter((item) => {
          return item.count > 0;
        }).length > 0 ? (
        <img
          alt="No data available"
          style={{ width: "100%", padding: "0px 64px" }}
          src={NoDataImage}
        />
      ) : (
        <>
          <div
            style={{
              width: "fit-content",
              padding: "0px 24px",
              margin: "24px auto 0px"
            }}
          >
            <Typography
              variant="h6"
              style={{ display: "block", fontSize: 20, color: "#3b3e66" }}
            >
              {props.currentQuestionSelected.name}
            </Typography>
            <Typography  variant="caption"color="textSecondary" >{props.currentQuestionSelected.formatted_type}</Typography>
          </div>

          <div style={{ padding: 24 }}>
            <QuestionAndAnswersGraph
              totalUserCount = {props.questionAnswerResults.total_user_count}
              questionAnswerResults={props.questionAnswerResults.answer_data}
              currentQuestionSelected={props.currentQuestionSelected}
            />
          </div>
          <Divider />
          <div
            style={{
              width: "100%",
              background: "#f4f5fd",
              display: "flex",
              justifyContent: "center",
              borderRadius: 16
            }}
          >
            <Snackbar
              open={props.getParticipantResultsError}
              autoHideDuration={6000}
              onClose={props.getParticipantsResultsBegin}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert
                variant="filled"
                onClose={props.getParticipantsResultsBegin}
                severity="error"
              >
                Error downloading data
              </Alert>
            </Snackbar>
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "24px auto" }}
              onClick={() =>
                props.downloadParticipantResults(
                  props.isolationData.data.study_id,
                  props.currentQuestionSelected.question_system,
                  props.questionAnswerResults.question_id,
                  limit_to_eyetracking
                )
              }
            >
              Download participant results
            </Button>
          </div>
        </>
      )}
    </Paper>
  );
}

const mapStateToProps = (state) => {
  return {
    isolationData: state.isolationData,
    questionAnswerOverview: state.questionAnswerData.questionAnswerOverview,
    currentQuestionSelected: state.questionAnswerData.currentQuestionSelected,
    questionAnswerResults: state.questionAnswerData.questionAnswerResults,
    questionAnswerResultsError:
      state.questionAnswerData.questionAnswerResultsError,
    questionAnswerResultsLoading:
      state.questionAnswerData.questionAnswerResultsLoading,
    getParticipantResultsError:
      state.questionAnswerData.getParticipantResultsError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    downloadParticipantResults: (studyID, questionType, questionID, limit_to_eyetracking) =>
      dispatch(
        questionAnswerOperations.downloadParticipantResults(
          studyID,
          questionType,
          questionID,
          limit_to_eyetracking
        )
      ),
    getParticipantsResultsBegin: () =>
      dispatch(questionAnswerOperations.getParticipantsResultsBegin()),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(QuestionAndAnswers)
);
