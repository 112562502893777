import React from 'react';
import {
  Typography,
  Card,
  CardContent,
  CardActionArea,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FlagIcon } from 'react-flag-kit';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import SmartphoneRoundedIcon from '@material-ui/icons/SmartphoneRounded';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import TheatersOutlinedIcon from '@material-ui/icons/TheatersOutlined';
import CodeIcon from '@material-ui/icons/Code';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faNewspaper, faHashtag, faDigitalTachograph } from '@fortawesome/free-solid-svg-icons';
import logo from '../../../assets/images/lumen-logo.svg';
import { faSnapchat } from '@fortawesome/free-brands-svg-icons';
import propTypes from 'prop-types';
import Devices from '@mui/icons-material/Devices';

const useStyles = makeStyles(() => ({
  facebookIcon: {
    color: '#3B5998',
  },
  linkedInIcon: {
    color: '#0e76a8',
  },
  twitterIcon: {
    color: '#1DA1F2',
  },
  instagramIcon: {
    color: '#833AB4',
  },
  snapChatIcon: {
    color: '#F7F400',
    fontSize: '2.1875rem !important',
    padding:2
  },
  youTubeIcon: {
    color: '#FF0000',
  },
  generalIcon: {
    color: '#00badb',
  },
  logoGrayed: {
    webkitFilter: 'grayscale(80%)',
    mozFilter: 'grayscale(80%)',
    oFilter: 'grayscale(80%)',
    msFilter: 'grayscale(80%)',
    filter: 'grayscale(80%)',
    opacity: '0.7',
    boxSizing: 'border-box',
    height: 35,
    padding: '14px 0px 6px',
  },
  logoColor:{
    color: '#00badb',
    boxSizing: 'border-box',
    height: 35,
    padding: '14px 0px 6px',
  },
  snapChatGreyed: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '2.1875rem !important',
    padding:2
  },
  greyed: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  flagGrayed: {
    webkitFilter: 'grayscale(80%)',
    mozFilter: 'grayscale(80%)',
    oFilter: 'grayscale(80%)',
    msFilter: 'grayscale(80%)',
    filter: 'grayscale(80%)',
    opacity: '0.7',
  },
  container: {
    display: 'flex'
  },
  Content: {
    display: 'grid',
    justifyItems: 'center',
    gap: '8px',
    minWidth: '64px',
    padding: '10px 15px',
    verticalAlign: 'middle',
    width: '100%',
  },
  Card: {
    padding: '0px',
    minWidth: '124px',
    minHeight: '104px'
  },
  CardActionArea: {
    height: '100%',
  }

}));

export default function CardButton({
  color,
  iconName,
  onClick,
  label,
}) {
  const classes = useStyles();

  const selectIcon = () => {
    switch (iconName) {
      case 'ourSurvey':
        return <img src={logo} alt='logo' className={color ? classes.logoColor : classes.logoGrayed} />;
      case 'yourSurvey':
        return <AssignmentIndOutlinedIcon fontSize='large' className={color ? classes.generalIcon : classes.greyed} />;
      case 'US':
        return <FlagIcon code='US' size={50} className={color ? '' : classes.flagGrayed} />;
      case 'UK':
        return <FlagIcon code='GB' size={50} className={color ? '' : classes.flagGrayed} />;
      case 'japan':
        return <FlagIcon code='JP' size={50} className={color ? '' : classes.flagGrayed} />;
      case 'france':
        return <FlagIcon code='FR' size={50} className={color ? '' : classes.flagGrayed} />;
      case 'fb':
        return <FacebookIcon fontSize='large' className={color ? classes.facebookIcon : classes.greyed} />;
      case 'tw':
        return <TwitterIcon fontSize='large' className={color ? classes.twitterIcon : classes.greyed} />;
      case 'yt':
        return <YouTubeIcon fontSize='large' className={color ? classes.youTubeIcon : classes.greyed} />;
      case 'li':
        return <LinkedInIcon fontSize='large' className={color ? classes.linkedInIcon : classes.greyed} />;
      case 'ig':
        return <InstagramIcon fontSize='large' className={color ? classes.instagramIcon : classes.greyed} />;
      case 'sc':
        return <FontAwesomeIcon className={color ? classes.snapChatIcon : classes.snapChatGreyed} icon={faSnapchat} />;
      case 'digital':
        return <FontAwesomeIcon className={color ? classes.generalIcon : classes.greyed} icon={faDigitalTachograph} size='lg' />;
      case 'social':
        return <FontAwesomeIcon className={color ? classes.generalIcon : classes.greyed} icon={faHashtag} size='lg' />;
      case 'print':
        return <FontAwesomeIcon className={color ? classes.generalIcon : classes.greyed} icon={faNewspaper} size='lg' />;
      case 'Mobile':
        return <SmartphoneRoundedIcon fontSize='large' className={color ? classes.generalIcon : classes.greyed} />;
      case 'Webcam':
        return <DesktopWindowsIcon fontSize='large' className={color ? classes.generalIcon : classes.greyed} />;
      case 'Lab':
      case 'All Devices':
        return <Devices fontSize='large' className={color ? classes.generalIcon : classes.greyed} />;
      case 'image':
        return <ImageOutlinedIcon fontSize='large' className={color ? classes.generalIcon : classes.greyed} />;
      case 'video':
        return <TheatersOutlinedIcon fontSize='large' className={color ? classes.generalIcon : classes.greyed} />;
      case 'skin':
        return <CodeIcon fontSize='large' className={color ? classes.generalIcon : classes.greyed} />;

      default:
        return <FontAwesomeIcon className={color ? classes.generalIcon : classes.greyed} icon={faCoffee} size='lg' />;
    }
  };

  return (
    <span className={classes.container}>
      <Card
        className={classes.Card}
        aria-labelledby='form-dialog-title'
        justify='center'
      >
        <CardActionArea id={iconName} centerRipple onClick={onClick} className={classes.CardActionArea}>
          <CardContent className={classes.Content}>
            {selectIcon()}

            <Typography gutterBottom variant='body2' color='textSecondary'>
              {label === 'Lab' ? 'All Devices' : label}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </span>

  );
}

CardButton.propTypes = {
  color: propTypes.bool,
  iconName: propTypes.string,
  onClick: propTypes.func,
  label: propTypes.string,
};
