import React from 'react';
import PropTypes from 'prop-types';

import SocialMediaStepper from './SocialMediaStepper';
import CreateStudyContainer from '../CreateStudyContainer';

const SocialMediaContainer = ({history}) => {
  return (
    <CreateStudyContainer>
      <SocialMediaStepper {...{history}} />
    </CreateStudyContainer>
  );
};

SocialMediaContainer.propTypes = {
  history: PropTypes.object,
};

export default SocialMediaContainer;
