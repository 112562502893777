import React from 'react';
import Form from './Form';
import { TikTokPost } from '@lumen-developer/lumen-lookalikes/dist/Previews';
import { useFormikContext } from 'formik';
import { Divider, Grid, Typography } from '@mui/material';
import propTypes from 'prop-types';

import styles from '../socials.module.css';

const TikTok = ({ brandTypes }) => {
  const { values } = useFormikContext();
  return (
    <>
      <Divider variant='middle' />
      <Grid
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          margin: '24px 0px 12px',
        }}
        container
        wrap='nowrap'
        justifyContent='center'
        item
        xs={12}
      >
        <Typography align='center' variant='h6'>
          TikTok commercial
        </Typography>
      </Grid>
      <div className={styles.container}>
        <div className={styles.columnCenter}>
          <div className={styles.mobileContainer}>
            <TikTokPost
              tt_profile_src={values.tt_profile_src}
              tt_profile_name={values.tt_profile_name}
              tt_desc={values.tt_desc}
              tt_song_name={values.tt_song_name}
              tt_promo_text={values.tt_promo_text}
              stim_url={values.stim_url}
            />
          </div>
        </div>
        <div className={styles.column}>
          <Form brandTypes={brandTypes} />
        </div>
      </div>
    </>
  );
};

TikTok.propTypes = {
  brandTypes: propTypes.object,
};

export default TikTok;
